import { Layout, Collapse, Button } from "antd";
import { useEffect, useRef, useState } from "react";
import {
  TbLayoutSidebarRightCollapse,
} from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  clearOpportunity,
  loadSingleOpportunity,
} from "../../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllOpportunityStage } from "../../../../redux/rtk/features/crm/opportunityStage/opportunityStageSlice";
import Attachments from "../../CommonUi/Attachments";
import Emails from "../../CommonUi/Emails";
import Notes from "../../CommonUi/Notes";
import Quotes from "../../CommonUi/Quotes";
import Tasks from "../../CommonUi/Tasks";
import DetailsInfo from "./DetailsInfo";
import OpportunityProfile from "./OpportunityProfile";
import StageChanger from "./StageChanger";
import { TEXTS } from "../../../../constants";
import calculatorIcon from "../../../../assets/Icons/calculator.png";
import p2dIcont from "../../../../assets/Icons/2d.png";
import p3dIcont from "../../../../assets/Icons/3d-model.png";
import mailIcon from "../../../../assets/Icons/email.png";
import attachmentIcon from "../../../../assets/Icons/attach-file.png";
import taskIcon from "../../../../assets/Icons/clipboard.png";
import { ProfileImg } from "../../../../assets/Icons/Icons";
import { BiArrowBack } from "react-icons/bi";

const { Panel } = Collapse;

export default function OpportunityDetails() {
  const { OpportunityId: id } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const { opportunity, loading: opportunityLoading } = useSelector(
    (state) => state.opportunity
  );
  const { list: opportunityStage } = useSelector(
    (state) => state.opportunityStage
  );

  useEffect(() => {
    dispatch(loadSingleOpportunity(id));
    dispatch(loadAllOpportunityStage());
    return () => {
      dispatch(clearOpportunity());
    };
  }, [dispatch, id]);

  const location = useLocation();
  const navigate = useNavigate();

  const emailRef = useRef(null);
  const noteRef = useRef(null);
  const attachmentRef = useRef(null);
  const quoteRef = useRef(null);
  const taskRef = useRef(null);


  const cameFromRoom = location.state?.fromRoom;
  const cameFromContact = location.state?.fromContact;

  const handleBackClick = () => {
    if (cameFromRoom) {
      navigate(`/admin/room/${cameFromRoom}`, {
        state: { fromContact: cameFromContact }
      });
    }
  };

  return (
    <>
      {cameFromRoom && (
        <Button
          type="link"
          icon={<BiArrowBack style={{ fontSize: "24px" }} />}
          onClick={handleBackClick}
          className="ml-24 mb-4"
        />
      )}
      <Layout>
        <Layout.Content>
          <div
            className="container overflow-y-auto overflow-x-hidden flex flex-col gap-8"
            style={{ height: "calc(100vh - 114px)" }}
          >
            {collapsed && (
              <div className="p-1 absolute left-0 z-10">
                <TbLayoutSidebarRightCollapse
                  onClick={() => setCollapsed(false)}
                  className="text-3xl inline-block"
                />
              </div>
            )}
            <Collapse accordion defaultActiveKey={["1"]}>
              <StageChanger
                opportunityStage={opportunityStage}
                currentStage={opportunity}
                opportunity={opportunity}
              />
              {/* <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">Contract</span>
                  <img src={contractIcon} />
                </div>
              } key="3">
                <div ref={attachmentRef}>
                  <Attachments
                    data={opportunity}
                    loading={opportunityLoading}
                    name={"opportunityId"}
                    singleLoadThunk={loadSingleOpportunity}
                    attachmentType={2}
                    title="Contract"
                  />
                </div>
              </Panel> */}
              <DetailsInfo data={opportunity} loading={opportunityLoading} />
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">Proiecte2D</span>
                  <img src={p2dIcont} />
                </div>
              } key="4">
                <div ref={attachmentRef}>
                  <Attachments
                    data={opportunity}
                    loading={opportunityLoading}
                    name={"opportunityId"}
                    singleLoadThunk={loadSingleOpportunity}
                    attachmentType={3}
                    title="Proiecte2D"
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">Proiecte3D</span>
                  <img src={p3dIcont} />
                </div>
              } key="5">
                <div ref={attachmentRef}>
                  <Attachments
                    data={opportunity}
                    loading={opportunityLoading}
                    name={"opportunityId"}
                    singleLoadThunk={loadSingleOpportunity}
                    attachmentType={4}
                    title="Proiecte3D"
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">{TEXTS.LABELS.TASKS}</span>
                  <img src={taskIcon} />
                </div>
              } key="6">
                <div ref={taskRef}>
                  <Tasks
                    data={opportunity}
                    loading={opportunityLoading}
                    name={"opportunityId"}
                    singleLoadThunk={loadSingleOpportunity}
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">{TEXTS.LABELS.NOTES}</span>
                  <img src={mailIcon} />
                </div>
              } key="7">
                <div ref={noteRef}>
                  <Notes
                    data={opportunity}
                    loading={opportunityLoading}
                    name={"opportunityId"}
                    singleLoadThunk={loadSingleOpportunity}
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">{TEXTS.LABELS.ATTACHMENTS}</span>
                  <img src={attachmentIcon} />
                </div>
              } key="8">
                <div ref={attachmentRef}>
                  <Attachments
                    data={opportunity}
                    loading={opportunityLoading}
                    name={"opportunityId"}
                    singleLoadThunk={loadSingleOpportunity}
                    attachmentType={1}
                    title={TEXTS.LABELS.ATTACHMENTS}
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">{TEXTS.LABELS.QUOTES}</span>
                  <img src={calculatorIcon} />
                </div>
              } key="9">
                <div ref={quoteRef}>
                  <Quotes
                    data={opportunity}
                    loading={opportunityLoading}
                    name={"opportunityId"}
                    singleLoadThunk={loadSingleOpportunity}
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">Emails</span>
                  <img src={mailIcon} height={24} width={24} />
                </div>
              } key="10">
                <div ref={emailRef}>
                  <Emails
                    data={opportunity}
                    loading={opportunityLoading}
                    name={"opportunityId"}
                    singleLoadThunk={loadSingleOpportunity}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
}
