import React, { useState } from "react";
import { Select, Button } from "antd";
const { Option } = Select;

const PdfView = () => {
    // State to manage the selected language and document type
    const [language, setLanguage] = useState("RO");
    const [docType, setDocType] = useState("presentation"); // presentation or guide

    // Determine the PDF URL based on the selected language and document type
    const getPdfUrl = () => {
        if (docType === "presentation") {
            switch (language) {
                case "RU":
                    return "/Prezentare-2024-RU.pdf";
                case "ENG":
                    return "/Prezentare-2024-ENG.pdf";
                case "RO":
                default:
                    return "/Prezentare-2024.pdf";
            }
        } else if (docType === "guide") {
            switch (language) {
                case "RU":
                    return "/RU- ghid de utilizare.pdf";
                case "ENG":
                    return "/ENG- ghid de utilizare.pdf";
                case "RO":
                default:
                    return "/RO- ghid de utilizare.pdf";
            }
        }
    };

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <div className="flex justify-center">
                {/* Language Selector */}
                <Select
                    value={language}
                    onChange={(value) => setLanguage(value)}
                    style={{ margin: '10px', width: '200px' }}
                >
                    <Option value="RO">RO</Option>
                    <Option value="RU">RU</Option>
                    <Option value="ENG">ENG</Option>
                </Select>

                {/* Buttons to toggle between presentation and guide */}
                <div style={{ margin: '10px' }}>
                    <Button
                        type={docType === "presentation" ? "primary" : "default"}
                        onClick={() => setDocType("presentation")}
                        style={{ marginRight: '10px' }}
                    >
                        Presentation
                    </Button>
                    <Button
                        type={docType === "guide" ? "primary" : "default"}
                        onClick={() => setDocType("guide")}
                    >
                        Guide
                    </Button>
                </div>
            </div>
            {/* PDF Display */}
            <iframe
                src={getPdfUrl()}
                width="100%"
                height="85%"
                style={{ border: 'none' }}
                title="PDF Viewer"
            />
        </div>
    );
}

export default PdfView;
