import { Button, Form, Input, List, Divider, Typography, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
    addSingleRoom,
    updateRoom,
    loadAllRooms,
} from "../../../../redux/rtk/features/crm/room/roomSlice";
import { TEXTS } from "../../../../constants";
import { AiFillDelete } from "react-icons/ai";
import { getAllProductions } from "../../../../redux/rtk/features/crm/production/productionSlice";

const { Title } = Typography;

export default function CreateRoomForm({ onClose, edit }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [roomItems, setRoomItems] = useState(
        Array.isArray(edit?.values.roomItem) ? edit.values.roomItem : []
    );

    useEffect(() => {
        if (edit) {
            form.setFieldsValue({
                roomName: edit.values.roomName,
            });

            if (Array.isArray(edit.values.roomItem)) {
                setRoomItems(
                    edit.values.roomItem.map(item => ({
                        ...item,
                        itemName: item.itemName || "",
                    }))
                );
            } else {
                setRoomItems([]);
            }
        }
    }, [edit, form]);

    const onFinish = async (values) => {
        const roomData = {
            ...values,
            roomItems: roomItems.map(item => ({
                id: item.id,
                itemName: item.itemName,
            })),
        };

        const resp = await dispatch(
            edit?.id ? updateRoom({ id: edit.id, values: roomData }) : addSingleRoom(roomData)
        );

        if (resp.payload.message === "success") {
            dispatch(loadAllRooms());
            if (!edit?.id) {
                onClose();
                form.resetFields();
                setRoomItems([]);
            }
        }
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const addRoomItem = () => {
        setRoomItems(prevRoomItems => [
            ...(Array.isArray(prevRoomItems) ? prevRoomItems : []),
            { itemName: "" },
        ]);
    };

    const handleRoomItemChange = (index, e) => {
        if (Array.isArray(roomItems)) {
            const newRoomItems = [...roomItems];
            newRoomItems[index].itemName = e.target.value;
            setRoomItems(newRoomItems);
        }
    };

    const removeRoomItem = (index) => {
        if (Array.isArray(roomItems)) {
            const newRoomItems = roomItems.filter((_, i) => i !== index);
            setRoomItems(newRoomItems);
        }
    };

    return (
        <div className='flex justify-center mt-5'>
            <Form
                className='w-4/5'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                colon={false}
                layout='vertical'
                initialValues={edit ? { roomName: edit?.values.roomName } : {}}
                form={form}
            >
                <Form.Item
                    style={{ width: "350px" }}
                    label={`${TEXTS.LABELS.LAST_NAME} ${TEXTS.LABELS.FIRST_NAME}`}
                    name='roomName'
                    tooltip={TEXTS.TEXT.REQUIRED_FIELD}
                    rules={[{ required: true, message: TEXTS.TEXT.REQUIRED_FIELD }]}
                >
                    <Input placeholder={TEXTS.LABELS.ROOM_NAME} />
                </Form.Item>
                <Divider />

                <Title level={4}>{TEXTS.LABELS.ROOM_ITEMS}</Title>
                <Button onClick={addRoomItem} type="dashed">
                    {TEXTS.BUTTON_TEXT.ADD_BTN}
                </Button>
                <List
                    dataSource={Array.isArray(roomItems) ? roomItems : []}
                    renderItem={(item, index) => (
                        <List.Item
                            key={item.id || index} // Use item.id if available for key
                            actions={[
                                <Button
                                    type="link"
                                    className='bg-red-700 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded'
                                    onClick={() => removeRoomItem(index)}
                                >
                                    <AiFillDelete className="text-white" />
                                </Button>,
                            ]}
                        >
                            <Input
                                placeholder={TEXTS.LABELS.ITEM_NAME}
                                value={item.itemName}
                                onChange={(e) => handleRoomItemChange(index, e)}
                            />
                        </List.Item>
                    )}
                />

                <Form.Item label=''>
                    <div className='flex items-center gap-2'>
                        <Button size={"large"} htmlType='submit' type='primary'>
                            {edit?.id ? TEXTS.TEXT.UPDATE : TEXTS.TEXT.CREATE}
                        </Button>
                        {!edit?.id && (
                            <Button
                                size={"large"}
                                htmlType='button'
                                type='danger'
                                onClick={onCancel}
                            >
                                {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                            </Button>
                        )}
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}
