import { Button, Card, Table } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { BiEdit, BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
import CreateNoteForm from "../Notes/CreateNoteForm";
import CreateNoteSelective from "../Notes/CreateNoteSelective";
import SingleNote from "../Notes/SingleNote";
import CreateDrawer from "./CreateDrawer";
import UpdateDrawer from "./UpdateDrawer";
import { TEXTS } from "../../../constants";
import "../../../App.css"
import mailIcon from "../../../assets/Icons/email.png"

export default function Notes({ data, loading, name, singleLoadThunk }) {
  // Drawer state
  const [open, setOpen] = useState(false);
  const [singleNote, setSingleNote] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [createAsLoaded, setCreateAsLoaded] = useState(false); // Add state to manage loading

  useEffect(() => {
    // Simulate a loading process
    if (name && data?.id) {
      setCreateAsLoaded(true); // Set to true when data is ready
    }
  }, [name, data]);

  const onCloseEdit = () => {
    setEdit(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const columns = [
    {
      title: `${TEXTS.TEXT.TITLE}`,
      key: "title",
      width: 100,
      render: (note) =>
        note ? (
          <div className='cursor-pointer' onClick={() => setSingleNote(note)}>
            {note?.title}
          </div>
        ) : (
          "-"
        ),
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: `${TEXTS.LABELS.NOTE}`,
      key: "description",
      dataIndex: "description",
      width: 400,
      render: (description) =>
        description ? (
          <div className="description-column">
            {description}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: `${TEXTS.LABELS.CREATE_DATE}`,
      key: "Create date",
      dataIndex: "createdAt",
      width: 150,
      render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
    },
    {
      title: `${TEXTS.LABELS.OWNER}`,
      dataIndex: "noteOwner",
      key: "owner",
      width: 150,
      render: (noteOwner, item) => (
        <Link to={`/admin/setup/staffs/${item?.noteOwnerId}`}>
          {noteOwner?.firstName} {noteOwner?.lastName}
        </Link>
      ),
    },
    {
      title: `${TEXTS.LABELS.ACTIONS}`,
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (id, note) => (
        <span
          onClick={() => setEdit(note)}
          className='bg-teal-500 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded'
        >
          <BiEdit className='text-white' />
        </span>
      ),
    },
  ];

  const paginationConfig = {
    current: currentPage,
    pageSize,
    total: data?.note?.length || 0,
    onChange: (page, pageSize) => {
      setCurrentPage(page);
      setPageSize(pageSize);
    },
    showSizeChanger: false, // Adjust if you want to allow users to change page size
  };

  // Sort data by creation date in descending order
  const sortedData = [...(data?.note || [])].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const paginatedData = sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <Card
      title={<span className='font-bold flex flex-1'>{TEXTS.LABELS.NOTES}<img className="ml-2" src={mailIcon} /></span>}
      bodyStyle={{ padding: 0 }}
      extra={
        <UserPrivateComponent permission='create-note'>
          <Button
            onClick={() => setOpen(true)}
            className='flex items-center'
            icon={<BiPlus />}
          >
            {TEXTS.BUTTON_TEXT.ADD_BTN}
          </Button>
        </UserPrivateComponent>
      }
    >
      <div>
        <UserPrivateComponent permission='readAll-note'>
          <Table
            bordered
            columns={columns}
            loading={loading}
            dataSource={paginatedData}
            pagination={paginationConfig}
            scroll={{ x: 800, y: 300 }}
            onChange={handleTableChange}
          />
        </UserPrivateComponent>
      </div>

      <UserPrivateComponent permission='create-note'>
        <CreateDrawer onClose={onClose} open={open} title={"Note"}>
          <CreateNoteForm
            onClose={onClose}
            open={open}
            createAs={{ name, value: data?.id, singleLoadThunk }}
          />
        </CreateDrawer>
      </UserPrivateComponent>
      <UserPrivateComponent permission={"readSingle-note"}>
        <SingleNote note={singleNote} onClose={setSingleNote} />
      </UserPrivateComponent>
      {/* // edit */}
      <UserPrivateComponent permission='update-note'>
        <UpdateDrawer onClose={onCloseEdit} open={!!edit} title={"Note"}>
          <CreateNoteForm
            onClose={onCloseEdit}
            open={!!edit}
            edit={edit}
            singleLoad={{ thunk: singleLoadThunk, id: data?.id }}
          />
        </UpdateDrawer>
      </UserPrivateComponent>

      {/* Render CreateNoteSelective only after createAs values are loaded */}
      {createAsLoaded && (
        <UserPrivateComponent permission='create-note'>
          <CreateNoteSelective
            createAs={{ name, value: data?.id, singleLoadThunk }}
          />
        </UserPrivateComponent>
      )}
    </Card>
  );
}
