import axios from "axios";

const getCurrentUser = async () => {
    try {
        const response = await axios.get("/user/auth/current-user", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access-token")}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching current user:", error.message);
        throw error; // Propagate the error to the caller
    }
};

export default getCurrentUser;
