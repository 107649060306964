import { Drawer } from "antd";
import CreateContactSourceForm from "./CreateContactSourceForm";
import { TEXTS } from "../../../../../constants";

export default function CreateContactSource({ open, onClose }) {
  return (
    <Drawer
      width={450}
      title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.CONTACT_SOURCE}`}
      placement='right'
      onClose={onClose}
      open={open}
    >
      <CreateContactSourceForm onClose={onClose} />
    </Drawer>
  );
}
