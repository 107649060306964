import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, message, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { TEXTS, CURRENCY } from "../../../constants";
import { canSeeDiscount } from "../../../utils/CustomHooks/hasAccess";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../../../redux/rtk/features/settings/settingsSlice";

export default function ProductAdd({
  form,
  productList,
  productLoading,
  onTotalAmountChange,
  edit,
}) {
  const [subTotal, setSubTotal] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [aproxAmount, setAproxAmount] = useState(0);
  const [sales, setSales] = useState(0);
  const dispatch = useDispatch();
  const { settings, loading } = useSelector(state => state.settings);
  const [settingsData, setSettingsData] = useState(null);
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (settings.length > 0) {
      setSettingsData(settings[0]);
    }
  }, [settings]);
  const { maximX = 2.3, minimX = 2.1, eurExchangeRate = 19.5 } = settingsData || {};
  useEffect(() => {
    if (edit?.quoteProduct && productList.length) {
      const initialProducts = edit.quoteProduct.map((product) => {
        const matchedProduct = productList.find(p => p.id === product.productId);
        return {
          ...product,
          pricePerUnit: matchedProduct?.pricePerUnit || product.unitPrice,
          unitPrice: matchedProduct?.pricePerUnit || product.unitPrice,
          productImage: matchedProduct?.productImage || product.productImage,
        };
      });
      form.setFieldsValue({ quoteProduct: initialProducts });
      totalCalculator();
    }
  }, [edit, productList, form]);

  const totalCalculator = () => {
    const productArray = form.getFieldValue("quoteProduct") || [];
    const subTotal = productArray.map(item => (item?.pricePerUnit || 0) * (item?.productQuantity || 0));
    const total = subTotal.reduce((total, current) => total + current, 0);
    setSubTotal(subTotal);
    setTotal(total);

    const discount = form.getFieldValue("discount") || 0;
    const salesAmmount = Math.max(
      (total * maximX) - (discount * eurExchangeRate),
      total
    );
    const totalAmount = total;
    setSales(salesAmmount);
    setTotalAmount(totalAmount);
    setAproxAmount(totalAmount / eurExchangeRate);

    if (onTotalAmountChange) {
      onTotalAmountChange(totalAmount);
    }

    const isDiscountExcessive = salesAmmount === total;

    if (onTotalAmountChange) {
      onTotalAmountChange(totalAmount);
    }

    return isDiscountExcessive;
  };

  const handleProductChange = (prodId, key) => {
    const selectedProduct = productList.find((item) => item.id === prodId);
    const pricePerUnit = selectedProduct ? selectedProduct.pricePerUnit : 0;
    const productImage = selectedProduct ? selectedProduct.productImage : null;

    const productArray = form.getFieldValue("quoteProduct");
    productArray[key] = {
      ...productArray[key],
      pricePerUnit,
      unitPrice: pricePerUnit,
      productImage,
    };

    form.setFieldsValue({ quoteProduct: productArray });
    totalCalculator();
  };

  const handleProductQuantityChange = (value, key) => {
    const productArray = form.getFieldValue("quoteProduct");
    productArray[key] = {
      ...productArray[key],
      productQuantity: value,
    };

    form.setFieldsValue({ quoteProduct: productArray });
    totalCalculator();
  };

  const handleRemoveProduct = (key) => {
    const productArray = form.getFieldValue("quoteProduct");
    // Remove the product at the specified key
    productArray.splice(key, 1);
    form.setFieldsValue({ quoteProduct: productArray });
    totalCalculator();
  };

  return (
    <>
      <Row>
        <Col span={2}>
          <div className="font-weight-bold">SL</div>
        </Col>
        <Col span={6}>
          <div className="font-weight-bold">{TEXTS.LABELS.PRODUCT}</div>
        </Col>
        <Col span={5}>
          <div className="font-weight-bold">{TEXTS.TEXT.QUANTITY}</div>
        </Col>
        <Col span={5}>
          <div className="font-weight-bold">{TEXTS.TEXT.UNIT_PRICE}</div>
        </Col>
        <Col span={4}>
          <div className="font-weight-bold">Total</div>
        </Col>
        <Col span={2}>
          <div>{TEXTS.TEXT.DELETE}</div>
        </Col>
      </Row>

      <hr style={{ backgroundColor: "black", marginTop: "0.5rem" }} />

      <Form.List
        name="quoteProduct"
        rules={[
          {
            required: true,
            message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.PRODUCT}`,
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row className="mt-2" gutter={[5]} key={key}>
                <Col span={2}>{index + 1}</Col>
                <Col span={6}>
                  <Form.Item
                    {...restField}
                    name={[name, "productId"]}
                    rules={[
                      {
                        required: true,
                        message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.PRODUCT}`,
                      },
                    ]}
                  >
                    <Select
                      placeholder={TEXTS.PLACEHOLDERS.PRODUCT_PLACEHOLDER}
                      showSearch
                      allowClear
                      loading={productLoading}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(prodId) => handleProductChange(prodId, index)}
                    >
                      {productList?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.productName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {form.getFieldValue(["quoteProduct", name, "productImage"]) && (
                    <div>
                      <img
                        src={`data:image/jpeg;base64,${form.getFieldValue(["quoteProduct", name, "productImage"])}`}
                        alt="Product"
                        style={{ width: "100px", height: "auto", marginTop: "8px" }}
                      />
                    </div>
                  )}
                </Col>
                <Col span={5}>
                  <Form.Item
                    {...restField}
                    name={[name, "productQuantity"]}
                    rules={[
                      {
                        required: true,
                        message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.TEXT.QUANTITY}`,
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      size="small"
                      placeholder={TEXTS.TEXT.QUANTITY}
                      onChange={(value) => handleProductQuantityChange(value, index)}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    {...restField}
                    name={[name, "unitPrice"]}
                    rules={[
                      {
                        required: true,
                        message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.TEXT.UNIT_PRICE}`,
                      },
                    ]}
                  >
                    <InputNumber
                      size="small"
                      style={{ width: "100%" }}
                      disabled
                      value={form.getFieldValue(["quoteProduct", name, "unitPrice"])}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <div className="font-weight-bold totalMargin">
                    {subTotal[index] || 0}
                  </div>
                </Col>
                <Col span={2}>
                  <Form.Item>
                    <Button
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveProduct(index)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item style={{ marginTop: "20px" }}>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {TEXTS.BUTTON_TEXT.ADD_PRODUCT_BTN}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <div className='py-2'>
        {/* Display Total */}
        <div className='p-3 flex justify-between border'>
          <strong>Preț Total: </strong>
          <strong>{total} lei</strong>
        </div>
        <div className='p-3 flex justify-between border'>
          <strong>Preț Total~ (&euro;): </strong>
          <strong>{aproxAmount.toFixed(2)} &euro;</strong>
        </div>
        <div className='p-3 flex justify-between border'>
          <strong>Total Minim: </strong>
          <strong>{(total * minimX).toFixed(2)} lei</strong>
        </div>
        <div className='p-3 flex justify-between border'>
          <strong>Total Minim~ (&euro;): </strong>
          <strong>{(aproxAmount * minimX).toFixed(2)} &euro;</strong>
        </div>
        <div className='p-3 flex justify-between border'>
          <strong>Preț de Vânzare: </strong>
          <strong>{(sales).toFixed(2)} lei</strong>
        </div>
        <div className='p-3 flex justify-between border'>
          <strong>Preț de Vânzare~ (&euro;): </strong>
          <strong>{(sales / eurExchangeRate).toFixed(2)} &euro;</strong>
        </div>
        {canSeeDiscount && (<div className='flex justify-between items-center'>
          <strong > {TEXTS.TEXT.DISCOUNT} &euro;: </strong>
          <Form.Item
            className='mt-2'
            name='discount'
            onChange={() => {
              const isDiscountTooHigh = totalCalculator();
              if (isDiscountTooHigh) {
                message.warning('Discount is too high. It cannot reduce the sales price below the total price.');
              }
            }}
          >
            <InputNumber defaultValue={0} max={total || 0} min={0} />
          </Form.Item>
        </div>)}
        <div className='p-3 mb-4 flex justify-between border'>
          <strong>{TEXTS.LABELS.TOTAL_AMMOUNT}: </strong>
          <strong>{totalAmount} lei</strong>
        </div>
      </div >    </>
  );
}
