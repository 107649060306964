import { Drawer } from "antd";
import { TEXTS } from "../../../../../constants";
import CreateDesignerForm from "./CreateDesignerForm";

export default function CreateDesigner({ open, onClose }) {
    return (
        <Drawer
            width={450}
            title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.DESIGNER}`}
            placement="right"
            onClose={onClose}
            open={open}
        >
            <CreateDesignerForm onClose={onClose} />
        </Drawer>
    )
}