import { Card, Table } from 'antd';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { AiFillDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllRooms, deleteRoom } from '../../../../redux/rtk/features/crm/room/roomSlice';
import ColVisibilityDropdown from '../../../Shared/ColVisibilityDropdown';
import CustomDrawer from '../../CommonUi/CustomDrawer';
import CreateRoomForm from './CreateRoomForm';
import { TEXTS } from '../../../../constants';
import moment from "moment";
import "../../../../App.css";

export default function RoomsTable() {
    const dispatch = useDispatch();
    const { list: roomsList, loading } = useSelector((state) => state.room);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        dispatch(loadAllRooms());
    }, [dispatch]);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const deleteHandler = (id) => {
        dispatch(deleteRoom(id));
    };

    const columns = [
        {
            title: `${TEXTS.LABELS.LAST_NAME}`,
            dataIndex: 'roomName',
            width: 150,
        },
        {
            title: `${TEXTS.LABELS.ITEMS}`,
            dataIndex: 'roomItem',
            render: (items) => (
                <div
                    style={{
                        maxHeight: '4.5em', // Adjust the height to fit up to 3 lines of text
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        display: 'block', // Ensure the items are displayed as a block
                    }}
                >
                    {items.map((item, index) => (
                        <span key={item.id}>
                            {item.itemName}
                            {index < items.length - 1 ? ', ' : ''}
                        </span>
                    ))}
                </div>
            ),
            width: 300, // Adjust this width as needed
        },
        {
            title: `${TEXTS.LABELS.CREATE_DATE}`,
            dataIndex: 'createdAt',
            render: (date) => moment(date).format('MMMM Do YYYY HH:mm'),
            width: 200,
        },
        {
            title: `${TEXTS.LABELS.UPDATE_DATE}`,
            dataIndex: 'updatedAt',
            render: (date) => moment(date).format('MMMM Do YYYY HH:mm'),
            width: 200,
        },
        {
            title: `${TEXTS.LABELS.ACTIONS}`,
            dataIndex: 'id',
            render: (id, values) => (
                <span className='flex items-cupdate-contactSourceenter gap-2'>
                    <CustomDrawer
                        permission={"update-contactSource"}
                        update
                        title={`${TEXTS.TEXT.UPDATE} ${TEXTS.LABELS.ROOM}`}
                        width={25}
                    >
                        <CreateRoomForm edit={{ id, values }} />
                    </CustomDrawer>
                    <span
                        onClick={() => deleteHandler(id)}
                        className='bg-red-700 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded'
                    >
                        <AiFillDelete className='text-white' />
                    </span>
                </span>
            ),
            width: 150,
        },
    ];

    // column select
    const [columnsToShow, setColumnsToShow] = useState(columns);

    const columnsToShowHandler = (val) => {
        setColumnsToShow(val);
    };

    return (
        <Card className='border mt-2'>
            <div className='flex justify-between items-center'>
                <div></div>
                <div className='flex items-center gap-2'>
                    <span className='border px-2 py-1 rounded cursor-pointer bg-black text-white'>
                        <CSVLink
                            data={roomsList}
                            className='btn btn-dark btn-sm'
                            style={{ marginTop: '5px' }}
                            filename='Rooms_CRM'
                        >
                            {TEXTS.BUTTON_TEXT.DOWNLOAD_CSV}
                        </CSVLink>
                    </span>
                    <ColVisibilityDropdown
                        options={columns}
                        columns={columns}
                        columnsToShowHandler={columnsToShowHandler}
                    />
                </div>
            </div>
            <div>
                <span
                    style={{
                        marginLeft: 8,
                    }}
                >
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                </span>
            </div>
            <Table
                loading={loading}
                rowSelection={rowSelection}
                columns={columnsToShow}
                dataSource={roomsList?.map((item) => ({
                    ...item,
                    key: item.id,
                }))}
                pagination={false}
                scroll={{ x: 1000 }} // Adjust scroll width if needed
            />
        </Card>
    );
};