import { AiOutlinePlus } from "react-icons/ai";
import { TEXTS } from "../../../../../constants";
import ProductionsTable from "./ProductionsTable";
import CreateProduction from "./CreateProduction";
import { useState } from "react";

export default function Productions() {
    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    }

    return (
        <div className="container">
            <div className="py4 flex justify-center md:justify-end">
                <button
                    onClick={() => setOpen(true)}
                    className="py-2 px-3 border bg-teal-700 hover:bg-teal-500 text-white rounded cursor-pointer flex items-center gap-2"
                >
                    <AiOutlinePlus /> {TEXTS.TEXT.CREATE} {TEXTS.LABELS.PRODUCTION_SUCCESSION}
                </button>
            </div>
            <ProductionsTable />
            <CreateProduction onClose={onClose} open={open} />
        </div>
    )
}