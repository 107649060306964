import React from "react";
import { Navigate } from "react-router-dom";
import getRoleFromToken from "../../utils/getRoleFromToken";
import Page404 from "../404/404Page";
import checkTokenExp from "../../utils/checkTokenExp.js";

const PrivateRoute = ({ allowedRoles = [], children }) => {
    const userRole = getRoleFromToken();
    const token = localStorage.getItem("access-token");
    const isExpired = checkTokenExp(token);
    // Roles that can access all routes
    const allAccessRoles = ["Fondator", "Cofondator", "admin", "Administrator", "Manager Operațional"];

    // Check if allowedRoles contains "all" to grant access to everyone
    const hasAccess =
        allowedRoles.includes("all") || // If "all" is specified, everyone has access
        allAccessRoles.includes(userRole) || // Check if user is in all-access roles
        allowedRoles.includes(userRole); // Check if the user role is allowed for this route

    if (!userRole && isExpired) {
        // Redirect to login if the user is not authenticated
        return <Navigate to="/admin/auth/logout" />;
    }
    if (!hasAccess) {
        // Show 404 page if the user doesn't have permission
        return <Page404 />;
    }

    // If the user has access, render the children components
    return children;
};

export default PrivateRoute;
