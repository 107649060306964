import { Fragment } from "react";
import { Checkbox } from "antd";

function PermissionList({ permissionNames, selectedPermission, setSelectedPermission, existingPermissions }) {
    const permissionElements = permissionNames.map((item) => (
        <Fragment key={item.id}>
            <Checkbox
                value={item.id}
                onChange={() => {
                    setSelectedPermission((prev) => ({
                        ...prev,
                        [item.id]: !prev[item.id],
                    }));
                }}
                checked={selectedPermission[item.id]}
                disabled={existingPermissions.includes(item.id)} // Disable if permission already exists
            >
                {item.name}
            </Checkbox>
        </Fragment>
    ));

    const rows = [];
    for (let i = 0; i < permissionElements.length; i += 5) {
        rows.push(
            <div
                key={i}
                className='flex justify-between m-4 border-2 border-indigo-100 px-4 py-3'>
                {permissionElements.slice(i, i + 5)}
                <br />
            </div>
        );
    }
    return <div>{rows}</div>;
}

export default PermissionList;
