import { Drawer } from "antd";
import CreateCrmTicketCategoryForm from "./CreateCrmTicketCategoryForm";
import { TEXTS } from "../../../../../constants";

export default function CreateCrmTicketCategory({ open, onClose }) {
	return (
		<Drawer
			width={450}
			title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.TICKET_CATEGORY}`}
			placement='right'
			onClose={onClose}
			open={open}>
			<CreateCrmTicketCategoryForm onClose={onClose} />
		</Drawer>
	);
}
