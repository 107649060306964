import { useEffect, useState } from "react";
import { TEXTS } from "../../../constants";
import { Link } from "react-router-dom";
import { BiEdit, BiPlus } from "react-icons/bi";
import { Button, Card, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CreateDrawer from "./CreateDrawer";
import CreateContactRoomForm from "../Rooms/CreateContactRoomForm";
import UpdateDrawer from "./UpdateDrawer";
import { fetchSettings } from "../../../redux/rtk/features/settings/settingsSlice";
import { AiFillDelete } from "react-icons/ai";
import { deleteContactRoom } from "../../../redux/rtk/features/crm/contactRoom/contactRoomSlice";

export default function ContactRooms({ data, loading, name, singleLoadThunk }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const settings = useSelector((state) => state.settings);
    const onClose = () => setOpen(false);
    const onCloseEdit = () => setEdit(false);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    const calculateTotalAmount = (contactRoomId) => {
        return data.opportunity.reduce((total, opportunity) => {
            if (opportunity.contactRoomId === contactRoomId && opportunity.offerAmount) {
                return total + opportunity.offerAmount; // Return the total offerAmount (before discount)
            }
            return total;
        }, 0);
    };

    const calculateTotalDiscount = (contactRoomId) => {
        return data.opportunity.reduce((total, opportunity) => {
            if (opportunity.contactRoomId === contactRoomId) {
                // Calculate total discounts for all quotes
                const totalDiscounts = opportunity.quote?.reduce((discountSum, quote) => {
                    return discountSum + (quote.discount || 0); // Sum up discounts
                }, 0) || 0;

                return total + totalDiscounts; // Return total discounts
            }
            return total;
        }, 0);
    };

    const onDelete = (id) => {
        dispatch(deleteContactRoom(id)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                dispatch(fetchSettings()); // Refetch settings to update the list
            }
        });
    };

    const columns = [
        {
            title: `${TEXTS.LABELS.ROOM_NAME}`,
            key: "roomName",
            render: (contactRoom) => contactRoom.room ? (
                <Link to={`/admin/room/${contactRoom.id}`}
                    state={{ fromContact: data.id }}>
                    {contactRoom.room.roomName}
                </Link>
            ) : "-",
            sorter: (a, b) => a.room.roomName.localeCompare(b.room.roomName),
        },
        {
            title: `${TEXTS.LABELS.AMOUNT_SUM}`, // Display in Moldovan lei or without a currency symbol
            key: "totalAmount",
            render: (contactRoom) => {
                const eurExchangeRate = settings.settings?.[0]?.eurExchangeRate;
                const totalAmount = calculateTotalAmount(contactRoom.id);
                const totalDiscount = calculateTotalDiscount(contactRoom.id);
                const finalString = `${((totalAmount / eurExchangeRate) - totalDiscount).toFixed(2)} € (${(totalAmount - (totalDiscount * eurExchangeRate)).toFixed(2)} lei)`
                return finalString.toLocaleString(); // Formatting number with commas
            },
            sorter: (a, b) => calculateTotalAmount(a.id) - calculateTotalAmount(b.id),
        },
        {
            title: `${TEXTS.LABELS.CREATE_DATE}`,
            key: 'createdAt',
            render: ({ createdAt }) => moment(createdAt).format('MMMM Do YYYY HH:mm'),
        },
        {
            title: `${TEXTS.LABELS.UPDATE_DATE}`,
            key: 'updatedAt',
            render: ({ updatedAt }) => moment(updatedAt).format('MMMM Do YYYY HH:mm'),
        },
        {
            title: `${TEXTS.LABELS.ACTIONS}`,
            key: "actions",
            render: (contactRoom) => (
                <div className="flex flex-row gap-2">
                    <span
                        onClick={() => setEdit(contactRoom)}
                        className="bg-teal-500 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded"
                    >
                        <BiEdit className="text-white" />
                    </span>
                    <span
                        onClick={() => onDelete(contactRoom.id)}
                        className="bg-red-600 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded"
                    >
                        <AiFillDelete className="text-white" />
                    </span>
                </div>
            ),
        },
    ];

    return (
        <Card
            title={<span className="font-bold">{TEXTS.LABELS.ROOMS}</span>}
            extra={
                <Button
                    onClick={() => setOpen(true)}
                    className="flex items-center"
                    icon={<BiPlus />}
                >
                    {TEXTS.BUTTON_TEXT.ADD_BTN}
                </Button>
            }
            bodyStyle={{ padding: 0 }}
        >
            <div>
                <Table
                    bordered
                    columns={columns}
                    loading={loading}
                    dataSource={data ? data.contactRoom : []}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ x: 800, y: 300 }}
                    rowKey="id"
                />
            </div>

            <CreateDrawer onClose={onClose} open={open} title={`${TEXTS.LABELS.CONTACT_ROOM}`}>
                <CreateContactRoomForm
                    onClose={onClose}
                    open={open}
                    createAs={{ name, value: data?.id, singleLoadThunk }}
                />
            </CreateDrawer>

            <UpdateDrawer onClose={onCloseEdit} open={!!edit} title={`${TEXTS.LABELS.CONTACT_ROOM}`}>
                <CreateContactRoomForm
                    onClose={onCloseEdit}
                    open={!!edit}
                    edit={edit}
                    singleLoad={{ thunk: singleLoadThunk, id: data?.id }}
                />
            </UpdateDrawer>
        </Card>
    );
}
