import { MenuOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink } from "react-router-dom";
import getRoleFromToken from "../../utils/getRoleFromToken";
import { TEXTS } from "../../constants";
import Page404 from "../404/404Page";

const Headnav = ({ mobile }) => {
  const userRole = getRoleFromToken();

  // Roles that have access to all sections
  const allAccessRoles = ["Fondator", "Cofondator", "admin", "Administrator", "Manager Operațional"];

  // Function to check if the user has access to a menu item based on their role
  const hasAccess = (allowedRoles = []) => {
    // If allowedRoles includes 'all', everyone has access
    if (allowedRoles.includes("all")) {
      return true;
    }
    // If user's role is in the allowed roles or has full access, grant access
    return allowedRoles.includes(userRole) || allAccessRoles.includes(userRole);
  };

  const menu = [
    (hasAccess(["Manager Vânzări", "Manager Producere", "Designer", "Operator Producere"])) &&
    {
      label: (
        <NavLink to="/admin/calendar">
          <span>Calendar</span>
        </NavLink>
      )
    },
    (hasAccess()) &&
    {
      label: (
        <NavLink
          to={
            getRoleFromToken() !== "customer"
              ? hasAccess() ? "/admin/dashboard" : <Page404 />
              : "/customer/dashboard"
          }
        >
          <span>Bord Central</span>
        </NavLink>
      ),
      key: "dashboard",
    },
    (hasAccess(["Manager Vânzări", "Tehnician Prețuri", "Designer"])) && {
      label: (
        <NavLink to="/admin/contact/kanban">
          <span>{TEXTS.LABELS.CONTACTS_HEAD}</span>
        </NavLink>
      ),
      key: "kanbancl",
    },

    (hasAccess(["Manager Producere", "Operator Producere"])) && {
      label: (
        <NavLink to="/admin/opportunity/kanban">
          <span>{TEXTS.LABELS.OPPORTUNITIES_HEAD}</span>
        </NavLink>
      ),
      key: "opportunities",
    },
    (hasAccess(["Operator Producere", "Contabil"])) && {
      label: (
        <NavLink to="/admin/finances">
          {TEXTS.LABELS.FINANCE}
        </NavLink>
      ),
      key: "finances",
    },
    (hasAccess(["Tehnician Prețuri"])) && {
      label: (
        <span className="flex items-center gap-1">
          {TEXTS.LABELS.QUOTES} <IoIosArrowDown />
        </span>
      ),
      key: "quotes",

      children: [
        (hasAccess(["Tehnician Prețuri"])) && {
          label: (
            <NavLink to="/admin/quote">
              <span>{TEXTS.LABELS.QUOTE}</span>
            </NavLink>
          ),
          key: "quote",
        },
        (hasAccess(["Tehnician Prețuri"])
        ) && {
          label: (
            <NavLink to="/admin/products">
              <span>{TEXTS.LABELS.PRODUCTS}</span>
            </NavLink>
          ),
          key: "products",
        },
      ],
    },
    (hasAccess(["Manager Vânzări"])) && {
      label: (
        <span className="flex items-center gap-1">
          {TEXTS.LABELS.CONTACTS_BORD} <IoIosArrowDown />
        </span>),

      key: "contacts",

      children: [(hasAccess(["Manager Vânzări"])) && {
        label: (
          <NavLink to="/admin/contact">
            <span>{TEXTS.LABELS.CONTACTS_PERSON}</span>
          </NavLink>
        ),
      },
      (hasAccess()) && {
        label: (
          <NavLink to="/admin/company">
            <span>{TEXTS.LABELS.COMPANIES}</span>
          </NavLink>
        ),
        key: "company",
      },
      (hasAccess(["Manager Producere", "Operator Producere"])) && {
        label: (
          <NavLink to="/admin/opportunity">
            <span>{TEXTS.LABELS.OPPORTUNITIES}</span>
          </NavLink>
        ),
        key: "opportunity",
      },
      (hasAccess(["Manager Vânzări", "Operator Producere"])) && {
        label: (
          <NavLink to="/admin/contracts">
            <span>Contracte</span>
          </NavLink>
        ),
        key: "contract",
      },

      (hasAccess(["Manager Vânzări", "Operator Producere"])) && {
        label: (
          <NavLink to="/admin/offers">
            <span>Oferte</span>
          </NavLink>
        ),
        key: "offer",
      },
      ],
    },
    (hasAccess()) && {
      label: (
        <span className="flex items-center gap-1">
          {TEXTS.LABELS.OTHERS} <IoIosArrowDown />
        </span>
      ),
      key: "others",

      children: [
        (hasAccess(["Manager Producere"])) && {
          label: (
            <NavLink to="/admin/note">
              <span>{TEXTS.LABELS.NOTE}</span>
            </NavLink>
          ),
          key: "note",
        },
        (hasAccess(["Manager Producere"])) && {
          label: (
            <NavLink to="/admin/attachment">
              <span>{TEXTS.LABELS.ATTACHMENT}</span>
            </NavLink>
          ),
          key: "attachment",
        },
        (hasAccess(["Manager Producere"])) && {
          label: (
            <NavLink to="/admin/task">
              <span>{TEXTS.LABELS.TASKS}</span>
            </NavLink>
          ),
          key: "task",
        },
        (hasAccess()) && {
          label: (
            <NavLink to="/admin/setup">
              <span>Setup</span>
            </NavLink>
          ),
          key: "setup",
        },
        (hasAccess(["Manager Producere"])) && {
          label: (
            <NavLink to="/admin/email">
              <span>Email</span>
            </NavLink>
          ),
          key: "email",
        },
      ],
    },
  ];
  if (mobile) {
    return (
      <>
        <Dropdown
          className="md:hidden"
          overlay={<Menu items={menu} mode="inline" />}
          trigger={["click"]}
        >
          <Button type="text" icon={<MenuOutlined />} className="mr-0" />
        </Dropdown>
      </>
    );
  }
  return (
    <div className="w-[90%] hidden md:block">
      <Menu
        className=" bg-transparent text-white border-none font-poppins"
        mode="horizontal"
        items={menu}
      />
    </div>
  );
};

export default Headnav;
