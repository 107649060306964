import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderEventContent } from '../../calendar/DashboardCalendarInfo';
import "../../calendar/style.css";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { TEXTS } from '../../../constants';
import getCurrentUser from '../../user/getCurrentUserapi';
import roLocale from "@fullcalendar/core/locales/ro";
import { loadAllOpportunityPaginated } from '../../../redux/rtk/features/crm/opportunity/opportunitySlice';
import { loadAllContactPaginated } from '../../../redux/rtk/features/crm/contact/contactSlice';
import { loadAllContactStage } from '../../../redux/rtk/features/crm/ContactStage/contactStageSlice';
import { loadAllOpportunityStage } from '../../../redux/rtk/features/crm/opportunityStage/opportunityStageSlice';
import { Menu, Dropdown, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { loadAllStaff } from '../../../redux/rtk/features/user/userSlice';
import { FiFilter } from 'react-icons/fi';


const { Option } = Select;

export default function CalendarBar() {
    const dispatch = useDispatch();
    const [clientEvents, setClientEvents] = useState([]);
    const [opportunityEvents, setOpportunityEvents] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [selectedStages, setSelectedStages] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [selectedOpportunityStages, setSelectedOpportunityStages] = useState([]);

    const contactList = useSelector((state) => state.contact.list);
    const contactLoading = useSelector((state) => state.contact.loading);
    const contactError = useSelector((state) => state.contact.error);
    const opportunityList = useSelector((state) => state.opportunity.list);
    const loadingOpportunity = useSelector((state) => state.opportunity.loading);
    const contactStages = useSelector((state) => state.contactStage.list);
    const loadingContactStages = useSelector((state) => state.contactStage.loading);
    const opportunityStages = useSelector((state) => state.opportunityStage.list);
    const loadingOpportunityStages = useSelector((state) => state.opportunityStage.loading);
    const { list: staffList, loading: staffLoading } = useSelector((state) => state.users);
    const [filtersVisible, setFiltersVisible] = useState(false);

    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const user = await getCurrentUser();
                setCurrentUser(user);
            } catch (error) {
                console.error("Error fetching current user:", error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (contactLoading || loadingOpportunity || loadingContactStages || loadingOpportunityStages) return;
        dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
        dispatch(loadAllContactPaginated({ status: true, count: 999 }));
        dispatch(loadAllContactStage());
        dispatch(loadAllOpportunityStage());
        dispatch(loadAllStaff({ status: true }));
    }, [dispatch]);

    useEffect(() => {
        if (!currentUser || loading || contactLoading || loadingOpportunity || contactError) return;

        const clientEvents = currentUser.department.id === 1 || currentUser.department.id === 3
            ? contactList
                .filter(contact =>
                    currentUser.department.id === 1 || contact.contactOwnerId === currentUser.id
                )
                .flatMap(contact => [
                    {
                        id: contact.id,
                        title: `${contact.firstName} ${contact.lastName} - ${TEXTS.LABELS.BIRTHDATE}`,
                        start: moment(contact.dateOfBirth).toDate(),
                        type: 'client',
                    },
                    {
                        id: contact.id,
                        title: `${contact.firstName} ${contact.lastName} - ${TEXTS.LABELS.CREATE_DATE}`,
                        start: moment(contact.createdAt).toDate(),
                        type: 'client',
                    }
                ])
            : [];

        const opportunityEvents = currentUser.department.id === 1
            ? opportunityList.flatMap(opportunity => {
                const contact = contactList.find(c => c.id === opportunity.contactId); // Assuming opportunity has contactId
                const contactStageName = contact ? contact.contactStage.contactStageName : '';

                return (contactStageName === 'Predat Producere') ? {
                    id: opportunity.contact.id,
                    title: `${opportunity.contact.firstName} ${opportunity.contact.lastName}`,
                    start: moment(opportunity.opportunityCreateDate).toDate(),
                    end: moment(opportunity.opportunityCloseDate).toDate(),
                    deliveryDate: moment(opportunity.opportunityDeliveryDate).toDate(), // Include delivery date
                    type: 'opportunity',
                } : [];
            })
            : currentUser.department.id === 3
                ? opportunityList
                    .filter(opportunity => {
                        const contact = contactList.find(c => c.id === opportunity.contactId);
                        const contactStageName = contact ? contact.contactStage.contactStageName : '';

                        // If department ID is 3, we ensure that opportunityDeliveryDate is set
                        return opportunity.opportunityDeliveryDate && contactStageName === 'Predat Producere';
                    })
                    .flatMap(opportunity => ({
                        id: opportunity.contact.id,
                        title: `${opportunity.contact.firstName} ${opportunity.contact.lastName}` || 'Fara client',
                        start: moment(opportunity.opportunityCreateDate).toDate(),
                        end: moment(opportunity.opportunityCloseDate).toDate(),
                        deliveryDate: moment(opportunity.opportunityDeliveryDate).toDate(), // Include delivery date
                        type: 'opportunity',
                    }))
                : [];

        setClientEvents(clientEvents);
        setOpportunityEvents(opportunityEvents);
    }, [currentUser, contactList, opportunityList, contactLoading, loadingOpportunity, contactError, loading]);


    const handleEventClick = (clickInfo) => {
        const eventId = clickInfo.event.id;
        if (clickInfo.event.extendedProps.type === 'client') {
            navigate(`/admin/contact/${eventId}`);
        } else if (clickInfo.event.extendedProps.type === 'opportunity') {
            navigate(`/admin/contact/${eventId}`);
        }
    };

    const handleFilterChange = (value) => {
        setSelectedFilter(value);
    };

    const handleStaffChange = (value) => {
        setSelectedStaff(value);
    };

    const handleStageChange = (value) => {
        setSelectedStages(value);
    };

    const handleOpportunityStageChange = (value) => {
        setSelectedOpportunityStages(value);
    };

    // Filter menu should only appear for specific departments
    const filterMenu = (
        <Menu onClick={({ key }) => handleFilterChange(key)}>
            <Menu.Item key="all">Toate</Menu.Item>
            <Menu.Item key="client">{TEXTS.LABELS.CONTACTS}</Menu.Item>
            <Menu.Item key="opportunity">{TEXTS.LABELS.OPPORTUNITIES}</Menu.Item>
        </Menu>
    );

    const stageOptions = contactStages.map(stage => (
        <Option key={stage.id} value={stage.id}>{stage.contactStageName}</Option>
    ));

    const opportunityStageOptions = opportunityStages.map(stage => (
        <Option key={stage.id} value={stage.id}>{stage.opportunityStageName}</Option>
    ));

    const staffOptions = staffList.filter(staff => [1, 3, 4].includes(staff.departmentId)).map(staff => (
        <Option key={staff.id} value={staff.id}>{staff.firstName} {staff.lastName}</Option>
    ))

    const filteredClientEvents = (selectedFilter === 'all' || selectedFilter === 'client')
        ? clientEvents.filter(event => {
            const contact = contactList.find(contact => contact.id === event.id);
            return (!selectedStages.length || selectedStages.includes(contact?.contactStage.id)) &&
                (!selectedStaff.length || selectedStaff.includes(contact?.contactOwnerId));
        })
        : [];

    const filteredOpportunityEvents = (selectedFilter === 'all' || selectedFilter === 'opportunity')
        ? opportunityEvents.filter(event => {
            const opportunity = opportunityList.find(opportunity => opportunity.id === event.id);
            return (!selectedOpportunityStages.length || selectedOpportunityStages.includes(opportunity?.opportunityStage.id)) &&
                (!selectedStaff.length || selectedStaff.includes(opportunity?.opportunityOwnerId));
        })
        : [];

    const filteredEvents = selectedFilter === 'all'
        ? filteredClientEvents.concat(filteredOpportunityEvents)
        : selectedFilter === 'client'
            ? filteredClientEvents
            : filteredOpportunityEvents;

    if (loading || contactLoading || loadingOpportunity || loadingContactStages || loadingOpportunityStages) return <div>Loading...</div>;

    return (
        <div className="p-4">
            {/* Calendar */}
            <div className='container w-auto ml-12 md:col-span-1 mb-2 ant-shadow callendar-container'>
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView='dayGridMonth'
                    weekends={true}
                    events={filteredEvents}
                    eventContent={renderEventContent}
                    aspectRatio={2.1}
                    eventClick={handleEventClick}
                    moreLinkClick='popover'
                    dayCellClassNames='custom-day-cell'
                    dayMaxEventRows={2}
                    locale={roLocale}
                    key={selectedFilter + selectedStages.join('-') + selectedOpportunityStages.join('-')} // Forces FullCalendar to re-render when filters change
                />
            </div>
            {/* Filter Toggle Button */}
            <div className="flex mb-4 space-x-4 ml-12">
                <button
                    onClick={() => setFiltersVisible(!filtersVisible)}
                    className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600"
                >
                    <FiFilter className="mr-2" /> {/* Filter icon */}
                    {filtersVisible ? 'Ascunde filtrele' : 'Afișează filtrele'}
                </button>
            </div>

            {/* Filters - Conditionally Rendered */}
            {filtersVisible && (
                <div className="flex mb-4 space-x-4 ml-12">
                    {(currentUser && (currentUser.department.id === 1)) && (
                        <Dropdown overlay={filterMenu} trigger={['click']}>
                            <a className="ant-dropdown-link px-6 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600" onClick={e => e.preventDefault()}>
                                {selectedFilter === 'all' ? `${TEXTS.TEXT.FILTER}` : `${TEXTS.TEXT.FILTER}: ${selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1)}`} <DownOutlined />
                            </a>
                        </Dropdown>
                    )}
                    {(currentUser && (currentUser.department.id === 1 || currentUser.department.id === 3) &&
                        <Select
                            mode="multiple"
                            placeholder="Selectează etapa de contact"
                            className="w-full md:w-48 bg-green-500 text-white rounded-lg shadow-md"
                            onChange={handleStageChange}
                            value={selectedStages}
                        >
                            {stageOptions}
                        </Select>
                    )}
                    {(currentUser && (currentUser.department.id === 1 || currentUser.department.id === 4) &&
                        <Select
                            mode="multiple"
                            placeholder="Selectează etapa de proiect"
                            className="w-full md:w-48 bg-green-500 text-white rounded-lg shadow-md"
                            onChange={handleOpportunityStageChange}
                            value={selectedOpportunityStages}
                        >
                            {opportunityStageOptions}
                        </Select>
                    )}
                    <Select
                        mode="multiple"
                        placeholder="Selectează membrul personalului"
                        className='w-full md:w-48 bg-green-500 text-white rounded-lg shadow-md'
                        value={selectedStaff}
                        onChange={handleStaffChange}
                        loading={staffLoading}
                    >
                        {staffOptions}
                    </Select>
                </div>
            )}
        </div>
    );

}
