import {
	Button,
	Card,
	Col,
	Row,
	Typography,
	Form
} from "antd";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import Loader from "../loader/loader";
import { useSelector, useDispatch } from "react-redux";
import { loadSingleRole, loadPermission, addRolePermission } from "../../redux/rtk/features/role/roleSlice";
import PermissionList from "./PermissionList";

const AddPermission = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { permissionList: permissions } = useSelector((state) => state.role);

	const [selectedPermission, setSelectedPermission] = useState({});
	const [allChecked, setAllChecked] = useState(false);
	const location = useLocation();
	const { data } = location.state;
	const roleName = data.name;
	const rolePermissions = data.rolePermission;

	const { loading } = useSelector((state) => state.role);
	const dispatch = useDispatch();
	const { Title } = Typography;
	const [form] = Form.useForm();

	useEffect(() => {
		dispatch(loadSingleRole(id));
		dispatch(loadPermission());
	}, [id]);

	useEffect(() => {
		if (permissions.length > 0 && rolePermissions.length > 0) {
			const permissionsMap = permissions.reduce((acc, item) => {
				acc[item.id] = rolePermissions.some((rp) => rp.permission_id === item.id);
				return acc;
			}, {});
			setSelectedPermission(permissionsMap);
		}
	}, [permissions, rolePermissions]);

	const existingPermissions = rolePermissions.map(rp => rp.permission_id);

	const permisionIds = Object.entries(selectedPermission).reduce(
		(acc, [key, value]) => {
			if (value && !existingPermissions.includes(parseInt(key, 10))) {
				acc.push(parseInt(key, 10));
			}
			return acc;
		},
		[]
	);

	const onFinish = async () => {
		try {
			const data = {
				role_id: parseInt(id, 10),
				permission_id: permisionIds,
			};

			dispatch(addRolePermission({ values: data }));
			navigate(-1);
			form.resetFields();
		} catch (error) {
			console.log(error.message);
		}
	};

	const toggleAllPermissions = () => {
		const newSelectedPermission = {};
		permissions.forEach((permission) => {
			if (!existingPermissions.includes(permission.id)) {
				newSelectedPermission[permission.id] = !allChecked; // Toggle based on the current state
			}
		});
		setSelectedPermission(newSelectedPermission);
		setAllChecked(!allChecked); // Toggle the allChecked state
	};

	const isLogged = Boolean(localStorage.getItem("isLogged"));

	if (!isLogged) {
		return <Navigate to={"/admin/auth/login"} replace={true} />;
	}

	return (
		<>
			<UserPrivateComponent permission={"create-rolePermission"}>
				<Row className='mr-top' justify={"center"}>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={24}
						xl={24}
						className='border rounded column-design'>
						<Card bordered={false} className='criclebox h-full'>
							<Title level={3} className='m-3 text-center mb-5'>
								Adaugă Permisiune :{" "}
								<span className='text-primary'>{roleName}</span>
							</Title>

							{permissions?.length > 0 ? (
								<>
									<PermissionList
										permissionNames={permissions}
										setSelectedPermission={setSelectedPermission}
										selectedPermission={selectedPermission}
										existingPermissions={existingPermissions}
									/>

									<div className='text-center'>
										<Button
											className='m-3 w-80 '
											onClick={onFinish}
											type='primary'
											htmlType='submit'
											size='large'
											shape='round'
											loading={loading}>
											Permite Acum
										</Button>
										<Button
											className='m-3 w-80'
											onClick={toggleAllPermissions}
											type='default'
											size='large'
											shape='round'>
											{allChecked ? "Deselectați Toate" : "Selectează Toate"}
										</Button>
									</div>
								</>
							) : (
								<Loader />
							)}
						</Card>
					</Col>
				</Row>
			</UserPrivateComponent>
		</>
	);
};

export default AddPermission;
