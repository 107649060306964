import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    failureCause: null,
    error: "",
    total: null,
    loading: false,
};

// ADD failureCause
export const addFailureCause = createAsyncThunk(
    "failureCause/addFailureCause",
    async (values) => {
        try {
            const { data } = await axios.post(`failure-cause`, values);
            toast.success("Failure Cause Added");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in adding failure cause, try again");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

export const updateFailureCause = createAsyncThunk(
    "failureCause/updateFailureCause",
    async ({ id, values }) => {
        try {
            const { data } = await axios.put(`failure-cause/${id}`, values);
            toast.success("Failure Cause Updated");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in updating failure cause, try again");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

// DELETE failureCause
export const deleteFailureCause = createAsyncThunk(
    "failureCause/deleteFailureCause",
    async (id) => {
        try {
            await axios.delete(`failure-cause/${id}`);
            toast.success("Failure Cause Deleted");
            return {
                data: id,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in deleting failure cause, try again");
            console.log(error.message);
        }
    }
);

// DELETE Many failureCauses
export const deleteManyFailureCauses = createAsyncThunk(
    "failureCause/deleteManyFailureCauses",
    async (ids) => {
        try {
            await axios.post(`failure-cause?query=deletemany`, { ids });
            toast.success("Failure Causes Deleted");
            return {
                data: ids,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in deleting failure causes, try again");
            console.log(error.message);
        }
    }
);

// failureCause DETAILS
export const loadFailureCause = createAsyncThunk(
    "failureCause/loadFailureCause",
    async (id) => {
        try {
            const { data } = await axios.get(`failure-cause/${id}`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const loadAllFailureCausesCounts = createAsyncThunk(
    "failureCause/loadAllFailureCausesCounts",
    async (id) => {
        try {
            const { data } = await axios.get(`failure-cause/counts`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
)

// LOAD All failureCauses
export const loadAllFailureCauses = createAsyncThunk(
    "failureCause/loadAllFailureCauses",
    async () => {
        try {
            const { data } = await axios.get(`failure-cause`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const failureCauseSlice = createSlice({
    name: "failureCause",
    initialState,
    reducers: {
        clearFailureCause: (state) => {
            state.failureCause = null;
        },
    },
    extraReducers: (builder) => {
        // 1) ====== builders for loadAllFailureCausesCounts ======

        builder.addCase(loadAllFailureCausesCounts.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllFailureCausesCounts.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(loadAllFailureCausesCounts.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        // 2) ====== builders for loadAllFailureCauses ======

        builder.addCase(loadAllFailureCauses.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllFailureCauses.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(loadAllFailureCauses.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        // 3) ====== builders for addFailureCause ======

        builder.addCase(addFailureCause.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addFailureCause.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(addFailureCause.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(updateFailureCause.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateFailureCause.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(updateFailureCause.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
        // 4) ====== builders for loadFailureCause ======

        builder.addCase(loadFailureCause.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadFailureCause.fulfilled, (state, action) => {
            state.loading = false;
            state.failureCause = action.payload;
        });

        builder.addCase(loadFailureCause.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        // 5) ====== builders for deleteFailureCause ======

        builder.addCase(deleteFailureCause.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteFailureCause.fulfilled, (state, action) => {
            state.loading = false;
            state.list = state.list.filter(
                (failureCause) => failureCause.id !== action.payload.data
            );
        });

        builder.addCase(deleteFailureCause.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        // 6) ====== builders for deleteManyFailureCauses ======

        builder.addCase(deleteManyFailureCauses.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteManyFailureCauses.fulfilled, (state, action) => {
            state.loading = false;
            state.list = state.list.filter(
                (failureCause) => !action.payload.data.includes(failureCause.id)
            );
        });

        builder.addCase(deleteManyFailureCauses.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    },
});

export default failureCauseSlice.reducer;
export const { clearFailureCause } = failureCauseSlice.actions;
