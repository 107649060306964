import { Form, Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addSingleDesigner, loadAllDesigners, updateDesigner } from "../../../../../redux/rtk/features/crm/designer/designerSlice";
import { TEXTS } from "../../../../../constants";
import { loadAllStaff } from "../../../../../redux/rtk/features/user/userSlice";
import { useEffect } from "react";

export default function CreateDesignerForm({ onClose, edit }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { list: staffList, loading: staffLoading } = useSelector(
        (state) => state.users
    );

    useEffect(() => {
        dispatch(loadAllStaff({ status: true }));
    }, [dispatch]);

    const onFinish = async (values) => {
        // Find the selected owner from the staffList
        const selectedOwner = staffList.find((item) => item.id === parseInt(values.designerOwnerId));

        if (!selectedOwner) {
            console.error("Owner not found!");
            return;
        }

        // Prepare form data with the owner’s firstName and lastName
        const formData = {
            ...values,
            designerOwnerId: parseInt(values.designerOwnerId),
            designerFirstName: selectedOwner.firstName,
            designerLastName: selectedOwner.lastName,
        };

        const resp = await dispatch(
            edit?.id
                ? updateDesigner({ id: edit?.id, formData })
                : addSingleDesigner(formData)
        );

        if (resp.payload.message === "success") {
            dispatch(loadAllDesigners());
            if (!edit?.id) {
                onClose();
                form.resetFields();
            }
        }
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className="flex justify-center mt-5">
            <Form
                className='w-4/5'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                colon={false}
                layout='vertical'
                initialValues={edit?.id ? { ...edit?.values } : {}}
                form={form}
            >
                <Form.Item
                    label={"Designer/Proiectant"}
                    name={"designerOwnerId"}
                    tooltip={TEXTS.TEXT.REQUIRED_FIELD}
                    rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} Designer/Proiectant` }]}
                >
                    <Select
                        style={{ width: "100%" }}
                        loading={staffLoading}
                        allowClear
                        showSearch
                        placeholder={`${TEXTS.TEXT.SELECT} Designer/Proiectant`}
                    >
                        {staffList
                            ?.filter((item) =>
                                item.role.name === "Designer" || item.role.name === "Proiectant"
                            )
                            .map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item?.firstName} {item?.lastName}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item label=''>
                    <div className='flex items-center gap-2'>
                        <Button size={"large"} htmlType='submit' type='primary'>
                            {edit?.id ? `${TEXTS.TEXT.UPDATE}` : `${TEXTS.TEXT.CREATE}`}
                        </Button>
                        {!edit?.id && (
                            <Button
                                size={"large"}
                                htmlType='submit'
                                type='danger'
                                onClick={onCancel}
                            >
                                {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                            </Button>
                        )}
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}
