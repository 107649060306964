import { Button, Card, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
import CreateAttachmentForm from "../Attachment/CreateAttachmentForm";
import CreateDrawer from "./CreateDrawer";
import getImageUrl from "../../../utils/getimageUrl";
import { TEXTS } from "../../../constants";
import { useDispatch } from "react-redux";
import { deleteAttachment } from "../../../redux/rtk/features/crm/attachment/crmAttachmentSlice";
import { AiFillDelete } from "react-icons/ai";

export default function Attachments({ data, loading, name, singleLoadThunk, attachmentType, opportunityItem, opportunityId, title }) {
	// Drawer state
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const onClose = () => {
		setOpen(false);
	};

	const onDelete = (id) => {
		dispatch(deleteAttachment(id));
	}
	const filteredAttachments = data?.attachment?.filter(attachment => attachment.attachmentTypeId === attachmentType) || [];

	const columns = [
		{
			title: `${TEXTS.LABELS.LAST_NAME}`,
			key: "attachmentName",
			render: ({ attachmentName, attachmentPath }) =>
				attachmentName ? (
					<Link target='_blank' to={`${getImageUrl(attachmentPath)}`}>
						{attachmentName}
					</Link>
				) : (
					"-"
				),
			sorter: (a, b) => a.attachmentName.localeCompare(b.attachmentName),
		},

		{
			title: `${TEXTS.LABELS.OWNER}`,
			dataIndex: "attachmentOwner",
			key: "owner",
			render: (attachmentOwner, item) => (
				<Link to={`/admin/setup/staffs/${item?.noteOwnerId}`}>
					{attachmentOwner?.fullName}
				</Link>
			),
		},

		{
			title: `${TEXTS.LABELS.CREATE_DATE}`,
			key: "Create date",
			dataIndex: "createdAt",
			render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
		},
		{
			title: `${TEXTS.LABELS.ACTIONS}`,
			key: "actions",
			render: (text, record) => (
				<span
					onClick={() => onDelete(record.id)}
					className='bg-red-500 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded'
				>
					<AiFillDelete className='text-white' />
				</span>
			),
		},
	];

	return (
		<Card
			title={<span className='font-bold'>{title}</span>}
			bodyStyle={{ padding: 0 }}
			extra={
				<UserPrivateComponent permission='create-attachment'>
					<Button
						onClick={() => setOpen(true)}
						className='flex items-center'
						icon={<BiPlus />}>
						{TEXTS.BUTTON_TEXT.ADD_BTN}
					</Button>
				</UserPrivateComponent>
			}>
			<div>
				<UserPrivateComponent permission='readAll-attachment'>
					<Table
						bordered
						columns={columns}
						loading={loading}
						dataSource={data ? filteredAttachments : []}
						pagination={{ hideOnSinglePage: true }}
						scroll={{ x: 800, y: 300 }}
					/>
				</UserPrivateComponent>
			</div>

			<UserPrivateComponent permission='create-attachment'>
				<CreateDrawer onClose={onClose} open={open} title={"Attachment"}>
					<CreateAttachmentForm
						onClose={onClose}
						open={open}
						opportunityId={opportunityId}
						createAs={{ name, value: data?.id, attachment: attachmentType, attachmentValue: data?.attachmentTypeId, opportunityItem: opportunityItem, opportunityItemValue: data?.opportunityItemId, singleLoadThunk }}
						contactId={data?.contact?.id}
					/>
				</CreateDrawer>
			</UserPrivateComponent>
		</Card>
	);
}
