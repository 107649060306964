export const TEXTS = {
    FORM_TITLES: {
        USER_INFO: "Informație Utilizator",
        ADD_NEW_DESSIGNATION: "Adaugă Desemnare Nouă",
        ADD_NEW_EDUCATION: "Adaugă Educație Nouă",
        ADD_NEW_USER: "Adaugă Utilizator Nou",
        DESIGNATION_LIST: "Lista Desemnărilor",
        OPPORTUNITY_ROOMS: "Cămerile Proiectului",
        EDIT_DESIGNATION_FORM: "Forma de Actualizat Desemnări",
        DEPARTMENT_LIST: "Lista Departamentelor",
        EMPLOYEE_LIST: "Lista Angajaților",
        CUSTOMER_PROFILE: "Profilul Cumpărătorului",
        ADD_AWARD_HISTORY: "Adăugați Istoricul Premiilor",
    },
    LABELS: {
        ACTIONS: "Acțiuni",
        ATTACHMENT: "Atașare",
        ATTACHMENTS: "Atașări",
        ATTACHMENT_OWNER: "Destinatarul Atașamentului",
        ATTACHMENT_TYPE: "Tipul Atașamentului",
        AMMOUNT: "Sinecost",
        AMOUNT_SUM: "Sumă",
        PAID_AMMOUNT: "Suma plătită",
        TOTAL_AMMOUNT: "Preț Total",
        AMMOUNT_DUE: "Suma Datorată",
        ANNUAL_REVENUE: "Venituri Anuale",
        ACCOUNT_TYPE: "Tipul Accountului",
        ASSIGNEE: "Cesionar",
        ASSETS: "Active",
        CLICKS: "Clickuri",
        EXPENSE: "Cheltuieli",
        FIRST_NAME: "Prenume",
        LAST_NAME: "Nume",
        USERNAME: "Nume Utilizator",
        USER: "Utilizator",
        USERS: "Utilizatori",
        USERS_LIST: "Lista de Utilizatoru",
        BIRTHDATE: "Data Nașterii",
        PASSWORD: "Parolă",
        OLD_PASSWORD: "Parola Veche",
        NEW_PASSWORD: "Parola Nouă",
        PHONE: "Număr de Contact",
        PHONE_DIS: "Număr de Tel.",
        PHOTO: "Fotografie",
        PRICE_PER_UNIT: "Preț per Unitate",
        PRODUCTION_SUCCESSION: "Succesiune Producție",
        PARTNER: "Partener",
        ROOM: "Cameră",
        ROOMS: "Cămeri",
        ROOM_NAME: "Nume Cameră",
        ROOM_ITEMS: "Itemii Camerei",
        CONTACT_ROOM: "Camera Clientului",
        ADDRESS: "Adresă",
        STREET: "Strada",
        CITY: "Oraș",
        STATE: "Stat",
        ZIP_CODE: "Cod poștal",
        COUNTRY: "Țară",
        FROM: "De la",
        TO: "Înspre",
        SUBJECT: "Subiect",
        COLOR_CODE: "Codul de culori",
        CONTACT: "Client",
        CONTACTS: "Clienți",
        CONTACTS_BORD: "Bord Clienți",
        CONTACTS_PERSON: "Persoane Fizice",
        CONTACTS_HEAD: "Bord Vânzări",
        CONTACT_SOURCE: "Sursă Client",
        CONTACT_SOURCE_NAME: "Numele Sursă Client",
        CONTACT_STAGE: "Etapa",
        CONTACT_STAGE_NAME: "Numele Etapei De Contact",
        CONTACT_OWNER: "Responsabilul Contactului",
        CONTRACT_NAME: "Numele Contractului",
        COMPANY: "Persoană Juridică",
        COMPANY_RE: "Pers. Juridică",
        COMPANIES: "Persoane Juridice",
        COMPANY_TYPE: "Tipul Companiei",
        COMPANY_TYPE_NAME: "Numele Tipului Companiei",
        COMPANY_OWNER: "Administrator",
        COMPANY_SIZE: "Mărimea Companiei",
        COMPANY_MANAGE: "Setarea Companiei",
        COMPETITORS: "Competitori",
        CAUSE_OF_FAILURE: "Cauza Eșecului",
        CONFING_NAME: "Numele Configurării",
        DETAILS: "Detalii",
        DESIGNER: "Proiectant/Designer",
        EQUITY: "Capital",
        EQUITY_AND_LIABILITY: "Datorii și Capital",
        FINANCE: "Bord Finanțe",
        INDUSTRY: "Industrie",
        INDUSTRY_NAME: "Numele Industriei",
        IMAGE: "Imagine",
        JOB_TITLE: "Funcție",
        LIABILITIES: "Datorii",
        OPPORTUNITY: "Proiect",
        OPPORTUNITIES: "Proiecte",
        OPPORTUNITIES_HEAD: "Bord Producere",
        OPPORTUNITY_CREATE_DATE: "Data de Creare a Proiectului",
        OPPORTUNITY_CLOSE_DATE: "Data de Închidere a Proiectului",
        OPPORTUNITY_SOURCE: "Lista de Proiecte",
        OPPORTUNITY_SOURCE_NAME: "Numele Listei de Proiecte",
        OPPORTUNITY_STAGE: "Etapa de Proiectare",
        OPPORTUNITY_STAGE_NAME: "Numele Etapei de Proiectare",
        OPPORTUNITY_TYPE: "Tipul Proiectului",
        OPPORTUNITY_TYPE_NAME: "Numele Tipului Proiectului",
        OPPORTUNITY_NAME: "Numele Proiectului",
        OPPORTUNITY_OWNER: "Responsabil de Proiectului",
        OWNER: "Responsabil",
        TASK: "Sarcină",
        TASKS: "Sarcini",
        TASK_NAME: "Numele Sarcinei",
        TASK_STATUS: "Starea Sarciniei",
        TASK_STATUS_NAME: "Numele Stării Sarcinei",
        TASK_TYPE: "Tip de Sarcină",
        TASK_TYPE_NAME: "Nume Tipului Sarcinei",
        TASK_PRIORITY: "Prioritate de Sarcină",
        TASK_PRIORITY_NAME: "Nume Prioritare de Sarcină",
        QUOTE: "Estimare",
        QUOTES: "Bord Tehnic",
        QUOTE_NAME: "Nume Estimare",
        QUOTE_STAGE: "Etapa Estimării",
        QUOTE_STAGE_NAME: "Numele Etapii de Estimări",
        QUOTE_INFORMATION: "Informație Estimare",
        REVENUE: "Venit",
        TICKETS: "Tichete",
        TICKET_CATEGORY: "Categoria Tichetului",
        TICKET_PRIORITY: "Prioritatea Tichetului",
        TICKET_STATUS: "Statutul Tichetului",
        ANNOUNCEMENT: "Anunț",
        ANNOUNCEMENTS: "Anunțuri",
        TRANSACTION_LIST: "Lista de tranzacții",
        TRIAL_BALANCE: "Balanț de Probă",
        BALANCE_SHEET: "Bilanț",
        INCOME_STATEMENT: "Adeverința de Venit",
        AWARDS: "Premii",
        AWARD: "Premiu",
        AWARD_NAME: "Numele Premiului",
        AWARD_DATE: "Data Premierii",
        AWARD_LIST: "Lista Premiilor",
        ORGANIZATION: "Organizație",
        EMAIL_CONFIG: "Configurare Email",
        DATE: "Data",
        CREATE_DATE: "Data de Creare",
        CLOSE_DATE: "Data de Închidere",
        QUOTATION_DATE: "Data Estimării",
        EXPIRATION_DATE: "Data Expirării",
        JOIN_DATE: "Data alăturării",
        LEAVE_DATE: "Data Plecării",
        START_DATE: "Data de Început",
        START_TIME: "Ora de Început",
        END_DATE: "Data de Sfârșit",
        END_TIME: "Ora de Sfârșit",
        UPDATE_DATE: "Data Actualizării",
        DUE_DATE: "Termenul",
        MY_PROFILE: "Profilul Meu",
        NOTE: "Mesaj",
        NOTES: "Mesaje",
        EMPLOYEE: "Angajat",
        EMPLOYEE_ID: "ID Angajat",
        NEW_EMPLOYEE: "Angajat Nou",
        PRIORITY: "Prioritate",
        PARTICULARS: "Indicații",
        EMPLOYEE_LIST: "Lista Angajați",
        ROLE_AND_PERMISSION: "Roluri si Permisiuni",
        FIELD_OF_STUDY: "Domeniul de Studii",
        BLOOD_GROUP: "Grupa Sangvină",
        EMPLOYMENT_STATUS: "Statutul Angajatului",
        DEPARTMENT: "Departament",
        PRODUCTS: "Produse",
        PRODUCT: "Produs",
        PRODUCT_NAME: "Nume Produs",
        PURCHASE_NEW: "Cumpără Nou",
        PRODUCT_LIST: "Lista de Produse",
        RESULT: "Rezultat",
        ROLE: "Rol",
        SALES: "Vânzări",
        SHIFT: "Schimb",
        STATUS: "Statut",
        STAGE: "Etapa",
        TITLE: "Titlu",
        NOTE_OWNER: "Destinatarul Notiței",
        SUPPLIERS: "Furnizori",
        DESCRPTION: "Descriere",
        DESIGNATION: "Desemnare",
        DESIGNATION_START_DATE: "Data de Start a Desemnării",
        DESIGNATION_END_DATE: "Data de Sfârșit a Desemnării",
        DEGREE: "Gradul",
        INSTITUTION: "Instituția",
        ITEMS: "Elemente",
        OTHERS: "Extra",
        SALARY: "Salariu",
        SALARY_START_DATE: "Data de Start al Salariului",
        SALARY_END_DATE: "Data de Sfârșit al Salariului",
        COMMENT: "Comentariu",
        UNIT: "Unitate de Măsură",
    },
    TITLES: {
        DESIGNATION_EDIT: "Editează Desemnarea",
        ACCOUNT_EDIT: "Editează Accountul",
        ACCOUNTS_LIST: "Lista de Accounturi",
        NOTE_EDIT: "Editează Notița",
        RESET_PASSWORD: "Resetează Parola",
        EDUCATION_EDIT: "Editează Educația",
        EMPLOYEE_NAME: "Numele Angajatului",
        EMPLOYEE_INFO_EDIT: "Editează Informația Angajator",
        SHIFT_EDIT: "Editează Schimbul",
        ADD_SALARY: "Adaugă Salariu",
        SALARY_EDIT: "Editează Salariu",
        CREATE_A: "Crează un ",
        CREATED_AT: "Creat la",
        ADD_NEW_DEPARTMENT: "Adaugă Departament Nou",
        ADD_AWARD: "Adaugă Premiu",
        STAFFS_INFORMATION: "Informația Personalului",
        RECENT_CONTACTS: "Clienți Recenți",
        RECENT_COMPANIES: "Companii Recente",
        RECENT_OPPORTUNITIES: "Oportunități Recente",
        RECENT_QUOTES: "Estimări Recente",
        CREATE_ATTACHMENT: "Crează Atașament",
        PRICE_SUMMARY: "Rezumatul Prețurilor",
    },
    TEXT: {
        USER_INFO_TEXT: "Informație Utilizator",
        ADDRESS_INFO_TEXT: "Informație de Adresă",
        BALANCE: "Balanț",
        BILLING_ADDRESS: "Adresa Sediului Juridic",
        BILLING_STREET: "Strada Sediului Juridic",
        BILLING_CITY: "Orașul Sediului Juridic",
        BILLING_ZIP_CODE: "Codul Poștal a Sediului Juridic",
        BILLING_COUNTRY: "Orașul de Facturare",
        SHIPPING_ADDRESS: "Adresa oficiului",
        SHIPPING_STREET: "Strada oficiului",
        SHIPPING_CITY: "Orașul Oficiului",
        SHIPPING_ZIP_CODE: "Codul Poștal al Oficiului",
        SHIPPING_STATE: "Statul de Transport",
        SHIPPING_COUNTRY: "Țara de Transport",
        REQUIRED_FIELD: "Aceast câmp este obligatoriu",
        PRESENT: "Prezentă",
        PRESENT1: "Prezent",
        PERMANENT: "Permanentă",
        PERMANENT1: "Permanent",
        SELECT: "Selectați",
        EMPLOYEE_INFO_TEXT: "Informație Angajat",
        COMPANY_TYPE: "Tipul Companiei",
        COMPANY_SIZE: "Mărimea Companiei",
        DESCRIPTION_TEXT: "Descrieți despre",
        SIZE: "Mărimea",
        FILTER: "Filtrează",
        SHIFTS_LIST_TEXT: "Lista de schimburi",
        SEARCH: "Caută",
        LOADER: "Se încarcă, Vă rugăm să așteptați ...",
        NO_TASK_FOUND: "Sarcina nu a fost găsită",
        NEXT_STEP: "Următorul Pas",
        HOURS: "Ore",
        IMAGE: "Imagine",
        INVOICE: "Facturi",
        EMPLOYEE_LIST: "Lista Angajați",
        YES_PLEASE: "Da, Vă Rog!",
        ON_DELETE_TEXT: "Ești sigur că dorești să ștergi?",
        NEED_SUPPORT: "VĂ TREBUIE AJUTOR?",
        HERE_TO_HELP: "SUNTEM AICI SĂ VĂ AJUTĂM",
        VIEW: "Uităte",
        VIEW_MORE: "Uite Mai Multe",
        TRUE: "Adevăr",
        TITLE: "Titlu",
        TYPE: "Tip",
        FALSE: "Fals",
        VALUE: "Valoare",
        PRICE: "Preț",
        CREATE: "Crează",
        DELETE: "Șterge",
        UPDATE: "Actualizare",
        LOST: "Se pare că te-ai pierdut !",
        PAGE_NOT_AVAILABLE: "Pagina nu a fost găsită",
        UPLOAD_CSV: "Selectați CSV file pentru a-l încărca",
        IMPORT_CSV: "Importă din CSV",
        CONFIG: "Configurare",
        QUANTITY: "Cantitate",
        SOURCE: "Sursă",
        RECIEVED_BY: "Primit de",
        AUTHORIZED_BY: "Autorizat de",
        UNIT_PRICE: "Preț Unitar",
        TOTAL_PRICE: "Preț Total",
        GRAND_TOTAL: "Total General",
        DISCOUNT: "Reducere",
        IN_WORDS: "În Cuvinte",
        TERMS_AND_CONDITIONS: "Termeni și Condiții",
    },
    MESSAGES: {
        DESSIGNATION_EDITED: "Detaliile Desemnării au fost Actualizate cu Succes",
        AMMOUNT_MESSAGE: "Introduceți cantitatea Dorită!",
        ATTACHMENT_OWNER_MESSAGE: "Proprietarul Atașamentului este necesar",
        ATTACHMENT_TYPE_MESSAGE: "Tipul Atașamentului este necesar",
        OPPORTUNITY_NAME_MESAGE: "Numele Proiectului este necesa.",
        AWARD_DATE_MESSAGE: "Introduceți Data Premierii!",
        AWARD_MESSAGE: "Introduceți Premiul",
        AWARD_DESCRIPTION_MESSAGE: "Introduceți Descrierea Premiului!",
        DESSIGNATION_MESSAGE: "Introduceți Desemnarea Dorită!",
        DESSIGNATION_NAME_MESSAGE: "Introduceți Denumirea Desemnării!",
        DEGREE_MESSAGE: "Introduceți Gradul Dorit!",
        DEBIT_ACCOUNT_MESSAGE: "Introduceți Account Debit!",
        END_DATE_MESSAGE: "Introduceți Data de Sfârșit!",
        FIELD_OF_STUDY_MESSAGE: "Introduceți Domeniul de Studii!",
        FIRST_NAME_MESSAGE: "Introduceți Prenumele Dorit!",
        LAST_NAME_MESSAGE: "Introduceți Numele Dorit!",
        USERNAME_MESSAGE: "Introduceți Numele de Utilizator!",
        EMAIL_MESSAGE: "Introduceți Email-ul Dorit!",
        PHONE_MESSAGE: "Introduceți Numărul de Contact!",
        STREET_MESSAGE: "Introduceți Strada Dorită!",
        CITY_MESSAGE: "Introduceți Orașul Dorit!",
        STATE_MESSAGE: "Introduceți Statul Dorit!",
        ZIP_CODE_MESSAGE: "Introduceți Codul Poștal!",
        COUNTRY_MESSAGE: "Introduceți Țara Dorită!",
        BLOOD_GROUP_MESSAGE: "Introduceți Grupa Sangvină!",
        DEPARTMENT_MESSAGE: "Introduceți Departamentul Dorit!",
        EMPLOYEE_ID_MESSAGE: "Introduceți ID Angajat!",
        JOIN_DATE_MESSAGE: "Introduceți Data alăturării",
        RESULT_MESSAGE: "Introduceți Rezultatul!",
        SALARY_MESSAGE: "Introduceți Salariul Dorit!",
        START_DATE_MESSAGE: "Introduceți Data de Început!",
        SUPPLIERS_NAME_MESSAGE: "Introduceți Numele Furnizorului!",
        SUPPLIERS_ADRESS_MESSAGE: "Introduceți Adresa Furnizorului!",
        NAME_REQUIRED_MESSAGE: "Este Necesar numele",
        REQUIRED_MESSAGE: "Este Necesar",
        SHIFT_MESSAGE: "Introduceți Schimbul Dorit!",
        SELECT_MESSAGE: "Vă rugăm să selectați",
        INSTITUTION_MESSAGE: "Introduceți Instituția Dorită!",
        PASSWORD_MESSAGE: "Introduceți Parola Dorită!",
        TYPE_MESSAGE: "Selectați Tipul Dorit!",
        TITLE_MESSAGE: "Introduceți Titlul Dorit!",
        UPLOAD_MESSAGE: "Încărcați un file",
        DELETE_IMAGE_MESSAGE: "Ești sigur că dorești să ștergi imaginea?",
    },
    PLACEHOLDERS: {
        ASSIGNEE_PLACEHOLDER: "Selectează Cesionarul acestei sarcini",
        ATTACHMENT_PLACEHOLDER: "Selectează Atașamentul",
        ATTACHMENT_TYPE: "Selectează Tipul Atașamentului",
        ACCOUNT_TYPE_PLACEHOLDER: "Selectează Tipul Accountului",
        AWARD_PLACEHOLDER: "Selectează Premiu",
        TASK_TYPE_PLACEHOLDER: "Selectează Tipul Sarcinei",
        TASK_PRIORITY_PLACEHOLDER: "Selectează Prioritatea Sarcinei",
        TASK_STATUS_PLACEHOLDER: "Selectează Statutul Sarcinei",
        BLOOD_GROUP_PLACEHOLDER: "Selectează Grupa Sangvină",
        DESSIGNATION_PLACEHOLDER: "Selectează Desemnarea",
        DEPARTMENT_PLACEHOLDER: "Selectează Departamentul",
        DUE_DATE_PLACEHOLDER: "Selectează Termenul",
        INDUSTRY_PLACEHOLDER: "Selectează Industria",
        ROLE_PLACEHOLDER: "Selectează Rolul",
        ROOM_PLACEHOLDER: "Selectează Camera",
        SHIFT_PLACEHOLDER: "Selectează Schimbul",
        SELECT_ATTACHMENT_OWNER: "Selectează Proprietarul Atașamentului",
        COMPANY_PLACEHOLDER: "Selecteză Persoana Juridică",
        COMPANY_OWNER_PLACEHOLDER: "Selectează Responsabilul de Companiei",
        CONTACT_PLACEHOLDER: "Selectează Clientul",
        PASSWORD_PLACEHOLDER: "Introdu Parola Utilizator",
        OPPORTUNITY_PLACEHOLDER: "Selectează Proiectul",
        QUOTE_PLACEHOLDER: "Selectează Estimarea",
        QUOTE_OWNER_PLACEHOLDER: "Selectează Responsabilul estimării",
        STAGE_PLACEHOLDER: "Selectează Etapa",
        QUOTATION_DATE_PLACEHOLDER: "Selectează Data Estimării",
        TYPE_PLACEHOLDER: "Selectează Tipul",
        NOTE_OWNER: "Selectează Notița Proprietarului",
        CONTACT_DESCRIPTION: "Descrie despre Client",
        OWNER_NAME_PLACEHOLDER: "Selectează Numele Proprietarului",
        OPPORTUNITY_OWNER_PLACEHOLDER: "Selectează Responsabilu de Proiect",
        PRODUCT_PLACEHOLDER: "Selectează Produsul",
        OPPORTUNITY_TYPE_PLACEHOLDER: "Selectează Tipul Proiectului",
        SOURCE_PLACEHOLDER: "Selectează Sursa",
        CREATE_DATE_PLACEHOLDER: "Selectează Data de Creare",
        CLOSE_DATE_PLACEHOLDER: "Selectează Data de Închidere",
        UNIT_PLACEHOLDER: "Selectează unitatea de măsură",
    },
    BUTTON_TEXT: {
        UPDATE_BTN: "Actualizează Acum",
        UPDATE_SHIFT_BTN: "Actualizează Schimbul",
        UPDATE_ACCOUNT_BTN: "Actualizează Accountul",
        ADD_BTN: "Adaugă Acum",
        ADD_SALARY_BTN: "Adaugă Salariu Nou",
        ADD_NEW_AWARD: "Adaugă Premiu Nou",
        ADD_NEW_ACCOUNT: "Adaugă Account Nou",
        ADD_TASK_COLUMN_BTN: "Adaugă Coloana De Statutul Sarciniei",
        ADD_TASK_BTN: "Adaugă Sarcină",
        ADD_PRODUCT_BTN: "Adaugă Produs",
        ADD_ANNOUNCEMENT: "Adaugă Anunț",
        CLOSE_BTN: "Închide",
        ADD_EMPLOYMENT_STATUS: "Adaugă Statut de Angajare",
        ADD_DESSIGNATION_BTN: "Adaugă Desemnare Nouă",
        REMOVE_BTN: "Elimină",
        SAVE_BTN: "Salvează",
        UPLOAD: "Încărcați",
        SUBMIT_BTN: "Aplică",
        DOWNLOAD_CSV: "Descarcă CSV",
    }
}

export const CURRENCY = {
    EURO: 19.50,
}