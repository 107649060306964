import axios from "axios";
import { toast } from "react-toastify";

// Get Roles
export const getRoles = async () => {
  const { data } = await axios.get(`role?query=all`);
  return data;
};

// Create Role

export const addRole = async (values) => {
  try {
    const { data } = await axios({
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: `role`,
      data: {
        ...values,
      },
    });
    //dispatching data
    toast.success("Adăugat cu succes");

    return {
      data,
      message: "success",
    };
  } catch (error) {
    toast.error("Eroare în adăugarea rolului, încearcă din nou");
    console.log(error.message);
    return {
      message: "error",
    };
  }
};

// Detail Role View

export const loadSingleRole = async (id) => {
  //dispatching with an call back function and returning that

  try {
    const { data } = await axios.get(`role/${id}`);
    return {
      data,
      message: "Success",
    };
    //dispatching data
  } catch (error) {
    console.log(error.message);
  }
};

//Add Permission in Role

export const addPermission = async (values) => {
  try {
    const { data } = await axios({
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: `role-permission`,
      data: {
        ...values,
      },
    });
    //dispatching data
    toast.success("Added successful");

    return {
      data,
      message: "success",
    };
  } catch (error) {
    toast.error("Eroare în adăugarea Permisiunei, Înceară din nou");
    console.log(error.message);
    return {
      message: "error",
    };
  }
};

// Get Permsiion
export const loadPermission = async () => {
  const { data } = await axios.get(`permission?query=all`);
  return data;
};

export const updateRolePermissions = async (values) => {
  try {
    const { data } = await axios({
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: `role-permission/`, // Assuming the same endpoint
      data: values,
    });
    toast.success("Permisiuni actualizate cu succes");

    return {
      data,
      message: "success",
    };
  } catch (error) {
    toast.error("Eroare în actualizarea permisiunilor, încearcă din nou");
    console.log(error.message);
    return {
      message: "error",
    };
  }
};

// Action creator
export const deleteRolePermission = (ids) => async (dispatch) => {
  try {
    const { data } = await axios({
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: "/role-permission?query=deletemany", // No query parameters in URL
      data: ids, // IDs sent in the request body
    });

    toast.success("Șters cu succes");
    return {
      type: 'DELETE_ROLE_PERMISSION_SUCCESS',
      payload: { message: "success", data },
    };
  } catch (error) {
    toast.error("Eroare în ștergerea permisiunii, încearcă din nou");
    return {
      type: 'DELETE_ROLE_PERMISSION_FAILURE',
      payload: { message: "error" },
    };
  }
};
