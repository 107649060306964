import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadAllCompanyPaginated } from "../../../redux/rtk/features/crm/company/companySlice";
import { loadAllContactPaginated } from "../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllOpportunityPaginated } from "./../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllQuotePaginated } from "./../../../redux/rtk/features/crm/quote/quoteSlice";
import DashboardTable from "./DashboardTable";
import { TEXTS } from "../../../constants";

const contactColumns = [
  {
    title: `${TEXTS.LABELS.LAST_NAME}`,
    key: "name",
    render: ({ firstName, lastName, id }) =>
      id ? (
        <Link to={`/admin/contact/${id}`}>
          {firstName} {lastName}
        </Link>
      ) : (
        "-"
      ),
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
  },
  {
    title: `${TEXTS.LABELS.PHONE}`,
    key: "phone",
    dataIndex: "phone",
  },
  {
    title: `${TEXTS.LABELS.CREATE_DATE}`,
    dataIndex: "createdAt",
    render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
  },
];

const companyColumns = [
  {
    title: `${TEXTS.LABELS.LAST_NAME}`,
    key: "COMPANY NAME",
    render: ({ companyName, id }) =>
      id ? <Link to={`/admin/company/${id}`}>{companyName}</Link> : "-",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: `${TEXTS.LABELS.PHONE}`,
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: `${TEXTS.LABELS.CREATE_DATE}`,
    dataIndex: "createdAt",
    render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
  },
];

const opportunityColumns = [
  {
    title: `${TEXTS.LABELS.LAST_NAME}`,
    key: "Opportunity Name",
    render: ({ opportunityName, id }) =>
      id ? <Link to={`/admin/opportunity/${id}`}>{opportunityName}</Link> : "-",
  },
  {
    title: `${TEXTS.LABELS.OWNER}`,
    dataIndex: "opportunityOwner",
    key: "owner",
    render: (opportunityOwner, item) => (
      <Link to={`/admin/setup/staffs/${item?.opportunityOwnerId}`}>
        {opportunityOwner.firstName} {opportunityOwner.lastName}
      </Link>
    ),
  },
  {
    title: `${TEXTS.LABELS.AMMOUNT}`,
    dataIndex: "amount",
    key: "amount",
    amount: "amount",
  },
  {
    title: `${TEXTS.LABELS.CREATE_DATE}`,
    dataIndex: "createdAt",
    render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
  },
];

const quoteColumns = [
  {
    title: `${TEXTS.LABELS.LAST_NAME}`,
    key: "quoteName",
    render: ({ quoteName, id }) =>
      id ? (
        <Link to={`/admin/quote/${id}`}>
          {quoteName || "-"}
        </Link>
      ) : (
        "-"
      ),
  },
  {
    title: `${TEXTS.LABELS.OWNER}`,
    dataIndex: "quoteOwner",
    render: (quoteOwner, item) =>
      quoteOwner ? (
        <Link to={`/admin/setup/staffs/${item?.quoteOwnerId || "-"}`}>
          {quoteOwner.firstName || "-"} {quoteOwner.lastName || "-"}
        </Link>
      ) : (
        "-"
      ),
  },
  {
    title: `${TEXTS.LABELS.QUOTATION_DATE}`,
    dataIndex: "quotationDate",
    render: (date) => date ? moment(date).format("MMMM Do YYYY HH:mm") : "-",
  },
  {
    title: `${TEXTS.LABELS.CREATE_DATE}`,
    dataIndex: "createdAt",
    render: (date) => date ? moment(date).format("MMMM Do YYYY HH:mm") : "-",
  },
];


export default function Content() {
  const dispatch = useDispatch();
  const { list: contactList, contactLoading } = useSelector(
    (state) => state.contact
  );
  const { list: companyList, companyLoading } = useSelector(
    (state) => state.company
  );
  const { list: opportunityList, opportunityLoading } = useSelector(
    (state) => state.opportunity
  );
  const { list: quoteList, quoteLoading } = useSelector((state) => state.quote);

  useEffect(() => {
    dispatch(loadAllContactPaginated({ status: true, count: 5 }));
    dispatch(loadAllCompanyPaginated({ status: true, count: 5 }));
    dispatch(loadAllOpportunityPaginated({ status: true, count: 5 }));
    dispatch(loadAllQuotePaginated({ status: true, count: 5 }));
  }, [dispatch]);
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-3 pb-3'>
      <div className='w-full md:col-span-1'>
        <DashboardTable
          list={contactList}
          loading={contactLoading}
          title={TEXTS.TITLES.RECENT_CONTACTS}
          columns={contactColumns}
          slug={"contact"}
        />
      </div>
      <div className='w-full md:col-span-1'>
        <DashboardTable
          list={quoteList}
          loading={quoteLoading}
          title={TEXTS.TITLES.RECENT_QUOTES}
          columns={quoteColumns}
          slug={"quote"}
        />
      </div>
    </div>
  );
}
