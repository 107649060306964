import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteManyCompany,
  loadAllCompanyPaginated,
} from "../../../redux/rtk/features/crm/company/companySlice";
import { loadAllCompanyType } from "../../../redux/rtk/features/crm/companyType/companyTypeSlice";
import { loadAllIndustry } from "../../../redux/rtk/features/crm/industry/industrySlice";
import { loadAllStaff } from "../../../redux/rtk/features/user/userSlice";
import ViewBtn from "../../Buttons/ViewBtn";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
import CreateDrawer from "../CommonUi/CreateDrawer";
import CrmSearch from "../CommonUi/CrmSearch";
import TableComponent from "../CommonUi/TableComponent";
import CreateCompanyForm from "./CreateCompanyForm";
import { TEXTS } from "../../../constants";
import CreateContractForm from "./CompanyDetails/Contracts/CreateContractForm";

const columns = [
  {
    title: `${TEXTS.LABELS.LAST_NAME}`,
    key: "COMPANY NAME",
    render: ({ companyName, id }) =>
      id ? <Link to={`/admin/company/${id}`}>{companyName}</Link> : "-",
    sorter: (a, b) => a.companyName.localeCompare(b.companyName),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: `${TEXTS.LABELS.PHONE}`,
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: `${TEXTS.LABELS.COMPANY_OWNER}`,
    dataIndex: "companyAdmin",
    key: "companyAdmin",
  },
  {
    title: `${TEXTS.TEXT.TYPE}`,
    dataIndex: "companyType",
    render: (companyType) => companyType?.companyTypeName,
  },
  {
    title: `${TEXTS.TEXT.SIZE}`,
    dataIndex: "companySize",
    key: "companySize",
  },

  {
    title: `${TEXTS.LABELS.ANNUAL_REVENUE}`,
    dataIndex: "annualRevenue",
    key: "annualRevenue",
  },
  {
    title: `${TEXTS.LABELS.INDUSTRY}`,
    dataIndex: "industry",
    render: (industry) => industry?.industryName,
  },
  {
    title: `${TEXTS.LABELS.ACTIONS}`,
    dataIndex: "id",
    key: "action",
    render: (id) => (
      <div className="flex justify-start">
        <UserPrivateComponent permission="readSingle-company">
          <ViewBtn path={`/admin/company/${id}`} />
        </UserPrivateComponent>
      </div>
    ),
  },
];

const Companies = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  // Drawer state
  const [open, setOpen] = useState(false);
  const [openContract, setOpenContract] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onCloseContract = () => {
    setOpenContract(false);
  }
  // selector
  const { list, loading, total } = useSelector((state) => state.company);
  // filter
  const [isFilter, setFilter] = useState(false);
  const filterToggle = () => {
    setFilter((prev) => !prev);
  };
  const companyType = useSelector((state) => state.companyType.list) || [];
  const industry = useSelector((state) => state.industry.list) || [];
  const companyOwner = useSelector((state) => state.users.list) || [];

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    dispatch(loadAllCompanyPaginated({ ...values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    dispatch(
      loadAllCompanyPaginated({
        status: true,
        page: 1,
        count: 10,
      })
    );
    dispatch(loadAllCompanyType());
    dispatch(loadAllIndustry());
    dispatch(loadAllStaff({ status: true }));
  }, [dispatch]);

  return (
    <div className="container">
      <div className="bg-white dark:bg-DTableBg  rounded-lg flex flex-col  md:flex-row justify-between items-center gap-3 p-3 px-6 border">
        <div className="flex items-center gap-5">
          <button
            onClick={filterToggle}
            className={`hidden md:block ${isFilter ? "bg-transparent border border-red-600" : "bg-blue-500"
              }  px-5 py-2 rounded cursor-pointer`}
            type="submit"
          >
            {isFilter ? (
              <span className="flex justify-center dark:text-white items-center gap-2">
                <AiOutlineClose /> {TEXTS.BUTTON_TEXT.CLOSE_BTN}
              </span>
            ) : (
              <span className="text-white">{TEXTS.TEXT.FILTER}</span>
            )}
          </button>
          {!isFilter ? (
            <CrmSearch paginatedThunk={loadAllCompanyPaginated} />
          ) : (
            <div className="flex text-md">
              <Form
                className="flex flex-wrap justify-center gap-2 md:items-center"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="inline"
                initialValues={{
                  status: ["true"],
                }}
              >
                <Form.Item name="companyOwner" className="w-full md:w-[180px]">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={TEXTS.PLACEHOLDERS.COMPANY_OWNER_PLACEHOLDER}
                    maxTagCount={0}
                    showArrow
                    maxTagPlaceholder={
                      <div className="w-[150px]">{TEXTS.LABELS.COMPANY_OWNER}</div>
                    }
                  >
                    {companyOwner?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item?.firstName} {item?.lastName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="companyType" className="w-full md:w-[180px]">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={TEXTS.PLACEHOLDERS.TYPE_PLACEHOLDER}
                    maxTagCount={0}
                    showArrow
                    maxTagPlaceholder={<div className="w-[150px]">{TEXTS.TEXT.TYPE}</div>}
                  >
                    {companyType?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.companyTypeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="industry" className="w-full md:w-[180px]">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={TEXTS.PLACEHOLDERS.INDUSTRY_PLACEHOLDER}
                    maxTagCount={0}
                    showArrow
                    maxTagPlaceholder={
                      <div className="w-[150px]">{TEXTS.LABELS.INDUSTRY}</div>
                    }
                  >
                    {industry?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.industryName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="status" className="w-full md:w-[180px]">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={TEXTS.MESSAGES.SELECT_MESSAGE}
                    defaultValue={["true"]}
                  >
                    <Option value="true">{TEXTS.TEXT.TRUE}</Option>
                    <Option value="false">{TEXTS.TEXT.FALSE}</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <div className="w-full md:w-[180px] flex justify-start">
                    <button
                      className="bg-blue-500 text-white px-5 py-2 rounded cursor-pointer"
                      type="submit"
                    >
                      {TEXTS.TEXT.FILTER}
                    </button>
                    <button
                      onClick={filterToggle}
                      className="block md:hidden  px-5 py-2 rounded cursor-pointer ml-2 text-rose-700 transition-colors duration-150 border border-rose-500 focus:shadow-outline hover:bg-rose-500 hover:text-rose-100"
                    >
                      {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                    </button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
        {!isFilter && (
          <UserPrivateComponent permission="create-contact">
            <button
              onClick={() => setOpenContract(true)}
              className="py-2 px-3 w-[195px] border bg-lime-700 hover:bg-lime-500 text-white rounded cursor-pointer flex items-center gap-2"
            >
              <AiOutlinePlus />{TEXTS.TEXT.CREATE} Contract
            </button>
            <button
              onClick={() => setOpen(true)}
              className="py-2 px-3 w-[195px] border bg-lime-700 hover:bg-lime-500 text-white rounded cursor-pointer flex items-center gap-2"
            >
              <AiOutlinePlus />{TEXTS.TEXT.CREATE} {TEXTS.LABELS.COMPANY_RE}
            </button>
          </UserPrivateComponent>
        )}
      </div>
      <UserPrivateComponent permission="readAll-company">
        <TableComponent
          list={list}
          total={total}
          loading={loading}
          columns={columns}
          paginatedThunk={loadAllCompanyPaginated}
          deleteManyThunk={deleteManyCompany}
          csvFileName={"Company-List"}
        />
      </UserPrivateComponent>

      <UserPrivateComponent permission="create-company">
        <CreateDrawer onClose={onClose} open={open} title={TEXTS.LABELS.COMPANY}>
          <CreateCompanyForm onClose={onClose} open={open} />
        </CreateDrawer>
      </UserPrivateComponent>
      <UserPrivateComponent permission="create-company">
        <CreateDrawer onClose={onCloseContract} open={openContract} title='Contract'>
          <CreateContractForm onClose={onCloseContract} open={openContract} />
        </CreateDrawer>
      </UserPrivateComponent>
    </div>
  );
};
export default Companies;
