import { Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { addSinglePartner, loadAllPartners, updatePartner } from "../../../../../redux/rtk/features/crm/partner/partnerSlice";
import { TEXTS } from "../../../../../constants";

export default function CreatePartnerForm({ onClose, edit }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onFinish = async (values) => {
        const resp = await dispatch(
            edit?.id
                ? updatePartner({ id: edit?.id, values })
                : addSinglePartner(values)
        );
        if (resp.payload.message === "success") {
            dispatch(loadAllPartners());
            if (!edit?.id) {
                onClose();
                form.resetFields();
            }
        }
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className="flex justify-center mt-5">
            <Form
                className='w-4/5'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                colon={false}
                layout='vertical'
                initialValues={edit?.id ? { ...edit?.values } : {}}
                form={form}
            >
                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.LABELS.FIRST_NAME}
                    name='partnerFirstName'
                    tooltip={TEXTS.TEXT.REQUIRED_FIELD}
                    rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.PARTNER}` }]}
                >
                    <Input placeholder={TEXTS.LABELS.FIRST_NAME} />
                </Form.Item>
                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.LABELS.LAST_NAME}
                    name='partnerLastName'
                    tooltip={TEXTS.TEXT.REQUIRED_FIELD}
                    rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.PARTNER}` }]}
                >
                    <Input placeholder={TEXTS.LABELS.LAST_NAME} />
                </Form.Item>
                <Form.Item label=''>
                    <div className='flex items-center gap-2'>
                        <Button size={"large"} htmlType='submit' type='primary'>
                            {edit?.id ? `${TEXTS.TEXT.UPDATE}` : `${TEXTS.TEXT.CREATE}`}
                        </Button>
                        {!edit?.id && (
                            <Button
                                size={"large"}
                                htmlType='submit'
                                type='danger'
                                onClick={onCancel}
                            >
                                {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                            </Button>
                        )}
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}