import React, { createContext, useEffect, useState, useContext } from 'react';
import getUserFromToken from '../../utils/getUserFromToken';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const user = getUserFromToken(); // Fetch the authenticated user

    useEffect(() => {
        if (user) {
            setIsAuthenticated(true); // Set the authenticated state
        } else {
            setIsAuthenticated(false);
        }
    }, [user]);

    useEffect(() => {
        let ws;
        if (isAuthenticated) {
            // Initialize WebSocket connection if the user is authenticated
            ws = new WebSocket(
                process.env.NODE_ENV === 'production'
                    ? 'wss://crm.mobo.md:4000'
                    : 'ws://localhost:4000',
                [`${user}`] // Ensure user ID is passed correctly
            );

            setSocket(ws);

            // Handle connection close on component unmount
            return () => {
                if (ws) {
                    ws.close();
                }
            };
        }
    }, [isAuthenticated, user]);

    return (
        <WebSocketContext.Provider value={{ socket }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (context === undefined) {
        throw new Error("useWebSocket must be used within a WebSocketProvider");
    }
    return context;
};
