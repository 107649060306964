import { Card } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  loadSingleOpportunity,
  updateOpportunity,
} from "../../../../redux/rtk/features/crm/opportunity/opportunitySlice";

export default function StageChanger({ opportunityStage, currentStage, opportunity }) {
  const { OpportunityId: id } = useParams();
  const dispatch = useDispatch();

  const lastIndex = opportunityStage?.length - 1;
  const handleStageChange = async (stageId) => {
    const result = await dispatch(
      updateOpportunity({ id, values: { opportunityStageId: stageId } })
    );
    if (result.payload.message === "success") {
      dispatch(loadSingleOpportunity(id));
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Card>
      <div className='flex flex-col md:flex-row md:justify-between items-center pb-3 gap-2'>
        <span className='flex gap-2'>
          <span className='text-base font-bold'>START:</span>
          <span className='text-base font-normal'>
            {moment(currentStage?.opportunityCreateDate).format("MMMM Do YYYY")}
          </span>
        </span>
        <div className='flex gap-2'>
          <span className='text-base font-bold'>Denumire:</span>
          <span className='text-base font-normal'>{opportunity?.opportunityName || 'N/A'}</span>
        </div>
        <div className='flex gap-2'>
          <span className='text-base font-bold'>Responsabil:</span>
          <span className='text-base font-normal'>
            {opportunity?.opportunityOwner?.firstName} {opportunity?.opportunityOwner?.lastName}
          </span>
        </div>
        <div className='flex gap-2'>
          <span className='text-base font-bold'>Clienți:</span>
          <span className='text-base font-normal'>
            {opportunity?.contact?.firstName} {opportunity?.contact?.lastName}
          </span>
        </div>
        <span className='text-base font-bold'>
          DEADLINE:
          <span className='text-base font-normal'>
            {" "}
            {moment(currentStage?.opportunityCloseDate).format("MMMM Do YYYY")}
          </span>
        </span>
      </div>
    </Card >
  );
}
