import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    contactRoom: null,
    error: "",
    loading: false,
}

export const addContactRoom = createAsyncThunk(
    "contactRoom/addContactRoom",
    async (values) => {
        try {
            const { data } = await axios.post(`contact-room`, values);
            return { data, message: "success" };
        } catch (error) {
            toast.error("Error while adding Contact Room");
            console.log(error.message);
            return { message: "error" };
        }
    }
);

export const deleteContactRoom = createAsyncThunk(
    "contactRoom/deleteContactRoom",
    async (id, api) => {
        try {
            const resp = await axios.delete(`contact-room/${id}`, {
                headers: { Accept: "application/json", "Content-Type": "application/json;charset=UTF-8" },
                data: { status: false },
            });
            toast.success("Contact Room deleted");
            api.dispatch(loadAllContactRooms());
            return { data: resp.data.id, message: "success" };
        } catch (error) {
            toast.error("Error while deleting Contact Room");
            console.log(error.message);
        }
    }
);

export const loadSingleContactRoom = createAsyncThunk(
    "contactRoom/loadSingleContactRoom",
    async (id) => {
        try {
            const { data } = await axios.get(`contact-room/${id}`);
            return data; // Adjust based on actual response structure
        } catch (error) {
            console.log(error.message);
            return { error: error.message };
        }
    }
);

export const loadAllContactRooms = createAsyncThunk(
    "contactRoom/loadAllContactRooms",
    async () => {
        try {
            const { data } = await axios.get(`contact-room`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
)

export const updateContactRoom = createAsyncThunk(
    "contactRoom/updateContactRoom",
    async ({ id, data }) => {
        try {
            const response = await axios.put(`contact-room/${id}`, data);
            return response.data;
        } catch (error) {
            console.error(error);
            return { message: "error" };
        }
    }
);

const contactRoomSlice = createSlice({
    name: "contactRoom",
    initialState,
    reducers: {
        clearContactRoom: (state) => {
            state.contactRoom = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addContactRoom.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addContactRoom.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(addContactRoom.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(loadAllContactRooms.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllContactRooms.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });
        builder.addCase(loadAllContactRooms.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(loadSingleContactRoom.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadSingleContactRoom.fulfilled, (state, action) => {
            state.loading = false;
            state.contactRoom = action.payload; // Adjust based on actual response structure
        });
        builder.addCase(loadSingleContactRoom.rejected, (state, action) => {
            state.loading = false;
            console.error('Error loading contact room:', action.error.message);
            state.error = action.error.message;
        });

        builder.addCase(updateContactRoom.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateContactRoom.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateContactRoom.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(deleteContactRoom.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteContactRoom.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(deleteContactRoom.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    }
});

export default contactRoomSlice.reducer;
export const { clearContactRoom } = contactRoomSlice.actions;
