import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row,
    Col,
    Card,
    Select,
    DatePicker,
    Table,
    Statistic,
    Space
} from 'antd';
import {
    ClockCircleOutlined,
    UserOutlined,
    FolderOpenOutlined
} from '@ant-design/icons';
import { fetchOpportunityStageStatistics } from '../../../redux/rtk/features/crm/stageStatistics/opportunityStageStatistics';
import { fetchContactStageStatistics } from '../../../redux/rtk/features/crm/stageStatistics/contactStageStatisticSlice';
import { loadAllContactStage } from '../../../redux/rtk/features/crm/ContactStage/contactStageSlice';
import { loadAllOpportunityStage } from '../../../redux/rtk/features/crm/opportunityStage/opportunityStageSlice';

const { RangePicker } = DatePicker;
const { Option } = Select;

// Utility function to convert milliseconds to human-readable time
const formatDuration = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} zile`;
    if (hours > 0) return `${hours} ore`;
    if (minutes > 0) return `${minutes} minute`;
    return `${seconds} secunde`;
};

const StageStatisticsDashboard = () => {
    const dispatch = useDispatch();

    // State for filters
    const [opportunityStageFilter, setOpportunityStageFilter] = useState([]);
    const [contactStageFilter, setContactStageFilter] = useState([]);
    const [dateRange, setDateRange] = useState(null);

    // Selectors for stages and statistics
    const {
        list: opportunityStageData,
        loading: opportunityStageLoading,
    } = useSelector((state) => state.opportunityStage);

    const {
        list: contactStageData,
        loading: contactStageLoading,
    } = useSelector((state) => state.contactStage);

    const {
        data: opportunityStatistics = [],
        totalUniqueContactsCount: totalOpportunityCount,
        loading: opportunityStatisticsLoading
    } = useSelector((state) => state.opportunityStageStatistics);

    const {
        data: contactStatistics = [],
        totalUniqueContactsCount: totalContactCount,
        loading: contactStatisticsLoading
    } = useSelector((state) => state.contactStageStatistics);

    // Fetch stages and statistics on component mount and filter changes
    useEffect(() => {
        dispatch(loadAllContactStage());
        dispatch(loadAllOpportunityStage());
    }, [dispatch]);

    useEffect(() => {
        // Prepare date parameters
        const params = {
            startDate: dateRange ? dateRange[0].toISOString() : undefined,
            endDate: dateRange ? dateRange[1].toISOString() : undefined,
        };

        // Fetch opportunity stage statistics
        dispatch(fetchOpportunityStageStatistics({
            ...params,
            ...(opportunityStageFilter.length > 0 && {
                opportunityStageIds: opportunityStageFilter
            })
        }));

        // Fetch contact stage statistics
        dispatch(fetchContactStageStatistics({
            ...params,
            ...(contactStageFilter.length > 0 && {
                contactStageIds: contactStageFilter
            })
        }));
    }, [dispatch, dateRange, opportunityStageFilter, contactStageFilter]);

    // Columns for Opportunity Stage Table
    const opportunityStageColumns = [
        {
            title: 'Etapa',
            dataIndex: 'opportunityStageName',
            key: 'opportunityStageName',
        },
        {
            title: 'Nr. Clienți',
            dataIndex: 'uniqueContactCount',
            key: 'uniqueContactCount',
            render: (count) => (
                <Space>
                    <UserOutlined />
                    {count || 0}
                </Space>
            )
        },
        {
            title: 'Timpul Petrecut',
            dataIndex: 'totalTimeSpent',
            key: 'totalTimeSpent',
            render: (timeSpent) => (
                <Space>
                    <ClockCircleOutlined />
                    {formatDuration(timeSpent || 0)}
                </Space>
            )
        }
    ];

    // Columns for Contact Stage Table
    const contactStageColumns = [
        {
            title: 'Etapa',
            dataIndex: 'contactStageName',
            key: 'contactStageName',
        },
        {
            title: 'Nr. Clienți',
            dataIndex: 'uniqueContactCount',
            key: 'uniqueContactCount',
            render: (count) => (
                <Space>
                    <UserOutlined />
                    {count || 0}
                </Space>
            )
        },
        {
            title: 'Timpul Petrecut',
            dataIndex: 'totalTimeSpent',
            key: 'totalTimeSpent',
            render: (timeSpent) => (
                <Space>
                    <ClockCircleOutlined />
                    {formatDuration(timeSpent || 0)}
                </Space>
            )
        }
    ];

    // Calculate total statistics
    const calculateTotalStats = (statistics = []) => {
        return {
            totalTimeSpent: formatDuration(
                statistics.reduce((sum, item) => sum + (item.totalTimeSpent || 0), 0)
            )
        };
    };

    const opportunityTotalStats = calculateTotalStats(opportunityStatistics);
    const contactTotalStats = calculateTotalStats(contactStatistics);

    return (
        <Card title="Statistică Borduri" className='mb-4'>
            <Row gutter={[16, 16]}>
                {/* Filters */}
                <Col span={24}>
                    <Space>
                        {/* Date Range Picker */}
                        <RangePicker
                            onChange={(dates) => setDateRange(dates)}
                            placeholder={['Data de Început', 'Data de Sfârșit']}
                        />

                        {/* Opportunity Stage Selector */}
                        <Select
                            mode="multiple"
                            style={{ width: 300 }}
                            placeholder="Filtreză etapele Bord Producere"
                            loading={opportunityStageLoading}
                            onChange={(selectedValues) => {
                                // Ensure we're setting the values as an array of numbers
                                const parsedValues = selectedValues.map(val => Number(val));
                                setOpportunityStageFilter(parsedValues);
                            }}
                            value={opportunityStageFilter}
                        >
                            {opportunityStageData.map(stage => (
                                <Option key={stage.id} value={stage.id}>
                                    {stage.opportunityStageName}
                                </Option>
                            ))}
                        </Select>

                        {/* Contact Stage Selector */}
                        <Select
                            mode="multiple"
                            style={{ width: 300 }}
                            placeholder="Filtreză etapele Bord Vânzări"
                            loading={contactStageLoading}
                            onChange={(selectedValues) => {
                                // Ensure we're setting the values as an array of numbers
                                const parsedValues = selectedValues.map(val => Number(val));
                                setContactStageFilter(parsedValues);
                            }}
                            value={contactStageFilter}

                        >
                            {contactStageData.map(stage => (
                                <Option key={stage.id} value={stage.id}>
                                    {stage.contactStageName}
                                </Option>
                            ))}
                        </Select>
                    </Space>
                </Col>

                {/* Opportunity Stage Statistics */}
                <Col span={12}>
                    <Card
                        title={
                            <Space>
                                <FolderOpenOutlined />
                                Statistică Bord Producere
                            </Space>
                        }
                        extra={
                            <Space>
                                <Statistic
                                    title="Total Clienți"
                                    value={totalOpportunityCount}
                                />
                                <Statistic
                                    title="Total Timp"
                                    value={opportunityTotalStats.totalTimeSpent}
                                />
                            </Space>
                        }
                    >
                        <Table
                            columns={opportunityStageColumns}
                            dataSource={opportunityStatistics}
                            loading={opportunityStatisticsLoading}
                            rowKey="opportunityStageId"
                            pagination={false}
                        />
                    </Card>
                </Col>

                {/* Contact Stage Statistics */}
                <Col span={12}>
                    <Card
                        title={
                            <Space>
                                <UserOutlined />
                                Statistică Bord Vânzări
                            </Space>
                        }
                        extra={
                            <Space>
                                <Statistic
                                    title="Total Clienți"
                                    value={totalContactCount}
                                />
                                <Statistic
                                    title="Total Timp"
                                    value={contactTotalStats.totalTimeSpent}
                                />
                            </Space>
                        }
                    >
                        <Table
                            columns={contactStageColumns}
                            dataSource={contactStatistics}
                            loading={contactStatisticsLoading}
                            rowKey="contactStageId"
                            pagination={false}
                        />
                    </Card>
                </Col>
            </Row>
        </Card>
    );
};

export default StageStatisticsDashboard;