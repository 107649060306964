import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    contract: null,
    error: "",
    total: null,
    loading: false,
}

export const addSingleContract = createAsyncThunk(
    "contract/createContract",
    async (values) => {
        try {
            const { data } = await axios({
                method: "post",
                url: `contract`,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: values,
            });
            toast.success("Contract Added");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in adding Contract");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

export const deleteContract = createAsyncThunk(
    "contract/deleteContract",
    async (id) => {
        try {
            const resp = await axios({
                method: "delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `contract/${id}`,
            });

            toast.success("Contract Deleted");
            return {
                data: resp.data.id,
                message: "success",
            };
        } catch (error) {
            toast.error("Error while deleting contract");
            console.log(error.message);
        }
    }
);

export const loadAllContract = createAsyncThunk(
    'contract/loadAllContract',
    async ({ firstName = '', lastName = '' } = {}, { rejectWithValue }) => {
        try {
            const response = await axios.get('contract', {
                params: {
                    firstName,
                    lastName,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const contractSlice = createSlice({
    name: "contract",
    initialState,
    reducers: {
        clearContract: (state) => {
            state.contract = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadAllContract.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllContract.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(loadAllContract.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(addSingleContract.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addSingleContract.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(addSingleContract.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    }
});

export default contractSlice.reducer;
export const { clearContract } = contractSlice.actions;