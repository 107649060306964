import { Drawer } from "antd";
import CreateOpportunityStageForm from "./CreateOpportunityStageForm";
import { TEXTS } from "../../../../../constants";

export default function CreateOpportunityStage({ open, onClose }) {
  return (
    <Drawer
      width={450}
      title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.OPPORTUNITY_STAGE}`}
      placement='right'
      onClose={onClose}
      open={open}
    >
      <CreateOpportunityStageForm onClose={onClose} />
    </Drawer>
  );
}
