import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteContract } from "../../../redux/rtk/features/crm/contract/contractSlice";
import { TEXTS } from "../../../constants";
import { Link } from "react-router-dom";
import { Card, Button, Table } from "antd";
import CreateContractForm from "../Contacts/Contract/CreateContractForm";
import moment from "moment";
import { AiFillDelete } from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
import CreateDrawer from "./CreateDrawer";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";

export default function Contracts({ data, loading, name, singleLoadThunk, title }) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const onClose = () => {
        setOpen(false);
    };

    const onDelete = (id) => {
        dispatch(deleteContract(id));
    };

    const columns = [
        {
            title: `${TEXTS.LABELS.LAST_NAME}`,
            key: "contractName",
            render: ({ contractName, contractPath }) =>
                contractName ? (
                    <Link target="_blank" to={`${contractPath}`}>
                        {contractName}
                    </Link>
                ) : (
                    "-"
                ),
            sorter: (a, b) => a.contractName.localeCompare(b.contractName),
        },
        {
            title: `${TEXTS.LABELS.CONTACTS}`,
            dataIndex: "contact",
            key: "contact",
            render: (contact) => contact ? (
                <span>
                    {contact.firstName} {contact.lastName}
                </span>
            ) : (
                "-"
            ),
        },
        {
            title: `${TEXTS.LABELS.OPPORTUNITIES}`,
            dataIndex: "opportunity",
            key: "opportunity",
            render: (opportunity) => opportunity ? (
                <span>
                    {opportunity.opportunityName}
                </span>
            ) : (
                "-"
            ),
        },
        {
            title: `${TEXTS.LABELS.AMOUNT_SUM}`,
            dataIndex: "sum",
            key: "sum",
            render: (sum) => sum ? sum : "-",
        },
        {
            title: `${TEXTS.LABELS.CREATE_DATE}`,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (date) => date ? moment(date).format("MMMM Do YYYY HH:mm") : "-",
        },
        {
            title: `${TEXTS.LABELS.ACTIONS}`,
            key: "actions",
            render: (text, record) => (
                <span
                    onClick={() => onDelete(record.id)}
                    className='bg-red-500 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded'
                >
                    <AiFillDelete className='text-white' />
                </span>
            ),
        },
    ];
    console.log(data.contract);
    return (
        <Card
            title={<span className='font-bold'>{title}</span>}
            bodyStyle={{ padding: 0 }}
            extra={
                <UserPrivateComponent permission='create-contact'>
                    <Button
                        onClick={() => setOpen(true)}
                        className='flex items-center'
                        icon={<BiPlus />}>
                        {TEXTS.BUTTON_TEXT.ADD_BTN}
                    </Button>
                </UserPrivateComponent>
            }
        >
            <div>
                <UserPrivateComponent permission='readAll-contact'>
                    <Table
                        bordered
                        columns={columns}
                        loading={loading}
                        dataSource={Array.isArray(data) ? data.contract : []}
                        pagination={{ hideOnSinglePage: true }}
                        scroll={{ x: 800, y: 300 }}
                        rowKey={(record) => record.id}
                    />
                </UserPrivateComponent>
            </div>

            <UserPrivateComponent permission='create-contact'>
                <CreateDrawer onClose={onClose} open={open} title={"Contracte"}>
                    <CreateContractForm
                        onClose={onClose}
                        open={open}
                        createAs={{ name, value: data?.id, singleLoadThunk }}
                    />
                </CreateDrawer>
            </UserPrivateComponent>
        </Card>
    );
}
