import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    list: [],
    notification: null,
    count: 0,
    error: "",
    loading: false,
}

// Async thunk to fetch notifications and count by recipientId
export const loadNotificationsByRecipientId = createAsyncThunk(
    'notifications/getNotificationsByRecipientId',
    async (recipientId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/notification/${recipientId}`);
            return response.data; // The response should include { count, notifications }
        } catch (error) {
            return rejectWithValue('Error fetching notifications');
        }
    }
);

// Async thunk to mark all notifications as read by recipientId
export const markAllNotificationsAsRead = createAsyncThunk(
    'notifications/markAllAsRead',
    async (recipientId, { rejectWithValue }, api) => {
        try {
            const response = await axios.put(`/notification/read/${recipientId}`);
            api.dispatch(loadNotificationsByRecipientId(recipientId));
            return response.data; // The response will include a message and count of updated notifications
        } catch (error) {
            return rejectWithValue('Error marking notifications as read');
        }
    }
);

export const approveNotification = createAsyncThunk(
    'notifications/approveNotification',
    async (notificationId, { rejectWithValue }, api) => {
        try {
            const response = await axios.post(`/notification/${notificationId}`);
            return response.data; // This should return the updated contact and any necessary info
        } catch (error) {
            return rejectWithValue('Error approving notification');
        }
    }
);

export const deleteNotificationById = createAsyncThunk(
    'notifications/deleteNotification',
    async (notificationId, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`/notification/${notificationId}`);
            return response.data; // The response should include a success message
        } catch (error) {
            return rejectWithValue('Error deleting notification');
        }
    }
);

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        clearNotification: (state) => {
            state.notification = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadNotificationsByRecipientId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadNotificationsByRecipientId.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload?.notifications;
            state.count = action.payload?.count;
        });
        builder.addCase(loadNotificationsByRecipientId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });



        builder.addCase(markAllNotificationsAsRead.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(markAllNotificationsAsRead.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(markAllNotificationsAsRead.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });



        builder.addCase(approveNotification.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(approveNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.list = state.list.filter(notification => notification.id !== action.meta.arg);
        });
        builder.addCase(approveNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });



        builder.addCase(deleteNotificationById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteNotificationById.fulfilled, (state, action) => {
            state.loading = false;
            state.list = state.list.filter(notification => notification.id !== action.meta.arg);
        });
        builder.addCase(deleteNotificationById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default notificationSlice.reducer;
export const { clearNotification } = notificationSlice.actions;

