import { Drawer } from "antd";
import CreateIndustryForm from "./CreateIndustryForm";
import { TEXTS } from "../../../../../constants";

export default function CreateIndustry({ open, onClose }) {
  return (
    <Drawer
      width={450}
      title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.INDUSTRY}`}
      placement='right'
      onClose={onClose}
      open={open}
    >
      <CreateIndustryForm onClose={onClose} />
    </Drawer>
  );
}
