import {
  Button,
  Card,
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  Skeleton,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSingleOpportunity,
  updateOpportunity,
} from "../../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllOpportunitySource } from "../../../../redux/rtk/features/crm/opportunitySource/opportunitySourceSlice";
import { loadAllOpportunityStage } from "../../../../redux/rtk/features/crm/opportunityStage/opportunityStageSlice";
import { loadAllOpportunityType } from "../../../../redux/rtk/features/crm/opportunityType/opportunityTypeSlice";
import getPermissions from "../../../../utils/getPermissions";
import { TEXTS } from "../../../../constants";
import 'dayjs/locale/en'; // Import the desired locale
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { loadAllContactRooms } from "../../../../redux/rtk/features/crm/contactRoom/contactRoomSlice";

dayjs.extend(utc);
dayjs.extend(timezone);
export default function DetailsInfo({ data, loading }) {
  const [form] = Form.useForm();
  const [triggerSave, setTriggerSave] = useState(false);
  const dispatch = useDispatch();
  const { list: opportunitySourceList, loading: opportunitySourceLoading } =
    useSelector((state) => state.opportunitySource);
  const { list: opportunityTypeList, loading: opportunityTypeLoading } =
    useSelector((state) => state.opportunityType);
  const { list: opportunityStageList, loading: opportunityStageLoading } =
    useSelector((state) => state.opportunityStage);
  const { list: contactRoomList, loading: contactRoomLoading } = useSelector(state => state.contactRoom);

  // company profile edit form
  const permissions = getPermissions();
  const canEdit = permissions?.includes("update-opportunity");

  useEffect(() => {
    dispatch(loadAllOpportunitySource());
    dispatch(loadAllOpportunityType());
    dispatch(loadAllOpportunityStage());
    dispatch(loadAllContactRooms());
  }, [dispatch]);

  const getProductionName = (roomId) => {
    const contactRoom = contactRoomList.find(room => room.id === roomId);
    return contactRoom?.room?.production?.productionName || "No Production Name";
  };

  const onFinish = async (values) => {
    const formData = {
      ...values,
      contactRoomId: parseInt(values.contactRoomId),
      opportunityCreateDate: dayjs(values.opportunityCreateDate).tz('Europe/Chisinau').format(
        "YYYY-MM-DD HH:mm"
      ),
      opportunityCloseDate: dayjs(values.opportunityCloseDate).tz('Europe/Chisinau').format(
        "YYYY-MM-DD HH:mm"
      ),
      opportunityTypeId: parseInt(values.opportunityTypeId),
      opportunitySourceId: parseInt(values.opportunitySourceId),
      opportunityStageId: parseInt(values.opportunityStageId),
    };
    const resp = await dispatch(
      updateOpportunity({ id: data.id, values: formData })
    );
    if (resp.payload.message === "success") {
      dispatch(loadSingleOpportunity(data.id));
      setTriggerSave(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setTriggerSave(false);
    form.resetFields();
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Skeleton loading={loading} active>
        <Card
          bordered={false}
          headStyle={{ display: "none" }}
          bodyStyle={{
            padding: 0,
          }}
        >
          {data && (
            <Form
              form={form}
              colon={false}
              disabled={!canEdit}
              labelCol={{
                xs: {
                  span: 24,
                },
                sm: {
                  span: 8,
                },
              }}
              wrapperCol={{
                xs: {
                  span: 24,
                },
                sm: {
                  span: 16,
                },
              }}
              layout='inline'
              onFieldsChange={() => setTriggerSave(true)}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{
                contactRoomId: data?.contactRoomId || "",
                opportunityTypeId: data?.opportunityTypeId || "",
                opportunityStageId: data?.opportunityStageId || "",
                opportunitySourceId: data?.opportunitySourceId || "",
                nextStep: data?.nextStep || "",
                competitors: data?.competitors || "",
                description: data?.description || "",
                opportunityCreateDate:
                  moment(data?.opportunityCreateDate) || "",
                opportunityCloseDate: moment(data?.opportunityCloseDate) || "",
              }}
            >
              <Collapse className='bg-transparent w-full' bordered={true}>
                <Collapse.Panel
                  header={
                    <span className='font-bold text-md dark:text-white'>
                      {TEXTS.LABELS.DETAILS}
                    </span>
                  }
                  key='1'
                  extra={
                    <>
                      {triggerSave && (
                        <Form.Item>
                          <div className='flex items-center gap-4'>
                            <Button type='primary' htmlType='submit'>
                              {TEXTS.BUTTON_TEXT.SAVE_BTN}
                            </Button>
                            <Button
                              loading={loading}
                              type='danger'
                              onClick={onFinishFailed}
                            >
                              {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                            </Button>
                          </div>
                        </Form.Item>
                      )}
                    </>
                  }
                >
                  <div className='flex flex-wrap xl:px-10'>
                    <div className='w-full xl:w-[50%] flex flex-col gap-2 p-3'>
                      <div className='font-bold ml-5'>
                        {TEXTS.LABELS.PRODUCTION_SUCCESSION}: {getProductionName(data?.contactRoomId)}
                      </div>
                      <Form.Item
                        className='flex flex-col'
                        label={TEXTS.LABELS.ROOM}
                        name={"contactRoomId"}
                      >
                        <Select
                          bordered={false}
                          className='md:ml-5'
                          loading={contactRoomLoading}
                        >
                          {contactRoomList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item?.room.roomName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className='flex flex-col'
                        label={TEXTS.LABELS.OPPORTUNITY_SOURCE}
                        name={"opportunitySourceId"}
                      >
                        <Select
                          bordered={false}
                          className='md:ml-5'
                          loading={opportunitySourceLoading}
                        >
                          {opportunitySourceList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item?.opportunitySourceName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className='flex flex-col'
                        label={TEXTS.LABELS.OPPORTUNITY_TYPE}
                        name={"opportunityTypeId"}
                      >
                        <Select
                          bordered={false}
                          className='md:ml-5'
                          loading={opportunityTypeLoading}
                        >
                          {opportunityTypeList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item?.opportunityTypeName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className='flex flex-col'
                        label={TEXTS.LABELS.OPPORTUNITY_STAGE}
                        name={"opportunityStageId"}
                      >
                        <Select
                          bordered={false}
                          className='md:ml-5'
                          loading={opportunityStageLoading}
                        >
                          {opportunityStageList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item?.opportunityStageName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item label={TEXTS.TEXT.NEXT_STEP} name={"nextStep"}>
                        <Input
                          bordered={false}
                          className='md:ml-5'
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                      <Form.Item label={TEXTS.LABELS.COMPETITORS} name={"competitors"}>
                        <Input
                          bordered={false}
                          className='md:ml-5'
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={TEXTS.LABELS.CREATE_DATE}
                        name={"opportunityCreateDate"}
                      >
                        <DatePicker format="YYYY-MM-DD HH:mm"
                          bordered={false}
                          className='md:ml-5'
                          suffix={<BsFillPencilFill />}
                          value={moment(data?.opportunityCreateDate).toDate()}
                        />
                      </Form.Item>
                      <Form.Item
                        label={TEXTS.LABELS.CLOSE_DATE}
                        name={"opportunityCloseDate"}
                      >
                        <DatePicker format="YYYY-MM-DD HH:mm"
                          bordered={false}
                          className='md:ml-5'
                          suffix={<BsFillPencilFill />}
                          value={moment(data?.opportunityCloseDate).toDate()}
                        />
                      </Form.Item>
                      <Form.Item label={TEXTS.LABELS.DESCRPTION} name={"description"}>
                        <Input.TextArea
                          bordered={false}
                          className='md:ml-5'
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Form>
          )}
        </Card>
      </Skeleton>
    </>
  );
}
