import { Card, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteContactSource,
  loadAllContactSource,
} from "../../../../../redux/rtk/features/crm/contactSource/contactSourceSlice";
import ColVisibilityDropdown from "../../../../Shared/ColVisibilityDropdown";
import CustomDrawer from "../../../CommonUi/CustomDrawer";
import CreateContactSourceForm from "./CreateContactSourceForm";
import { TEXTS } from "../../../../../constants";

const TableComponent = () => {
  const dispatch = useDispatch();
  const { list: contactSourceList, loading } = useSelector(
    (state) => state.contactSource
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {

    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    dispatch(loadAllContactSource());
  }, [dispatch]);

  const deleteHandler = (id) => {
    dispatch(deleteContactSource(id));
  };

  const columns = [
    {
      title: `${TEXTS.LABELS.LAST_NAME}`,
      dataIndex: "contactSourceName",
    },
    {
      title: `${TEXTS.LABELS.CREATE_DATE}`,
      dataIndex: "createdAt",
      render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
    },
    {
      title: `${TEXTS.LABELS.UPDATE_DATE}`,
      dataIndex: "updatedAt",
      render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
    },
    {
      title: `${TEXTS.LABELS.ACTIONS}`,
      dataIndex: "id",
      render: (id, values) => (
        <span className='flex items-center gap-2'>
          <CustomDrawer
            permission={"update-contactSource"}
            update
            title={`${TEXTS.TEXT.UPDATE} ${TEXTS.LABELS.CONTACT_SOURCE}`}
            width={25}
          >
            <CreateContactSourceForm edit={{ id, values }} />
          </CustomDrawer>
          <span
            onClick={() => deleteHandler(id)}
            className='bg-red-500 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded'
          >
            <AiFillDelete className='text-white' />
          </span>
        </span>
      ),
    },
  ];

  // column select
  const [columnsToShow, setColumnsToShow] = useState([]);
  useEffect(() => {
    setColumnsToShow(columns);
  }, []);
  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };
  return (
    <Card className='border mt-2'>
      <div className='flex justify-between items-center'>
        <div></div>
        <div className='flex items-center gap-2'>
          <span className='border px-2 py-1 rounded cursor-pointer bg-black text-white'>
            <CSVLink
              data={contactSourceList}
              className='btn btn-dark btn-sm'
              style={{ marginTop: "5px" }}
              filename='Contact-source_CRM-OS'
            >
              Descarcă CSV
            </CSVLink>
          </span>
          <ColVisibilityDropdown
            options={columns}
            columns={columns}
            columnsToShowHandler={columnsToShowHandler}
          />
        </div>
      </div>
      <div>
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selectat ${selectedRowKeys.length} itemi` : ""}
        </span>
      </div>
      <Table
        loading={loading}
        rowSelection={rowSelection}
        columns={columnsToShow}
        dataSource={contactSourceList?.map((item) => ({
          ...item,
          key: item.id,
        }))}
        pagination={false}
        scroll={{ x: 500 }}
      />
    </Card>
  );
};
export default TableComponent;
