import { Button, DatePicker, Form, Input, Select } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllCompany } from "../../../redux/rtk/features/crm/company/companySlice";
import { loadAllContact, loadAllContactPaginated } from "../../../redux/rtk/features/crm/contact/contactSlice";
import {
	addSingleTask,
	loadAllTask,
	loadAllTaskPaginated,
} from "../../../redux/rtk/features/crm/task/crmTaskSlice";
import { loadAllTaskPriority } from "../../../redux/rtk/features/crm/taskPriority/crmTaskPrioritySlice";
import { loadAllTaskStatus } from "../../../redux/rtk/features/crm/taskStatus/crmTaskStatusSlice";
import { loadAllTaskType } from "../../../redux/rtk/features/crm/taskType/crmTaskTypeSlice";
import { loadAllStaff } from "../../../redux/rtk/features/user/userSlice";
import { loadAllOpportunity } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllQuote } from "../../../redux/rtk/features/crm/quote/quoteSlice";
import { TEXTS } from "../../../constants";
import sendEmail from "../Email/sendEmailApi";

export default function CreateTaskForm({ onClose, createAs }) {
	// selector
	const { list: companyList, loading: companyLoading } = useSelector(
		(state) => state.company
	);
	const { list: contactList, loading: contactLoading } = useSelector(
		(state) => state.contact
	);
	const { list: quoteList, loading: quoteLoading } = useSelector(
		(state) => state.quote
	);
	const { list: opportunityList, loading: opportunityLoading } = useSelector(
		(state) => state.opportunity
	);

	const { list: taskPriorityList, loading: taskPriorityLoading } = useSelector(
		(state) => state.crmTaskPriority
	);
	const { list: taskTypeList, loading: taskTypeLoading } = useSelector(
		(state) => state.crmTaskType
	);
	const { list: taskStatusList, loading: taskStatusLoading } = useSelector(
		(state) => state.crmTaskStatus
	);
	const { list: staffList, loading: staffLoading } = useSelector(
		(state) => state.users
	);
	const { loading: taskLoading } = useSelector((state) => state.crmTask);

	const [form] = Form.useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadAllTaskPriority());
		dispatch(loadAllTaskStatus());
		dispatch(loadAllOpportunity());
		dispatch(loadAllContactPaginated({ status: true, count: 999 }));
		dispatch(loadAllCompany());
		dispatch(loadAllTaskType());
		dispatch(loadAllQuote());
		dispatch(loadAllStaff({ status: true }));
	}, [dispatch]);

	const onFinish = async (values) => {
		const formData = {
			...values,
			assigneeId: parseInt(values.assigneeId),
			companyId: parseInt(values.companyId),
			contactId: parseInt(values.contactId),
			opportunityId: parseInt(values.opportunityId),
			quoteId: parseInt(values.quoteId),
			taskTypeId: parseInt(values.taskTypeId),
			taskPriorityId: parseInt(values.taskPriorityId),
			taskStatusId: parseInt(values.taskStatusId),
		};
		const selectedContact = contactList.find(contact => contact.id === values.contactId);
		const selectedQuote = quoteList.find(quote => quote.id === values.quoteId);

		const emailBody = `Client: ${selectedContact?.firstName} ${selectedContact?.lastName}\nProiect: ${selectedQuote?.quoteName}\nDescriere: ${values.notes}`;
		const emailData = {
			receiverEmail: selectedContact.email,
			subject: values.taskName,
			body: emailBody.replace(/\n/g, '<br>'),
		};

		sendEmail(emailData);
		const resp = await dispatch(addSingleTask(formData));
		if (resp.payload.message === "success") {
			form.resetFields();
			if (createAs?.name) {
				dispatch(createAs.singleLoadThunk(createAs.value));
			} else {
				dispatch(loadAllTaskPaginated({ status: true, page: 1, count: 10 }));
			}
			onClose();
		}
	};

	const onCancel = () => {
		form.resetFields();
		onClose();
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className='flex justify-center mt-5'>
			<Form
				className='w-4/5'
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				colon={false}
				layout='vertical'
				form={form}
				initialValues={
					createAs
						? {
							[createAs.name]: createAs.value,
						}
						: {}
				}>
				<Form.Item
					style={{ width: "100%" }}
					label={TEXTS.LABELS.TASK_NAME}
					name='taskName'
					tooltip={TEXTS.TEXT.REQUIRED_FIELD}
					rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.TASK_NAME}` }]}>
					<Input placeholder='Examplu nume' />
				</Form.Item>

				<Form.Item
					label={TEXTS.LABELS.ASSIGNEE}
					name={"assigneeId"}
					tooltip={TEXTS.TEXT.REQUIRED_FIELD}
					rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.ASSIGNEE}` }]}>
					<Select
						style={{ width: "100%" }}
						loading={staffLoading}
						allowClear
						showSearch
						placeholder={TEXTS.LABELS.ASSIGNEE}>
						{staffList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item?.firstName} {item?.lastName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label={TEXTS.LABELS.COMPANY} name='companyId'>
					<Select
						style={{ width: "100%" }}
						loading={companyLoading}
						allowClear
						showSearch
						placeholder={TEXTS.PLACEHOLDERS.COMPANY_PLACEHOLDER}
						disabled={!!(createAs?.name === "companyId")}>
						{companyList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item.companyName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label={TEXTS.LABELS.CONTACT} name='contactId'>
					<Select
						style={{ width: "100%" }}
						loading={contactLoading}
						allowClear
						showSearch
						disabled={!!(createAs?.name === "contactId")}
						placeholder={TEXTS.PLACEHOLDERS.CONTACT_PLACEHOLDER}>
						{contactList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item?.firstName} {item?.lastName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label={TEXTS.LABELS.OPPORTUNITY} name='opportunityId'>
					<Select
						style={{ width: "100%" }}
						loading={opportunityLoading}
						allowClear
						showSearch
						placeholder={TEXTS.PLACEHOLDERS.OPPORTUNITY_PLACEHOLDER}>
						{opportunityList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item.opportunityName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label={TEXTS.LABELS.QUOTE} name='quoteId'>
					<Select
						style={{ width: "100%" }}
						loading={quoteLoading}
						allowClear
						showSearch
						placeholder={TEXTS.PLACEHOLDERS.QUOTE_PLACEHOLDER}>
						{quoteList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item.quoteName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label={TEXTS.LABELS.TASK_TYPE}
					name='taskTypeId'
					tooltip={TEXTS.TEXT.REQUIRED_FIELD}
					rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.TASK_TYPE}` }]}>
					<Select
						style={{ width: "100%" }}
						loading={taskTypeLoading}
						allowClear
						showSearch
						placeholder={TEXTS.PLACEHOLDERS.TASK_TYPE_PLACEHOLDER}>
						{taskTypeList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item.taskTypeName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label={TEXTS.LABELS.TASK_PRIORITY}
					name='taskPriorityId'
					tooltip={TEXTS.TEXT.REQUIRED_FIELD}
					rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.TASK_PRIORITY}` }]}>
					<Select
						style={{ width: "100%" }}
						loading={taskPriorityLoading}
						allowClear
						showSearch
						placeholder={TEXTS.PLACEHOLDERS.TASK_PRIORITY_PLACEHOLDER}>
						{taskPriorityList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item.taskPriorityName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label={TEXTS.LABELS.TASK_STATUS}
					name='taskStatusId'
					tooltip={TEXTS.TEXT.REQUIRED_FIELD}
					rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.TASK_STATUS}` }]}>
					<Select
						style={{ width: "100%" }}
						loading={taskStatusLoading}
						allowClear
						showSearch
						placeholder={TEXTS.PLACEHOLDERS.TASK_STATUS_PLACEHOLDER}>
						{taskStatusList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item.taskStatusName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					label={TEXTS.LABELS.DUE_DATE}
					name='dueDate'
					tooltip={TEXTS.TEXT.REQUIRED_FIELD}
					rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.DUE_DATE}` }]}>
					<DatePicker format="YYYY-MM-DD HH:mm" placeholder={TEXTS.PLACEHOLDERS.DUE_DATE_PLACEHOLDER}
					/>
				</Form.Item>
				<Form.Item label={TEXTS.LABELS.NOTES} name='notes'>
					<Input placeholder={TEXTS.LABELS.NOTES} />
				</Form.Item>

				<div className='w-[726.4px]'>
					<Form.Item label=''>
						<div className='flex items-center gap-2'>
							<Button
								size={"large"}
								htmlType='submit'
								type='primary'
								loading={taskLoading}>
								{TEXTS.TEXT.CREATE}
							</Button>
							<Button size={"large"} type='danger' onClick={onCancel}>
								{TEXTS.BUTTON_TEXT.CLOSE_BTN}
							</Button>
						</div>
					</Form.Item>
				</div>
			</Form>
		</div>
	);
}
