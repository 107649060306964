import { Form, Select, Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllRooms } from "../../../redux/rtk/features/crm/room/roomSlice";
import { loadAllContactPaginated } from "../../../redux/rtk/features/crm/contact/contactSlice";
import { addContactRoom, updateContactRoom } from "../../../redux/rtk/features/crm/contactRoom/contactRoomSlice";
import { TEXTS } from "../../../constants";

export default function CreateContactRoomForm({
    onClose,
    createAs,
    edit,
    singleLoad,
}) {
    const { list: roomList, loading: roomLoading } = useSelector(state => state.room);
    const { list: contactList, loading: contactLoading } = useSelector(state => state.contact);
    const { loading: contactRoomLoading } = useSelector(state => state.contactRoom);
    const [roomItems, setRoomItems] = useState([]);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAllRooms());
        dispatch(loadAllContactPaginated({ status: true, count: 999 }));

        if (edit) {
            const selectedRoom = roomList.find(room => room.id === edit.roomId);
            setRoomItems(selectedRoom ? selectedRoom.roomItem : []);
            form.setFieldsValue({
                contactId: edit.contactId,
                roomId: edit.roomId,
                roomItems: edit.contactItem.map(item => item.roomItemId) || [], // Pre-fill selected roomItems
            });
        }
    }, [dispatch]);

    const handleRoomChange = (roomId) => {
        const selectedRoom = roomList.find(room => room.id === roomId);
        setRoomItems(selectedRoom ? selectedRoom.roomItem : []);
        form.setFieldsValue({
            roomItems: [], // Reset room items when room changes
        });
    };

    const onFinish = async (values) => {
        const formData = {
            ...values,
            contactId: parseInt(values.contactId),
            roomId: parseInt(values.roomId),
        };

        let contactRoomId;

        if (!!edit) {
            const resp = await dispatch(
                updateContactRoom({ id: edit.id, values: formData })
            );
            if (resp.payload.message === "success") {
                contactRoomId = edit.id;
                if (singleLoad?.thunk) {
                    dispatch(singleLoad.thunk(singleLoad.id));
                }

                onClose();
            }
        } else {
            const resp = await dispatch(addContactRoom(formData))
            if (resp.payload.message === "success") {
                contactRoomId = resp.payload.data.id;
                form.resetFields();
                if (createAs?.name) {
                    dispatch(createAs.singleLoadThunk(createAs.value));
                } else {
                    dispatch(loadAllRooms());
                }
                onClose();
            }
        }
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    };

    return (
        <div className="flex justify-center mt-5">
            <Form
                className="w-4/5"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                colon={false}
                layout="vertical"
                form={form}
                initialValues={
                    createAs
                        ? {
                            [createAs.name]: createAs.value
                        }
                        : !!edit
                            ? {
                                contactId: edit?.contactId,
                                roomId: edit?.roomId,
                                roomItems: edit.contactItem?.map(item => item.roomItemId) || [],
                            }
                            : {}
                }>
                <Form.Item
                    style={{ width: "100%" }}
                    label={TEXTS.LABELS.CONTACT}
                    name='contactId'
                    tooltip={TEXTS.TEXT.REQUIRED_FIELD}
                    rules={[
                        { required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE}` }
                    ]}
                >
                    <Select
                        style={{ width: "100%" }}
                        loading={contactLoading}
                        allowClear
                        showSearch
                        placeholder={TEXTS.PLACEHOLDERS.CONTACT_PLACEHOLDER}
                        disabled={!!(createAs?.name === "contactId")}
                    >
                        {contactList?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.firstName} {item.lastName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={TEXTS.LABELS.ROOM_NAME} name={"roomId"}>
                    <Select
                        style={{ width: "100%" }}
                        loading={roomLoading}
                        placeholder={TEXTS.PLACEHOLDERS.ROOM_PLACEHOLDER}
                        onChange={handleRoomChange}
                    >
                        {roomList?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.roomName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label=''>
                    <div className='flex items-center gap-2'>
                        <Button
                            size={"large"}
                            htmlType='submit'
                            type='primary'
                            loading={contactRoomLoading}>
                            {TEXTS.TEXT.CREATE}
                        </Button>
                        <Button size={"large"} type='danger' onClick={onCancel}>
                            {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div >
    )
}
