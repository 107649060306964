import { Button, DatePicker, Form, Input, Select, Image } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllContactPaginated } from "../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllOpportunityPaginated } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllProduct } from "../../../redux/rtk/features/crm/product/productSlice";
import { addSingleQuote, loadAllQuotePaginated, updateQuote } from "../../../redux/rtk/features/crm/quote/quoteSlice";
import ProductAdd from "./ProductAdd";
import { TEXTS } from "../../../constants";
import moment from "moment";
import { loadAllAttachment } from "../../../redux/rtk/features/crm/attachment/crmAttachmentSlice";
import getImageUrl from "../../../utils/getimageUrl";
import dayjs from "dayjs";
import { fetchSettings } from "../../../redux/rtk/features/settings/settingsSlice";

export default function CreateQuoteForm({ onClose, createAs, contactId, edit, singleLoad }) {
	// selector
	const { list: contactList, loading: contactLoading } = useSelector(
		(state) => state.contact
	);
	const { list: productList, loading: productLoading } = useSelector(
		(state) => state.product
	);
	const { list: opportunityList, loading: opportunityLoading } = useSelector(
		(state) => state.opportunity,
	);
	const { loading: quoteLoading } = useSelector((state) => state.quote);
	const { list: attachmentList, loading: attachmentLoading } = useSelector((state) => state.crmAttachment);

	const [form] = Form.useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		if (productList.length === 0) {
			dispatch(loadAllProduct());
		}
		dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
		dispatch(loadAllContactPaginated({ status: true, count: 999 }));
		dispatch(loadAllAttachment());
		dispatch(fetchSettings());
	}, [dispatch, productList.length]);


	const [quoteName, setQuoteName] = useState('');
	const [filteredAttachments, setFilteredAttachments] = useState([]);
	const [selectedOpportunityId, setSelectedOpportunityId] = useState(createAs?.value || edit?.opportunityId || null);
	const [selectedAttachment, setSelectedAttachment] = useState(null);
	const [isPdf, setIsPdf] = useState(false);

	useEffect(() => {
		if (selectedOpportunityId) {
			// Filter attachments based on the selected or provided opportunityId
			const opportunityAttachments = attachmentList.filter(
				(att) => att.opportunityId === selectedOpportunityId && att.attachmentTypeId === 3
			);
			setFilteredAttachments(opportunityAttachments);
		}
	}, [attachmentList, selectedOpportunityId]);

	const onAttachmentChange = (attachmentId) => {
		const attachment = filteredAttachments.find(att => att.id === attachmentId);
		if (attachment) {
			const filePath = getImageUrl(attachment.attachmentPath);
			setSelectedAttachment(filePath);
			setIsPdf(attachment.attachmentPath.endsWith('.pdf')); // Check if file is PDF
		}
	};

	const onFinish = async (values) => {
		const formData = {
			...values,
			contactId: parseInt(values.contactId),
			opportunityId: parseInt(values.opportunityId),
			discount: parseInt(values.discount),
			quoteName: quoteName,
			quoteDate: dayjs(values.quoteDate).format(
				"YYYY-MM-DD HH:mm"
			),
		};

		let quoteId;

		// general create request
		if (!!edit) {
			const resp = await dispatch(
				updateQuote({ id: edit.id, values: formData })
			);
			if (resp.payload.message === "success") {
				quoteId = edit.id;
				if (singleLoad?.thunk) {
					dispatch(singleLoad.thunk(singleLoad.id));
				}

				onClose();
			}
		} else {
			const resp = await dispatch(addSingleQuote(formData));
			if (resp.payload.message === "success") {
				form.resetFields();
				if (createAs?.name) {
					dispatch(createAs.singleLoadThunk(createAs.value));
				} else {
					dispatch(loadAllQuotePaginated({ status: true }));
				}
			}
			setSelectedAttachment(null);
			setIsPdf(false);
			onClose();
		}
	};

	const onCancel = () => {
		form.resetFields();
		setSelectedAttachment(null);
		setIsPdf(false);
		onClose();
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	const onOpportunityChange = (opportunityId) => {
		setSelectedOpportunityId(opportunityId);
		form.setFieldsValue({ opportunityId });
	};

	const handleTotalAmountChange = (totalAmount) => {
		setQuoteName(`Estimare la pret ${totalAmount}`);
		form.setFieldsValue({ quoteName: `Estimare la pret ${totalAmount}` });
	};

	const closeAttachmentPreview = () => {
		setSelectedAttachment(null);
		setIsPdf(false);
		form.setFieldsValue({ attachmentId: null });
	};

	return (
		<div className='relative flex justify-center mt-5'>
			{/* Scrollable Form Container */}
			<div className="w-[90%] h-[80vh] overflow-y-auto z-30 relative">
				<Form
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					colon={false}
					layout='vertical'
					form={form}
					initialValues={
						createAs
							? {
								[createAs.name]: createAs.value,
								contactId: contactId,
								quoteDate: moment(),
							}
							: !!edit
								? {
									opportunityId: edit?.opportunityId || "",
									attachmentId: edit?.attachmentId || "",
									contactId: edit?.contactId || "",
									quoteDate: moment(edit?.quoteDate) || "",
									quoteProduct: edit?.quoteProduct || {},
								}
								: {}
					}>
					<div className='flex justify-center gap-5'>
						<div className='w-1/2'>
							<Form.Item label={TEXTS.LABELS.OPPORTUNITY} name='opportunityId'>
								<Select
									style={{ width: "100%" }}
									loading={opportunityLoading}
									allowClear
									showSearch
									onChange={onOpportunityChange}
									placeholder={TEXTS.PLACEHOLDERS.OPPORTUNITY_PLACEHOLDER}
									disabled={!!(createAs?.name === "opportunityId")}>
									{opportunityList?.map((item) => (
										<Select.Option key={item.id} value={item.id}>
											{item.opportunityName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label={TEXTS.LABELS.ATTACHMENT}
								name='attachmentId'
								tooltip={TEXTS.TEXT.REQUIRED_FIELD}
								rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.ATTACHMENT}` }]}>
								<Select
									style={{ width: "100%" }}
									loading={attachmentLoading}
									allowClear
									showSearch
									placeholder={TEXTS.PLACEHOLDERS.ATTACHMENT_PLACEHOLDER}
									onChange={onAttachmentChange}>
									{filteredAttachments.map((item) => (
										<Select.Option key={item.id} value={item.id}>
											{item.attachmentName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</div>
						<div className='w-1/2'>
							<Form.Item
								label={TEXTS.LABELS.QUOTATION_DATE}
								name='quoteDate'
								tooltip={TEXTS.TEXT.REQUIRED_FIELD}
								rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.QUOTATION_DATE}` }]}>
								<DatePicker format="YYYY-MM-DD HH:mm" placeholder={TEXTS.PLACEHOLDERS.QUOTATION_DATE_PLACEHOLDER} />
							</Form.Item>
							<Form.Item label={TEXTS.LABELS.CONTACT} name='contactId'>
								<Select
									style={{ width: "100%" }}
									loading={contactLoading}
									allowClear
									showSearch
									placeholder={TEXTS.PLACEHOLDERS.CONTACT_PLACEHOLDER}
									disabled={!!(createAs?.name === "contactId") || !!(contactId)}>
									{contactList?.map((item) => (
										<Select.Option key={item.id} value={item.id}>
											{item?.firstName} {item?.lastName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</div>
					</div>
					<div className='flex justify-center'>
						<div className='w-full'>
							<ProductAdd
								form={form}
								productList={productList}
								productLoading={productLoading}
								onTotalAmountChange={handleTotalAmountChange}
								edit={edit}
							/>
						</div>
					</div>
					<div className='flex justify-center'>
						<div className='w-full'>
							<Form.Item label=''>
								<div className='flex items-center gap-2'>
									<Button
										size={"large"}
										htmlType='submit'
										type='primary'
										loading={quoteLoading}>
										{TEXTS.TEXT.CREATE}
									</Button>
									<Button
										size={"large"}
										htmlType='submit'
										type='danger'
										onClick={onCancel}>
										{TEXTS.BUTTON_TEXT.CLOSE_BTN}
									</Button>
								</div>
							</Form.Item>
						</div>
					</div>
				</Form>
			</div>
			{/* Attachment Preview */}
			{selectedAttachment && (
				<div className='fixed inset-0 flex items-center justify-center z-20'>
					<div className='relative' style={{ transform: 'translateX(-45%)' }}>
						{/* Close Button */}
						<Button
							className='absolute top-2 right-2'
							type='text'
							icon={<span className='text-red-500 text-lg'>×</span>}
							onClick={closeAttachmentPreview}
							style={{ zIndex: 1000 }}
						/>
						{isPdf ? (
							<iframe
								title="quotePdf"
								src={selectedAttachment}
								style={{ width: '50vw', height: '80vh' }}
								frameBorder="0"
							></iframe>
						) : (
							<Image
								src={selectedAttachment}
								alt="Selected attachment"
								style={{ maxWidth: '50vw', maxHeight: '80vh', objectFit: 'contain' }}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
