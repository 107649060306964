import { Form, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadAllCompany } from "../../../redux/rtk/features/crm/company/companySlice";
import { loadAllContact, loadAllContactPaginated } from "../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllOpportunityPaginated } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import {
  deleteManyQuote,
  loadAllQuotePaginated,
} from "../../../redux/rtk/features/crm/quote/quoteSlice";
import { loadAllQuoteStage } from "../../../redux/rtk/features/crm/quoteStage/quoteStageSlice";
import { loadAllStaff } from "../../../redux/rtk/features/user/userSlice";
import ViewBtn from "../../Buttons/ViewBtn";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
import CreateDrawer from "../CommonUi/CreateDrawer";
import CrmSearch from "../CommonUi/CrmSearch";
import TableComponent from "../CommonUi/TableComponent";
import CreateQuoteForm from "./CreateQuoteForm";
import { TEXTS } from "../../../constants";

const columns = [
  {
    title: `${TEXTS.LABELS.LAST_NAME}`,
    key: "quoteName",
    render: ({ quoteName, id }) =>
      id ? <Link to={`/admin/quote/${id}`}>{quoteName || "-"}</Link> : "-",
    sorter: (a, b) => (a.quoteName || "").localeCompare(b.quoteName || ""),
  },
  {
    title: `${TEXTS.LABELS.OWNER}`,
    dataIndex: "quoteOwner",
    render: (quoteOwner, item) => (
      quoteOwner ? (
        <Link to={`/admin/setup/staffs/${item?.quoteOwnerId}`}>
          {quoteOwner.firstName || ""} {quoteOwner.lastName || ""}
        </Link>
      ) : "-"
    ),
  },
  {
    title: `${TEXTS.LABELS.QUOTATION_DATE}`,
    dataIndex: "quotationDate",
    render: (date) => date ? moment(date).format("MMMM Do YYYY HH:mm") : "-",
  },
  {
    title: `${TEXTS.LABELS.EXPIRATION_DATE}`,
    dataIndex: "expirationDate",
    render: (date) => date ? moment(date).format("MMMM Do YYYY HH:mm") : "-",
  },
  {
    title: `${TEXTS.LABELS.TOTAL_AMMOUNT}`,
    dataIndex: "totalAmount",
    key: "totalAmount",
    render: (total) => (total !== undefined && total !== null) ? total : "-",
  },
  {
    title: `${TEXTS.LABELS.COMPANY}`,
    dataIndex: "company",
    render: (company, item) => (
      company ? (
        <Link to={`/admin/company/${item?.companyId}`}>
          {company.companyName || "-"}
        </Link>
      ) : "-"
    ),
  },
  {
    title: `${TEXTS.LABELS.CREATE_DATE}`,
    dataIndex: "createdAt",
    render: (date) => date ? moment(date).format("MMMM Do YYYY HH:mm") : "-",
  },
  {
    title: `${TEXTS.LABELS.ACTIONS}`,
    dataIndex: "id",
    key: "action",
    render: (id) => (
      <div className='flex justify-start'>
        <ViewBtn path={`/admin/quote/${id}`} />
      </div>
    ),
  },
];

const Quotes = () => {
  const dispatch = useDispatch();
  // Drawer state
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  // filter
  const [isFilter, setFilter] = useState(false);
  const filterToggle = () => {
    setFilter((prev) => !prev);
  };
  const { Option } = Select;
  const quoteStage = useSelector((state) => state.quoteStage.list) || [];
  const company = useSelector((state) => state.company.list) || [];
  const contact = useSelector((state) => state.contact.list) || [];
  const opportunity = useSelector((state) => state.opportunity.list) || [];
  const quoteOwner = useSelector((state) => state.users.list) || [];
  const { list, loading, total } = useSelector((state) => state.quote);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    dispatch(
      loadAllQuotePaginated({
        ...values,
      })
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    dispatch(loadAllContactPaginated());
    dispatch(loadAllCompany());
    dispatch(loadAllOpportunityPaginated({ status: true }));
    dispatch(loadAllQuoteStage());
    dispatch(loadAllStaff({ status: true }));
    dispatch(loadAllQuotePaginated({ status: true }));
  }, [dispatch]);

  return (
    <div className="container">
      <div className="bg-white dark:bg-DTableBg  rounded-lg flex flex-col md:flex-row justify-between items-center gap-3 p-3 px-6 border">
        <div className="flex justify-end items-center gap-5">
          <button
            onClick={filterToggle}
            className={`hidden md:block ${isFilter ? "bg-transparent border border-red-600" : "bg-blue-500"
              }  px-5 py-2 rounded cursor-pointer`}
            type="submit"
          >
            {isFilter ? (
              <span className="flex justify-center dark:text-white items-center gap-2">
                <AiOutlineClose /> {TEXTS.BUTTON_TEXT.CLOSE_BTN}
              </span>
            ) : (
              <span className="text-white">{TEXTS.TEXT.FILTER}</span>
            )}
          </button>
          {!isFilter ? (
            <CrmSearch paginatedThunk={loadAllQuotePaginated} />
          ) : (
            <div className="flex text-md">
              <Form
                className="flex flex-wrap justify-center gap-2 md:items-center"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="inline"
                initialValues={{
                  status: ["true"],
                }}
              >
                <Form.Item name="quoteStage" className="w-full md:w-[180px]">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={TEXTS.PLACEHOLDERS.STAGE_PLACEHOLDER}
                    maxTagCount={0}
                    maxTagPlaceholder={<div className="w-[150px]">{TEXTS.LABELS.STAGE}</div>}
                  >
                    {quoteStage?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.quoteStageName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="contact" className="w-full md:w-[180px]">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={TEXTS.PLACEHOLDERS.CONTACT_PLACEHOLDER}
                    maxTagCount={0}
                    maxTagPlaceholder={<div className="w-[150px]">{TEXTS.LABELS.CONTACT}</div>}
                  >
                    {contact?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item?.firstName} {item?.lastName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="company" className="w-full md:w-[180px]">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={TEXTS.PLACEHOLDERS.COMPANY_PLACEHOLDER}
                    maxTagCount={0}
                    maxTagPlaceholder={<div className="w-[150px]">{TEXTS.LABELS.COMPANY}</div>}
                  >
                    {company?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="opportunity" className="w-full md:w-[180px]">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={TEXTS.PLACEHOLDERS.OPPORTUNITY_PLACEHOLDER}
                    maxTagCount={0}
                    maxTagPlaceholder={
                      <div className="w-[150px]">{TEXTS.LABELS.OPPORTUNITY}</div>
                    }
                  >
                    {opportunity?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.opportunityName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="status" className="w-full md:w-[180px]">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={TEXTS.MESSAGES.SELECT_MESSAGE}
                    defaultValue={["true"]}
                  >
                    <Option value="true">{TEXTS.TEXT.TRUE}</Option>
                    <Option value="false">{TEXTS.TEXT.FALSE}</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <div className="w-full md:w-[180px] flex justify-start">
                    <button
                      className="bg-blue-500 text-white px-5 py-2 rounded cursor-pointer"
                      type="submit"
                    >
                      {TEXTS.TEXT.FILTER}
                    </button>
                    <button
                      onClick={filterToggle}
                      className="block md:hidden  px-5 py-2 rounded cursor-pointer ml-2 text-rose-700 transition-colors duration-150 border border-rose-500 focus:shadow-outline hover:bg-rose-500 hover:text-rose-100"
                    >
                      {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                    </button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
        {!isFilter && (
          <UserPrivateComponent permission="create-quote">
            <button
              onClick={() => setOpen(true)}
              className="py-2 px-3 border bg-lime-700 hover:bg-lime-500 text-white rounded cursor-pointer flex items-center gap-2"
            >
              <AiOutlinePlus /> {TEXTS.TEXT.CREATE} {TEXTS.LABELS.QUOTE}
            </button>
          </UserPrivateComponent>
        )}
      </div>
      <UserPrivateComponent permission="readAll-quote">
        <TableComponent
          list={list}
          total={total}
          loading={loading}
          columns={columns}
          paginatedThunk={loadAllQuotePaginated}
          deleteManyThunk={deleteManyQuote}
          csvFileName={"Opportunity-List"}
        />
      </UserPrivateComponent>

      <UserPrivateComponent permission="create-quote">
        <CreateDrawer onClose={onClose} open={open} title={TEXTS.LABELS.QUOTE}>
          <CreateQuoteForm onClose={onClose} open={open} />
        </CreateDrawer>
      </UserPrivateComponent>
    </div>
  );
};
export default Quotes;
