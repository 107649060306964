import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    data: [],
    totalUniqueContactsCount: 0,
    error: "",
    loading: false,
}

export const fetchOpportunityStageStatistics = createAsyncThunk(
    'opportunityStageStatistics/fetchStatistics',
    async ({ startDate, endDate, opportunityStageIds }, { rejectWithValue }) => {
        try {
            // Construct query parameters
            const params = new URLSearchParams();
            if (startDate) params.append('startDate', startDate);
            if (endDate) params.append('endDate', endDate);
            if (opportunityStageIds) {
                params.append('opportunityStageIds', opportunityStageIds.join(','));
            }

            const response = await axios.get(`/opportunity-stats?${params.toString()}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch opportunity stage statistics');
        }
    }
);

const opportunityStageStatisticSlice = createSlice({
    name: "opportunityStageStatistics",
    initialState,
    reducers: {
        clearOpportunityStageStatistics: (state) => {
            state.data = null;
            state.totalUniqueContactsCount = 0;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOpportunityStageStatistics.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchOpportunityStageStatistics.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.statistics;
            state.totalUniqueContactsCount = action.payload.totalUniqueContactsCount;
        });
        builder.addCase(fetchOpportunityStageStatistics.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default opportunityStageStatisticSlice.reducer;
export const { clearOpportunityStageStatistics } = opportunityStageStatisticSlice.actions;