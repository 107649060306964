import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Typography, Input, Space } from 'antd';
import { loadAllOffer } from '../../../redux/rtk/features/crm/offer/offerSlice'; // Adjust import path based on your actual structure
import { TEXTS } from "../../../constants"; // Adjust import path for TEXTS constant
import moment from 'moment';


const Offers = () => {
    const dispatch = useDispatch();

    // States for search inputs
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const { list, loading, error } = useSelector(state => state.offer); // Adjust state path based on your actual Redux structure
    const [open, setOpen] = useState(false);
    useEffect(() => {
        dispatch(loadAllOffer());
    }, [dispatch]);

    const onClose = () => {
        setOpen(false);
    }
    const handleSearch = () => {
        const searchParams = {};
        // Only add firstName if it's not empty
        if (firstName) {
            searchParams.firstName = firstName;
        }
        // Only add lastName if it's not empty
        if (lastName) {
            searchParams.lastName = lastName;
        }
        // Dispatch the action with searchParams (it could be empty if no values)
        dispatch(loadAllOffer(searchParams));
    };
    const handleDownload = async (offerPath, offerName) => {
        try {
            // Adjust the base URL based on your backend API endpoint
            const baseUrl = process.env.REACT_APP_API; // Ensure this matches your .env variable
            const url = `${baseUrl}/files/${offerPath}`; // Adjust the path to match your backend storage structure

            const response = await fetch(url);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = offerName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const columns = [
        {
            title: 'Denumire',
            dataIndex: 'offerName',
            key: 'offerName',
        },
        {
            title: 'Nume Persoana',
            dataIndex: 'personName',
            key: 'personName',
        },
        {
            title: `${TEXTS.LABELS.CREATE_DATE}`,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => moment(date).format('MMM Do YYYY, hh:mm'),
        },
        {
            title: `${TEXTS.LABELS.ACTIONS}`,
            key: 'action',
            render: (text, record) => (
                <Button type="primary" onClick={() => handleDownload(record.offerPath, record.offerName)}>
                    Download
                </Button>
            ),
        },
    ];

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const mappedOffers = list.map(offer => ({
        key: offer.id,
        offerName: offer.offerName,
        personName: `${offer.contact.firstName} ${offer.contact.lastName}`,
        createdAt: offer.createdAt,
        offerPath: offer.offerPath,
    }));

    return (
        <div className="container">
            <div className="search-container">
                <Space style={{ marginBottom: 16 }}>
                    <Input
                        placeholder={TEXTS.LABELS.FIRST_NAME}
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                    />
                    <Input
                        placeholder={TEXTS.LABELS.LAST_NAME}
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                    />
                    <Button type="primary" onClick={handleSearch}>
                        Caută
                    </Button>
                </Space>
            </div>
            <Table columns={columns} dataSource={mappedOffers} />
        </div>
    );
};

export default Offers;
