import { Drawer } from "antd";
import CreateCrmTaskStatusForm from "./CreateCrmTaskStatusForm";
import { TEXTS } from "../../../../../constants";

export default function CreateCrmTaskStatus({ open, onClose }) {
  return (
    <Drawer
      width={450}
      title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.TASK_STATUS}`}
      placement='right'
      onClose={onClose}
      open={open}
    >
      <CreateCrmTaskStatusForm onClose={onClose} />
    </Drawer>
  );
}
