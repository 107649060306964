/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Card,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Skeleton,
} from "antd";
import { useEffect, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteContact,
  loadAllContactPaginated,
  loadSingleContact,
  updateContact,
} from "../../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllStaff } from "../../../../redux/rtk/features/user/userSlice";
import getPermissions from "../../../../utils/getPermissions";
import UserPrivateComponent from "../../../PrivateRoutes/UserPrivateComponent";
import ImageUpload from "./../../CommonUi/ImageUpload";
import RemoveImage from "./../../CommonUi/RemoveImage";
import getImageUrl from "../../../../utils/getimageUrl";
import { TEXTS } from "../../../../constants";
import { getAllProductions } from "../../../../redux/rtk/features/crm/production/productionSlice";


export default function ContactProfile({ contact, contactLoading }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ContactId: id } = useParams();
  const [form] = Form.useForm();
  const [triggerSave, setTriggerSave] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleImage, setVisibleImage] = useState(false);

  const { list: ownerList, loading: ownerLoading } = useSelector(
    (state) => state.users
  );
  const { list: productionList, loading: productionLoading } = useSelector(state => state.production);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // delete contact
  const onDelete = async () => {
    var result = window.confirm(`${TEXTS.TEXT.ON_DELETE_TEXT}`);
    if (result) {
      const resp = await dispatch(deleteContact(id));
      if (resp.payload.message === "success") {
        navigate(-1);
        dispatch(loadAllContactPaginated({}));
      }
    }
  };
  const itemsProfile = [
    contact?.image && {
      key: "1",
      label: (
        <button onClick={() => setVisibleImage(true)}>
          {TEXTS.TEXT.VIEW} {TEXTS.TEXT.IMAGE}
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button onClick={showModal}>
          {TEXTS.BUTTON_TEXT.UPLOAD} {TEXTS.TEXT.IMAGE}
        </button>
      ),
    },
    contact?.image && {
      key: "3",
      label: (
        <RemoveImage
          id={id}
          loadThunk={loadSingleContact}
          updateThunk={updateContact}
        />
      ),
    },
  ];

  // contact profile edit form
  const permissions = getPermissions();
  const canEdit = permissions?.includes("update-contact");
  const onFinish = async (values) => {
    const formData = {
      ...values,
      contactOwnerId: parseInt(values.contactOwnerId),
    };
    const resp = await dispatch(
      updateContact({ id: contact.id, values: formData })
    );
    if (resp.payload.message === "success") {
      dispatch(loadSingleContact(contact.id));
      setTriggerSave(false);
    }
  };
  function generateContactID(contact) {
    const departmentId = contact.contactOwner.department.id < 10
      ? `0${contact.contactOwner.department.id}`
      : contact.contactOwner.department.id;

    const contactId = contact.id < 10
      ? `0${contact.id}`
      : contact.id;
    const createdAt = new Date(contact.createdAt);
    const month = (createdAt.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
    const year = createdAt.getFullYear().toString().slice(-2); // Get last two digits of the year

    return `${departmentId}/${contactId}/${month}/${year}`;
  }

  const onFinishFailed = (errorInfo) => {
    setTriggerSave(false);
    form.resetFields();
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    dispatch(loadAllStaff({ status: true }));
    dispatch(getAllProductions());
  }, [dispatch]);

  function formatDate(dateString) {
    if (!dateString) return "Nu este stabilită"; // Handle null or undefined date

    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  function getOldestOpportunityDate(opportunities) {
    if (!opportunities || opportunities.length === 0) {
      return "Nu este stabilită"; // No opportunities, so return the message
    }
    // Sort opportunities by delivery date (earliest first)
    const sortedOpportunities = [...opportunities].sort((a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate));
    // Check if the oldest opportunity's delivery date is null or undefined
    const oldestDeliveryDate = sortedOpportunities[0]?.deliveryDate;
    if (!oldestDeliveryDate) {
      return "Nu este stabilită"; // If delivery date is null, return the message
    }
    // Return formatted delivery date if available
    return formatDate(oldestDeliveryDate);
  }


  return (
    <>
      <Skeleton loading={contactLoading} active>
        {contact && (
          <Card headStyle={{ display: "none" }} bodyStyle={{ padding: 0 }}>
            <Form
              form={form}
              colon={false}
              disabled={!canEdit}
              labelCol={{
                span: 9,
              }}
              wrapperCol={{
                span: 12,
              }}
              layout="inline"
              onFieldsChange={() => setTriggerSave(true)}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{
                firstName: contact?.firstName || "",
                lastName: contact?.lastName || "",
                contactOwnerId: contact?.contactOwnerId || "",
                email: contact?.email || "",
                phone: contact?.phone || "",
                jobTitle: contact?.jobTitle || "",
                productionId: contact?.productionId || "",
                idnp: contact?.idnp || "",
              }}
            >
              <div className="w-full">
                <div className="flex justify-between items-center px-5 p-3 border-b">
                  <div className="flex items-end gap-5">
                    {/* Section for image user  */}
                    <div className="w-16 h-16 flex items-center justify-center rounded-full bg-slate-600 group relative">
                      {contact?.image && (
                        <img
                          className="w-full overflow-hidden rounded-full select-none"
                          src={`${getImageUrl(contact?.image)}`}
                          alt="logo"
                        />
                      )}
                      <Dropdown
                        className="bg-blue-600"
                        menu={{
                          items: itemsProfile,
                        }}
                        placement="bottomLeft"
                        arrow={{
                          pointAtCenter: true,
                        }}
                        trigger={["click"]}
                      >
                        <div className="w-16 h-16 rounded-full bg-blue-600 cursor-pointer group hidden group-hover:flex absolute justify-center items-center">
                          <FaPencilAlt className="text-white text-xl" />
                        </div>
                      </Dropdown>
                    </div>
                    {/* Section Where is name user  */}
                    <div className="flex flex-col dark:text-white">
                      <span className="font-bold">
                        ID: {generateContactID(contact)}  <br></br>
                        {contact?.firstName} {contact?.lastName}
                      </span>
                      {contact.jobTitle && contact.company?.companyName && (
                        <span>
                          {contact.jobTitle} at{" "}
                          {
                            <Link to={`/admin/company/${contact.companyId}`}>
                              {contact.company?.companyName}
                            </Link>
                          }
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Button danger onClick={onDelete}>
                      {TEXTS.TEXT.DELETE}
                    </Button>
                  </div>
                </div>
                <div className="overflow-hidden  gap-2 p-2">
                  <Form.Item label={TEXTS.LABELS.OWNER} name={"contactOwnerId"}>
                    <Select
                      bordered={false}
                      style={{ width: "200px", textWrap: "wrap", padding: "5px 0px" }}
                      className=""
                      loading={ownerLoading}
                    >
                      {ownerList.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item?.firstName} {item?.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={TEXTS.LABELS.FIRST_NAME} name="firstName">
                    <Input
                      bordered={false}
                      style={{ width: "200px", textWrap: "wrap", padding: "5px 0px" }}
                      className=""
                      suffix={<BsFillPencilFill />}
                    />
                  </Form.Item>
                  <Form.Item label={TEXTS.LABELS.LAST_NAME} name="lastName">
                    <Input
                      bordered={false}
                      style={{ width: "200px", textWrap: "wrap", padding: "5px 0px" }}
                      className=""
                      suffix={<BsFillPencilFill />}
                    />
                  </Form.Item>

                  <Form.Item label="Email" name={"email"}>
                    <Input
                      bordered={false}
                      suffix={<BsFillPencilFill />}
                      style={{ width: "200px", textWrap: "wrap", padding: "5px 0px" }}
                      className=""
                    />
                  </Form.Item>

                  <Form.Item label={TEXTS.LABELS.PHONE_DIS} name={"phone"}>
                    <Input
                      bordered={false}
                      suffix={<BsFillPencilFill />}
                      style={{
                        width: "200px", textWrap: "wrap", padding: "5px 0px",
                      }}
                      className=""
                    />
                  </Form.Item>
                  <Form.Item
                    name="idnp"
                    label="IDNP"
                    rules={[
                      { len: 13, message: "IDNP trebuie să fie de lungimea de 13 caractere." },
                      {
                        pattern: /^\d{13}$/,
                        message: "Sunt permise doar cifre!",
                      },
                    ]}
                  >
                    <Input bordered={false}
                      suffix={<BsFillPencilFill />}
                      style={{
                        width: "200px", textWrap: "wrap", padding: "5px 0px",
                      }}
                      className="" maxLength={13} />
                  </Form.Item>

                  <Form.Item label={TEXTS.LABELS.PRODUCTION_SUCCESSION} name={"productionId"}>
                    <Select
                      bordered={false}
                      style={{ width: "200px", textWrap: "wrap", padding: "5px 0px" }}
                      className=""
                      loading={productionLoading}
                    >
                      {productionList.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item?.productionName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {contact.opportunity && contact.opportunity.length > 0 && (
                    <span>
                      Data Livrării: {getOldestOpportunityDate(contact.opportunity)}
                    </span>
                  )}
                </div>
                {triggerSave && (
                  <div className="flex justify-end mr-4">
                    <Form.Item>
                      <div className="flex items-center gap-2">
                        <Button type="primary" htmlType="submit">
                          {TEXTS.BUTTON_TEXT.SAVE_BTN}
                        </Button>
                        <Button
                          loading={contactLoading}
                          type="danger"
                          onClick={onFinishFailed}
                        >
                          {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                        </Button>
                      </div>
                    </Form.Item>
                  </div>
                )}
              </div>
            </Form>

            <Modal
              title={`${TEXTS.BUTTON_TEXT.UPLOAD} ${TEXTS.LABELS.PHOTO}`}
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <UserPrivateComponent permission="update-contact">
                <ImageUpload
                  handleCancel={handleCancel}
                  id={id}
                  loadThunk={loadSingleContact}
                  updateThunk={updateContact}
                />
              </UserPrivateComponent>
            </Modal>
            <Image
              style={{
                display: "none",
              }}
              src={`${getImageUrl(contact?.image)}`}
              preview={{
                visible: visibleImage,
                scaleStep: 1,
                src: `${getImageUrl(contact?.image)}`,
                onVisibleChange: (value) => {
                  setVisibleImage(value);
                },
              }}
            />
          </Card>
        )}
      </Skeleton >
    </>
  );
}