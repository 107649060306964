import { Button, Card, Form, Input, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addUser } from "../../redux/rtk/features/user/userSlice";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import getRoleFromToken from "../../utils/getRoleFromToken";

const Login = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { Title } = Typography;

  // Check if the user is already logged in
  const isLogged = Boolean(localStorage.getItem("isLogged"));
  if (isLogged) {
    // Fetch the current user role to determine the redirection
    const userRole = getRoleFromToken();
    if (userRole) {
      switch (userRole) {
        case "Administrator":
        case "admin":
          return <Navigate to="/admin/dashboard" replace={true} />;
        case "Operator Producere":
          return <Navigate to="/admin/opportunity/kanban" />;
        case "Tehnician Prețuri":
          return <Navigate to="/admin/products" />
        case "Manager Vânzări":
        case "Manager Producere":
        case "Designer":
          return <Navigate to="/admin/calendar" replace={true} />;
        case "Contabil":
          return <Navigate to="/admin/finances" replace={true} />;
        default:
          return <Navigate to="/admin/login" replace={true} />;
      }
    }
    return <Navigate to="/admin/login" replace={true} />;
  }

  // Handle successful login
  const onFinish = async (values) => {
    setLoader(true);
    try {
      const resp = await dispatch(addUser(values));

      if (resp.payload.message === "success") {
        // Save login state in local storage
        localStorage.setItem("isLogged", "true");

        // Fetch the current user after login to determine their role
        const userRole = await getRoleFromToken();

        // Redirect based on the user's role
        switch (userRole) {
          case "Administrator":
          case "admin":
            window.location.href = "/admin/dashboard";
            break;
          case "Operator Producere":
            window.location.href = "/admin/opportunity/kanban";
            break;
          case "Tehnician Prețuri":
            window.location.href = "/admin/products";
            break;
          case "Manager Vânzări":
          case "Manager Producere":
          case "Designer":
            window.location.href = "/admin/calendar";
            break;
          case "Contabil":
            window.location.href = "/admin/finances"
            break;
          default:
            window.location.href = "/admin/login";
        }
      } else {
        throw new Error("Login failed");
      }
    } catch (error) {
      setLoader(false);
      toast.error("Eroare la logare, încearcă din nou");
    } finally {
      setLoader(false);
    }
  };

  // Handle login failure
  const onFinishFailed = (errorInfo) => {
    console.log("Login failed:", errorInfo);
    setLoader(false);
    toast.error("Eroare la logare, încearcă din nou");
  };

  return (
    <div className="card-row flex justify-center items-center h-[80vh]">
      <div className="w-4/5 md:[500px]">
        <Card bordered={false} className="rounded-lg w-full max-w-[30rem] mx-auto">
          <Title level={3} className="m-3 text-center">Admin Login</Title>
          <Form
            name="basic"
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              className="mb-5"
              name="userName"
              rules={[
                {
                  required: true,
                  message: "Vă rugăm sa introduceți numele de utilizator sau email!",
                },
              ]}
            >
              <Input placeholder="Introduceți Nume Utilizator sau Email" />
            </Form.Item>

            <Form.Item
              className="mb-5"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Vă rugăm să introduceți parola!",
                },
              ]}
            >
              <Input.Password placeholder="Introduceți Parola" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={loader}
                onClick={() => setLoader(true)}
              >
                Trimite
              </Button>
            </Form.Item>
          </Form>
          <div className="flex justify-center">
            <Link
              to={"/customer/login"}
              className="bg-lime-400 p-1 mt-3 w-2/6 rounded text-white font-semibold text-center hidden"
            >
              Customer login
            </Link>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Login;
