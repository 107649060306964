import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify"

const initialState = {
    list: [],
    room: null,
    error: "",
    loading: false,
}

export const addSingleRoom = createAsyncThunk(
    "room/addSingleRoom",
    async (values) => {
        try {
            const { data } = await axios({
                method: "post",
                url: `room`,
                data: {
                    ...values,
                },
            });
            toast.success("Room Added");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in adding room");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

export const deleteRoom = createAsyncThunk(
    "room/deleteRoom",
    async (id, api) => {
        try {
            const resp = await axios.delete(`room/${id}`);
            api.dispatch(loadAllRooms());
            toast.success("Room Deleted");
            return {
                data: resp.data.id,
                message: "success",
            };
        } catch (error) {
            toast.error("Error while deleting room");
            console.log(error.message)
            return {
                message: "error",
            };
        }
    }
);

export const loadAllRooms = createAsyncThunk(
    "room/loadAllUnits",
    async () => {
        try {
            const { data } = await axios.get(`room`);
            return data;
        } catch (error) {
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

export const updateRoom = createAsyncThunk(
    "room/updateRoom",
    async ({ id, values }) => {
        try {
            const { data } = await axios.put(`room/${id}`, values);
            toast.success("Room Updated");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in updating room");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

const roomSlice = createSlice({
    name: "room",
    initialState,
    reducers: {
        clearRoom: (state) => {
            state.room = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadAllRooms.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllRooms.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(loadAllRooms.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message;
        });


        builder.addCase(addSingleRoom.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addSingleRoom.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(addSingleRoom.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message;
        });


        builder.addCase(updateRoom.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateRoom.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(updateRoom.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message;
        });


        builder.addCase(deleteRoom.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteRoom.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(deleteRoom.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message;
        });
    },
});

export default roomSlice.reducer;
export const { clearRoom } = roomSlice.actions;