import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { deletePartner, loadAllPartners } from "../../../../../redux/rtk/features/crm/partner/partnerSlice";
import { TEXTS } from "../../../../../constants";
import moment from "moment";
import CustomDrawer from "../../../CommonUi/CustomDrawer";
import { AiFillDelete } from "react-icons/ai";
import CreatePartnerForm from "./CreatePartnerForm";
import { Card, Table } from "antd";
import { CSVLink } from "react-csv";
import ColVisibilityDropdown from "../../../../Shared/ColVisibilityDropdown";

const PartnerTable = () => {
    const dispatch = useDispatch();
    const { list: partnerList, loading } = useSelector(
        (state) => state.partner
    );

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    useEffect(() => {
        dispatch(loadAllPartners());
    }, [dispatch]);

    const deleteHandler = (id) => {
        dispatch(deletePartner(id));
        dispatch(loadAllPartners());
    };

    const columns = [
        {
            title: `${TEXTS.LABELS.LAST_NAME} ${TEXTS.LABELS.FIRST_NAME}`,
            dataIndex: "partnerName",
            render: (_, { partnerFirstName, partnerLastName }) =>
                partnerLastName && partnerFirstName ? (
                    `${partnerLastName} ${partnerFirstName}`
                ) : (
                    "-"
                ),
        },
        {
            title: `${TEXTS.LABELS.CREATE_DATE}`,
            dataIndex: "createdAt",
            render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
        },
        {
            title: `${TEXTS.LABELS.UPDATE_DATE}`,
            dataIndex: "updatedAt",
            render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
        },
        {
            title: `${TEXTS.LABELS.ACTIONS}`,
            dataIndex: "id",
            render: (id, values) => (
                <span className='flex items-center gap-2'>
                    <CustomDrawer
                        permission={"update-contactSource"}
                        update
                        title={`${TEXTS.TEXT.UPDATE} ${TEXTS.LABELS.PARTNER}`}
                        width={25}
                    >
                        <CreatePartnerForm edit={{ id, values }} />
                    </CustomDrawer>
                    <span
                        onClick={() => deleteHandler(id)}
                        className="bg-red-500 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded"
                    >
                        <AiFillDelete className="text-white" />
                    </span>
                </span>
            ),
        },
    ];

    const [columnsToShow, setColumnsToShow] = useState([]);
    useEffect(() => {
        setColumnsToShow(columns);
    }, []);
    const columnsToShowHandler = (val) => {
        setColumnsToShow(val);
    }
    return (
        <Card className="border mt-2">
            <div className="flex justify-between items-center">
                <div></div>
                <div className="flex items-center gap-2">
                    <span className="border px-2 py-1 rounded cursor-pointer bg-black text-white">
                        <CSVLink
                            data={partnerList}
                            className="btn btn-dark btn-sm"
                            style={{ marginTop: "5px" }}
                            filename="Partner_CRM-OS"
                        >
                            Descarcă CSV
                        </CSVLink>
                    </span>
                    <ColVisibilityDropdown
                        options={columns}
                        columns={columns}
                        columnsToShowHandler={columnsToShowHandler}
                    />
                </div>
            </div>
            <div>
                <span
                    style={{ marginLeft: 8, }}
                >
                    {hasSelected ? `Selectat ${selectedRowKeys.length} itemi` : ""}
                </span>
            </div>
            <Table
                loading={loading}
                rowSelection={rowSelection}
                columns={columnsToShow}
                dataSource={partnerList?.map((item) => ({
                    ...item,
                    key: item.id,
                }))}
                pagination={false}
                scroll={{ x: 500 }}
            />
        </Card>
    )
};

export default PartnerTable;