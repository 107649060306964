import { Drawer } from "antd";
import { TEXTS } from "../../../constants";

export default function CreateDrawer({
  open,
  onClose,
  title,
  width,
  children,
}) {
  return (
    <>
      <Drawer
        width={window.innerWidth <= 768 ? "100%" : width ? `${width}%` : "45%"}
        title={`${TEXTS.TEXT.CREATE} ${title}`}
        placement='right'
        closable={true}
        onClose={onClose}
        open={open}
        style={{ zIndex: 1050 }}
      >
        {children}
      </Drawer>
    </>
  );
}
