import { Drawer } from "antd";
import CreateOpportunityTypeForm from "./CreateOpportunityTypeForm";
import { TEXTS } from "../../../../../constants";

export default function CreateOpportunityType({ open, onClose }) {
  return (
    <Drawer
      width={450}
      title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.OPPORTUNITY_TYPE}`}
      placement='right'
      onClose={onClose}
      open={open}
    >
      <CreateOpportunityTypeForm onClose={onClose} />
    </Drawer>
  );
}
