import getRoleFromToken from "../getRoleFromToken";

const userRole = getRoleFromToken();

// Roles that have access to all sections
const allAccessRoles = ["Fondator", "Cofondator", "admin", "Administrator", "Manager Operațional"];

// Function to check if the user has access to a menu item based on their role
const hasAccess = (allowedRoles = []) => {
    // If allowedRoles includes 'all', everyone has access
    if (allowedRoles.includes("all")) {
        return true;
    }
    // If user's role is in the allowed roles or has full access, grant access
    return allowedRoles.includes(userRole) || allAccessRoles.includes(userRole);
};

const canSeeDiscount = () => {
    return userRole !== "Designer"; // Exclude Designer role
};

export default hasAccess;
export { canSeeDiscount }