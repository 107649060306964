import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import getUserFromToken from "../../utils/getUserFromToken";
import { loadNotificationsByRecipientId, markAllNotificationsAsRead } from "../../redux/rtk/features/notification/notificationSlice";
import { FaBell } from "react-icons/fa";
import { useWebSocket } from "../../components/PrivateRoutes/WebSocketContext"; // Ensure correct import
import NotificationList from "./NotificationList";

const NotificationIcon = () => {
    const dispatch = useDispatch();
    const [showNotifications, setShowNotifications] = useState(false);
    const recipientId = getUserFromToken();
    const notificationRef = useRef(null);
    const { count } = useSelector((state) => state.notification);

    useEffect(() => {
        dispatch(loadNotificationsByRecipientId(recipientId))
    },
        [dispatch, recipientId]);

    const handleNotificationOpen = () => {
        setShowNotifications(!showNotifications);
        if (!showNotifications) {
            dispatch(loadNotificationsByRecipientId(recipientId));
            dispatch(markAllNotificationsAsRead(recipientId));
        }
    };

    // Handle outside click to close notification list
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [notificationRef]);

    const { socket } = useWebSocket();

    useEffect(() => {
        if (!socket) return;

        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === "notification") {
                dispatch(loadNotificationsByRecipientId(recipientId));
            }
        };

        socket.addEventListener("message", handleMessage);
        return () => {
            socket.removeEventListener("message", handleMessage);
        };
    }, [dispatch, recipientId, socket]);

    return (
        <div className="relative flex items-center justify-center" ref={notificationRef}>
            <button
                onClick={handleNotificationOpen}
                className="text-white hover:text-gray-800 relative"
            >
                <FaBell className="w-6 h-6" />
                {count > 0 && (
                    <span className="absolute top-0 right-0 bg-red-600 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                        {count}
                    </span>
                )}
            </button>

            {showNotifications && (
                <div className="absolute right-0 mt-2 w-64 bg-white shadow-lg rounded-lg z-50">
                    <NotificationList />
                </div>
            )}
        </div>
    );
};

export default NotificationIcon;
