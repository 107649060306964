import { Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { addSingleProduction, getAllProductions, updateProduction } from "../../../../../redux/rtk/features/crm/production/productionSlice";
import { TEXTS } from "../../../../../constants";

export default function CreateProductionForm({ onClose, edit }) {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onFinish = async (values) => {
        const resp = await dispatch(
            edit?.id
                ? updateProduction({ id: edit?.id, values })
                : addSingleProduction(values)
        );
        if (resp.payload.message === "success") {
            dispatch(getAllProductions());
            if (!edit?.id) {
                onClose();
                form.resetFields();
            }
        }
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className='flex justify-center mt-5'>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                colon={false}
                layout='vertical'
                initialValues={edit?.id ? { ...edit?.values } : {}}
                form={form}
                style={{ width: "80%" }}
            >
                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.LABELS.PRODUCTION_SUCCESSION}
                    name='productionName'
                    tooltip={TEXTS.TEXT.REQUIRED_FIELD}
                    rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE}` }]}
                >
                    <Input placeholder={TEXTS.LABELS.PRODUCTION_SUCCESSION} />
                </Form.Item>
                <div className="text-xl pb-2">
                    INFORMAȚII SRL
                </div>
                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.LABELS.USERNAME}
                    name='productionUserName'
                >
                    <Input placeholder={TEXTS.LABELS.PRODUCTION_SUCCESSION} />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.LABELS.PHONE}
                    name='phone'
                >
                    <Input placeholder={TEXTS.LABELS.PHONE} />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label="Email"
                    name='email'
                >
                    <Input placeholder="Emails" />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label="IDNO"
                    name='idno'
                >
                    <Input placeholder="IDNO" />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label="TVA"
                    name='tva'
                >
                    <Input placeholder="TVA" />
                </Form.Item>
                <div className="text-xl pb-2">
                    Richizite Bancare:
                </div>
                <Form.Item
                    style={{ width: "350px" }}
                    label="IBAN"
                    name='iban'
                >
                    <Input placeholder="IBAN" />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label="BIC"
                    name='bic'
                >
                    <Input placeholder="BIC" />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label="Nume Bancă"
                    name='bankName'
                >
                    <Input placeholder="Nume bancă" />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.TEXT.BILLING_STREET}
                    name='juridicStreet'
                >
                    <Input placeholder={TEXTS.TEXT.BILLING_STREET} />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.TEXT.BILLING_CITY}
                    name='juridicCity'
                >
                    <Input placeholder={TEXTS.TEXT.BILLING_CITY} />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.LABELS.ZIP_CODE}
                    name='postalCode'
                >
                    <Input placeholder={TEXTS.LABELS.ZIP_CODE} />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.TEXT.SHIPPING_STREET}
                    name='officeStreet'
                >
                    <Input placeholder={TEXTS.TEXT.SHIPPING_STREET} />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.TEXT.SHIPPING_CITY}
                    name='officeCity'
                >
                    <Input placeholder={TEXTS.TEXT.SHIPPING_CITY} />
                </Form.Item>

                <Form.Item
                    style={{ width: "350px" }}
                    label={TEXTS.TEXT.BILLING_ZIP_CODE}
                    name='officePostalCode'
                >
                    <Input placeholder={TEXTS.TEXT.BILLING_ZIP_CODE} />
                </Form.Item>

                <Form.Item label=''>
                    <div className='flex items-center gap-2'>
                        <Button size={"large"} htmlType='submit' type='primary'>
                            {edit?.id ? `${TEXTS.TEXT.UPDATE}` : `${TEXTS.TEXT.CREATE}`}
                        </Button>
                        {!edit?.id && (
                            <Button
                                size={"large"}
                                onClick={onCancel}
                                type='default'
                            >
                                {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                            </Button>
                        )}
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}
