import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    list: [],
    contactItem: null,
    error: "",
    loading: false,
};

export const getContactItemsByContact = createAsyncThunk(
    "contactItem/getContactItemsByContact",
    async (contactId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/contact-item/contact/${contactId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createContactItems = createAsyncThunk(
    "contactItem/createaOpportuityItems",
    async ({ roomItemIds, contactId }, { rejectWithValue }) => {
        try {
            const response = await axios.post("/contact-item", { roomItemIds, contactId });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateContactItem = createAsyncThunk(
    'contactItems/updateContactItem',
    async ({ id, roomItemId }, { rejectWithValue }) => {
        try {
            const response = await axios.put(`/contact-item/${id}`, { roomItemId });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteContactItem = createAsyncThunk(
    'contactItems/deleteContactItem',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`/contact-item/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const contactItemSlice = createSlice({
    name: "contactItem",
    initialState,
    reducers: {
        clearContactItem: (state) => {
            state.contactItem = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getContactItemsByContact.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getContactItemsByContact.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(getContactItemsByContact.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });


        builder.addCase(createContactItems.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(createContactItems.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(createContactItems.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });


        builder.addCase(updateContactItem.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateContactItem.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(updateContactItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });


        builder.addCase(deleteContactItem.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteContactItem.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(deleteContactItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    }
});

export default contactItemSlice.reducer;