import { Button } from "antd";
import moment from "moment";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import getSetting from "../../../../api/getSettings";
import numberToWords from "../../../../utils/numberToWords";
import "./style.css";
import { TEXTS } from "../../../../constants";

const PrintToPdf = forwardRef(({ data, invoiceData, vatAmount }, ref) => {
  return (
    <>
      <div ref={ref} className='wrapper'>
        {/* Your existing code */}

        <div className='box7'>
          <table className='table1'>
            <thead>
              <th>Sl</th>
              <th>{TEXTS.LABELS.PRODUCT_NAME}</th>
              <th>Product Image</th> {/* Add this column for the image */}
              <th>{TEXTS.TEXT.QUANTITY}</th>
              <th>{TEXTS.TEXT.UNIT_PRICE}</th>
              <th>{TEXTS.TEXT.TOTAL_PRICE}</th>
            </thead>
            <tbody>
              {data &&
                data.quoteProduct.map((d) => (
                  <tr key={d.id}>
                    <td>{d.id}</td>
                    <td>
                      <p>{d.product.productName}</p>
                    </td>
                    <td> {/* Render the image here */}
                      <img src={`data:image/jpeg;base64,${d.product.productImage}`} alt="Product Image" width="50" />
                    </td>
                    <td>{d.productQuantity}</td>
                    <td>{d.unitPrice}</td>
                    <td>{d.productQuantity * d.unitPrice}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* Your existing code */}

      </div>
    </>
  );
});

const Invoice = ({ data, vatAmount }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [invoiceData, setInvoiceData] = useState(null);
  useEffect(() => {
    getSetting().then((data) => setInvoiceData(data.result));
  }, []);

  return (
    <div>
      <div className='hidden'>
        <PrintToPdf
          ref={componentRef}
          data={data}
          invoiceData={invoiceData}
          vatAmount={vatAmount}
        />
      </div>
      {invoiceData && (
        <Button type='primary' shape='round' onClick={handlePrint}>
          Print PDF
        </Button>
      )}
    </div>
  );
};

export default Invoice;
