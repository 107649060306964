import axios from "axios";
const sendEmail = async (emailData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API}/email/smtp`, emailData);
        return response.data;
    } catch (error) {
        console.error('Error sending email:', error);
        throw error;
    }
};

export default sendEmail;