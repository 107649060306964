import { Badge, Button, Card, Skeleton, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteQuote,
  loadAllQuotePaginated,
  loadSingleQuote,
  updateQuote,
} from "../../../../redux/rtk/features/crm/quote/quoteSlice";
import Invoice from "./Invoice";
import TableComponent from "./Table";
import { CURRENCY, TEXTS } from "../../../../constants";
import { BiArrowBack } from "react-icons/bi";

export default function QuoteDetails() {
  const { QuoteId: id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { quote, loading } = useSelector((state) => state.quote) || {};

  const [totalAmount, setTotalAmount] = useState(0);
  const [aproxAmount, setAproxAmount] = useState(0);
  const [aproxDiscount, setAproxDiscount] = useState(0);
  const [discountInput, setDiscountInput] = useState(quote?.discount || 0);

  const onDelete = async () => {
    var result = window.confirm(`${TEXTS.TEXT.ON_DELETE_TEXT}`);
    if (result) {
      const resp = await dispatch(deleteQuote(id));
      if (resp.payload.message === "success") {
        navigate(-1);
        dispatch(loadAllQuotePaginated({ status: true }));
      }
    }
  };

  useEffect(() => {
    if (quote && quote.quoteProduct) {
      // Calculate total amount dynamically
      let sum = 0;
      quote.quoteProduct.forEach((product) => {
        const unitPrice = product.unitPrice || 0;
        const productQuantity = product.productQuantity || 0;
        sum += unitPrice * productQuantity;
      });

      // Apply discount
      const discount = quote.discount ?? 0;
      const discountedTotal = sum - discount;
      setTotalAmount(discountedTotal);
      setAproxAmount(discountedTotal / CURRENCY.EURO);
      setAproxDiscount(discount / CURRENCY.EURO);
    }
  }, [quote]);

  useEffect(() => {
    dispatch(loadSingleQuote(id));
  }, [dispatch, id]);


  useEffect(() => {
    setDiscountInput(quote?.discount ?? 0);
  }, [quote]);

  const cameFromOpportunity = location.state?.fromOpportunity;

  const handleBackClick = () => {
    if (cameFromOpportunity) {
      navigate(`/admin/opportunity/${cameFromOpportunity}`, {
        state: { fromOpportunity: cameFromOpportunity }
      });
    }
  };

  const minPrice = aproxAmount * 2.1;
  const offerPrice = aproxAmount * 2.3;
  const maxDiscount = offerPrice - minPrice;
  const discountedOfferPrice = offerPrice - discountInput;
  const discountedTotalPrice = (totalAmount * 2.3) - (discountInput * CURRENCY.EURO);
  const handleDiscountChange = (e) => {
    const newDiscount = parseFloat(discountInput) || 0;

    // Ensure the discount does not exceed the maxDiscount
    if (newDiscount >= maxDiscount) {
      alert(`Reducerea nu poate fi pusă după limita de ${maxDiscount.toFixed(2)} €`);
      return;
    }

    // Dispatch the action to update the quote with the new discount
    dispatch(updateQuote({ id, values: { discount: newDiscount } }));
  };

  return (
    <>
      <div>
        <Card>
          <div className="flex justify-between items-center">
            {cameFromOpportunity && (<BiArrowBack type="link" onClick={handleBackClick} className="cursor-pointer text-2xl text-[#1890ff]" />)}
            <p className="text-base ml-6">Proiect: {quote?.opportunity?.opportunityName}</p>
            <div className='flex justify-center gap-3 items-center p-3 mb-2 flex-grow'>
              <Button onClick={onDelete} type='danger'>
                {TEXTS.TEXT.DELETE}
              </Button>
              <Invoice data={quote} />
            </div>
          </div>
          <div className='tw-mt-5'>
            <div className='flex flex-col md:flex-row gap-2'>
              <div className='w-full md:w-1/2'>
                <Card title={TEXTS.LABELS.QUOTE_INFORMATION}>
                  <Skeleton loading={loading}>
                    <p>
                      <Typography.Text strong>{TEXTS.LABELS.QUOTE_NAME} :</Typography.Text>{" "}
                      <strong>{quote?.quoteName}</strong>
                    </p>

                    <p>
                      <Typography.Text strong>{TEXTS.LABELS.QUOTATION_DATE} :</Typography.Text>{" "}
                      <strong>
                        {moment(quote?.quoteDate).format("MMMM Do YYYY")}
                      </strong>
                    </p>
                    <p>
                      <strong className='text-center block border-b py-1'>
                        {TEXTS.LABELS.DESCRPTION}
                      </strong>
                      {quote?.description}
                    </p>
                  </Skeleton>
                </Card>
              </div>
              <div className='w-full md:w-1/2'>
                <Badge.Ribbon
                  text={quote?.quoteStage?.quoteStageName}
                  className='tw-mt-10'
                >
                  <Card title={TEXTS.TITLES.PRICE_SUMMARY}>
                    <Skeleton loading={loading}>
                      {/* <p>
                        <Typography.Text strong>{TEXTS.LABELS.TOTAL_AMMOUNT} lei:</Typography.Text>{" "}
                        <strong>{totalAmount}</strong>
                      </p>
                      <p>
                        <Typography.Text strong>{TEXTS.LABELS.TOTAL_AMMOUNT}~ &euro;:</Typography.Text>{" "}
                        <strong>{aproxAmount.toFixed(2)}</strong>
                      </p> */}
                      <p>
                        <Typography.Text strong>Preț Minim &euro;:</Typography.Text>{" "}
                        <strong>{(aproxAmount * 2.1).toFixed(2)} ({(totalAmount * 2.1).toFixed(2)} lei)</strong>
                      </p>
                      <p>
                        <Typography.Text strong>Preț de Ofertare &euro;:</Typography.Text>{" "}
                        <strong>{discountedOfferPrice.toFixed(2)} ({discountedTotalPrice.toFixed(2)} lei)</strong>
                      </p>
                      <div className="flex items-center">
                        <div className="flex flex-col flex-grow">
                          <p>
                            <Typography.Text strong>
                              {TEXTS.TEXT.DISCOUNT} Eur:
                            </Typography.Text>{" "}
                            <input
                              type="text"
                              value={discountInput}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) { // Allow only numbers
                                  setDiscountInput(value === "" ? "" : Number(value));
                                }
                              }}
                              className="w-[7vw] border-teal-500 border-2 text-black"
                            />
                          </p>
                          <span> (Maximum {maxDiscount.toFixed(2)} &euro; / {maxDiscount.toFixed(2) * CURRENCY.EURO} lei)</span>
                        </div>
                        <Button type="primary" onClick={handleDiscountChange} className="ml-8">
                          Save Discount
                        </Button>
                      </div>
                    </Skeleton>
                  </Card>
                </Badge.Ribbon>
              </div>
            </div>
          </div>
          <TableComponent loading={loading} productList={quote?.quoteProduct} />
        </Card>
      </div>
    </>
  );
}
