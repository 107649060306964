import { Drawer } from "antd";
import CreateCompanyTypeForm from "./CreateCompanyTypeForm";
import { TEXTS } from "../../../../../constants";
export default function CreateCompanyType({ open, onClose }) {
  return (
    <Drawer
      width={450}
      title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.COMPANY_TYPE}`}
      placement='right'
      onClose={onClose}
      open={open}
    >
      <CreateCompanyTypeForm onClose={onClose} />
    </Drawer>
  );
}
