import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Select, Upload } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addSingleAttachment,
	loadAllAttachmentPaginated,
} from "../../../redux/rtk/features/crm/attachment/crmAttachmentSlice";
import { getAttachmentTypes } from "./attachmentApis";
import { loadAllCompany } from "../../../redux/rtk/features/crm/company/companySlice";
import { loadAllContactPaginated } from "../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllOpportunity } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllQuote } from "../../../redux/rtk/features/crm/quote/quoteSlice";
import { loadAllStaff } from "../../../redux/rtk/features/user/userSlice";
import { loadAllContactRooms } from "../../../redux/rtk/features/crm/contactRoom/contactRoomSlice";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
import fileConfig from "../../../utils/fileConfig";
import { TEXTS } from "../../../constants";
import { getOpportunityItemsByOpportunity } from "../../../redux/rtk/features/crm/opportunityItem/opportunityItemSlice";

export default function CreateAttachmentForm({ onClose, createAs, opportunityId, contactId }) {
	// selector
	const { list: companyList, loading: companyLoading } = useSelector(
		(state) => state.company
	);
	const { list: contactRooms, loading: roomLoading } = useSelector(
		(state) => state.contactRoom
	);
	const { list: contactList, loading: contactLoading } = useSelector(
		(state) => state.contact
	);
	const { list: opportunityList, loading: opportunityLoading } = useSelector(
		(state) => state.opportunity
	);
	const { list: opportunityItemsList } = useSelector(
		(state) => state.opportunityItem
	);
	const { loading: attatchmentLoading } = useSelector(
		(state) => state.crmAttachment
	);
	const { list: staffList, loading: staffLoading } = useSelector(
		(state) => state.users
	);
	const { loading: noteLoading } = useSelector((state) => state.crmNote);

	const [form] = Form.useForm();
	const [attachmentType, setAttachmentType] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadAllContactPaginated({ status: true, count: 999 }));
		dispatch(loadAllCompany());
		dispatch(loadAllOpportunity());
		dispatch(loadAllQuote());
		dispatch(loadAllStaff({ status: true }));
		dispatch(loadAllContactRooms());
		if (opportunityId) {
			dispatch(getOpportunityItemsByOpportunity(opportunityId));
		}
		const loadAllAttachmentTypes = async () => {
			try {
				const data = await getAttachmentTypes();
				setAttachmentType(data);
			} catch (error) {
				console.error('Failed to load attachment types:', error);
			}
		};

		loadAllAttachmentTypes();
	}, [dispatch]);


	const onFinish = async (values) => {
		const formData = new FormData();
		if (values.attachmentOwnerId)
			formData.append("attachmentOwnerId", parseInt(values.attachmentOwnerId));
		if (values.attachmentTypeId)
			formData.append("attachmentTypeId", parseInt(values.attachmentTypeId));
		if (createAs?.name === "contactRoomId" && values.contactRoomId) {
			formData.append("contactRoomId", parseInt(values.contactRoomId));
		} else {
			if (values.contactId) formData.append("contactId", parseInt(values.contactId));
			if (values.opportunityId) formData.append("opportunityId", parseInt(values.opportunityId));
			if (values.companyId) formData.append("companyId", parseInt(values.companyId));
		}

		if (values.opportunityItemId) formData.append("opportunityItemId", parseInt(values.opportunityItemId));
		if (fileConfig() !== "laravel") {
			formData.append("files", values.files[0]?.originFileObj);
		} else {
			formData.append("files[]", values.files[0]?.originFileObj);
		}

		const resp = await dispatch(addSingleAttachment(formData));
		if (resp.payload.message === "success") {
			form.resetFields();
			if (createAs?.name) {
				dispatch(createAs.singleLoadThunk(createAs.value));
			} else {
				dispatch(loadAllAttachmentPaginated({}));
			}
			onClose();
		}
	};

	const onCancel = () => {
		form.resetFields();
		onClose();
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};
	const [fileLength, setFileLength] = useState(0);
	const normFile = (e) => {
		if (Array.isArray(e)) {
			setFileLength(e.length);
			return e;
		}
		setFileLength(e?.fileList?.length);
		return e?.fileList;
	};

	return (
		<div className='flex justify-center mt-5'>
			<UserPrivateComponent permission='create-note'>
				<Form
					className='w-4/5'
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					colon={false}
					layout='vertical'
					form={form}
					initialValues={
						createAs
							? {
								[createAs?.name]: createAs?.value,
								attachmentTypeId: createAs?.attachment,
								opportunityItemId: createAs?.opportunityItem,
								contactId: contactId,
							}
							: {}
					}>
					<Form.Item
						label={TEXTS.LABELS.ATTACHMENT}
						name={"files"}
						valuePropName='fileList'
						getValueFromEvent={normFile}
						tooltip={TEXTS.TEXT.REQUIRED_FIELD}
						rules={[{ required: true, message: `${TEXTS.MESSAGES.UPLOAD_MESSAGE}` }]}>
						<Upload listType='text' beforeUpload={() => false}>
							{!(fileLength >= 1) && (
								<div className='flex items-center border p-1 px-5 gap-3 '>
									<PlusOutlined />
									<div>{TEXTS.BUTTON_TEXT.UPLOAD}</div>
								</div>
							)}
						</Upload>
					</Form.Item>
					<Form.Item
						label={TEXTS.LABELS.ATTACHMENT_OWNER}
						name={"attachmentOwnerId"}
						tooltip={TEXTS.TEXT.REQUIRED_FIELD}
						rules={[
							{ required: true, message: `${TEXTS.MESSAGES.ATTACHMENT_OWNER_MESSAGE}` },
						]}>
						<Select
							style={{ width: "100%" }}
							loading={staffLoading}
							allowClear
							showSearch
							placeholder={TEXTS.PLACEHOLDERS.NOTE_OWNER}>
							{staffList?.map((item) => (
								<Select.Option key={item.id} value={item.id}>
									{item?.firstName} {item?.lastName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label={TEXTS.LABELS.ATTACHMENT_TYPE}
						name={"attachmentTypeId"}
						tooltip={TEXTS.TEXT.REQUIRED_FIELD}
						rules={[
							{ required: true, message: `${TEXTS.MESSAGES.ATTACHMENT_TYPE_MESSAGE}` }
						]}>
						<Select
							style={{ width: "100%" }}
							allowClear
							showSearch
							placeholder={TEXTS.PLACEHOLDERS.ATTACHMENT_TYPE}
							disabled={!!(createAs?.name === "attachmentTypeId") || createAs?.attachment}>
							{attachmentType?.map((item) => (
								<Select.Option key={item.id} value={item.id}>
									{item.type}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					{createAs?.name === "contactRoomId" ? (
						<Form.Item
							label={TEXTS.LABELS.CONTACT_ROOM} name='contactRoomId'>
							<Select
								style={{ width: "100%" }}
								loading={roomLoading}
								allowClear
								showSearch
								placeholder={TEXTS.LABELS.CONTACT_ROOM}
								disabled={!!(createAs?.name === "contactRoomId") || createAs?.contactRoom}
							>
								{contactRooms?.map((item) => (
									<Select.Option key={item.id} value={item.id}>
										{item.room.roomName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					) : (<>
						<Form.Item label={TEXTS.LABELS.COMPANY} name='companyId'>
							<Select
								style={{ width: "100%" }}
								loading={companyLoading}
								allowClear
								showSearch
								placeholder={TEXTS.PLACEHOLDERS.COMPANY_PLACEHOLDER}
								disabled={!!(createAs?.name === "companyId")}>
								{companyList?.map((item) => (
									<Select.Option key={item.id} value={item.id}>
										{item.companyName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label={TEXTS.LABELS.CONTACT} name='contactId'>
							<Select
								style={{ width: "100%" }}
								loading={contactLoading}
								allowClear
								showSearch
								placeholder={TEXTS.PLACEHOLDERS.CONTACT_PLACEHOLDER}
								disabled={!!(createAs?.name === "contactId") || !!(contactId)}>
								{contactList?.map((item) => (
									<Select.Option key={item.id} value={item.id}>
										{item.fullName
											? item.fullName
											: item.firstName + " " + item.lastName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label={TEXTS.LABELS.OPPORTUNITY} name='opportunityId'>
							<Select
								style={{ width: "100%" }}
								loading={opportunityLoading}
								allowClear
								showSearch
								placeholder={TEXTS.PLACEHOLDERS.OPPORTUNITY_PLACEHOLDER}
								disabled={!!(createAs?.name === "opportunityId")}>
								{opportunityList?.map((item) => (
									<Select.Option key={item.id} value={item.id}>
										{item.opportunityName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						{opportunityId && <Form.Item
							label={TEXTS.LABELS.ROOM_ITEMS}
							name={"opportunityItemId"}
							tooltip={TEXTS.TEXT.REQUIRED_FIELD}
						>
							<Select
								style={{ width: "100%" }}
								allowClear
								showSearch
								placeholder={TEXTS.PLACEHOLDERS.ROOM_PLACEHOLDER}
								disabled={!!(createAs?.name === "opportunityItemId") || createAs?.opportunityItem}
							>
								{opportunityItemsList
									?.filter(item => item.opportunityId === Number(opportunityId)) // Convert opportunityId to a number
									.map(item => (
										<Select.Option key={item.id} value={item.id}>
											{item.roomItem.itemName}
										</Select.Option>
									))}
							</Select>
						</Form.Item>}
					</>)}
					<Form.Item label=''>
						<div className='flex items-center gap-2'>
							<Button
								size={"large"}
								htmlType='submit'
								type='primary'
								loading={attatchmentLoading}>
								{TEXTS.TEXT.CREATE}
							</Button>
							<Button
								loading={noteLoading}
								size={"large"}
								htmlType='submit'
								type='danger'
								onClick={onCancel}>
								{TEXTS.BUTTON_TEXT.CLOSE_BTN}
							</Button>
						</div>
					</Form.Item>
				</Form>
			</UserPrivateComponent>
		</div>
	);
}
