import { Layout, Collapse, Button } from "antd";
import { useEffect, useRef, useState } from "react";
import {
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarRightExpand,
} from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearContact,
  loadSingleContact,
  updateContact,
} from "../../../../redux/rtk/features/crm/contact/contactSlice";
import Notes from "../../CommonUi/Notes";
import ContactProfile from "./ContactProfile";
import DetailsInfo from "./DetailsInfo";
import { TEXTS } from "../../../../constants";
import getCurrentUser from '../../../user/getCurrentUserapi';
import projectIcon from "../../../../assets/Icons/project-management.png"
import ContactRooms from "../../CommonUi/ContactRooms";
import { BiPlus } from "react-icons/bi";
import CreateDrawer from "../../CommonUi/CreateDrawer";
import CreateContractForm from "../Contract/CreateContractForm";
import contractIcon from "../../../../assets/Icons/contract.png"
import CreatePredatPreluatForm from "../../Contracts/CreatePredatPreluatForn";
import CreateOfertaForm from "../../Contracts/CreateOfertaForm";
import Contracts from "../../CommonUi/Contracts";

const { Panel } = Collapse;

export default function ContactDetails() {
  const { ContactId: id } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openPredatPreluat, setPredatPreluat] = useState(false);
  const [openOferta, setOpenOferta] = useState(false);
  const { contact, loading: contactLoading } = useSelector(
    (state) => state.contact
  );
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    dispatch(loadSingleContact(id));
    return () => {
      dispatch(clearContact());
    };
  }, [dispatch, id]);

  useEffect(() => {
    async function fetchUser() {
      const user = await getCurrentUser();
      setCurrentUser(user);
    }
    if (!currentUser) fetchUser();
  }, [currentUser]);

  const opportunityRef = useRef(null);
  const attachmentRef = useRef(null);

  const onClose = () => {
    setOpen(false);
  };

  const toggleGuaranteeStatus = async () => {
    dispatch(
      updateContact({
        id: contact.id,
        values: { guarantee: false }
      })
    );

    dispatch(loadSingleContact(id));
  };

  const onPredatPreluatClose = () => {
    setPredatPreluat(false);
  }

  const onOfertaClose = () => {
    setOpenOferta(false);
  }

  if (!currentUser) {
    return <div>Loading...</div>;
  }


  return (
    <>
      <Layout className="flex">
        <Layout.Sider
          width={350}
          className="dark:bg-DTableBg dark:text-white hidden md:block"
          collapsible
          trigger={null}
          collapsed={collapsed}
        >
          {!collapsed && (
            <div className="flex items-start flex-col">
              <div className="w-full p-2 mb-10 flex justify-end">
                <TbLayoutSidebarRightExpand
                  onClick={() => setCollapsed(true)}
                  className="text-3xl inline-block"
                />
              </div>
              <div>
                <ContactProfile contact={contact} />
              </div>
              <div>
                {contact?.guarantee === true && (
                  <div className="mx-16 flex flex-grow mb-4 w-16">
                    <Button
                      onClick={toggleGuaranteeStatus}
                      className='border bg-red-600 hover:bg-red-500 text-white rounded cursor-pointer mx-auto'
                    >
                      Reîntoarce În Garanție
                    </Button>
                  </div>
                )}
              </div>
              <div className="grid h-auto w-full">
                <DetailsInfo contact={contact} />
              </div>
            </div>
          )}
        </Layout.Sider>
        <Layout.Content>
          <div className="flex justify-center">
            <Button
              onClick={() => setOpen(true)}
              className='flex items-center justify-center w-1/4 border mb-6 bg-lime-700 hover:bg-lime-500 text-white rounded cursor-pointer mx-auto'

              icon={<BiPlus />}
            >
              Creare Contract
            </Button>
            <Button
              onClick={() => setPredatPreluat(true)}
              className='flex items-center justify-center w-1/4 border mb-6 bg-lime-700 hover:bg-lime-500 text-white rounded cursor-pointer mx-auto'

              icon={<BiPlus />}
            >
              Creare Predat/Preluat Document
            </Button>
            <Button
              onClick={() => setOpenOferta(true)}
              className='flex items-center justify-center w-1/4 border mb-6 bg-lime-700 hover:bg-lime-500 text-white rounded cursor-pointer mx-auto'

              icon={<BiPlus />}
            >
              Creare Ofertă
            </Button>
          </div>
          <div className="container overflow-y-auto overflow-x-hidden flex flex-col gap-8 w-auto">
            {collapsed && (
              <div className="p-1 absolute left-0 z-10">
                <TbLayoutSidebarRightCollapse
                  onClick={() => setCollapsed(false)}
                  className="text-3xl inline-block"
                />
              </div>
            )}
            <Collapse accordion>

              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">{TEXTS.LABELS.ROOMS}</span>
                  <img
                    src={projectIcon}
                    alt="prod"
                  />
                </div>
              } key="5">
                <div ref={opportunityRef}>
                  <ContactRooms
                    data={contact}
                    loading={contactLoading}
                    name={"contactId"}
                    singleLoadThunk={loadSingleContact}
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">Contracte</span>
                  <img
                    src={contractIcon}
                    alt="prod"
                  />
                </div>
              } key="2">
                <div ref={attachmentRef}>
                  <Contracts
                    title={"Contracte"}
                    data={contact}
                    loading={contactLoading}
                    name={"contactId"}
                    singleLoadThunk={loadSingleContact}
                  />
                </div>
              </Panel>
              <div>
                <Notes
                  data={contact}
                  loading={contactLoading}
                  name={"contactId"}
                  singleLoadThunk={loadSingleContact}
                />
              </div>
              {/*
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">Proiecte2D</span>
                  <img
                    src={p2dIcont}
                  />
                </div>
              } key="3">
                <div ref={attachmentRef}>
                  <Attachments
                    title={"Proiecte2D"}
                    attachmentType={3}
                    data={contact}
                    loading={contactLoading}
                    name={"contactId"}
                    singleLoadThunk={loadSingleContact}
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">Proiecte3D</span>
                  <img
                    src={p3dIcont}
                  />
                </div>
              } key="4">
                <div ref={attachmentRef}>
                  <Attachments
                    title={"Proiecte3D"}
                    attachmentType={4}
                    data={contact}
                    loading={contactLoading}
                    name={"contactId"}
                    singleLoadThunk={loadSingleContact}
                  />
                </div>
              </Panel> */
              /* <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">{TEXTS.LABELS.TASKS}</span>
                  <img
                    src={taskIcon}
                  />
                </div>
              } key="6">
                <div ref={taskRef}>
                  <Tasks
                    data={contact}
                    loading={contactLoading}
                    name={"contactId"}
                    singleLoadThunk={loadSingleContact}
                  />
                </div>
              </Panel>
              {showSpreadsheet && (
                <Panel header={
                  <div className="flex items-center">
                    <span className="mr-2">Finanțe</span>
                    <img
                      src={financeIcon}
                    />
                  </div>
                } key="7">
                  <div ref={spreadsheetRef}>
                    <SpreadsheetComponent id={id} />
                  </div>
                </Panel>
              )}
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">{TEXTS.LABELS.ATTACHMENTS}</span>
                  <img
                    src={attachmentIcon}
                  />
                </div>
              } key="8">
                <div ref={attachmentRef}>
                  <Attachments
                    title={TEXTS.LABELS.ATTACHMENTS}
                    attachmentType={1}
                    data={contact}
                    loading={contactLoading}
                    name={"contactId"}
                    singleLoadThunk={loadSingleContact}
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">Email</span>
                  <img
                    src={mailIcon}
                  />
                </div>
              } key="9">
                <div ref={emailRef}>
                  <Emails
                    data={contact}
                    loading={contactLoading}
                    name={"contactId"}
                    singleLoadThunk={loadSingleContact}
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="flex items-center">
                  <span className="mr-2">Calculator</span>
                  <img
                    src={calculatorIcon}
                  />
                </div>
              } key="10">
                <div ref={quoteRef}>
                  <Quotes
                    data={contact}
                    loading={contactLoading}
                    name={"contactId"}
                    singleLoadThunk={loadSingleContact}
                  />
                </div>
              </Panel> */}
            </Collapse>
            <CreateDrawer onClose={onClose} open={open} title={"Contract"}>
              <CreateContractForm onClose={onClose} createAs={{ name: "contactId", value: contact?.id, singleLoadThunk: loadSingleContact }} />
            </CreateDrawer>
            <CreateDrawer onClose={onPredatPreluatClose} open={openPredatPreluat} title={"Predat/Preluat Document"}>
              <CreatePredatPreluatForm onClose={onPredatPreluatClose} createAs={{ name: "contactId", value: contact?.id, singleLoadThunk: loadSingleContact }} />
            </CreateDrawer>
            <CreateDrawer onClose={onOfertaClose} open={openOferta} title={"Ofertă"}>
              <CreateOfertaForm onClose={onOfertaClose} createAs={{ name: "contactId", value: contact?.id, singleLoadThunk: loadSingleContact }} />
            </CreateDrawer>
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
}
