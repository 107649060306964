import { Drawer } from "antd";
import { TEXTS } from "../../../../../constants";
import CreatePartnerForm from "./CreatePartnerForm";

export default function CreatePartner({ open, onClose }) {
    return (
        <Drawer
            width={450}
            title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.PARTNER}`}
            placement="right"
            onClose={onClose}
            open={open}
        >
            <CreatePartnerForm onClose={onClose} />
        </Drawer>
    )
}