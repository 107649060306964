import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Board from "react-trello";
import { Button, Modal, DatePicker, message, Select, Input } from "antd";
import moment from "moment";
import { loadAllOpportunityPaginated, updateOpportunityDeliveryDate, updateOpportunityStage } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllContactPaginated, updateContact } from "../../../redux/rtk/features/crm/contact/contactSlice";
import CreateOpportunityForm from "../Opportunity/CreateOpportunityForm";
import { useNavigate } from "react-router-dom";
import { TEXTS } from "../../../constants";
import getCurrentUser from "../../user/getCurrentUserapi";
import { loadAllFailureCauses } from "../../../redux/rtk/features/crm/causeOfFailure/causeOfFailureSlice";
import { getAllProductions } from "../../../redux/rtk/features/crm/production/productionSlice";
import getRoleFromToken from "../../../utils/getRoleFromToken";
import { BiXCircle } from "react-icons/bi";
const { Option } = Select;

const stageDeadlines = {
    "Predat Producere": 1 * 24 * 60 * 60 * 1000, // 1 day
    "Preluat Producere": 1 * 24 * 60 * 60 * 1000, // 1 day
    "Concretizări": 4 * 24 * 60 * 60 * 1000, // 3 days
    "Măsurări Finale": 5 * 24 * 60 * 60 * 1000, // 5 days
    "Asamblare": 60 * 24 * 60 * 60 * 1000, // 60 days
    "Livrare": 7 * 24 * 60 * 60 * 1000, // 7 days
    "Montare": 14 * 24 * 60 * 60 * 1000, // 14 days
    "Finisat": 5 * 24 * 60 * 60 * 1000, // 5 days
    "Garanție": 5 * 24 * 60 * 60 * 1000,
};

const convertToUTC3 = (date) => {
    return moment(date).utc().add(3, 'hours').toISOString();
}

export default function OpportunityKanbanBoard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { list: opportunityList, loading: opportunityLoading } = useSelector((state) => state.opportunity);
    const { list: contactList, loading: contactLoading } = useSelector((state) => state.contact);
    const { list: failureCauseList, loading: failureLoading } = useSelector((state) => state.failureCause);
    const { list: productionList, loading: productionLoading } = useSelector((state) => state.production);
    const [boardData, setBoardData] = useState({ lanes: [] });
    const [currentUser, setCurrentUser] = useState(null);
    const [description, setDescription] = useState("");
    const [failureCause, setFailureCause] = useState(null);
    const [selectedProductionId, setSelectedProductionId] = useState(null);
    const [isSpecialModalOpen, setIsSpecialModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [opportunityCloseDate, setOpportunityCloseDate] = useState(null);
    const [opportunityDeliveryDate, setOpportunityDeliveryDate] = useState(null);
    const [newLaneName, setNewLaneName] = useState(null);
    const [maxAllowedDate, setMaxAllowedDate] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedLanes, setSelectedLanes] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
                await dispatch(loadAllContactPaginated({ status: true, count: 999 }));
                await dispatch(loadAllFailureCauses());
                await dispatch(getAllProductions());
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            try {
                const user = await getCurrentUser();
                setCurrentUser(user);
            } catch (error) {
                console.error("Error fetching current user:", error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (!opportunityLoading && !contactLoading && !failureLoading && !productionLoading && !currentUser) {
            setDataLoaded(true); // Set dataLoaded to true only when all data is loaded
        }
    }, [opportunityLoading, contactLoading, failureLoading, productionLoading, currentUser]);

    useEffect(() => {
        if (dataLoaded && currentUser) {
            setBoardData(prepareBoardData());
        }
    }, [dataLoaded, opportunityList, contactList, selectedLanes, currentUser]);




    function generateContactID(contact) {
        if (!contact || !contact.contactOwner || !contact.contactOwner.department) {
            return 'Invalid contact';
        }
        const departmentId = contact.contactOwner.department.id < 10
            ? `0${contact.contactOwner.department.id}`
            : contact.contactOwner.department.id;

        const contactId = contact.id < 10
            ? `0${contact.id}`
            : contact.id;
        const createdAt = new Date(contact.createdAt);
        const month = (createdAt.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
        const year = createdAt.getFullYear().toString().slice(-2); // Get last two digits of the year

        return `${departmentId}/${contactId}/${month}/${year}`;
    }


    const prepareBoardData = () => {
        const lanes = [
            { id: "predat", title: "Predat Producere", label: "", cards: [] },
            { id: "preluat", title: "Preluat Producere", label: "", cards: [] },
            { id: "concretizari", title: "Concretizări", label: "", cards: [] },
            { id: "masurare", title: "Măsurări Finale", label: "", cards: [] },
            { id: "asamblare", title: "Asamblare", label: "", cards: [] },
            { id: "livrare", title: "Livrare", label: "", cards: [] },
            { id: "montare", title: "Montare", label: "", cards: [] },
            { id: "finisat", title: "Finisat", label: "", cards: [] },
            { id: "garantie", title: "Garanție", label: "", cards: [] },
        ];

        if (currentUser && currentUser?.department.id === 1) {
            lanes.push({
                id: "esuat",
                title: "Asistență Juridică",
                label: "",
                cards: [],
                style: { backgroundColor: "#f8d7da" },
            });
        }

        const laneCounts = {
            predat: 0,
            preluat: 0,
            concretizari: 0,
            masurare: 0,
            asamblare: 0,
            livrare: 0,
            montare: 0,
            finisat: 0,
            garantie: 0,
            esuat: 0,
        };

        // Track the IDs of contacts that have visible opportunities
        const contactIdsWithVisibleOpportunities = new Set();

        // Filter opportunities to keep only the oldest for each contact
        const oldestOpportunities = [...opportunityList] // Create a copy of the array
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Sort opportunities by creation date
            .filter((opportunity, index, self) => {
                // Find the first (oldest) opportunity for each contact
                return index === self.findIndex(o => o.contactId === opportunity.contactId);
            });

        // Loop through the filtered opportunities and create cards
        oldestOpportunities.forEach((opportunity) => {
            const createdAt = new Date(opportunity.opportunityCreateDate).toLocaleString('ro-RO');
            const associatedContact = contactList.find(contact => contact.id === opportunity.contactId);
            if (associatedContact && associatedContact.guarantee) {
                return;
            }
            const contactId = associatedContact ? generateContactID(associatedContact) : "Unknown";
            if (associatedContact &&
                (associatedContact.contactStage.contactStageName === 'Predat Producere' ||
                    (associatedContact.contactStage.contactStageName === 'Concretizări' &&
                        opportunity.opportunityStage.opportunityStageName === 'Concretizări'))) {
                let cardDescription = `${createdAt}\n${opportunity.amount} lei\nProiect: ${opportunity.opportunityName}`;

                // Check if the lane is "Măsurări Finale" and add opportunityDeliveryDate
                if (opportunity.opportunityStage.opportunityStageName === 'Măsurări Finale') {
                    const deliveryDate = opportunity.opportunityDeliveryDate
                        ? new Date(opportunity.opportunityDeliveryDate).toLocaleString('ro-RO')
                        : "N/A";
                    cardDescription += `\nData Livrare: ${deliveryDate}`;
                }

                const card = {
                    id: opportunity.id,
                    title: `${associatedContact ? `ID:${contactId} ${associatedContact.firstName} ${associatedContact.lastName}` : "N/A"}`,
                    description: cardDescription,
                    style: { backgroundColor: "#fff" },
                };

                const opportunityCloseDate = new Date(opportunity.opportunityCloseDate);
                const now = new Date();
                const timeDiff = opportunityCloseDate - now;
                const hoursDiff = timeDiff / (1000 * 3600); // Difference in hours
                const opportunityCreateDate = new Date(opportunity.opportunityCreateDate);
                const half = new Date(opportunityCreateDate.getTime() + (opportunityCloseDate.getTime() - opportunityCreateDate.getTime()) / 2);

                if (hoursDiff <= 1) {
                    card.style = { backgroundColor: "#f8d7da" }; // Highlight with a red shade if the deadline is in 1 hour or less
                } else if (now >= half && now < opportunityCloseDate) {
                    card.style = { backgroundColor: "#fff3cd" }; // Highlight with a yellow shade
                } else if (now >= opportunityCreateDate && now < half) {
                    card.style = { backgroundColor: "#d1ecf1" }; // Highlight with a blue shade
                }

                const lane = lanes.find(lane => lane.title.toLowerCase() === opportunity.opportunityStage.opportunityStageName.toLowerCase());
                if (lane) {
                    // Check if the lane is "Eșuat" and if it has less than 5 cards
                    if (lane.title.toLowerCase() === 'asistență juridică' && lane.cards.length >= 5) {
                        console.log('The Eșuat lane already has 5 cards. No more cards will be added.');
                    } else {
                        lane.cards.push(card);
                        laneCounts[lane.id] += 1;

                        if (associatedContact && associatedContact.id) {
                            contactIdsWithVisibleOpportunities.add(associatedContact.id);
                        }
                    }
                }
            }
        });

        // Filter the contacts and ensure that those with opportunities are not displayed
        contactList.forEach((contact) => {
            const isRestrictedDepartment = ["Operator Producere"].includes(currentUser?.department.name);

            const isProductionMatching = isRestrictedDepartment
                ? contact.production &&
                contact.production.productionName &&
                contact.production.productionName === currentUser?.department.name
                : true; // For other users, show everything without restriction


            if (contact.guarantee)
                return;
            // Show contacts only if their production matches and they have no visible opportunity
            if (isProductionMatching && !contactIdsWithVisibleOpportunities.has(contact.id)) {
                if (contact.contactStage.contactStageName === "Predat Producere") {
                    const contactCreatedAt = new Date(contact.createdAt).toLocaleString('ro-RO');
                    const generatedId = generateContactID(contact);

                    const card = {
                        id: contact.id,
                        title: `ID:${generatedId} \n${contact.firstName} ${contact.lastName}`,
                        description: `${contactCreatedAt}\n${contact.phone}`,
                        canMove: false,
                        style: { backgroundColor: "#fff" },
                    }
                    const contactCreatedAtDate = new Date(contact.createdAt);
                    const deadline = new Date(contactCreatedAtDate.getTime() + (stageDeadlines[contact.contactStage.contactStageName] || 0));
                    const now = new Date();
                    if (now > deadline) {
                        card.style = { backgroundColor: "#f8d7da" }; // Red background if the deadline is missed
                    } else {
                        const timeDiff = deadline - now;
                        const hoursDiff = timeDiff / (1000 * 3600); // Difference in hours

                        if (hoursDiff <= 1) {
                            card.style = { backgroundColor: "#f8d7da" }; // Red background if the deadline is within 1 hour
                        } else if (hoursDiff <= 24) {
                            card.style = { backgroundColor: "#fff3cd" }; // Yellow background if the deadline is within 24 hours
                        }
                    }

                    const lane = lanes.find(lane => lane.title.toLowerCase() === "predat producere");
                    if (lane) {
                        lane.cards.push(card);
                        laneCounts[lane.id] += 1;
                    }
                }
                else if (contact.contactStage.contactStageName === "Concretizări") {
                    const contactCreatedAt = new Date(contact.createdAt).toLocaleString('ro-RO');
                    const generatedId = generateContactID(contact);

                    const card = {
                        id: contact.id,
                        title: `ID:${generatedId} \n${contact.firstName} ${contact.lastName}`,
                        description: `${contactCreatedAt}\n${contact.phone}`,
                        canMove: false,
                        style: { backgroundColor: "#fff" },
                    }
                    const contactCreatedAtDate = new Date(contact.createdAt);
                    const deadline = new Date(contactCreatedAtDate.getTime() + (stageDeadlines[contact.contactStage.contactStageName] || 0));
                    const now = new Date();
                    if (now > deadline) {
                        card.style = { backgroundColor: "#f8d7da" }; // Red background if the deadline is missed
                    } else {
                        const timeDiff = deadline - now;
                        const hoursDiff = timeDiff / (1000 * 3600); // Difference in hours

                        if (hoursDiff <= 1) {
                            card.style = { backgroundColor: "#f8d7da" }; // Red background if the deadline is within 1 hour
                        } else if (hoursDiff <= 24) {
                            card.style = { backgroundColor: "#fff3cd" }; // Yellow background if the deadline is within 24 hours
                        }
                    }

                    const lane = lanes.find(lane => lane.title.toLowerCase() === "concretizări");
                    if (lane) {
                        lane.cards.push(card);
                        laneCounts[lane.id] += 1;
                    }
                }
            }
        });

        lanes.forEach(lane => {
            lane.label = `${laneCounts[lane.id]}`;
        });

        const filteredLanes = selectedLanes.length > 0 ? lanes.filter(lane => selectedLanes.includes(lane.id)) : lanes;
        return { lanes: filteredLanes };
    };


    const handleDragEnd = async (cardId, sourceLaneId, targetLaneId) => {
        if (sourceLaneId === targetLaneId) {
            message.warning('Nu e posibil de mutat clientul în aceeași etapa în care el deja este amplasat');
            return false;
        }
        const laneIdToStageNameMap = {
            predat: 'Predat Producere',
            preluat: 'Preluat Producere',
            concretizari: 'Concretizări',
            masurare: 'Măsurări Finale',
            asamblare: 'Asamblare',
            livrare: 'Livrare',
            montare: 'Montare',
            finisat: 'Finisat',
            esuat: 'Asistență Juridică',
            garantie: 'Garanție',
        };

        const opportunity = opportunityList.find(opportunity => opportunity.id === cardId);
        const contact = contactList.find(contact => contact.id === cardId);

        if (contact && contact.contactStage.contactStageName === 'Predat Producere') {
            if (sourceLaneId === 'predat' && targetLaneId !== 'predat') {
                setSelectedContact(contact);
                setIsModalOpen(true);
            }
            return;
        }

        if (opportunity) {
            const newStage = laneIdToStageNameMap[targetLaneId];
            const now = new Date();
            let maxAllowedDate = new Date(now.getTime() + stageDeadlines[newStage]);
            if (newStage === 'Asistență Juridică') {
                setSelectedCard(opportunity);
                setIsSpecialModalOpen(true);
            } else {
                setMaxAllowedDate(maxAllowedDate);
                setOpportunityCloseDate(null);
                setOpportunityDeliveryDate(null);
                setNewLaneName(newStage);
                setSelectedCard(opportunity);
                setSelectedProductionId(selectedProductionId);
                setIsDateModalOpen(true);
            }
        }
    };

    const handleSpecialModalOk = async () => {
        if (selectedCard) {
            try {
                await dispatch(updateOpportunityStage({ id: selectedCard.id, values: { opportunityStageName: 'Asistență Juridică', description, failureCauseId: failureCause } }));
                dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
                dispatch(loadAllContactPaginated({ status: true, count: 999 }));
                setIsSpecialModalOpen(false);
                setSelectedCard(null);
                setDescription("");
                setFailureCause("");
            } catch (error) {
                message.error('Eroare la actualizarea contactului.');
            }
        }
    };

    const handleSpecialModalCancel = () => {
        setIsSpecialModalOpen(false);
        setSelectedCard(null);
        setDescription("");
        setFailureCause("")
        dispatch(loadAllContactPaginated({ status: true, count: 999 }));
        dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
    };

    const handleDateModalOk = async () => {
        if (selectedCard && opportunityCloseDate && newLaneName) {
            const now = new Date();
            const selectedDate = new Date(opportunityCloseDate);
            if (selectedDate > maxAllowedDate) {
                message.error(`Data și ora selectate depășesc termenul limită pentru etapa ${newLaneName}. Data maximă permisă este ${moment(maxAllowedDate).format('YYYY-MM-DD HH:mm:ss')}.`);
                return;
            }

            const opportunityCloseDateUTC3 = convertToUTC3(opportunityCloseDate);
            const opportunityDeliveryDateUTC3 = opportunityDeliveryDate ? convertToUTC3(opportunityDeliveryDate) : null;
            try {
                await dispatch(updateOpportunityStage({
                    id: selectedCard.id,
                    values: {
                        opportunityStageName: newLaneName,
                        opportunityCloseDate: opportunityCloseDateUTC3,
                        productionId: selectedProductionId,
                    }
                }));

                if (newLaneName === 'Măsurări Finale' && opportunityDeliveryDate) {
                    await dispatch(updateOpportunityDeliveryDate({
                        id: selectedCard.id,
                        values: {
                            opportunityDeliveryDate: opportunityDeliveryDateUTC3,
                        }
                    }))
                }
                dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
                dispatch(loadAllContactPaginated({ status: true, count: 999 }));
                setIsDateModalOpen(false);
                setSelectedCard(null);
                setNewLaneName(null);
                setOpportunityCloseDate(null);
                setOpportunityDeliveryDate(null);
            } catch (error) {
                message.error('Eroare la actualizarea oportunității.');
            }
        }
    };

    const handleDateModalCancel = () => {
        setIsDateModalOpen(false);
        setSelectedCard(null);
        setNewLaneName(null);
        setOpportunityCloseDate(null);
        setOpportunityDeliveryDate(null);
        dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
        dispatch(loadAllContactPaginated({ status: true, count: 999 }));
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const onCardClick = (cardId, metadata, laneId) => {
        // Find if the clicked card is an opportunity or a contact
        const opportunity = opportunityList.find(opportunity => opportunity.id === cardId);
        const contact = contactList.find(contact => contact.id === cardId);

        if (opportunity) {
            // If the card represents an opportunity, find the associated contact
            const associatedContact = contactList.find(contact => contact.id === opportunity.contactId);

            if (associatedContact) {
                // Navigate to the associated contact's page
                navigate(`/admin/contact/${associatedContact.id}`);
            } else {
                // If no associated contact found, handle the case (optional)
                message.warning('No associated contact found.');
            }
        } else if (contact) {
            // If the card represents a contact, navigate to the contact's page
            navigate(`/admin/contact/${contact.id}`);
        }
    };


    const cancelModal = () => {
        setIsModalOpen(false);
        setSelectedContact(null);
        dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
        dispatch(loadAllContactPaginated({ status: true, count: 999 }));
    };

    // const customLaneHeader = ({ title, label, id }) => {
    //     return (
    //         <div>
    //             <div style={{ fontWeight: 'bold' }}>{title}</div>
    //             <div>{label}</div>
    //             {id === 'predat' && (
    //                 <Button className="w-full" type="primary" style={{ marginBottom: '10px', marginTop: '5px' }} onClick={showModal}>
    //                     Adaugă Proiect Nou
    //                 </Button>
    //             )}
    //         </div>
    //     );
    // };

    const handleGuaranteeClose = async (opportunity) => {
        try {
            // Confirm before closing
            const confirmClose = window.confirm('Sunteți sigur că doriți să închideți garanția pentru acest proiect?');

            if (confirmClose) {
                // Find the associated contact
                const associatedContact = contactList.find(contact => contact.id === opportunity.contactId);

                if (associatedContact) {
                    // Update the contact's guarantee field to true
                    await dispatch(updateContact({
                        id: associatedContact.id,
                        values: { guarantee: true }
                    }));

                    // Refresh the data
                    await dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
                    await dispatch(loadAllContactPaginated({ status: true, count: 999 }));

                    message.success('Garanție închisă cu succes.');
                } else {
                    message.error('Contact asociat negăsit.');
                }
            }
        } catch (error) {
            message.error('Eroare la închiderea garanției.');
            console.error('Guarantee close error:', error);
        }
    }

    const customCard = ({ id, title, description, onDelete, style, tagStyle, laneId, onClick }) => {
        // Find the opportunity or contact associated with this card
        const opportunity = opportunityList.find(opp => opp.id === id);
        const contact = contactList.find(cont => cont.id === id);

        // Check if this is in the 'garantie' lane
        const isGuaranteeLane = laneId === 'garantie';

        // Calculate if the guarantee deadline has passed
        let showGuaranteeButton = false;
        if (isGuaranteeLane) {
            let opportunityToCheck = opportunity;

            // If it's a contact card, find its associated opportunity
            if (!opportunityToCheck && contact) {
                opportunityToCheck = opportunityList.find(
                    opp => opp.contactId === contact.id &&
                        opp.opportunityStage.opportunityStageName === 'Garanție'
                );
            }

            // Check if the opportunity's close date has passed 5 days ago
            if (opportunityToCheck && opportunityToCheck.opportunityCreateDate) {
                const closeDate = new Date(opportunityToCheck.opportunityCreateDate);
                const guaranteePeriod = 60 * 60 * 1000; // 5 days in milliseconds
                const guaranteeDeadline = new Date(closeDate.getTime() + guaranteePeriod);
                const now = new Date();

                showGuaranteeButton = now > guaranteeDeadline;
            }
        }

        return (
            <div
                style={{ ...style, position: 'relative', cursor: 'pointer', marginBottom: '4px' }}
                onClick={(e) => {
                    // Check if clicked element is not the close button
                    if (!e.target.classList.contains('guarantee-close-button')) {
                        onClick(e, id, laneId);
                    }
                }}
            >
                <header style={{ padding: '6px 10px', borderBottom: '1px solid #eee' }}>
                    <h3 style={{ margin: 0, fontWeight: "bold" }}>{title}</h3>
                </header>
                <div style={{ padding: '10px', whiteSpace: 'pre-wrap', fontSize: '12px', color: '#777' }}>
                    <p>{description}</p>
                </div>
                {showGuaranteeButton && (
                    <button
                        className="guarantee-close-button"
                        onClick={(e) => {
                            // Prevent default behavior
                            try {
                                if (e.preventDefault) e.preventDefault();
                            } catch { }

                            // If it's an opportunity, pass the opportunity
                            // If it's a contact, we'll need to find the associated opportunity
                            const opportunityToClose = opportunity ||
                                (contact
                                    ? opportunityList.find(
                                        opp => opp.contactId === contact.id &&
                                            opp.opportunityStage.opportunityStageName === 'Garanție'
                                    )
                                    : null);

                            if (opportunityToClose) {
                                handleGuaranteeClose(opportunityToClose);
                            }
                        }}
                        style={{
                            position: 'absolute',
                            bottom: '10px',
                            right: '10px',
                            backgroundColor: 'red',
                            color: 'white',
                            border: 'none',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        X
                    </button>
                )}
            </div>
        );
    };
    const components = {
        Card: (props) => customCard({
            ...props,
            laneId: props.laneId,
            opportunityList,
            contactList
        })
    };

    const handleFilterChange = (selectedLanes) => {
        setSelectedLanes(selectedLanes);
    }

    const handleFailureCause = (value) => {
        setFailureCause(value);
    }
    if (opportunityLoading) return <div>Loading...</div>;

    return (
        <>
            {loading ? <div>Loading...</div> : null}
            <Select
                mode="multiple"
                placeholder='Selectați Etapele'
                style={{ width: '100%' }}
                onChange={handleFilterChange}
            >
                <Option value="predat">Predat Producere</Option>
                <Option value="preluat">Preluat Producere</Option>
                <Option value="concretizari">Concretizări</Option>
                <Option value="masurare">Măsurări Finale</Option>
                <Option value="asamblare">Asamblare</Option>
                <Option value="livrare">Livrare</Option>
                <Option value="montare">Montare</Option>
                <Option value="finisat">Finisat</Option>
                <Option value="garantie">Garanție</Option>
            </Select>
            <Board
                data={boardData}
                handleDragEnd={handleDragEnd}
                onCardClick={onCardClick}
                components={components}
                laneDraggable={false}
                style={{ backgroundColor: 'aliceblue' }}
                cardDraggable
            />
            <Modal
                title="Selectează Deadline"
                visible={isDateModalOpen}
                onOk={handleDateModalOk}
                onCancel={handleDateModalCancel}
            >
                <label>Selectează Deadline</label>
                <DatePicker
                    showTime
                    value={opportunityCloseDate}
                    onChange={setOpportunityCloseDate}
                    disabledDate={current => current && current > moment(maxAllowedDate)}
                />
                {newLaneName === 'Măsurări Finale' && (
                    <div>
                        <label>Selectează Data Livrării</label>
                        <DatePicker
                            showTime
                            value={opportunityDeliveryDate}
                            onChange={setOpportunityDeliveryDate}
                            disabledDate={(current) => {
                                const now = moment();
                                const maxDeliveryDate = now.clone().add(120, 'days');
                                return current && (
                                    current < now || // Prevent dates in the past
                                    current > maxDeliveryDate // Prevent dates more than 120 days in the future
                                );
                            }}
                        />
                    </div>
                )}
                {newLaneName === 'Preluat Producere' && (
                    <div>
                        <label>Selectează Producție</label>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Selectați Producție"
                            loading={productionLoading}
                            onChange={setSelectedProductionId}
                        >
                            {productionList.map(item => (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.productionName}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                )}
            </Modal>
            <Modal
                title="Adaugă Detalii"
                visible={isSpecialModalOpen}
                onOk={handleSpecialModalOk}
                onCancel={handleSpecialModalCancel}
                okText="Salvează"
                cancelText="Anulează"
            >
                <Input.TextArea
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Introdu detalii aici..."
                />
                <span><b>{`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.CAUSE_OF_FAILURE}`}</b></span>
                <Select
                    style={{ width: "100%" }}
                    loading={failureLoading}
                    allowClear
                    showSearch
                    value={failureCause}
                    onChange={handleFailureCause}
                    placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.CAUSE_OF_FAILURE}`}
                >
                    {failureCauseList?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item?.cause}
                        </Select.Option>
                    ))}
                </Select>
            </Modal>
            <Modal
                title="Crează Proiect Nou"
                visible={isModalOpen}
                onCancel={cancelModal}
                footer={null}
                width={800}
                bodyStyle={{ padding: '20px', width: '100%' }}
            >
                <CreateOpportunityForm onClose={cancelModal} createAs={selectedContact ? { contact: selectedContact.id, contactData: 'contactId' } : null} showProductionField={true} />
            </Modal>
        </>
    );
}
