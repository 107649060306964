// src/components/EditSettingsForm.js
import React from 'react';
import { Form, Button, message, InputNumber } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchSettings, updateSettings } from '../../../../redux/rtk/features/settings/settingsSlice';

const EditSettingsForm = ({ currentSettings, onClose }) => {
    const dispatch = useDispatch();

    const onFinish = (values) => {
        const numericValues = {
            minimX: parseFloat(values.minimX),
            maximX: parseFloat(values.maximX),
            eurExchangeRate: parseFloat(values.eurExchangeRate),
        };

        dispatch(updateSettings(numericValues)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                message.success('Settings updated successfully!');
                dispatch(fetchSettings());
                onClose(); // Close the form after saving
            } else {
                message.error('Failed to update settings.');
            }
        });
    };


    return (
        <Form
            className='w-1/4'
            layout="vertical"
            initialValues={currentSettings}
            onFinish={onFinish}
        >
            <Form.Item
                label="Minim de Preț"
                name="minimX"
                rules={[{ required: true, message: 'Introduceți Minimul de Preț!' }]}
            >
                <InputNumber />
            </Form.Item>
            <Form.Item
                label="Maxim de Preț"
                name="maximX"
                rules={[{ required: true, message: 'Introduceți Maxim de Preț!' }]}
            >
                <InputNumber />
            </Form.Item>
            <Form.Item
                label="Rata de Scimb Euro"
                name="eurExchangeRate"
                rules={[{ required: true, message: 'Introduceți Rata de Scimb Euro!' }]}
            >
                <InputNumber />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
                <Button onClick={onClose} style={{ marginLeft: '8px' }}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );
};

export default EditSettingsForm;
