import { Button, DatePicker, Form, Input, message, Select } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllCompany } from "../../../redux/rtk/features/crm/company/companySlice";
import { loadAllContactPaginated } from "../../../redux/rtk/features/crm/contact/contactSlice";
import {
	addSingleOpportunity,
	loadAllOpportunity,
	updateOpportunity,
} from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllOpportunitySource } from "../../../redux/rtk/features/crm/opportunitySource/opportunitySourceSlice";
import { loadAllOpportunityStage } from "../../../redux/rtk/features/crm/opportunityStage/opportunityStageSlice";
import { loadAllOpportunityType } from "../../../redux/rtk/features/crm/opportunityType/opportunityTypeSlice";
import { loadAllStaff } from "../../../redux/rtk/features/user/userSlice";
import { TEXTS } from "../../../constants";
import { loadAllRooms } from "../../../redux/rtk/features/crm/room/roomSlice";
import { loadAllContactRooms } from "../../../redux/rtk/features/crm/contactRoom/contactRoomSlice";
import { getAllProductions } from "../../../redux/rtk/features/crm/production/productionSlice";

export default function CreateOpportunityForm({
	onClose,
	createAs,
	edit,
	singleLoad,
	showProductionField = false,
}) {
	// selector
	const { list: companyList, loading: companyLoading } = useSelector(
		(state) => state.company
	);
	const { list: opportunitySourceList, loading: opportunitySourceLoading } =
		useSelector((state) => state.opportunitySource);
	const { list: opportunityTypeList, loading: opportunityTypeLoading } =
		useSelector((state) => state.opportunityType);
	const { list: staffList, loading: staffLoading } = useSelector(
		(state) => state.users
	);
	const { list: productionList, loading: productionLoading } = useSelector(state => state.production);
	const { list: contactRoomList, loading: contactRoomLoading } = useSelector((state) => state.contactRoom);
	const { list: contactList, loading: contactLoading } = useSelector(
		(state) => state.contact
	);
	const { loading: opportunityLoading } = useSelector(
		(state) => state.opportunity
	);

	const [form] = Form.useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadAllOpportunitySource());
		dispatch(loadAllOpportunityType());
		dispatch(loadAllCompany());
		dispatch(getAllProductions());
		dispatch(loadAllContactPaginated({ status: true, count: 999 }));
		dispatch(loadAllContactRooms());
		dispatch(loadAllStaff({ status: true }));

	}, [dispatch]);

	const onFinish = async (values) => {
		const formData = {
			...values,
			opportunityCreateDate: dayjs(values.opportunityCreateDate).format(
				"YYYY-MM-DD HH:mm"
			),
			opportunityCloseDate: dayjs(values.opportunityCloseDate).format(
				"YYYY-MM-DD HH:mm"
			),
			opportunityOwnerId: parseInt(values.opportunityOwnerId),
			companyId: parseInt(values.companyId),
			contactId: parseInt(values.contactId),
			opportunityTypeId: parseInt(values.opportunityTypeId),
			opportunitySourceId: parseInt(values.opportunitySourceId),
			contactRoomId: parseInt(values.contactRoomId) // Directly use selectedRooms
		};

		let opportunityId;

		if (!!edit) {
			const resp = await dispatch(
				updateOpportunity({ id: edit.id, values: formData })
			);
			if (resp.payload.message === "success") {
				opportunityId = edit.id;
				if (singleLoad?.thunk) {
					dispatch(singleLoad.thunk(singleLoad.id));
				}

				onClose();
			}
		} else {
			const resp = await dispatch(addSingleOpportunity(formData));
			if (resp.payload.message === "success") {
				opportunityId = resp.payload.data.id;
				form.resetFields();
				if (createAs?.name) {
					dispatch(createAs.singleLoadThunk(createAs.value));
				} else {
					dispatch(loadAllOpportunity());
				}
				onClose();
			}
		}
	};

	const onCancel = () => {
		form.resetFields();
		onClose();
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};



	return (
		<div className='flex justify-center mt-5'>
			<Form
				className='w-4/5'
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				colon={false}
				layout='vertical'
				form={form}
				initialValues={
					createAs
						? {
							[createAs.name]: createAs.value,
							contactId: createAs?.contact,
							opportunityCreateDate: moment(),
						}
						: !!edit
							? {
								opportunityName: edit?.opportunityName,
								amount: edit?.amount,
								contactRoomId: edit?.contactRoomId,
								opportunityOwnerId: edit?.opportunityOwnerId,
								contactId: edit?.contactId,
								companyId: edit?.companyId,
								opportunityTypeId: edit?.opportunityTypeId || "",
								opportunitySourceId: edit?.opportunitySourceId || "",
								nextStep: edit?.nextStep || "",
								competitors: edit?.competitors || "",
								description: edit?.description || "",
								productionId: edit?.productionId || "",
								opportunityCreateDate:
									moment(edit?.opportunityCreateDate) || "",
								opportunityCloseDate: moment(edit?.opportunityCloseDate) || "",
							}
							: {}
				}>
				<Form.Item
					style={{ width: "100%" }}
					label={TEXTS.LABELS.LAST_NAME}
					name='opportunityName'
					tooltip={TEXTS.TEXT.REQUIRED_FIELD}
					rules={[
						{ required: true, message: `${TEXTS.MESSAGES.OPPORTUNITY_NAME_MESAGE}` },
					]}>
					<Input placeholder='Test' />
				</Form.Item>
				<Form.Item label={TEXTS.LABELS.ROOM_NAME} name={"contactRoomId"}>
					<Select
						style={{ width: "100%" }}
						loading={contactRoomLoading}
						allowClear
						showSearch
						placeholder={TEXTS.PLACEHOLDERS.ROOM_PLACEHOLDER}
						disabled={!!(createAs?.name === "contactRoomId")}
					>
						{contactRoomList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item.room.roomName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				{showProductionField && (
					<Form.Item
						name={"productionId"}
						label={TEXTS.LABELS.PRODUCTION_SUCCESSION}
					>
						<Select
							style={{ width: "100%" }}
							loading={productionLoading}
							allowClear
							showSearch
							placeholder={TEXTS.PLACEHOLDERS.ROOM_PLACEHOLDER}
						>
							{productionList.map(item => (
								<Select.Option key={item.id} value={item.id}>
									{item.productionName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				)}
				<Form.Item label={TEXTS.LABELS.OPPORTUNITY_OWNER} name={"opportunityOwnerId"}>
					<Select
						style={{ width: "100%" }}
						loading={staffLoading}
						allowClear
						showSearch
						placeholder={TEXTS.PLACEHOLDERS.OPPORTUNITY_OWNER_PLACEHOLDER}
					>
						{staffList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item?.firstName} {item?.lastName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				{/* <Form.Item label={`${TEXTS.LABELS.AMMOUNT} (MDL)`} name='amount'>
					<Input placeholder='10000' />
				</Form.Item> */}
				<Form.Item label={TEXTS.LABELS.OPPORTUNITY_CREATE_DATE} name='opportunityCreateDate'>
					<DatePicker format="YYYY-MM-DD HH:mm" placeholder={TEXTS.PLACEHOLDERS.CREATE_DATE_PLACEHOLDER}
					/>
				</Form.Item>
				<Form.Item label={TEXTS.LABELS.OPPORTUNITY_CLOSE_DATE} name='opportunityCloseDate'>
					<DatePicker format="YYYY-MM-DD HH:mm" placeholder={TEXTS.PLACEHOLDERS.CLOSE_DATE_PLACEHOLDER}
					/>
				</Form.Item>
				<Form.Item label={TEXTS.LABELS.CONTACT} name='contactId'>
					<Select
						style={{ width: "100%" }}
						loading={contactLoading}
						allowClear
						showSearch
						placeholder={TEXTS.PLACEHOLDERS.CONTACT_PLACEHOLDER}
						disabled={!!(createAs?.name === "contactId") || !!createAs?.contact}>
						{contactList?.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{item?.firstName} {item?.lastName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>


				<Form.Item label={TEXTS.LABELS.DESCRPTION} name='description'>
					<Input.TextArea placeholder={TEXTS.TEXT.DESCRIPTION_TEXT} />
				</Form.Item>

				<Form.Item label=''>
					<div className='flex items-center gap-2'>
						<Button
							size={"large"}
							htmlType='submit'
							type='primary'
							loading={opportunityLoading}>
							{TEXTS.TEXT.CREATE}
						</Button>
						{/* <button
              className='py-1 px-3 text-lg border border-orange-500 rounded cursor-pointer'
              type='submit'
            >
              Create and add another
            </button> */}
						<Button size={"large"} type='danger' onClick={onCancel}>
							{TEXTS.BUTTON_TEXT.CLOSE_BTN}
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
}
