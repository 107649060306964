import { Drawer } from "antd";
import CreateQuoteStageForm from "./CreateQuoteStageForm";
import { TEXTS } from "../../../../../constants";

export default function CreateQuoteStage({ open, onClose }) {
  return (
    <Drawer
      width={450}
      title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.QUOTE_STAGE}`}
      placement='right'
      onClose={onClose}
      open={open}
    >
      <CreateQuoteStageForm onClose={onClose} />
    </Drawer>
  );
}
