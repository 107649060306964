import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";

import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllDesignation } from "../../redux/rtk/features/designation/designationSlice";
import { loadAllEmploymentStatus } from "../../redux/rtk/features/employemntStatus/employmentStatusSlice";
import { loadAllShift } from "../../redux/rtk/features/shift/shiftSlice";
import { addStaff } from "../../redux/rtk/features/user/userSlice";
import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
import { getDepartments } from "../department/departmentApis";
import { getRoles } from "../role/roleApis";
import { TEXTS } from "../../constants"

const AddUser = () => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { Option } = Select;
  const [list, setList] = useState(null);
  const [department, setDepartment] = useState(null);

  useEffect(() => {
    getRoles()
      .then((d) => setList(d))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getDepartments()
      .then((d) => setDepartment(d))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    dispatch(loadAllDesignation());
    dispatch(loadAllEmploymentStatus());
    dispatch(loadAllShift());
  }, [dispatch]);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const FormData = {
      ...values,
    };
    try {
      const resp = await dispatch(addStaff(FormData));
      setLoader(true);

      if (resp.payload.message === "success") {
        form.resetFields();
        setLoader(false);
      } else if (resp.payload.message === "error") {
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoader(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoader(false);
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment bordered={false}>
      <UserPrivateComponent permission={"create-user"}>
        <div className='mr-top mt-5 p-5 ant-card ' style={{ maxWidth: "100%" }}>
          <Form
            size='small'
            form={form}
            name='basic'
            labelCol={{
              span: 7,
            }}
            wrapperCol={{
              span: 22,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col span={12} className='gutter-row form-color'>
                <h2 className='text-center text-xl mt-3 mb-3 txt-color'>
                  {TEXTS.FORM_TITLES.USER_INFO}
                </h2>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Prenume'
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: "Introduceți Prenumele Dorit!",
                    },
                  ]}
                >
                  <Input placeholder='John' />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Nume'
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: "Introduceți Numele Dorit!",
                    },
                  ]}
                >
                  <Input placeholder='Doe' />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Nume Utilizator'
                  name='userName'
                  rules={[
                    {
                      required: true,
                      message: "Introduceți Numele de Utilizator Dorit!",
                    },
                  ]}
                >
                  <Input placeholder='john_doe' />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Parolă'
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: "Introduceți Parola Dorită!!",
                    },
                  ]}
                >
                  <Input.Password placeholder='Parolă puternică' />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Email'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: "Introduceți Email-ul Dorit!",
                    },
                  ]}
                >
                  <Input placeholder='johndoe2@example.com' />
                </Form.Item>
              </Col>
              <Col span={12} className='gutter-row'>
                <h2 className='text-center text-xl mt-3 mb-3 txt-color'>
                  Informația de adresă
                </h2>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Strada'
                  name='street'
                  rules={[
                    {
                      required: true,
                      message: "Introduceți Strada Dorită!",
                    },
                  ]}
                >
                  <Input
                    placeholder='123 Main Street'
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Oraș'
                  name='city'
                  rules={[{ required: true, message: "Introduceți Orașul Dorit!!" }]}
                >
                  <Input placeholder='Los Angeles' />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Cod poștal'
                  name='zipCode'
                  rules={[
                    { required: true, message: "Introduceți Codul poștal Dorit!" },
                  ]}
                >
                  <Input placeholder='90211' />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Țară'
                  name='country'
                  rules={[{ required: true, message: "Introduceți Țara Dorit!" }]}
                >
                  <Input placeholder='USA' />
                </Form.Item>
              </Col>
            </Row>

            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col span={12} className='gutter-row'>
                <h2 className='text-center text-xl mt-3 mb-3 txt-color'>
                  {" "}
                  Informație Angajat{" "}
                </h2>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Data alăturării'
                  name='joinDate'
                  rules={[
                    {
                      required: true,
                      message: "Introduceți Data alăturării Dorită!",
                    },
                  ]}
                >
                  <DatePicker format="YYYY-MM-DD HH:mm" className='date-picker hr-staffs-date-picker'
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Data Plecării'
                  name='leaveDate'
                >
                  <DatePicker format="YYYY-MM-DD HH:mm" className='date-picker hr-staffs-date-picker'
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='ID Angajat '
                  name='employeeId'
                  rules={[
                    {
                      required: true,
                      message: "Introduceți ID Angajat Dorit!",
                    },
                  ]}
                >
                  <Input placeholder='OE-012' />
                </Form.Item>
                <Form.Item
                  name={"departmentId"}
                  style={{ marginBottom: "10px" }}
                  label='Departament'
                  rules={[
                    { required: true, message: "Introduceți Departament Dorit!" },
                  ]}
                >
                  <Select
                    loading={!department}
                    placeholder='Selectează Departament'
                    allowClear
                    showSearch
                    size={"middle"}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={(menu) => (
                      <div className="overflow-y-auto">
                        {menu}
                      </div>
                    )}
                    virtual
                  >
                    {department &&
                      department.map((department) => (
                        <Option key={department.id} value={department.id}>
                          {department.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Introduceți Departament Dorit!" },
                  ]}
                  label='Rol'
                  name={"roleId"}
                  style={{ marginBottom: "10px" }}
                >
                  <Select
                    loading={!list}
                    size='middle'
                    mode='single'
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder='Vă rog selectați'
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={(menu) => (
                      <div className="overflow-y-auto">
                        {menu}
                      </div>
                    )}
                    virtual
                  >
                    {list &&
                      list.map((role) => (
                        <Option key={role.id} value={role.id}>
                          {role.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              style={{ marginBottom: "10px", marginTop: "10px" }}
              wrapperCol={{
                offset: 4,
                span: 16,
              }}
            >
              <Button
                className='mt-5'
                size='large'
                onClick={() => setLoader(true)}
                block
                type='primary'
                htmlType='submit'
                shape='round'
                loading={loader}
              >
                Adaugă Lucrător Nou
              </Button>
            </Form.Item>
          </Form>
        </div>
      </UserPrivateComponent>
    </Fragment >
  );
};

export default AddUser;
