import React, { useEffect, useState } from "react";
import { Select, List, Typography, Card, Row, Col, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loadAllContract } from "../../../redux/rtk/features/crm/contract/contractSlice";
import { loadAllOpportunity } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllStaff } from "../../../redux/rtk/features/user/userSlice";
import moment from "moment";

const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const DaysList = () => {
    const dispatch = useDispatch();
    const { list: contracts, loading: contractLoading } = useSelector(state => state.contract);
    const { list: opportunities } = useSelector(state => state.opportunity);
    const { list: staff, loading: staffLoading } = useSelector(state => state.users);
    const [selectedDays, setSelectedDays] = useState(1);
    const [viewMode, setViewMode] = useState('General');
    const [selectedStaffId, setSelectedStaffId] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [isCustomRange, setIsCustomRange] = useState(false); // State to determine the range selection type

    useEffect(() => {
        dispatch(loadAllContract());
        dispatch(loadAllOpportunity());
        dispatch(loadAllStaff({ status: true }));
    }, [dispatch]);

    // Calculate the date that is 'selectedDays' days ago from today
    const filterDate = new Date();
    filterDate.setDate(filterDate.getDate() - selectedDays);

    // Convert the dateRange values to moment objects
    const [startDate, endDate] = dateRange;

    // Filter contracts based on the selected days or custom date range
    const filteredContracts = contracts.filter(contract => {
        const contractDate = moment(contract.createdAt); // Convert to moment object
        if (isCustomRange && startDate && endDate) {
            return contractDate.isBetween(startDate, endDate, 'days', '[]'); // Use moment's isBetween
        } else {
            return contractDate.isAfter(moment(filterDate)); // Use isAfter for predefined range
        }
    });

    // Filter out contracts with null or undefined contact IDs
    const validContracts = filteredContracts.filter(contract => contract.contact?.id);

    // Calculate totals
    const totalSum = validContracts.reduce((acc, contract) => acc + contract.sum, 0);
    const totalAvans = validContracts.reduce((acc, contract) => {
        const avans = (contract.sum * (contract.avansPercent || 0)) / 100;
        return acc + avans;
    }, 0);

    // Get unique valid contact IDs
    const uniqueContactIds = Array.from(new Set(validContracts.map(contract => contract.contact.id)));

    // Filter contracts based on selected staff member
    const filteredContractsByStaff = selectedStaffId
        ? validContracts.filter(contract => contract.contact.contactOwnerId === selectedStaffId)
        : validContracts;

    // Filter opportunities based on unique contact IDs
    const filteredOpportunities = opportunities.filter(opportunity =>
        uniqueContactIds.includes(opportunity.contactId) &&
        (selectedStaffId ? validContracts.some(contract => contract.contact.contactOwnerId === selectedStaffId && contract.contact.id === opportunity.contactId) : true)
    );

    const totalOpportunityAmount = filteredOpportunities.reduce((acc, opportunity) => acc + opportunity.amount, 0);

    // Calculate contract count by staff
    const contractCountByStaff = staff.reduce((acc, person) => {
        const count = validContracts.filter(contract => contract.contact.contactOwnerId === person.id).length;
        if (count > 0) {
            acc[person.id] = count;
        }
        return acc;
    }, {});

    const options = [
        { label: '1 zi', value: 1 },
        { label: '1 săptămână', value: 7 },
        { label: '2 săptămâni', value: 14 },
        { label: '1 lună', value: 30 },
        { label: '3 luni', value: 90 },
        { label: '6 luni', value: 180 },
        { label: '1 an', value: 365 },
    ];

    return (
        <div style={{ padding: '16px' }}>
            <Card
                headStyle={{ padding: "0px 24px" }}
                bodyStyle={{ padding: 0 }}
                style={{ height: 380, borderRadius: "15px", marginBottom: "16px", overflow: "hidden" }}
                loading={contractLoading}
                title="Statistică"
            >
                <div className="flex" style={{ padding: '16px' }}>
                    <Select
                        style={{ width: 150, marginRight: "8px" }}
                        value={selectedDays}
                        onChange={(value) => {
                            setSelectedDays(value);
                            setIsCustomRange(false); // Reset custom range when changing predefined days
                        }}
                    >
                        {options.map(option => (
                            <Option key={option.value} value={option.value}>{option.label}</Option>
                        ))}
                    </Select>
                    <RangePicker
                        style={{ marginRight: "8px" }}
                        value={isCustomRange ? dateRange : null}
                        onChange={(dates) => {
                            setDateRange(dates); // Set the custom date range
                            setIsCustomRange(true); // Switch to custom range selection
                        }}
                    />
                    <Select
                        style={{ width: 150, marginRight: "8px" }}
                        value={viewMode}
                        onChange={(value) => {
                            setViewMode(value);
                            if (value === 'General') {
                                setSelectedStaffId(null);
                            }
                        }}
                    >
                        <Option value="General">General</Option>
                        <Option value="Angajat">Angajat</Option>
                    </Select>
                    {viewMode === 'Angajat' && (
                        <Select
                            style={{ width: 200 }}
                            placeholder="Selectează angajat"
                            onChange={(value) => setSelectedStaffId(value)}
                            value={selectedStaffId}
                        >
                            <Option value={null}>Toți angajații</Option>
                            {staff.map(person => (
                                <Option key={person.id} value={person.id}>
                                    {person.firstName} {person.lastName}
                                </Option>
                            ))}
                        </Select>
                    )}
                </div>
                <div style={{ padding: '16px', overflowY: 'auto', maxHeight: '250px' }}>
                    {viewMode === 'General' ? (
                        <div>
                            <Row>
                                <Col span={12}>
                                    <Text strong>NR de Contracte</Text>
                                </Col>
                                <Col span={8} style={{ textAlign: "right" }}>
                                    <Text>{filteredContractsByStaff.length}</Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '16px' }}>
                                <Col span={12}>
                                    <Text strong>Suma de vânzare</Text>
                                </Col>
                                <Col span={8} style={{ textAlign: "right" }}>
                                    <Text>{totalSum}</Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '16px' }}>
                                <Col span={12}>
                                    <Text strong>Suma avansurilor</Text>
                                </Col>
                                <Col span={8} style={{ textAlign: "right" }}>
                                    <Text>{totalAvans}</Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '16px' }}>
                                <Col span={12}>
                                    <Text strong>Suma Oportunităților</Text>
                                </Col>
                                <Col span={8} style={{ textAlign: "right" }}>
                                    <Text>{totalOpportunityAmount}</Text>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <Row style={{ padding: "16px", height: "300px" }}>
                            <Col span={24}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={staff}
                                    renderItem={(person) => (
                                        <List.Item key={person.id}>
                                            <List.Item.Meta
                                                title={`${person.firstName} ${person.lastName}`}
                                            />
                                            <div>
                                                {contractCountByStaff[person.id] || 0} Contracte
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default DaysList;
