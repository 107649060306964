import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    data: [],
    totalUniqueContactsCount: 0,
    error: "",
    loading: false,
}

export const fetchContactStageStatistics = createAsyncThunk(
    'contactStageStatistics/fetchStatistics',
    async ({ startDate, endDate, contactStageIds }, { rejectWithValue }) => {
        try {
            // Construct query parameters
            const params = new URLSearchParams();
            if (startDate) params.append('startDate', startDate);
            if (endDate) params.append('endDate', endDate);
            if (contactStageIds) {
                params.append('contactStageIds', contactStageIds.join(','));
            }

            const response = await axios.get(`/contact-stats?${params.toString()}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch contact stage statistics');
        }
    }
);

const contactStageStatisticSlice = createSlice({
    name: "contactStageStatistics",
    initialState,
    reducers: {
        clearContactStageStatistics: (state) => {
            state.data = null;
            state.totalUniqueContactsCount = 0;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchContactStageStatistics.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchContactStageStatistics.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.statistics;
            state.totalUniqueContactsCount = action.payload.totalUniqueContactsCount;
        });
        builder.addCase(fetchContactStageStatistics.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default contactStageStatisticSlice.reducer;
export const { clearContactStageStatistics } = contactStageStatisticSlice.actions;