import { Button, Form, Input, Select } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllCompany } from "../../../redux/rtk/features/crm/company/companySlice";
import { loadAllContact, loadAllContactPaginated } from "../../../redux/rtk/features/crm/contact/contactSlice";
import {
    addSingleNote,
    updateNote,
} from "../../../redux/rtk/features/crm/note/crmNoteSlice";
import { loadAllOpportunity } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllQuote } from "../../../redux/rtk/features/crm/quote/quoteSlice";
import { loadAllNotePaginated } from "../../../redux/rtk/features/crm/note/crmNoteSlice";
import { loadAllStaff } from "../../../redux/rtk/features/user/userSlice";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
import { TEXTS } from "../../../constants";
import sendEmail from "../Email/sendEmailApi";

export default function CreateNoteSelective({
    onClose,
    createAs,
    edit,
    singleLoad,
}) {
    const { list: companyList, loading: companyLoading } = useSelector(
        (state) => state.company
    );
    const { list: contactList, loading: contactLoading } = useSelector(
        (state) => state.contact
    );
    const { list: opportunityList, loading: opportunityLoading } = useSelector(
        (state) => state.opportunity
    );
    const { list: quoteList, loading: quoteLoading } = useSelector(
        (state) => state.quote
    );
    const { list: staffList, loading: staffLoading } = useSelector(
        (state) => state.users
    );
    const { loading: noteLoading } = useSelector((state) => state.crmNote);

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAllContactPaginated({ status: true, count: 999 }));
        dispatch(loadAllCompany());
        dispatch(loadAllOpportunity());
        dispatch(loadAllQuote());
        dispatch(loadAllStaff({ status: true }));
    }, [dispatch]);

    const onFinish = async (values) => {
        const formData = {
            ...values,
            noteOwnerId: parseInt(values.noteOwnerId),
            companyId: parseInt(values.companyId),
            opportunityId: parseInt(values.opportunityId),
            contactId: parseInt(values.contactId),
            quoteId: parseInt(values.quoteId),
        };
        const selectedContact = contactList.find(contact => contact.id === values.contactId);
        const selectedOwner = staffList.find(staff => staff.id === values.noteOwnerId);
        const selectedQuote = quoteList.find(quote => quote.id === values.quoteId);
        const emailBody = `Client: ${selectedContact?.firstName} ${selectedContact?.lastName}\nDescription: ${values.description}`;
        const emailData = {
            receiverEmail: selectedOwner.email,
            subject: values.title,
            body: emailBody.replace(/\n/g, '<br>'),
        };
        sendEmail(emailData);
        if (!!edit) {
            const resp = await dispatch(
                updateNote({ id: singleLoad?.id, values: formData })
            );
            if (resp.payload.message === "success") {
                form.resetFields();
                if (singleLoad?.thunk) {
                    dispatch(singleLoad.thunk(singleLoad.id));
                }
            }
        } else {
            const resp = await dispatch(addSingleNote(formData));
            if (resp.payload.message === "success") {
                form.resetFields();
                if (createAs?.name) {
                    dispatch(createAs.singleLoadThunk(createAs.value));
                } else {
                    dispatch(loadAllNotePaginated({}));
                }
            }
        }
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className='flex justify-center mt-5'>
            <UserPrivateComponent permission='create-note'>
                <Form
                    className='w-4/5'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    colon={false}
                    layout='vertical'
                    form={form}
                    initialValues={
                        createAs
                            ? {
                                [createAs?.name]: createAs?.value,
                            }
                            : !!edit
                                ? {
                                    title: edit?.title,
                                    noteOwnerId: edit?.noteOwnerId,
                                    companyId: edit?.companyId,
                                    opportunityId: edit?.opportunityId,
                                    contactId: edit?.contactId,
                                    quoteId: edit?.quoteId,
                                    description: edit?.description,
                                }
                                : {}
                    }
                >
                    <Form.Item
                        style={{ width: "100%" }}
                        label={TEXTS.LABELS.TITLE}
                        name='title'
                        tooltip={TEXTS.TEXT.REQUIRED_FIELD}
                        rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.TITLE}` }]}
                    >
                        <Input placeholder='Titlu' />
                    </Form.Item>

                    <Form.Item
                        label={TEXTS.LABELS.NOTE_OWNER}
                        name={"noteOwnerId"}
                        tooltip={TEXTS.TEXT.REQUIRED_FIELD}
                        rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.OWNER}` }]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            loading={staffLoading}
                            allowClear
                            showSearch
                            placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.NOTE_OWNER}`}
                        >
                            {staffList?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item?.firstName} {item?.lastName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {createAs?.name === 'companyId' && (
                        <Form.Item label={TEXTS.LABELS.COMPANY} name='companyId'>
                            <Select
                                style={{ width: "100%" }}
                                loading={companyLoading}
                                allowClear
                                showSearch
                                placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.COMPANY}`}
                                disabled
                            >
                                {companyList?.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.companyName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {createAs?.name === 'contactId' && (
                        <Form.Item label={TEXTS.LABELS.CONTACT} name='contactId'>
                            <Select
                                style={{ width: "100%" }}
                                loading={contactLoading}
                                allowClear
                                showSearch
                                placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.CONTACT}`}
                                disabled
                            >
                                {contactList?.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.fullName ? item.fullName : item.firstName + " " + item.lastName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {createAs?.name === 'opportunityId' && (
                        <Form.Item label={TEXTS.LABELS.OPPORTUNITY} name='opportunityId'>
                            <Select
                                style={{ width: "100%" }}
                                loading={opportunityLoading}
                                allowClear
                                showSearch
                                placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.OPPORTUNITY}`}
                                disabled
                            >
                                {opportunityList?.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.opportunityName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {createAs?.name === 'quoteId' && (
                        <Form.Item label={TEXTS.LABELS.QUOTE} name='quoteId'>
                            <Select
                                style={{ width: "100%" }}
                                loading={quoteLoading}
                                allowClear
                                showSearch
                                placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.QUOTE}`}
                                disabled
                            >
                                {quoteList?.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.quoteName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item label={TEXTS.LABELS.NOTE} name='description'>
                        <Input.TextArea placeholder={TEXTS.PLACEHOLDERS.CONTACT_DESCRIPTION} />
                    </Form.Item>
                    <div className="flex justify-center">
                        <Form.Item label=''>
                            <div className='flex items-center'>
                                <Button size={"large"} htmlType='submit' type='primary'>
                                    {TEXTS.TEXT.CREATE}
                                </Button>

                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </UserPrivateComponent>
        </div>
    );
}
