import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduction, getAllProductions } from "../../../../../redux/rtk/features/crm/production/productionSlice";
import { TEXTS } from "../../../../../constants";
import CreateProductionForm from "./CreateProductionForm";
import { Card, Table } from "antd";
import { CSVLink } from "react-csv";
import ColVisibilityDropdown from "../../../../Shared/ColVisibilityDropdown";
import moment from "moment";
import CustomDrawer from "../../../CommonUi/CustomDrawer";
import { AiFillDelete } from "react-icons/ai";

export default function ProductionsTable() {
    const dispatch = useDispatch();
    const { list: productionList, loading } = useSelector(state => state.production);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        dispatch(getAllProductions());
    }, [dispatch]);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const deleteHandler = (id) => {
        dispatch(deleteProduction(id));
    };

    const columns = [
        {
            title: `${TEXTS.LABELS.PRODUCTION_SUCCESSION}`,
            dataIndex: 'productionName',
        },
        {
            title: `${TEXTS.LABELS.USERNAME}`,
            dataIndex: 'productionUserName',
            render: (text) => text || 'N/A',
        },
        {
            title: `${TEXTS.LABELS.PHONE}`,
            dataIndex: 'phone',
            render: (text) => text || 'N/A',
        },
        {
            title: `Email`,
            dataIndex: 'email',
            render: (text) => text || 'N/A',
        },
        {
            title: `IDNO`,
            dataIndex: 'idno',
            render: (text) => text || 'N/A',
        },
        {
            title: `TVA`,
            dataIndex: 'tva',
            render: (text) => text || 'N/A',
        },
        {
            title: `IBAN`,
            dataIndex: 'iban',
            render: (text) => text || 'N/A',
        },
        {
            title: `BIC`,
            dataIndex: 'bic',
            render: (text) => text || 'N/A',
        },
        {
            title: `Nume Bancă`,
            dataIndex: 'bankName',
            render: (text) => text || 'N/A',
        },
        {
            title: `${TEXTS.TEXT.BILLING_STREET}`,
            dataIndex: 'juridicStreet',
            render: (text) => text || 'N/A',
        },
        {
            title: `${TEXTS.TEXT.BILLING_CITY}`,
            dataIndex: 'juridicCity',
            render: (text) => text || 'N/A',
        },
        {
            title: `${TEXTS.LABELS.ZIP_CODE}`,
            dataIndex: 'postalCode',
            render: (text) => text || 'N/A',
        },
        {
            title: `${TEXTS.TEXT.SHIPPING_STREET}`,
            dataIndex: 'officeStreet',
            render: (text) => text || 'N/A',
        },
        {
            title: `${TEXTS.TEXT.SHIPPING_CITY}`,
            dataIndex: 'officeCity',
            render: (text) => text || 'N/A',
        },
        {
            title: `${TEXTS.TEXT.BILLING_ZIP_CODE}`,
            dataIndex: 'officePostalCode',
            render: (text) => text || 'N/A',
        },
        {
            title: `${TEXTS.LABELS.CREATE_DATE}`,
            dataIndex: 'createdAt',
            render: (date) => moment(date).format('MMMM Do YYYY HH:mm'),
        },
        {
            title: `${TEXTS.LABELS.UPDATE_DATE}`,
            dataIndex: 'updatedAt',
            render: (date) => moment(date).format('MMMM Do YYYY HH:mm'),
        },
        {
            title: `${TEXTS.LABELS.ACTIONS}`,
            dataIndex: 'id',
            render: (id, values) => (
                <span className='flex items-center gap-2'>
                    <CustomDrawer
                        permission={"update-contactSource"}
                        update
                        title={`${TEXTS.TEXT.UPDATE} ${TEXTS.LABELS.ROOM}`}
                        width={25}
                    >
                        <CreateProductionForm edit={{ id, values }} />
                    </CustomDrawer>
                    <span
                        onClick={() => deleteHandler(id)}
                        className='bg-red-700 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded'
                    >
                        <AiFillDelete className='text-white' />
                    </span>
                </span>
            ),
        },
    ];

    const [columnsToShow, setColumnsToShow] = useState(columns);

    const columnsToShowHandler = (val) => {
        setColumnsToShow(val);
    };

    return (
        <Card className='border mt-2'>
            <div className='flex justify-between items-center'>
                <div></div>
                <div className='flex items-center gap-2'>
                    <span className='border px-2 py-1 rounded cursor-pointer bg-black text-white'>
                        <CSVLink
                            data={productionList}
                            className='btn btn-dark btn-sm'
                            style={{ marginTop: '5px' }}
                            filename='Productions_CRM'
                        >
                            {TEXTS.BUTTON_TEXT.DOWNLOAD_CSV}
                        </CSVLink>
                    </span>
                    <ColVisibilityDropdown
                        options={columns}
                        columns={columns}
                        columnsToShowHandler={columnsToShowHandler}
                    />
                </div>
            </div>
            <div>
                <span
                    style={{
                        marginLeft: 8,
                    }}
                >
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                </span>
            </div>
            <Table
                loading={loading}
                rowSelection={rowSelection}
                columns={columnsToShow}
                dataSource={productionList?.map((item) => ({
                    ...item,
                    key: item.id,
                }))}
                pagination={false}
                scroll={{ x: 1500 }} // Adjust scroll width if needed
            />
        </Card>
    );
};
