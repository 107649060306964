// src/components/Settings.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../../../../redux/rtk/features/settings/settingsSlice';
import { Button, Spin, Row, Col } from 'antd';
import EditSettingsForm from './EditSettingsForm'; // Import the editing form component

const Settings = () => {
    const dispatch = useDispatch();
    const { settings, loading, error } = useSelector((state) => state.settings);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);

    if (loading) return <Spin size="large" />;

    if (error) return <div>Error fetching settings: {error}</div>;

    // Access the first item in the settings array
    const currentSettings = settings.length > 0 ? settings[0] : null;

    if (!currentSettings) return <div>No settings available.</div>;

    return (
        <div className="p-4">
            <h2 className="text-2xl mb-4">Settings</h2>
            <Row gutter={16}>
                {/* Left Column: Settings Display */}
                <Col span={12}>
                    <div className="mb-4">
                        <strong>Minim de Preț:</strong> {currentSettings.minimX}
                    </div>
                    <div className="mb-4">
                        <strong>Maxim de Preț:</strong> {currentSettings.maximX}
                    </div>
                    <div className="mb-4">
                        <strong>Rata de Scimb Euro:</strong> {currentSettings.eurExchangeRate}
                    </div>
                    <Button
                        type="primary"
                        onClick={() => setIsEditing(true)}
                        style={{ marginBottom: '16px' }}
                    >
                        Edit
                    </Button>
                </Col>

                {/* Right Column: Edit Settings Form */}
                <Col span={12}>
                    {isEditing && (
                        <EditSettingsForm
                            currentSettings={currentSettings}
                            onClose={() => setIsEditing(false)}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default Settings;
