import { Menu } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import getPermissions from "../../utils/getPermissions";
import { TEXTS } from "../../constants";

export default function SideBar() {
  const permissions = getPermissions();
  const hasPermission = (item) => {
    return permissions?.includes(item ? item : "");
  };

  const menu = [
    {
      label: (
        <NavLink to="/admin/setup/settings">
          <span>Setări de Calcule</span>
        </NavLink>
      ),
      key: "Settings",
    },
    (hasPermission("create-user") ||
      hasPermission("readAll-user") ||
      hasPermission("readAll-role") ||
      hasPermission("readAll-designation") ||
      hasPermission("readAll-department")) && {
      label: `${TEXTS.LABELS.EMPLOYEE} Manage`,
      key: "EmployeeManage",
      children: [
        hasPermission("create-user") && {
          label: (
            <NavLink to="/admin/setup/staffs-new">
              <span>{TEXTS.LABELS.NEW_EMPLOYEE}</span>
            </NavLink>
          ),

          key: "staffs",
        },
        hasPermission("readAll-user") && {
          label: (
            <NavLink to="/admin/setup/staffs">
              <span>{TEXTS.LABELS.EMPLOYEE_LIST}</span>
            </NavLink>
          ),
          key: "users",
        },
        hasPermission("readAll-role") && {
          label: (
            <NavLink to="/admin/setup/role">
              <span>{TEXTS.LABELS.ROLE_AND_PERMISSION}</span>
            </NavLink>
          ),
          key: "roleAndPermissions",
        },
        hasPermission("readAll-designation") && {
          label: (
            <NavLink to="/admin/setup/designation">
              <span>{TEXTS.LABELS.DESIGNATION}</span>
            </NavLink>
          ),
          key: "designation",
        },
        hasPermission("readAll-employmentStatus") && {
          label: (
            <NavLink to="/admin/setup/employment-status">
              <span>{TEXTS.LABELS.EMPLOYMENT_STATUS}</span>
            </NavLink>
          ),
          key: "employmentStatus",
        },
        hasPermission("readAll-department") && {
          label: (
            <NavLink to="/admin/setup/department">
              <span>{TEXTS.LABELS.DEPARTMENT}</span>
            </NavLink>
          ),
          key: "department",
        },
        hasPermission("readAll-shift") && {
          label: (
            <NavLink to="/admin/setup/shift">
              <span>{TEXTS.LABELS.SHIFT}uri</span>
            </NavLink>
          ),
          key: "newShift",
        },
      ],
    },
    {
      label: (
        <NavLink to="/admin/setup/failure-cause">
          <span>Cauzele Eșecului</span>
        </NavLink>
      ),
      key: "CauseOfFailure",
    },
    (hasPermission("readAll-contactSource") ||
      hasPermission("readAll-contactStage")) && {
      label: `${TEXTS.LABELS.CONTACT} Setup`,
      key: "ContactSetup",
      children: [
        {
          label: (
            <NavLink to="/admin/setup/contact-source">
              <span>{TEXTS.LABELS.CONTACT_SOURCE}</span>
            </NavLink>
          ),
          key: "contactSource",
        },
        {
          label: (
            <NavLink to="/admin/setup/contact-stage">
              <span>{TEXTS.LABELS.CONTACT_STAGE}</span>
            </NavLink>
          ),
          key: "contactStage",
        },
        {
          label: (
            <NavLink to="/admin/setup/partner">
              <span>Partener</span>
            </NavLink>
          )
        },
        {
          label: (
            <NavLink to="/admin/setup/designer">
              <span>{TEXTS.LABELS.DESIGNER}</span>
            </NavLink>
          )
        },
      ],
    },

    (hasPermission("readAll-companyType") ||
      hasPermission("readAll-industry")) && {
      label: `${TEXTS.LABELS.COMPANY} Setup`,
      key: "CompanySetup",
      children: [
        {
          label: (
            <NavLink to="/admin/setup/company-type">
              <span>{TEXTS.LABELS.COMPANY_TYPE} </span>
            </NavLink>
          ),
          key: "companyType",
        },
        {
          label: (
            <NavLink to="/admin/setup/industry">
              <span>{TEXTS.LABELS.INDUSTRY}</span>
            </NavLink>
          ),
          key: "industry",
        },
      ],
    },
    (hasPermission("readAll-opportunitySource") ||
      hasPermission("readAll-opportunityStage") ||
      hasPermission("readAll-opportunityType")) && {
      label: `${TEXTS.LABELS.OPPORTUNITY} Setup`,
      key: "OpportunitySetup",
      children: [
        {
          label: (
            <NavLink to="/admin/setup/opportunity-source">
              <span>{TEXTS.LABELS.OPPORTUNITY_SOURCE}</span>
            </NavLink>
          ),
          key: "opportunity-source",
        },
        {
          label: (
            <NavLink to="/admin/setup/opportunity-type">
              <span>{TEXTS.LABELS.OPPORTUNITY_TYPE}</span>
            </NavLink>
          ),
          key: "opportunity-type",
        },
        {
          label: (
            <NavLink to="/admin/setup/opportunity-stage">
              <span>{TEXTS.LABELS.OPPORTUNITY_STAGE}</span>
            </NavLink>
          ),
          key: "opportunity-stage",
        },
      ],
    },
    (hasPermission("readAll-opportunitySource") ||
      hasPermission("readAll-opportunityStage") ||
      hasPermission("readAll-opportunityType")) && {
      label: `${TEXTS.LABELS.ROOMS}`,
      key: "roomSetup",
      children: [
        {
          label: (
            <NavLink to="/admin/setup/room">
              <span>{TEXTS.LABELS.ROOM}</span>
            </NavLink>
          ),
          key: "room",
        },
        {
          label: (
            <NavLink to="/admin/setup/production">
              <span>{TEXTS.LABELS.PRODUCTION_SUCCESSION}</span>
            </NavLink>
          ),
          key: "production",
        }
      ]
    },
    (hasPermission("readAll-crmTaskStatus") ||
      hasPermission("readAll-crmTaskType") ||
      hasPermission("readAll-crmTaskPriority")) && {
      label: `${TEXTS.LABELS.TASKS} Setup`,
      key: "TaskSetup",
      children: [
        {
          label: (
            <NavLink to="/admin/setup/task-status">
              <span>{TEXTS.LABELS.TASK_STATUS}</span>
            </NavLink>
          ),
          key: "task-status",
        },
        {
          label: (
            <NavLink to="/admin/setup/task-type">
              <span>{TEXTS.LABELS.TASK_TYPE}</span>
            </NavLink>
          ),
          key: "task-type",
        },
        {
          label: (
            <NavLink to="/admin/setup/task-priority">
              <span>{TEXTS.LABELS.TASK_PRIORITY}</span>
            </NavLink>
          ),
          key: "task-priority",
        },
      ],
    },
    hasPermission("readAll-quoteStage") && {
      label: `${TEXTS.LABELS.QUOTE} Setup`,
      key: "quoteSetup",
      children: [
        {
          label: (
            <NavLink to="/admin/setup/quote-stage">
              <span>{TEXTS.LABELS.QUOTE_STAGE}</span>
            </NavLink>
          ),
          key: "quoteStage",
        },
      ],
    },
    hasPermission("readAll-announcement") && {
      label: `${TEXTS.LABELS.ANNOUNCEMENT}`,
      key: "announcement",
      children: [
        hasPermission("readAll-announcement") && {
          label: (
            <NavLink to="/admin/setup/announcement">
              <span>{TEXTS.LABELS.ANNOUNCEMENT}</span>
            </NavLink>
          ),
          key: "newLeave",
        },
      ],
    },

    (hasPermission("readAll-account") ||
      hasPermission("readAll-transaction") ||
      hasPermission("create-transaction")) && {
      label: "Account Manage",
      key: "AccountManage",
      children: [
        hasPermission("readAll-account") && {
          label: (
            <NavLink to="/admin/setup/account">
              <span>Account</span>
            </NavLink>
          ),
          key: "accountList",
        },
        hasPermission("readAll-transaction") && {
          label: (
            <NavLink to="/admin/setup/transaction">
              <span>{TEXTS.LABELS.TRANSACTION_LIST}</span>
            </NavLink>
          ),
          key: "transactionList",
        },
      ],
    },

    hasPermission("readAll-account") && {
      label: `${TEXTS.LABELS.FINANCE} Report`,
      key: "report",
      children: [
        hasPermission("readAll-account") && {
          label: (
            <NavLink to="/admin/setup/account/trial-balance">
              <span>{TEXTS.LABELS.TRIAL_BALANCE}</span>
            </NavLink>
          ),
          key: "trialBalance",
        },
        hasPermission("readAll-account") && {
          label: (
            <NavLink to="/admin/setup/account/balance-sheet">
              <span>{TEXTS.LABELS.BALANCE_SHEET}</span>
            </NavLink>
          ),
          key: "balanceSheet",
        },
        hasPermission("readAll-account") && {
          label: (
            <NavLink to="/admin/setup/account/income">
              <span>{TEXTS.LABELS.INCOME_STATEMENT}</span>
            </NavLink>
          ),
          key: "incomeStatement",
        },
      ],
    },

    (hasPermission("crate-award") || hasPermission("readAll-award")) && {
      label: `${TEXTS.LABELS.AWARDS}`,
      key: "award",
      children: [
        hasPermission("readAll-award") && {
          label: (
            <NavLink to="/admin/setup/award">
              <span>{TEXTS.LABELS.AWARD}</span>
            </NavLink>
          ),
          key: "award",
        },
      ],
    },

    hasPermission("readAll-setting") && {
      label: `${TEXTS.LABELS.ORGANIZATION} Setup`,
      key: "OrganizationSetup",
      children: [
        hasPermission("readAll-setting") && {
          label: (
            <NavLink to="/admin/setup/company-setting">
              <span>{TEXTS.LABELS.ORGANIZATION}</span>
            </NavLink>
          ),
          key: "Organization",
        },
      ],
    },
    hasPermission("readAll-email") && {
      label: (
        <NavLink to="/admin/setup/email-config">
          <span>{TEXTS.LABELS.EMAIL_CONFIG}</span>
        </NavLink>
      ),
      key: "emailSetup",
    },
  ];
  return (
    <>
      <div className="select-none">
        <Menu
          className="bg-[#383434] text-white border-none font-poppins"
          mode="inline"
          items={menu}
        />
      </div>
    </>
  );
}
