import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import "moment/locale/ro";
import "./App.css";

import Login from "./components/user/Login";
import Logout from "./components/user/Logout";
import UserList from "./components/user/user";

import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

import Page404 from "./components/404/404Page";
import Dashboard from "./components/Dashboard/Graph/Dashboard";
import DetailStaff from "./components/user/detailsStaff";

import Designation from "./components/designation/designation";
import DetailDesignation from "./components/designation/detailDesignation";
import UpdateDesignation from "./components/designation/updateDesignation";

import Main from "./components/layouts/Main";

import Department from "./components/department/Department.js";
import DetailDepartment from "./components/department/DetailsDepartment";
import AddPermission from "./components/role/AddPermission";
import DetailRole from "./components/role/DetailsRole";
import RoleList from "./components/role/role";
import InvoiceSetting from "./components/settings/invoiceSetting";
import GetAllUsers from "./components/user/GetAllUser";

import EmploymentStatus from "./components/employmentStatus/EmploymentStatus";
import DetailEmploymentStatus from "./components/employmentStatus/EmploymentStatusDetails";
import Shift from "./components/shift/Shift";
import DetailShift from "./components/shift/ShiftDetails";

import Account from "./components/account/account";
import BalanceSheet from "./components/account/balanceSheet";
import DetailAccount from "./components/account/detailAccount";
import IncomeStatement from "./components/account/incomeStatement";
import TrialBalance from "./components/account/trialBalance";

import { Navigate } from "react-router-dom";
import Attachment from "./components/CRM/Attachment/Attachment";
import Companies from "./components/CRM/Companies/Companies";
import CompanyDetails from "./components/CRM/Companies/CompanyDetails/CompanyDetails";
import ContactDetails from "./components/CRM/Contacts/ContactDetails/ContactDetails";
import Contacts from "./components/CRM/Contacts/Contacts";
import UserAccount from "./components/CRM/CustomerDashboard/Account";
import Email from "./components/CRM/Email/Email";
import SingleEmail from "./components/CRM/Email/SingleEmail";
import Notes from "./components/CRM/Notes/Notes";
import Opportunity from "./components/CRM/Opportunity/Opportunity";
import OpportunityDetails from "./components/CRM/Opportunity/OpportunityDetails/OpportunityDetails";
import Products from "./components/CRM/Products/Products";
import QuoteDetails from "./components/CRM/Quotes/QuoteDetails/QuoteDetails";
import Quotes from "./components/CRM/Quotes/Quotes";
import CompanyType from "./components/CRM/Setup/CompanySetup/CompanyType/CompanyType";
import Industry from "./components/CRM/Setup/CompanySetup/IndustrySetup/Industry";
import ContactSource from "./components/CRM/Setup/ContactSetup/ContactSourceSetup/ContactSource";
import ContactStage from "./components/CRM/Setup/ContactSetup/ContactStageSetup/ContactStage";
import EmailSetup from "./components/CRM/Setup/EmailSetup/EmailSetup";
import OpportunitySource from "./components/CRM/Setup/OpportunitySetup/OpportunitySourceSetup/OpportunitySource";
import OpportunityStage from "./components/CRM/Setup/OpportunitySetup/OpportunityStageSetup/OpportunityStage";
import OpportunityType from "./components/CRM/Setup/OpportunitySetup/OpportunityTypeSetup/OpportunityType";
import QuoteStage from "./components/CRM/Setup/QuoteSetup/QuoteStageSetup/QuoteStage";
import CrmTaskPriority from "./components/CRM/Setup/TaskSetup/TaskPriority/CrmTaskPriority";
import CrmTaskStatus from "./components/CRM/Setup/TaskSetup/TaskStatus/CrmTaskStatus";
import CrmTaskType from "./components/CRM/Setup/TaskSetup/TaskType/CrmTaskType";
import CrmTicketCategory from "./components/CRM/Setup/TicketSetup/TicketCategory/CrmTicketCategory";
import CrmTicketPriority from "./components/CRM/Setup/TicketSetup/TicketPriority/CrmTicketPriority";
import CrmTicketStatus from "./components/CRM/Setup/TicketSetup/TicketStatus/CrmTicketStatus";
import TaskDetails from "./components/CRM/Tasks/TaskDetails/TaskDetails";
import Tasks from "./components/CRM/Tasks/Tasks";
import SupportTicketForm from "./components/CRM/ticket/CreateTicket";
import Ticket from "./components/CRM/ticket/Ticket";
import TicketDetails from "./components/CRM/ticket/TicketDetails/DetailsPage";
import UserPrivateRoute from "./components/PrivateRoutes/UserPrivateRoute";
import Announcement from "./components/announcement/Announcement";
import DetailAnnouncement from "./components/announcement/AnnouncementDetails";
import DetailAward from "./components/award/DetailsAward";
import GetAllAward from "./components/award/GetAllAward";
import Setup from "./components/setup/Setup";
import SetupLayout from "./components/setup/SetupLayout";
import DetailTransaction from "./components/transaction/detailTransaction";
import Transaction from "./components/transaction/transaction";
import CustomerForgetPass from "./components/user/CustomerForgetPass";
import CustomerLogin from "./components/user/CustomerLogin";
import CustomerRegister from "./components/user/CustomerRegister";
import getRoleFromToken from "./utils/getRoleFromToken";
import Contracts from "./components/CRM/Contracts/Contracts.js";
import KanbanBoard from "./components/CRM/Kanban/KanbanBoard.js";
import OpportunityKanbanBoard from "./components/CRM/Kanban/OpportunityKanbanBoard.js";
import CalendarBar from "./components/Dashboard/Graph/Calendar.js";
import Rooms from "./components/CRM/Setup/RoomSetup/Rooms.js";
import Finances from "./components/CRM/Finances/Finances.js";
import PartnerSetup from "./components/CRM/Setup/ContactSetup/PartnerSetup/PartnerSetup.js";
import DesignerSetup from "./components/CRM/Setup/ContactSetup/DesignerSetup/DesignerSetup.js";
import CauseOfFailure from "./components/CRM/Setup/CauseOfFailure/CauseOfFailure.js";
import PdfView from "./components/CRM/PdfView/PdfView.js";
import ContactRoomDetails from "./components/CRM/Rooms/ContactRoomDetails.js";
import Productions from "./components/CRM/Setup/RoomSetup/ProductionSetup/Productions.js";
import PrivateRoute from "./components/PrivateRoutes/PrivateRoute.js";
import Offers from "./components/CRM/Offer/Offers.js";
import SocketTest from "./utils/SocketTest.js";
import { WebSocketProvider } from "./components/PrivateRoutes/WebSocketContext.js";
import Settings from "./components/CRM/Setup/Settings/Settings.js";

function App() {

	moment.locale('ro');

	return (
		<div className='App container-fluid'>
			<ToastContainer
				position='bottom-left'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
			/>
			<WebSocketProvider>
				<BrowserRouter>
					<Main>
						<Routes>
							<Route path='/' element={<Navigate to={"/admin/auth/login"} />} />
							<Route
								path='/admin/dashboard'
								element={
									getRoleFromToken() !== "customer" ? <PrivateRoute><Dashboard /></PrivateRoute> : <PrivateRoute allowedRoles={["all"]}><Ticket /></PrivateRoute>
								}></Route>
							<Route path='/admin' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
							<Route path='/customer/dashboard' element={<PrivateRoute allowedRoles={['customer']}><Ticket /></PrivateRoute>} />
							<Route path='*' element={<Page404 />} />
							<Route path='/admin/calendar' element={<PrivateRoute allowedRoles={["Manager Vânzări", "Manager Producere", "Operator Producere", "Tehnician Prețuri", "Designer"]}><CalendarBar /></PrivateRoute>} />
							<Route path='/admin/pdf-view' element={<PrivateRoute allowedRoles={["all"]}><PdfView /></PrivateRoute>} />
							<Route path='/admin/auth/login' exact element={<Login />} />
							<Route path='/admin/auth/logout' exact element={<Logout />} />
							{/*         <Route path='/auth/register' exact element={<Register />} /> */}
							{/* === === === Setup Routes === === === */}
							<Route path='/admin/setup' element={<PrivateRoute><SetupLayout /></PrivateRoute>}>
								<Route path='failure-cause' element={<PrivateRoute><CauseOfFailure /></PrivateRoute>} />
								<Route path='' element={<PrivateRoute><Setup /></PrivateRoute>} />
								<Route path='settings' element={<PrivateRoute><Settings /></PrivateRoute>} />

								<Route element={<UserPrivateRoute permission={"readAll-user"} />}>
									<Route path='staffs-new' exact element={<PrivateRoute><UserList /></PrivateRoute>} />
								</Route>

								<Route element={<UserPrivateRoute permission={"readAll-user"} />}>
									<Route path='staffs' exact element={<PrivateRoute><GetAllUsers /> </PrivateRoute>} />
								</Route>
								<Route
									element={<UserPrivateRoute permission={"readSingle-user"} />}>
									<Route path='staffs/:id' exact element={<PrivateRoute><DetailStaff /></PrivateRoute>} />
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readAll-rolePermission"} />
									}>
									<Route path='role' exact element={<PrivateRoute><RoleList /></PrivateRoute>} />
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readSingle-rolePermission"} />
									}>
									<Route path='role/:id' element={<PrivateRoute><DetailRole /></PrivateRoute>} />
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"create-rolePermission"} />
									}>
									<Route path='role/permit/:id/' element={<PrivateRoute><AddPermission /></PrivateRoute>} />
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readAll-department"} />
									}>
									<Route path='department' exact element={<PrivateRoute><Department /></PrivateRoute>} />
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readSingle-department"} />
									}>
									<Route path='department/:id' element={<PrivateRoute><DetailDepartment /></PrivateRoute>} />
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readAll-designation"} />
									}>
									<Route path='designation' exact element={<PrivateRoute><Designation /></PrivateRoute>} />
								</Route>

								<Route
									element={
										<UserPrivateRoute permission={"readSingle-designation"} />
									}>
									<Route path='designation/:id' element={<PrivateRoute><DetailDesignation /></PrivateRoute>} />
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"update-designation"} />
									}>
									<Route
										path='designation/:id/update'
										element={<PrivateRoute><UpdateDesignation /></PrivateRoute>}
									/>
								</Route>
								<Route
									element={<UserPrivateRoute permission={"readAll-setting"} />}>
									<Route
										path='company-setting'
										exact
										element={<PrivateRoute><InvoiceSetting /></PrivateRoute>}
									/>
								</Route>

								{/* === === === Shift Routes === === === */}

								<Route
									element={<UserPrivateRoute permission={"readAll-shift"} />}>
									<Route path='shift' element={<PrivateRoute><Shift /></PrivateRoute>} />
								</Route>

								<Route
									element={<UserPrivateRoute permission={"readSingle-shift"} />}>
									<Route path='shift/:id' element={<PrivateRoute><DetailShift /></PrivateRoute>} />
								</Route>

								{/* === === === EmploymentStatus Routes === === === */}
								<Route
									element={
										<UserPrivateRoute permission={"readAll-employmentStatus"} />
									}>
									<Route
										path='employment-status'
										element={<PrivateRoute><EmploymentStatus /></PrivateRoute>}
									/>
								</Route>

								<Route
									element={
										<UserPrivateRoute
											permission={"readSingle-employmentStatus"}
										/>
									}>
									<Route
										path='employment-status/:id'
										element={<PrivateRoute><DetailEmploymentStatus /></PrivateRoute>}
									/>
								</Route>

								{/* === === === Accounting Routes === === === */}

								<Route
									element={<UserPrivateRoute permission={"readAll-account"} />}>
									<Route path='account' exact element={<PrivateRoute><Account /></PrivateRoute>} />
									<Route path='account/:id' element={<PrivateRoute><DetailAccount /></PrivateRoute>} />
									<Route
										path='account/trial-balance'
										exact
										element={<PrivateRoute><TrialBalance /></PrivateRoute>}
									/>
									<Route
										path='account/balance-sheet'
										exact
										element={<PrivateRoute><BalanceSheet /></PrivateRoute>}
									/>
									<Route
										path='account/income'
										exact
										element={<PrivateRoute><IncomeStatement /></PrivateRoute>}
									/>
								</Route>
								{/* === === === Transaction Routes === === === */}
								<Route
									element={
										<UserPrivateRoute permission={"readAll-transaction"} />
									}>
									<Route path='transaction' exact element={<PrivateRoute><Transaction /></PrivateRoute>} />
								</Route>

								<Route
									element={
										<UserPrivateRoute permission={"readSingle-transaction"} />
									}>
									<Route path='transaction/:id' element={<PrivateRoute><DetailTransaction /></PrivateRoute>} />
								</Route>

								{/* === === === Announcement Routes === === === */}
								<Route
									element={
										<UserPrivateRoute permission={"readAll-announcement"} />
									}>
									<Route path='announcement' exact element={<PrivateRoute allowedRoles={["all"]}><Announcement /></PrivateRoute>} />
								</Route>

								<Route
									element={
										<UserPrivateRoute permission={"readSingle-announcement"} />
									}>
									<Route
										path='announcement/:id'
										element={<PrivateRoute allowedRoles={["all"]}><DetailAnnouncement /></PrivateRoute>}
									/>
								</Route>

								{/* === === === Award Routes === === === */}

								<Route
									element={<UserPrivateRoute permission={"readAll-award"} />}>
									<Route path='award/:id' element={<PrivateRoute allowedRoles={["all"]}><DetailAward /></PrivateRoute>} />
									<Route path='award' exact element={<PrivateRoute allowedRoles={["all"]}><GetAllAward /></PrivateRoute>} />
								</Route>
								{/* === === === contact setup Routes === === === */}
								<Route
									element={
										<UserPrivateRoute permission={"readAll-contactSource"} />
									}>
									<Route
										path='contact-source'
										exact
										element={<PrivateRoute><ContactSource /></PrivateRoute>}
									/>
									<Route path='contact-stage' exact element={<PrivateRoute><ContactStage /></PrivateRoute>} />
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readAll-contactStage"} />
									}>
									<Route path='contact-stage' exact element={<PrivateRoute><ContactStage /></PrivateRoute>} />
								</Route>
								<Route path='partner' exact element={<PrivateRoute><PartnerSetup /></PrivateRoute>} />
								<Route path="designer" exact element={<PrivateRoute><DesignerSetup /></PrivateRoute>} />
								{/* === === === unit Routes === === === === === */}
								<Route
									element={
										<UserPrivateRoute permission={"readAll-unit"} />
									}>
								</Route>
								{/* === === === company setup Routes === === === */}
								<Route
									element={
										<UserPrivateRoute permission={"readAll-companyType"} />
									}>
									<Route path='company-type' exact element={<PrivateRoute><CompanyType /></PrivateRoute>} />
								</Route>
								<Route
									element={<UserPrivateRoute permission={"readAll-industry"} />}>
									<Route path='industry' exact element={<PrivateRoute><Industry /></PrivateRoute>} />
								</Route>

								{/* === === === contact setup Routes === === === */}
								<Route
									element={
										<UserPrivateRoute permission={"readAll-opportunitySource"} />
									}>
									<Route
										path='opportunity-source'
										exact
										element={<PrivateRoute><OpportunitySource /></PrivateRoute>}
									/>
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readAll-opportunityStage"} />
									}>
									<Route
										path='opportunity-stage'
										exact
										element={<PrivateRoute><OpportunityStage /></PrivateRoute>}
									/>
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readAll-opportunityType"} />
									}>
									<Route
										path='opportunity-type'
										exact
										element={<PrivateRoute><OpportunityType /></PrivateRoute>}
									/>
								</Route>
								<Route
									path='room'
									exact
									element={<PrivateRoute><Rooms /></PrivateRoute>}
								>
								</Route>
								<Route
									path='production'
									exact
									element={<PrivateRoute><Productions /></PrivateRoute>}
								></Route>
								{/* === === === Crm task setup Routes === === === */}
								<Route
									element={
										<UserPrivateRoute permission={"readAll-crmTaskStatus"} />
									}>
									<Route path='task-status' exact element={<PrivateRoute><CrmTaskStatus /></PrivateRoute>} />
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readAll-crmTaskType"} />
									}>
									<Route path='task-type' exact element={<PrivateRoute><CrmTaskType /></PrivateRoute>} />
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readAll-crmTaskPriority"} />
									}>
									<Route
										path='task-priority'
										exact
										element={<PrivateRoute><CrmTaskPriority /></PrivateRoute>}
									/>
								</Route>

								{/* === === ===  quote setup Routes === === === */}
								<Route
									element={
										<UserPrivateRoute permission={"readAll-quoteStage"} />
									}>
									<Route path='quote-stage' exact element={<PrivateRoute><QuoteStage /></PrivateRoute>} />
								</Route>

								{/* === === === email setup Routes === === === */}
								<Route path='email-config' element={<PrivateRoute><EmailSetup /></PrivateRoute>} />

								{/* === === ===  Ticket setup Routes === === === */}
								<Route
									element={
										<UserPrivateRoute permission={"readAll-ticketCategory"} />
									}>
									<Route
										path='ticket-category'
										exact
										element={<PrivateRoute><CrmTicketCategory /></PrivateRoute>}
									/>
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readAll-ticketPriority"} />
									}>
									<Route
										path='ticket-priority'
										exact
										element={<PrivateRoute><CrmTicketPriority /></PrivateRoute>}
									/>
								</Route>
								<Route
									element={
										<UserPrivateRoute permission={"readAll-ticketStatus"} />
									}>
									<Route
										path='ticket-status'
										exact
										element={<PrivateRoute><CrmTicketStatus /></PrivateRoute>}
									/>
								</Route>
							</Route>

							{/* === === === CRM Routes === === === */}
							<Route path='/admin/contracts' element={<PrivateRoute allowedRoles={["Manager Vânzări"]}><Contracts /></PrivateRoute>} />
							<Route path='/admin/offers' element={<PrivateRoute allowedRoles={["Manager Vânzări"]}><Offers /></PrivateRoute>} />
							<Route path='/admin/company' element={<PrivateRoute><Companies /></PrivateRoute>} />
							<Route
								path='/admin/company/:CompanyId'
								element={<PrivateRoute><CompanyDetails /></PrivateRoute>}
							/>
							<Route
								path="/admin/room/:RoomId"
								element={<PrivateRoute allowedRoles={["Manager Vânzări", "Operator Producere", "Tehnician Prețuri"]}><ContactRoomDetails /></PrivateRoute>}
							/>
							{/* === === === finances Routes === === === === */}
							<Route
								path='/admin/finances'
								exact
								element={<PrivateRoute allowedRoles={["Operator Producere", "Contabil"]}><Finances /></PrivateRoute>}
							/>
							{/* === === === contact Routes === === === */}
							<Route path='/admin/contact' element={<PrivateRoute allowedRoles={["Manager Vânzări", "Tehnician Prețuri", "Designer"]}><Contacts /></PrivateRoute>} />
							<Route
								path='/admin/contact/:ContactId'
								element={<PrivateRoute allowedRoles={["Manager Vânzări", "Operator Producere", "Tehnician Prețuri", "Designer"]}><ContactDetails /></PrivateRoute>}
							/>
							<Route
								path='/admin/contact/kanban'
								element={<PrivateRoute allowedRoles={["Manager Vânzări", "Tehnician Prețuri", "Designer"]}><KanbanBoard /></PrivateRoute>}
							/>
							{/* === === === opportunity Routes === === === */}
							<Route path='/admin/opportunity' element={<PrivateRoute allowedRoles={["Manager Producere"]}><Opportunity /></PrivateRoute>} />
							<Route
								path='/admin/opportunity/:OpportunityId'
								element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere", "Tehnician Prețuri"]}><OpportunityDetails /></PrivateRoute>}
							/>
							<Route
								path='/admin/opportunity/kanban'
								element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}><OpportunityKanbanBoard /></PrivateRoute>}
							/>
							{/* === === === product Routes === === === */}
							<Route path='/admin/products' element={<PrivateRoute allowedRoles={["Tehnician Prețuri"]}><Products /></PrivateRoute>} />

							{/* === === === quote Routes === === === */}
							<Route path='/admin/quote' element={<PrivateRoute allowedRoles={["Tehnician Prețuri"]}><Quotes /></PrivateRoute >} />
							<Route path='/admin/quote/:QuoteId' element={<PrivateRoute allowedRoles={["Tehnician Prețuri"]}><QuoteDetails /></PrivateRoute>} />

							{/* === === === task Routes === === === */}
							<Route path='/admin/task' element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}><Tasks /></PrivateRoute>} />
							<Route path='/admin/task/:TaskId' element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}><TaskDetails /></PrivateRoute>} />

							{/* === === === note Routes === === === */}
							<Route path='/admin/note' element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}><Notes /></PrivateRoute>} />

							{/* === === === Attachment Routes === === === */}
							<Route path='/admin/attachment' element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}><Attachment /></PrivateRoute>} />

							{/* === === === email Routes === === === */}
							<Route path='/admin/email' element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}><Email /></PrivateRoute>}>
								<Route path=':EmailId' element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}><SingleEmail /></PrivateRoute>} />
							</Route>

							{/* === === === Crm ticket  Routes === === === */}
							<Route path='/support/ticket' element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}><Ticket /></PrivateRoute>} />
							<Route path='/admin/ticket' element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}><Ticket /></PrivateRoute>} />
							<Route
								path='/support/ticket/create'
								element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}><SupportTicketForm /></PrivateRoute>}
							/>
							<Route path='/support/ticket/:id' element={<PrivateRoute allowedRoles={["Manager Producere", "Operator Producere"]}> <TicketDetails /></PrivateRoute>} />

							{/* === === === Crm Customer  Routes === === === */}
							<Route path='/customer/login' element={<CustomerLogin />} />
							<Route path='/customer/register' element={<CustomerRegister />} />
							<Route
								path='/customer/forget-password'
								element={<CustomerForgetPass />}
							/>
							<Route path='/customer/profile' element={<UserAccount />} />
						</Routes>
					</Main>
				</BrowserRouter>
			</WebSocketProvider>
		</div>
	);
}

export default App;
