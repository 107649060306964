import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { loadSingleContactRoom } from "../../../redux/rtk/features/crm/contactRoom/contactRoomSlice";
import { loadAllOpportunityPaginated } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { List, Typography, Alert, Button, Collapse } from 'antd';
import { TEXTS } from "../../../constants";
import attachmentIcon from "../../../assets/Icons/attach-file.png"
import { BiArrowBack } from "react-icons/bi";
import Opportunities from "../CommonUi/Opportunities";
import Attachments from "../CommonUi/Attachments";

const { Title } = Typography;
const { Panel } = Collapse;

export default function ContactRoomDetails() {
    const { RoomId: id } = useParams();
    const dispatch = useDispatch();
    const { contactRoom, loading: contactRoomLoading } = useSelector((state) => state.contactRoom);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(loadSingleContactRoom(id));
        dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
    }, [dispatch, id]);
    const cameFromContact = location.state?.fromContact;

    if (!contactRoom || !contactRoom.room) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Alert message="No Data" description="No contact room data available." type="info" />
            </div>
        );
    }

    const handleBackClick = () => {
        if (cameFromContact) {
            navigate(`/admin/contact/${cameFromContact}`);
        }
    };

    const handleNavigateToOpportunity = (opportunityId) => {
        navigate(`/admin/opportunity/${opportunityId}`, {
            state: { fromRoom: id, fromContact: cameFromContact }
        });
    };

    return (
        <div className="p-6">
            <Title level={1}>{contactRoom.contact.lastName} {contactRoom.contact.firstName} : {contactRoom.room.roomName}</Title>
            {cameFromContact && (
                <Button
                    type="link"
                    icon={<BiArrowBack style={{ fontSize: "24px" }} />}
                    onClick={handleBackClick}
                    className="ml-4"
                />
            )}
            <div>
                <Collapse accordion >
                    <Panel header={
                        <div className="flex items-center">
                            <span className="mr-2">{TEXTS.LABELS.ATTACHMENTS}</span>
                            <img src={attachmentIcon} alt="icon" />
                        </div>
                    } key="8">
                        <div>
                            <Attachments
                                data={contactRoom}
                                loading={contactRoomLoading}
                                name={"contactRoomId"}
                                singleLoadThunk={loadSingleContactRoom}
                                attachmentType={1}
                                title={TEXTS.LABELS.ATTACHMENTS}
                            />
                        </div>
                    </Panel>
                </Collapse>
                <Opportunities
                    data={contactRoom}
                    loading={contactRoomLoading}
                    name={"contactRoomId"}
                    contact={contactRoom.contactId}
                    singleLoadThunk={loadSingleContactRoom}
                    onItemClick={handleNavigateToOpportunity}
                />
            </div>
        </div>
    );
}
