import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Notification from "./Notification";
import { approveNotification, deleteNotificationById, loadNotificationsByRecipientId } from "../../redux/rtk/features/notification/notificationSlice";
import getUserFromToken from "../../utils/getUserFromToken";


const NotificationList = () => {
    const dispatch = useDispatch();
    const recipientId = getUserFromToken();
    const { list, loading } = useSelector((state) => state.notification);
    const handleApprove = (notificationId) => {
        dispatch(approveNotification(notificationId));
        dispatch(loadNotificationsByRecipientId(recipientId));
    }

    const handleDelete = (notificationId) => {
        dispatch(deleteNotificationById(notificationId))
            .then(() => {
                dispatch(loadNotificationsByRecipientId(recipientId));
            });
    };

    if (loading) {
        return <Spin className="flex justify-center items-center" />
    }

    return (
        <div className="absolute right-0 mt-2 w-80 h-max-96 overflow-y-scroll bg-white shadow-lg rounded-md p-4 z-50">
            {list.length > 0 ? (
                list.map((notification) => (
                    <Notification key={notification.id} notification={notification} onApprove={handleApprove} onDelete={handleDelete} />
                ))
            ) : (<p>Nu aveți notificări noi</p>)}
        </div>
    );
};

export default NotificationList;