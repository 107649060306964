import {
	Button,
	Card,
	Dropdown,
	Form,
	Image,
	Input,
	Modal,
	Select,
	Skeleton,
} from "antd";
import { useEffect, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	deleteCompany,
	loadAllCompanyPaginated,
	loadSinglCompany,
	updateCompany,
} from "../../../../redux/rtk/features/crm/company/companySlice";
import { loadAllStaff } from "../../../../redux/rtk/features/user/userSlice";
import getPermissions from "../../../../utils/getPermissions";
import ImageUpload from "../../CommonUi/ImageUpload";
import RemoveImage from "../../CommonUi/RemoveImage";
import getImageUrl from "../../../../utils/getimageUrl";
import { TEXTS } from "../../../../constants";

export default function CompanyProfile({ data, loading }) {
	const [form] = Form.useForm();
	const [triggerSave, setTriggerSave] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [visibleImage, setVisibleImage] = useState(false);
	const { list: ownerList, loading: ownerLoading } = useSelector(
		(state) => state.users
	);
	const { CompanyId: id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const onDelete = async () => {
		var result = window.confirm(`${TEXTS.TEXT.ON_DELETE_TEXT}`);
		if (result) {
			const resp = await dispatch(deleteCompany(id));
			if (resp.payload.message === "success") {
				navigate(-1);
				dispatch(loadAllCompanyPaginated({}));
			}
		}
	};

	const itemsProfile = [
		data?.image && {
			key: "1",
			label: <button onClick={() => setVisibleImage(false)}>{TEXTS.TEXT.VIEW} {TEXTS.TEXT.IMAGE}</button>,
		},
		{
			key: "2",
			label: <button onClick={showModal}>{TEXTS.BUTTON_TEXT.UPLOAD} {TEXTS.TEXT.IMAGE}</button>,
		},
		data?.image && {
			key: "3",
			label: (
				<RemoveImage
					id={id}
					loadThunk={loadSinglCompany}
					updateThunk={updateCompany}
				/>
			),
		},
	];

	// company profile edit form
	const permissions = getPermissions();
	const canEdit = permissions?.includes("update-company");
	const onFinish = async (values) => {
		const resp = await dispatch(updateCompany({ id, values }));
		if (resp.payload.message === "success") {
			dispatch(loadSinglCompany(id));
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	useEffect(() => {
		dispatch(loadAllStaff({ status: true }));
	}, [dispatch]);

	return (
		<>
			<Skeleton loading={loading} active>
				{data && (
					<Card headStyle={{ display: "none" }} bodyStyle={{ padding: 0 }}>
						<Form
							form={form}
							colon={false}
							disabled={!canEdit}
							labelCol={{
								span: 2,
							}}
							wrapperCol={{
								span: 8,
							}}
							layout='inline'
							onFieldsChange={() => setTriggerSave(true)}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							initialValues={{
								companyOwnerId: data.companyOwnerId,
								companyName: data?.companyName,
								website: data?.website,
								phone: data?.phone,
								email: data?.email,
							}}>
							<div className='w-full'>
								<div className='flex justify-between items-center px-5 p-3 border-b'>
									<div className='flex items-end gap-5'>
										<div className='w-16 h-16 flex items-center justify-center rounded-full bg-slate-600 group relative'>
											{data.image && (
												<img
													className='w-full overflow-hidden rounded-full select-none'
													src={`${getImageUrl(data.image)}`}
													alt='logo'
												/>
											)}
											<Dropdown
												className='bg-blue-600'
												menu={{
													items: itemsProfile,
												}}
												placement='bottomLeft'
												arrow={{
													pointAtCenter: true,
												}}
												trigger={["click"]}>
												<div className='w-16 h-16 rounded-full bg-blue-600 cursor-pointer group hidden group-hover:flex absolute justify-center items-center'>
													<FaPencilAlt className='text-white text-xl' />
												</div>
											</Dropdown>
										</div>
										<div className='flex flex-col dark:text-white'>
											<span className='font-bold'>{data.companyName}</span>
											{data.website && (
												<Link target='_blank' to={data.website}>
													{data.website}
												</Link>
											)}
											{data.phone}
										</div>
									</div>
									<div className='flex items-center gap-2'>
										{triggerSave && (
											<Form.Item>
												<div className='flex items-center gap-4'>
													<Button type='primary' htmlType='submit'>
														{TEXTS.BUTTON_TEXT.SAVE_BTN}
													</Button>
													<Button
														loading={loading}
														type='danger'
														onClick={onFinishFailed}>
														{TEXTS.BUTTON_TEXT.CLOSE_BTN}
													</Button>
												</div>
											</Form.Item>
										)}
										<Button danger onClick={onDelete}>
											{TEXTS.TEXT.DELETE}
										</Button>
									</div>
								</div>
								<div className='flex flex-col gap-2 p-3'>
									<Form.Item
										className='flex flex-col'
										label={TEXTS.LABELS.COMPANY_OWNER}
										name={"companyOwnerId"}>
										<Select
											bordered={false}
											loading={ownerLoading}
											className='md:ml-5'>
											{ownerList.map((item) => (
												<Select.Option key={item.id} value={item.id}>
													{item?.firstName} {item?.lastName}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item label={`${TEXTS.LABELS.LAST_NAME} ${TEXTS.LABELS.COMPANY}`} name='companyName'>
										<Input
											bordered={false}
											suffix={<BsFillPencilFill />}
											className='md:ml-5'
										/>
									</Form.Item>
									<Form.Item label='Website' name='website'>
										<Input
											bordered={false}
											suffix={<BsFillPencilFill />}
											className='md:ml-5'
										/>
									</Form.Item>

									<Form.Item label='Email' name={"email"}>
										<Input
											bordered={false}
											suffix={<BsFillPencilFill />}
											className='md:ml-5'
										/>
									</Form.Item>

									<Form.Item label={TEXTS.LABELS.PHONE} name={"phone"}>
										<Input
											bordered={false}
											suffix={<BsFillPencilFill />}
											className='md:ml-5'
										/>
									</Form.Item>
								</div>
							</div>
						</Form>
						<Modal
							title={`${TEXTS.BUTTON_TEXT.UPLOAD} ${TEXTS.LABELS.PHOTO}`}
							open={isModalOpen}
							onOk={handleOk}
							onCancel={handleCancel}>
							<ImageUpload
								handleCancel={handleCancel}
								id={id}
								loadThunk={loadSinglCompany}
								updateThunk={updateCompany}
							/>
						</Modal>
						<Image
							style={{
								display: "none",
							}}
							src={`${getImageUrl(data?.image)}`}
							preview={{
								visible: visibleImage,
								scaleStep: 1,
								src: `${getImageUrl(data?.image)}`,
								onVisibleChange: (value) => {
									setVisibleImage(value);
								},
							}}
						/>
					</Card>
				)}
			</Skeleton>
		</>
	);
}
