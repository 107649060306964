import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    list: [],
    reparation: null,
    error: "",
    loading: false,
}


export const loadAllReparations = createAsyncThunk(
    "contact/loadAllReparations",
    async () => {
        try {
            const { data } = await axios.get(`contact/rep`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

const reparationSlice = createSlice({
    name: "reparation",
    initialState,
    reducers: {
        clearReparation: (state) => {
            state.reparation = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadAllReparations.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllReparations.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(loadAllReparations.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    }
});

export default reparationSlice.reducer;
export const { clearReparation } = reparationSlice.actions;