import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllContactPaginated } from "../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllContactRooms } from "../../../redux/rtk/features/crm/contactRoom/contactRoomSlice";
import { loadAllOpportunity } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { Form, Select, Button, Input } from "antd";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import saveAs from "file-saver";
import { TEXTS } from "../../../constants";


export default function CreatePredatPreluatForm({ onClose, createAs }) {
    const [form] = Form.useForm();
    const { list: contactList, loading: contactLoading } = useSelector((state) => state.contact);
    const { list: contactRoomList, loading: contactRoomLoading } = useSelector((state) => state.contactRoom);
    const { list: opportunityList, loading: opportunityLoading } = useSelector((state) => state.opportunity);
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedContactRooms, setSelectedContactRooms] = useState([]);
    const [selectedOpportunities, setSelectedOpportunities] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('ro')
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAllContactPaginated({ status: true, count: 999 }));
        dispatch(loadAllContactRooms());
        dispatch(loadAllOpportunity());
    }, [dispatch]);

    useEffect(() => {
        if (createAs) {
            const { name, value } = createAs;
            form.setFieldsValue({ [name]: value });

            if (name === 'contactId') {
                const initialContact = contactList.find(contact => contact.id === value);
                setSelectedContact(initialContact);
                if (initialContact) {
                    const initialContactRooms = contactRoomList.filter(room => room?.contact?.id === initialContact?.id);
                    const initialOpportunities = opportunityList.filter(opp => initialContactRooms.some(room => room.id === opp?.contactRoom?.id));
                    form.setFieldsValue({ contactRoomIds: initialContactRooms.map(room => room.id), opportunityIds: initialOpportunities.map(opp => opp.id) });
                    setSelectedContactRooms(initialContactRooms);
                    setSelectedOpportunities(initialOpportunities);
                }
            }
        }
    }, [createAs, contactList, contactRoomList, opportunityList, form]);

    const onFinish = async (values) => {
        const contact = contactList.find(contact => contact.id === values.contactId);
        setSelectedContact(contact);

        if (contact) {
            const selectedContactRooms = contactRoomList.filter(room => values.contactRoomIds.includes(room.id));
            const selectedOpportunities = opportunityList.filter(opp => values.opportunityIds.includes(opp.id));

            try {
                // Fetch and process the template
                const templatePath = selectedLanguage === 'ro'
                    ? '/template_RO.docx'
                    : '/template_ru.docx';
                const response = await fetch(templatePath);
                const templateArrayBuffer = await response.arrayBuffer();
                const zip = new PizZip(templateArrayBuffer);
                const doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });

                // Prepare data for template
                const items = selectedOpportunities.map((opp, index) => ({
                    nr: index + 1,
                    opportunityName: opp.opportunityName,
                    contactRoom: selectedContactRooms.find(room => room.id === opp.contactRoom.id)?.room.roomName
                }));
                doc.setData({
                    nume: contact.lastName,
                    prenume: contact.firstName,
                    buc: 1,
                    idnp: values.idnp,
                    address: contact.permanentAddress,
                    phone: contact.phone,
                    items: items
                });

                doc.render();

                // Generate and save the document
                const out = doc.getZip().generate({
                    type: 'blob',
                    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });
                saveAs(out, `${contact.firstName} ${contact.lastName}_predat_preluat.docx`);
                form.resetFields();
                onClose();

            } catch (error) {
                console.error('Error generating DOC', error);
            }
        }
    }

    const onCancel = () => {
        form.resetFields();
        onClose();
    }

    return (
        <div className="flex justify-center mt-5">
            <Form
                className="w-4/5"
                colon={false}
                layout="vertical"
                form={form}
                initialValues={
                    createAs
                        ? {
                            [createAs?.name]: createAs?.value,
                        }
                        : {}
                }
                onFinish={onFinish}
            >
                <div className="w-1/2">
                    <Form.Item
                        style={{ width: '100%' }}
                        label={TEXTS.LABELS.CONTACT}
                        name={"contactId"}
                        rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE}` }]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            loading={contactLoading}
                            placeholder={`${TEXTS.PLACEHOLDERS.CONTACT_PLACEHOLDER}`}
                            onChange={(value) => {
                                setSelectedContact(contactList.find(contact => contact.id === value));
                                form.setFieldsValue({ contactRoomIds: [], opportunityIds: [] }); // Clear selected contact rooms and opportunities
                            }}
                            disabled={!!(createAs?.name === "contactId")}
                        >
                            {contactList?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.firstName} {item.lastName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="w-1/2">
                    <Form.Item
                        style={{ width: '100%' }}
                        label={TEXTS.LABELS.CONTACT_ROOM}
                        name={"contactRoomIds"}
                        rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE}` }]}
                    >
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Selectează camere client"
                            loading={contactRoomLoading}
                            onChange={(value) => {
                                setSelectedContactRooms(contactRoomList.filter(room => value.includes(room?.id)));
                                const relatedOpportunities = opportunityList.filter(opp => value.includes(opp?.contactRoom?.id));
                                setSelectedOpportunities(relatedOpportunities);
                                form.setFieldsValue({ opportunityIds: relatedOpportunities.map(opp => opp.id) });
                            }}
                        >
                            {contactRoomList?.filter(room => room?.contactId === selectedContact?.id).map((room) => (
                                <Select.Option key={room.id} value={room.id}>
                                    {room.room.roomName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="w-1/2">
                    <Form.Item
                        style={{ width: '100%' }}
                        label={TEXTS.LABELS.OPPORTUNITIES}
                        name={"opportunityIds"}
                        rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE}` }]}
                    >
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Selectează proiecte"
                            loading={opportunityLoading}
                            disabled={selectedContactRooms?.length === 0} // Disable if no contact rooms are selected
                        >
                            {selectedOpportunities.map((opp) => (
                                <Select.Option key={opp.id} value={opp.id}>
                                    {opp?.opportunityName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <Form.Item
                    style={{ width: '100%' }}
                    label={"IDNP"}
                    name={"idnp"}
                    rules={[{ required: true, message: 'Introduceți IDNP' },
                    { len: 13, message: 'IDNP trebuie sa fie exact 13 cifre' }
                    ]}
                >
                    <Input placeholder="Introduceți IDNP-ul" />
                </Form.Item>
                <div className="w-1/2 mb-4">
                    <Form.Item
                        label="Limba"
                        name="language"
                    >
                        <Select
                            style={{ width: "100%" }}
                            value={selectedLanguage}
                            onChange={(value) => setSelectedLanguage(value)}
                        >
                            <Select.Option value="ro">RO</Select.Option>
                            <Select.Option value="ru">RU</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <Form.Item>
                    <div className="flex items-center gap-2">
                        <Button type="primary" htmlType="submit" size={"large"}>
                            Generează DOC
                        </Button>
                        <Button
                            size={"large"}
                            type="danger"
                            onClick={onCancel}>
                            Închide
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}