import { Form, Input, Select, Button } from "antd";
import { TEXTS } from "../../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { saveAs } from 'file-saver';
import { loadAllCompanyPaginated } from "../../../../../redux/rtk/features/crm/company/companySlice";
import { addSingleContract, loadAllContract } from "../../../../../redux/rtk/features/crm/contract/contractSlice";
import fontkit from '@pdf-lib/fontkit';

export default function CreateContractForm({ onClose, createAs }) {
    const [form] = Form.useForm();

    const { list: companyList, loading: companyLoading } = useSelector((state => state.company));
    const { list: contractList, loading: contractLoading } = useSelector((state => state.contract));
    const dispatch = useDispatch();

    useEffect(() => {
        if (companyLoading || contractLoading) return;

        dispatch(loadAllCompanyPaginated({ status: true, count: 999 }))
        dispatch(loadAllContract());
    }, [dispatch]);

    const generatePDF = async (company, idnp, price, financialGarancy, beneficiar, contractId) => {
        const url = '/Default_Pers_Contract.pdf';
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(fontkit);
        const helveticaFontBytes = await fetch('/Roboto/Roboto-Regular.ttf').then(res => res.arrayBuffer());
        const helveticaFont = await pdfDoc.embedFont(helveticaFontBytes);
        const timesBoldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

        // Get the first page of the document
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const secondPage = pages[1];
        const lastPage = pages[6];

        const currentDate = new Date().toLocaleDateString();
        firstPage.drawText(`${contractId}`, {
            x: 265,
            y: 578,
            size: 12,
            font: helveticaFont,
        });
        firstPage.drawText(`${currentDate}`, {
            x: 318,
            y: 578,
            size: 12,
            font: helveticaFont,
        });
        // Draw the full name on the first page
        firstPage.drawText(`${company.companyName}`, {
            x: 125,
            y: 445,
            size: 12,
            font: helveticaFont,
        });

        firstPage.drawText(`${idnp}`, {
            x: 395,
            y: 445,
            size: 12,
            font: helveticaFont,
        });
        firstPage.drawText(`${company.billingStreet}`, {
            x: 345,
            y: 428,
            size: 12,
            font: helveticaFont,
        });
        firstPage.drawText(`${company.billingStreet}`, {
            x: 190,
            y: 396,
            size: 12,
            font: helveticaFont,
        });
        firstPage.drawText(`${company.phone}`, {
            x: 185,
            y: 412,
            size: 12,
            font: helveticaFont,
        });
        secondPage.drawText(`${price}`, {
            x: 175,
            y: 530,
            size: 12,
            font: helveticaFont,
        });
        secondPage.drawText(`${financialGarancy}`, {
            x: 172,
            y: 418,
            size: 12,
            font: helveticaFont,
        });
        secondPage.drawText(`${beneficiar}`, {
            x: 374,
            y: 345,
            size: 12,
            font: helveticaFont,
        });
        secondPage.drawText(`${100 - financialGarancy - beneficiar}`, {
            x: 185,
            y: 318,
            size: 12,
            font: helveticaFont,
        });
        lastPage.drawText(`${company.companyName}`.toUpperCase(), {
            x: 305,
            y: 350,
            size: 12,
            font: timesBoldFont,
        });
        lastPage.drawText(`${company.website}`, {
            x: 325,
            y: 336,
            size: 12,
            font: helveticaFont,
        });
        lastPage.drawText(`${company.billingStreet}`, {
            x: 400,
            y: 322,
            size: 12,
            font: helveticaFont,
        });
        lastPage.drawText(`${company.twitter}`, {
            x: 340,
            y: 308,
            size: 12,
            font: helveticaFont,
        });
        lastPage.drawText(`${company.facebook}`, {
            x: 355,
            y: 294,
            size: 12,
            font: helveticaFont,
        });
        lastPage.drawText(`${company.instagram}`, {
            x: 365,
            y: 280,
            size: 12,
            font: helveticaFont,
        });
        lastPage.drawText(`${company.phone}`, {
            x: 325,
            y: 252,
            size: 12,
            font: helveticaFont,
        });
        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Download the generated PDF
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        saveAs(blob, `${company.companyName}_juridic_contract.pdf`);
        return blob;
    }

    const onFinish = async (values) => {
        const selectedCompany = companyList.find(company => company.id === values.companyId);
        if (selectedCompany) {
            const newContractId = contractList.length === 0 ? 1 : contractList[contractList.length - 1].id + 1;
            const pdfBlob = await generatePDF(selectedCompany, values.idnp, values.price, values.financialGarancy, values.beneficiar, newContractId);
            const formData = new FormData();
            const price = parseInt(values.price.match(/\d+/)[0], 10);
            formData.append('companyId', values.companyId);
            formData.append('sum', price);
            formData.append('avansPercent', values.financialGarancy);
            formData.append('contractFile', pdfBlob, `${selectedCompany.companyName}_juridic_contract.pdf`);
            dispatch(addSingleContract(formData));
        }
        onClose();
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    }

    return (
        <div className="flex justify-center mt-5">
            <Form
                className="w-4/5"
                colon={false}
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <div className="w-1/2">
                    <Form.Item
                        style={{ width: "100%" }}
                        label="Nume Persoană Juridică"
                        name={"companyId"}
                        rules={[{ required: true, message: 'Selectați persoana juridică' }]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            placeholder={`${TEXTS.PLACEHOLDERS.COMPANY_PLACEHOLDER}`}
                        >
                            {companyList?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.companyName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="w-1/2">
                    <Form.Item
                        style={{ width: "100%" }}
                        label="IDNP"
                        name={"idnp"}
                        rules={[{ required: true, message: 'Introduceți IDNP' },
                        { len: 13, message: 'IDNP trebuie sa fie exact 13 cifre' }
                        ]}
                    >
                        <Input placeholder="Introduceți IDNP" />
                    </Form.Item>
                </div>
                <div className="w-1/2">
                    <Form.Item
                        style={{ width: "100%" }}
                        label="Retribuție( preț )"
                        name={"price"}
                        rules={[{ required: true, message: 'Introdu prețul' }]}
                    >
                        <Input placeholder="5000 (cinci mii)" />
                    </Form.Item>
                </div>
                <div className="flex justify-between gap-5">
                    <Form.Item
                        style={{ width: "100%" }}
                        label="Garanția Financiara(%)"
                        name={"financialGarancy"}
                        rules={[{ required: true, message: 'Introdu garanția' }]}
                    >
                        <Input placeholder="15" />
                    </Form.Item>
                    <Form.Item
                        style={{ width: "100%" }}
                        label="Procente Beneficiar(%)"
                        name={"beneficiar"}
                        rules={[{ required: true, message: 'Introdu procente beneficiar' }]}
                    >
                        <Input placeholder="15" />
                    </Form.Item>
                </div>
                <Form.Item>
                    <div className="flex items-center gap-2">
                        <Button type="primary" htmlType="submit" size={"large"}>
                            Generează PDF
                        </Button>
                        <Button
                            size={"large"}
                            type="danger"
                            onClick={onCancel}>
                            Închide
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}
