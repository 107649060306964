import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    partner: null,
    error: "",
    loading: false,
}

export const addSinglePartner = createAsyncThunk(
    "partner/addSinglePartner",
    async (values) => {
        try {
            const { data } = await axios({
                method: "post",
                url: `partner/`,
                data: {
                    ...values,
                },
            });
            toast.success("Partner Added");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in adding partner");
            console.log(error.message)
            return {
                message: "error",
            };
        }
    }
);

export const deletePartner = createAsyncThunk(
    "partner/deletePartner",
    async (id, api) => {
        try {
            const resp = await axios({
                method: "delete",
                url: `partner/${id}`,
            });

            api.dispatch(loadAllPartners());
            toast.success("Partner Deleted");
            return {
                data: resp.data.id,
                message: "success",
            };
        } catch (error) {
            toast.error("Error while deleting partner");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

export const loadSinglePartner = createAsyncThunk(
    "partner/loadSinglePartner",
    async (id) => {
        try {
            const data = await axios.get(`partner/${id}`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const loadAllPartners = createAsyncThunk(
    "partner/loadAllPartners",
    async () => {
        try {
            const { data } = await axios.get(`partner`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const updatePartner = createAsyncThunk(
    "partner/updatePartner",
    async ({ id, values }) => {
        try {
            const { data } = await axios({
                method: "put",
                url: `partner/${id}`,
                data: {
                    ...values,
                }
            });
            toast.success("Partner Updated");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error while updating Partner");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);


const partnerSlice = createSlice({
    name: "partner",
    initialState,
    reducers: {
        clearPartner: (state) => {
            state.partner = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadAllPartners.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllPartners.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(loadAllPartners.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });


        builder.addCase(loadSinglePartner.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadSinglePartner.fulfilled, (state, action) => {
            state.loading = false;
            state.partner = action.payload.data;
        });

        builder.addCase(loadSinglePartner.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(updatePartner.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updatePartner.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(updatePartner.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(addSinglePartner.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addSinglePartner.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(addSinglePartner.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(deletePartner.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deletePartner.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(deletePartner.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    }
});

export default partnerSlice.reducer;
export const { clearPartner } = partnerSlice.actions;