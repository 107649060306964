import { Drawer } from "antd";
import CreateContactStageForm from "./CreateContactStageForm";
import { TEXTS } from "../../../../../constants";
export default function CreateContactStage({ open, onClose }) {
  return (
    <Drawer
      width={450}
      title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.CONTACT_STAGE}`}
      placement='right'
      onClose={onClose}
      open={open}
    >
      <CreateContactStageForm onClose={onClose} />
    </Drawer>
  );
}
