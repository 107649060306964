import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    list: [],
    opportunityItem: null,
    error: "",
    loading: false,
};

export const getOpportunityItemsByOpportunity = createAsyncThunk(
    "opportunityItem/getOpportunityItemsByOpportunity",
    async (opportunityId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/opportunity-item/opportunity/${opportunityId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createOpportunityItems = createAsyncThunk(
    "opportunityItem/createaOpportuityItems",
    async ({ roomItemIds, opportunityId }, { rejectWithValue }) => {
        try {
            const response = await axios.post("/opportunity-item", { roomItemIds, opportunityId });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateOpportunityItem = createAsyncThunk(
    'opportunityItems/updateOpportunityItem',
    async ({ id, roomItemId }, { rejectWithValue }) => {
        try {
            const response = await axios.put(`/opportunity-item/${id}`, { roomItemId });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteOpportunityItem = createAsyncThunk(
    'opportunityItems/deleteOpportunityItem',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`/opportunity-item/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const opportunityItemSlice = createSlice({
    name: "opportunityItem",
    initialState,
    reducers: {
        clearOpportunityItem: (state) => {
            state.opportunityItem = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOpportunityItemsByOpportunity.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getOpportunityItemsByOpportunity.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(getOpportunityItemsByOpportunity.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });


        builder.addCase(createOpportunityItems.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(createOpportunityItems.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(createOpportunityItems.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });


        builder.addCase(updateOpportunityItem.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateOpportunityItem.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(updateOpportunityItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });


        builder.addCase(deleteOpportunityItem.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteOpportunityItem.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(deleteOpportunityItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    }
});

export default opportunityItemSlice.reducer;