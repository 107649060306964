import { Button, Card, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { BiEdit, BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
import CreateQuoteForm from "../Quotes/CreateQuoteForm";
import CreateDrawer from "./CreateDrawer";
import { TEXTS } from "../../../constants";
import UpdateDrawer from "./UpdateDrawer";

export default function Quotes({ data, loading, name, singleLoadThunk }) {
	// Drawer state
	const [open, setOpen] = useState(false);
	const [edit, setEdit] = useState(false);

	const onClose = () => {
		setOpen(false);
	};
	const onCloseEdit = () => setEdit(false);

	const columns = [
		{
			title: `${TEXTS.LABELS.LAST_NAME}`,
			key: "quoteName",
			render: ({ quoteName, id }) =>
				id ? <Link to={`/admin/quote/${id}`} state={{ fromOpportunity: data.id }}>{quoteName}</Link> : "-",
			sorter: (a, b) => a.quoteName.localeCompare(b.quoteName),
		},
		{
			title: `${TEXTS.LABELS.QUOTATION_DATE}`,
			dataIndex: "quotationDate",
			render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
		},
		{
			title: `${TEXTS.LABELS.EXPIRATION_DATE}`,
			dataIndex: "expirationDate",
			render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
		},
		{
			title: `${TEXTS.LABELS.TOTAL_AMMOUNT}`,
			dataIndex: "totalAmount",
			key: "totalAmount",
			render: (total) => (total ? total : "-"),
		},
		{
			title: `${TEXTS.LABELS.CREATE_DATE}`,
			dataIndex: "createdAt",
			render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
		},
		{
			title: `${TEXTS.LABELS.ACTIONS}`,
			key: "actions",
			render: (quote) => (
				<span
					onClick={() => setEdit(quote)}
					className="bg-teal-500 p-1 cursor-pointer w-8 h-8 flex justify-center items-center rounded"
				>
					<BiEdit className="text-white" />
				</span>
			),
		},
	];

	return (
		<Card
			title={<span className='font-bold'>{TEXTS.LABELS.QUOTES}</span>}
			bodyStyle={{ padding: 0 }}
			extra={
				<UserPrivateComponent permission='create-quote'>
					<Button
						onClick={() => setOpen(true)}
						className='flex items-center'
						icon={<BiPlus />}>
						{TEXTS.BUTTON_TEXT.ADD_BTN}
					</Button>
				</UserPrivateComponent>
			}>
			<div>
				<UserPrivateComponent permission='readAll-quote'>
					<Table
						bordered
						columns={columns}
						loading={loading}
						dataSource={data ? data.quote : []}
						pagination={{ hideOnSinglePage: true }}
						scroll={{ x: 800, y: 500 }}
					/>
				</UserPrivateComponent>
			</div>

			<UserPrivateComponent permission='create-quote'>
				<CreateDrawer onClose={onClose} open={open} title={TEXTS.LABELS.QUOTE}>
					<CreateQuoteForm
						onClose={onClose}
						open={open}
						createAs={{ name, value: data?.id, singleLoadThunk }}
						contactId={data?.contact?.id}
					/>
				</CreateDrawer>
			</UserPrivateComponent>
			<UpdateDrawer onClose={onCloseEdit} open={!!edit} title={`${TEXTS.LABELS.QUOTE}`}>
				<CreateQuoteForm
					onClose={onCloseEdit}
					open={!!edit}
					edit={edit}
					singleLoad={{ thunk: singleLoadThunk, id: data?.id, quoteProduct: data?.quote?.quoteProduct }}
				/>
			</UpdateDrawer>
		</Card>
	);
}
