import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Collapse, Spin } from "antd";
import { loadAllFailureCausesCounts } from "../../../redux/rtk/features/crm/causeOfFailure/causeOfFailureSlice";

const { Panel } = Collapse;

const FailureCauses = () => {
    const dispatch = useDispatch();
    const { list: failureList, loading: failureLoading } = useSelector((state) => state.failureCause);

    useEffect(() => {
        dispatch(loadAllFailureCausesCounts());
    }, [dispatch]);

    return (
        <div>
            {failureLoading ? (
                <Spin />
            ) : (
                <Card title="Cauze de Eșec" style={{ width: '100%' }} className="mb-4">
                    <Collapse accordion>
                        {failureList?.map((failure) => (
                            <Panel
                                header={
                                    <div className="flex justify-between" style={{ width: '100%' }}>
                                        <span className="font-semibold">{failure.cause}</span>
                                        <span className="ml-8">{failure.contactCount || 0}</span>
                                    </div>
                                }
                                key={failure.id}
                            >
                                <ul>
                                    {failure.contacts?.map((contact, index) => (
                                        <li key={index}>
                                            {contact.firstName} {contact.lastName}
                                        </li>
                                    ))}
                                </ul>
                            </Panel>
                        ))}
                    </Collapse>
                </Card>
            )}
        </div>
    );
};

export default FailureCauses;
