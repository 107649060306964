import { Drawer } from "antd";
import CreateProductForm from "./CreateProductForm";
import { TEXTS } from "../../../constants";

export default function CreateProduct({ open, onClose }) {
  return (
    <Drawer
      width={450}
      title={`${TEXTS.TEXT.CREATE} ${TEXTS.LABELS.PRODUCT}`}
      placement='right'
      onClose={onClose}
      open={open}
    >
      <CreateProductForm onClose={onClose} />
    </Drawer>
  );
}
