import {
  Button,
  Card,
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  Skeleton,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { loadAllContactStage } from "../../../../redux/rtk/features/crm/ContactStage/contactStageSlice";
import { loadAllCompany } from "../../../../redux/rtk/features/crm/company/companySlice";
import {
  loadSingleContact,
  updateContact,
} from "../../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllContactSource } from "../../../../redux/rtk/features/crm/contactSource/contactSourceSlice";
import { loadAllIndustry } from "../../../../redux/rtk/features/crm/industry/industrySlice";
import { loadAllStaff } from "../../../../redux/rtk/features/user/userSlice";
import getPermissions from "../../../../utils/getPermissions";
import { TEXTS } from "../../../../constants";

export default function DetailsInfo({ contact, contactLoading }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [triggerSave, setTriggerSave] = useState(false);

  const { list: companyList, loading: companyLoading } = useSelector(
    (state) => state.company
  );
  const { list: contactSourceList, loading: contactSourceLoading } =
    useSelector((state) => state.contactSource);
  const { list: contactStageList, loading: contactStageLoading } = useSelector(
    (state) => state.contactStage
  );
  const { list: industryList, loading: industryLoading } = useSelector(
    (state) => state.industry
  );

  // contact profile edit form
  const permissions = getPermissions();
  const canEdit = permissions?.includes("update-contact");

  const hasRequiredAttachments = contact?.attachment?.some(
    (att) => att.attachmentTypeId === 2
  ) && contact?.attachment?.some(
    (att) => att.attachmentTypeId === 3
  ) && contact?.attachment?.some(
    (att) => att.attachmentTypeId === 4
  );

  const onFinish = async (values) => {
    const formData = {
      ...values,
      dateOfBirth: values.dateOfBirth,
      companyId: parseInt(values.companyId) || null,
      industryId: parseInt(values.industryId) || null,
      contactSourceId: parseInt(values.contactSourceId),
      contactStageId: parseInt(values.contactStageId),
    };
    const resp = await dispatch(
      updateContact({ id: contact.id, values: formData })
    );
    if (resp.payload.message === "success") {
      dispatch(loadSingleContact(contact.id));
      setTriggerSave(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setTriggerSave(false);
    form.resetFields();
  };

  const onDisabledSelectClick = () => {
    alert("Nu e posibil să schimbi etapa fără contract, proiect2d și proiect3d");
  }

  useEffect(() => {
    dispatch(loadAllContactSource());
    dispatch(loadAllCompany());
    dispatch(loadAllContactStage());
    dispatch(loadAllIndustry());
    dispatch(loadAllStaff({ status: true }));
  }, [dispatch]);
  return (
    <>
      <Skeleton loading={contactLoading} active>
        <Card
          bordered={false}
          headStyle={{ display: "none" }}
          bodyStyle={{
            padding: 0,
          }}
        >
          {contact && (
            <Form
              form={form}
              colon={false}
              disabled={!canEdit}
              labelCol={{
                xs: {
                  span: 24,
                },
                sm: {
                  span: 8,
                },
              }}
              wrapperCol={{
                xs: {
                  span: 24,
                },
                sm: {
                  span: 16,
                },
              }}
              layout="inline"
              onFieldsChange={() => setTriggerSave(true)}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{
                dateOfBirth: moment(contact?.dateOfBirth) || "",
                companyId: contact?.companyId || "",
                industryId: contact?.industryId || "",
                contactStageId: contact?.contactStageId || "",
                contactSourceId: contact?.contactSourceId || "",
                department: contact?.department || "",
                linkedin: contact?.linkedin || "",
                twitter: contact?.twitter || "",
                presentAddress: contact?.presentAddress || "",
                presentCity: contact?.presentCity || "",
                presentZipCode: contact?.presentZipCode || "",
                presentState: contact?.presentState || "",
                presentCountry: contact?.presentCountry || "",
                permanentAddress: contact?.permanentAddress || "",
                permanentCity: contact?.permanentCity || "",
                permanentZipCode: contact?.permanentZipCode || "",
                permanentState: contact?.permanentState || "",
                permanentCountry: contact?.permanentCountry || "",
                description: contact?.description || "",
              }}
            >
              <Collapse
                className="bg-transparent w-full"
                bordered={false}
                defaultActiveKey={["1", "2"]}
              >
                <Collapse.Panel
                  header={
                    <span className="font-bold text-md dark:text-white">
                      {TEXTS.LABELS.DETAILS}
                    </span>
                  }
                  key="1"
                  extra={
                    <>
                      {triggerSave && (
                        <Form.Item>
                          <div className="flex items-center gap-4">
                            <Button type="primary" htmlType="submit">
                              {TEXTS.BUTTON_TEXT.SAVE_BTN}
                            </Button>
                            <Button
                              loading={contactLoading}
                              type="danger"
                              onClick={onFinishFailed}
                            >
                              {TEXTS.BUTTON_TEXT.CLOSE_BTN}
                            </Button>
                          </div>
                        </Form.Item>
                      )}
                    </>
                  }
                >
                  <div className="grid xl:px-1">
                    <div className="w-full grid p-1">
                      <div className="text-lg font-bold py-2 border-b"></div>
                      <Form.Item label="Date of birth" name={"dateOfBirth"}>
                        <DatePicker format="YYYY-MM-DD HH:mm" bordered={false} className="md:ml-5"
                        />
                      </Form.Item>

                      <Form.Item
                        className="flex flex-col"
                        label={TEXTS.LABELS.COMPANY}
                        name={"companyId"}
                      >
                        <Select
                          bordered={false}
                          className="md:ml-5"
                          loading={companyLoading}
                        >
                          {companyList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item?.companyName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className="flex flex-col"
                        label={TEXTS.LABELS.STAGE}
                        name={"contactStageId"}
                      >
                        <Select
                          bordered={false}
                          className="md:ml-5"
                          loading={contactStageLoading}
                          disabled={!hasRequiredAttachments}
                          onClick={onDisabledSelectClick}
                        >
                          {contactStageList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item?.contactStageName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        className="flex flex-col"
                        label={TEXTS.TEXT.SOURCE}
                        name={"contactSourceId"}
                      >
                        <Select
                          bordered={false}
                          className="md:ml-5"
                          loading={contactSourceLoading}
                        >
                          {contactSourceList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item?.contactSourceName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="w-full xl:w-full flex flex-col gap-2 p-1 ">
                      <div className="text-lg font-bold py-2 border-b">
                        {TEXTS.LABELS.ADDRESS} {TEXTS.TEXT.PRESENT}
                      </div>
                      <Form.Item
                        label={`${TEXTS.LABELS.STREET} ${TEXTS.TEXT.PRESENT}`}
                        name={"presentAddress"}
                      >
                        <Input
                          bordered={false}
                          className="md:ml-5"
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={`${TEXTS.LABELS.CITY} ${TEXTS.TEXT.PRESENT1}`}
                        name={"presentCity"}
                      >
                        <Input
                          bordered={false}
                          className="md:ml-5"
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={`${TEXTS.LABELS.ZIP_CODE} ${TEXTS.TEXT.PRESENT1}`}
                        name={"presentZipCode"}
                      >
                        <Input
                          bordered={false}
                          className="md:ml-5"
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={`${TEXTS.LABELS.COUNTRY} ${TEXTS.TEXT.PRESENT}`}
                        name={"presentCountry"}
                      >
                        <Input
                          bordered={false}
                          className="md:ml-5"
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                    </div>

                    <div className="w-full xl:w-[50%]   gap-2 p-3 hidden">
                      <div className="text-lg font-bold py-2 border-b">
                        {TEXTS.LABELS.STREET} {TEXTS.TEXT.PERMANENT}
                      </div>
                      <Form.Item
                        label={`${TEXTS.LABELS.ADDRESS} ${TEXTS.TEXT.PERMANENT}`}
                        name={"permanentAddress"}
                      >
                        <Input
                          bordered={false}
                          className="md:ml-5"
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={`${TEXTS.LABELS.CITY} ${TEXTS.TEXT.PERMANENT1}`}
                        name={"permanentCity"}
                      >
                        <Input
                          bordered={false}
                          className="md:ml-5"
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={`${TEXTS.LABELS.ZIP_CODE} ${TEXTS.TEXT.PERMANENT1}`}
                        name={"permanentZipCode"}
                      >
                        <Input
                          bordered={false}
                          className="md:ml-5"
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={`${TEXTS.LABELS.STATE} ${TEXTS.TEXT.PERMANENT1}`}
                        name={"permanentState"}
                      >
                        <Input
                          bordered={false}
                          className="md:ml-5"
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={`${TEXTS.LABELS.COUNTRY} ${TEXTS.TEXT.PERMANENT}`}
                        name={"permanentCountry"}
                      >
                        <Input
                          bordered={false}
                          className="md:ml-5"
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={TEXTS.LABELS.DESCRPTION}
                        name={"description"}
                      >
                        <Input.TextArea
                          bordered={false}
                          className="md:ml-5"
                          suffix={<BsFillPencilFill />}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Form>
          )}
        </Card>
      </Skeleton >
    </>
  );
}
