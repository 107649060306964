import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    designer: null,
    error: "",
    loading: false,
}

export const addSingleDesigner = createAsyncThunk(
    "designer/addSingleDesigner",
    async (values) => {
        try {
            const { data } = await axios({
                method: "post",
                url: `designer/`,
                data: {
                    ...values,
                },
            });
            toast.success("Designer Added");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in adding designer");
            console.log(error.message)
            return {
                message: "error",
            };
        }
    }
);

export const deleteDesigner = createAsyncThunk(
    "designer/deleteDesigner",
    async (id, api) => {
        try {
            const resp = await axios({
                method: "delete",
                url: `designer/${id}`,
            });

            api.dispatch(loadAllDesigners());
            toast.success("Designer Deleted");
            return {
                data: resp.data.id,
                message: "success",
            };
        } catch (error) {
            toast.error("Error while deleting designer");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

export const loadSingleDesigner = createAsyncThunk(
    "designer/loadSingleDesigner",
    async (id) => {
        try {
            const data = await axios.get(`designer/${id}`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const loadAllDesigners = createAsyncThunk(
    "designer/loadAllDesigners",
    async () => {
        try {
            const { data } = await axios.get(`designer`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const updateDesigner = createAsyncThunk(
    "designer/updateDesigner",
    async ({ id, values }) => {
        try {
            const { data } = await axios({
                method: "put",
                url: `designer/${id}`,
                data: {
                    ...values,
                }
            });
            toast.success("Designer Updated");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error while updating Designer");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);


const designerSlice = createSlice({
    name: "designer",
    initialState,
    reducers: {
        clearDesigner: (state) => {
            state.designer = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadAllDesigners.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllDesigners.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(loadAllDesigners.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });


        builder.addCase(loadSingleDesigner.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadSingleDesigner.fulfilled, (state, action) => {
            state.loading = false;
            state.designer = action.payload.data;
        });

        builder.addCase(loadSingleDesigner.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(updateDesigner.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateDesigner.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(updateDesigner.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(addSingleDesigner.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addSingleDesigner.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(addSingleDesigner.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(deleteDesigner.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteDesigner.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(deleteDesigner.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    }
});

export default designerSlice.reducer;
export const { clearDesigner } = designerSlice.actions;