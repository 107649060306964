import moment from 'moment';

export function renderEventContent(eventInfo) {
    const eventType = eventInfo.event.extendedProps.type;
    const isPast = eventType === 'opportunity' && moment().isAfter(eventInfo.event.end);
    const deliveryDate = eventInfo.event.extendedProps.deliveryDate;

    const bgColorClass = isPast ? 'bg-red-500' : (eventType === 'opportunity' ? 'bg-gradient-opportunity' : 'bg-violet-700');
    const borderColorClass = isPast ? 'border-red-600' : (eventType === 'opportunity' ? 'border-neutral-50' : 'border-violet-800');

    const formattedDeliveryDate = moment(deliveryDate).isValid() ? moment(deliveryDate).format('YYYY-MM-DD') : 'Data Livrării nu este disponibilă';

    return (
        <div className={`on-hover-color w-full ${bgColorClass} ${borderColorClass}`}>
            {eventType === 'client' && <>{eventInfo.timeText}<br /></>}
            <b>{eventInfo.event.title}</b>
            {deliveryDate && (
                <div>
                    <small>Data Livrării: {formattedDeliveryDate}</small>
                </div>
            )}
        </div>
    )
}
