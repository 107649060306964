import { Table, Image } from "antd";
import moment from "moment";
import { useState } from "react";
import { TEXTS } from "../../../../constants";
const columns = [
  {
    title: `${TEXTS.LABELS.LAST_NAME}`,
    dataIndex: "product",
    render: (product) => product.productName,
  },
  {
    title: `${TEXTS.TEXT.UNIT_PRICE} (MDL)`,
    dataIndex: "unitPrice",
  },
  {
    title: `${TEXTS.TEXT.QUANTITY}`,
    dataIndex: "productQuantity",
  },
  {
    title: "Total",
    dataIndex: "productQuantity",
    render: (qyt, all) => qyt * all.unitPrice,
  },
  {
    title: `${TEXTS.LABELS.CREATE_DATE}`,
    dataIndex: "createdAt",
    render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
  },
  {
    title: `${TEXTS.LABELS.UPDATE_DATE}`,
    dataIndex: "updatedAt",
    render: (date) => moment(date).format("MMMM Do YYYY HH:mm"),
  },
  {
    title: `${TEXTS.LABELS.IMAGE}`,
    dataIndex: "product",
    render: (product) => <Image src={`data:image/jpeg;base64,${product.productImage}`} width={50} />,
  },
];

const TableComponent = ({ loading, productList }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className='mt-2'>
      <div className='text-center p-3 font-bold text-xl mt-10'>
        {TEXTS.LABELS.PRODUCT_LIST}
      </div>
      <div>
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selectat ${selectedRowKeys.length} itemi` : ""}
        </span>
      </div>
      <Table
        loading={loading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={productList?.map((item) => ({ ...item, key: item.id }))}
        pagination={false}
        scroll={{ x: 1300 }}
      />
    </div>
  );
};
export default TableComponent;
