import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    unit: null,
    error: "",
    loading: false,
};

// ADD_UNIT
export const addSingleUnit = createAsyncThunk(
    "unit/addSingleUnit",
    async (values) => {
        try {
            const { data } = await axios.post(`unit/`, values);
            toast.success("Unit Added");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in adding unit, try again");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

// DELETE_UNIT
export const deleteUnit = createAsyncThunk(
    "unit/deleteUnit",
    async (id, api) => {
        try {
            const resp = await axios.delete(`unit/${id}`);
            api.dispatch(loadAllUnits()); // Adjust as needed
            toast.success("Unit Deleted");
            return {
                data: resp.data.id,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in deleting unit, try again");
            console.log(error.message);
        }
    }
);

// UNIT_DETAILS
export const loadSingleUnit = createAsyncThunk(
    "unit/loadSingleUnit",
    async (id) => {
        try {
            const { data } = await axios.get(`unit/${id}`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

// UNITS
export const loadAllUnits = createAsyncThunk(
    "unit/loadAllUnits",
    async () => {
        try {
            const { data } = await axios.get(`unit`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

// UPDATE_UNIT
export const updateUnit = createAsyncThunk(
    "unit/updateUnit",
    async ({ id, values }) => {
        try {
            const { data } = await axios.put(`unit/${id}`, values);
            toast.success("Unit Updated");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in updating unit, try again");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

const unitSlice = createSlice({
    name: "unit",
    initialState,
    reducers: {
        clearUnit: (state) => {
            state.unit = null;
        },
    },
    extraReducers: (builder) => {
        // Builders for loadAllUnits
        builder.addCase(loadAllUnits.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllUnits.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(loadAllUnits.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message;
        });

        // Builders for addSingleUnit
        builder.addCase(addSingleUnit.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addSingleUnit.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(addSingleUnit.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message;
        });

        // Builders for loadSingleUnit
        builder.addCase(loadSingleUnit.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadSingleUnit.fulfilled, (state, action) => {
            state.loading = false;
            state.unit = action.payload;
        });

        builder.addCase(loadSingleUnit.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message;
        });

        // Builders for updateUnit
        builder.addCase(updateUnit.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateUnit.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(updateUnit.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message;
        });

        // Builders for deleteUnit
        builder.addCase(deleteUnit.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteUnit.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(deleteUnit.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message;
        });
    },
});

export default unitSlice.reducer;
export const { clearUnit } = unitSlice.actions;
