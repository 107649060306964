import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    offer: null,
    error: "",
    total: null,
    loading: false,
}

export const addSingleOffer = createAsyncThunk(
    "offer/createOffer",
    async (values) => {
        try {
            const { data } = await axios({
                method: "post",
                url: `offer`,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: values,
            });
            console.log(data);
            toast.success("Offer Added");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error in adding Offer");
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

export const deleteOffer = createAsyncThunk(
    "offer/deleteOffer",
    async (id) => {
        try {
            const resp = await axios({
                method: "delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `offer/${id}`,
            });

            toast.success("Offer Deleted");
            return {
                data: resp.data.id,
                message: "success",
            };
        } catch (error) {
            toast.error("Error while deleting Offer");
            console.log(error.message);
        }
    }
);

export const loadAllOffer = createAsyncThunk(
    'offer/loadAllOffer',
    async ({ firstName = '', lastName = '' } = {}, { rejectWithValue }) => {
        try {
            const response = await axios.get('offer', {
                params: {
                    firstName,
                    lastName,
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const offerSlice = createSlice({
    name: "offer",
    initialState,
    reducers: {
        clearOffer: (state) => {
            state.offer = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadAllOffer.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllOffer.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(loadAllOffer.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(addSingleOffer.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addSingleOffer.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(addSingleOffer.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    }
});

export default offerSlice.reducer;
export const { clearOffer } = offerSlice.actions;