import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, InputNumber, Card, Button, Select } from "antd";
import { loadAllContactPaginated } from "../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllOpportunityPaginated } from "../../../redux/rtk/features/crm/opportunity/opportunitySlice";
import { loadAllContract } from "../../../redux/rtk/features/crm/contract/contractSlice";
import axios from "axios";
import { toast } from "react-toastify";
import getCurrentUser from "../../user/getCurrentUserapi";
const { Option } = Select;

export default function Finances() {
    const [dataSource, setDataSource] = useState([]);
    const [fullDataSource, setFullDataSource] = useState([]); // State for full data
    const [spreadsheetData, setSpreadsheetData] = useState(null); // State for spreadsheet data
    const [currentUser, setCurrentUser] = useState(null);
    const { list: contacts, loading: contactLoading } = useSelector((state) => state.contact);
    const { list: opportunities, loading: opportunityLoading } = useSelector((state) => state.opportunity);
    const { list: contracts, loading: contractLoading } = useSelector((state) => state.contract);
    const [filter, setFilter] = useState('all');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAllContactPaginated({ status: true, count: 999 }));
        dispatch(loadAllOpportunityPaginated({ status: true, count: 999 }));
        dispatch(loadAllContract());
    }, [dispatch]);

    useEffect(() => {
        async function fetchUser() {
            const user = await getCurrentUser();
            setCurrentUser(user);
        }
        if (!currentUser) fetchUser();
    }, [currentUser]);
    useEffect(() => {
        const fetchSpreadsheetData = async () => {
            try {
                const response = await axios.get('/spreadsheet/full');
                if (response.status === 200 && response.data) {
                    setSpreadsheetData(response.data);
                } else {
                    // Set empty data if response data is null or empty
                    setSpreadsheetData([]);
                }
            } catch (error) {
                console.error('Failed to load spreadsheet data:', error);
                // Set empty data if there is an error fetching spreadsheet data
                setSpreadsheetData([]);
            }
        };

        fetchSpreadsheetData();
    }, [filter, contracts]);

    useEffect(() => {
        const generateDataSource = (contacts) => {
            return contacts
                .filter(contact => {
                    const hasContract = contracts.some(contract => contract.contactId === contact.id);
                    const departmentMatch = !currentUser || !currentUser.department ||
                        !contact.production || currentUser.role.name === "admin" ||
                        currentUser.department.name === contact.production.productionName;

                    return hasContract && departmentMatch;
                })
                .map((contact, index) => {
                    const contactOpportunities = opportunities.filter(opportunity => opportunity.contactId === contact.id);
                    const mostRecentOpportunity = contactOpportunities.reduce((recent, opportunity) => {
                        return new Date(opportunity.opportunityCreateDate) > new Date(recent.opportunityCreateDate) ? opportunity : recent;
                    }, contactOpportunities[0] || { opportunityCreateDate: '1970-01-01' });

                    const contract = contracts.filter(contractx => contractx.contactId === contact.id).reduce((recent, contractx) => {
                        return contractx.id > recent.id ? contractx : recent;
                    }, { id: null });

                    const initialSumaContract = contract.sum || 0;
                    const initialAvans1 = 0;
                    const initialAvans2 = 0;
                    const initialAvans3 = 0;
                    const initialPretProducere = 0;
                    const initialAvansProducere = 0;
                    const initialTransa = 0;

                    return {
                        key: index,
                        nrContract: contract.id || 'Fără',
                        idClient: `${generateContactID(contact)}: ${contact.lastName} ${contact.firstName}`,
                        sumaContract: initialSumaContract,
                        avans1: initialAvans1,
                        avans2: initialAvans2,
                        avans3: initialAvans3,
                        pretProducere: initialPretProducere,
                        avansProducere: initialAvansProducere,
                        transaFinalaProducere: initialTransa,
                        datorieClient: initialSumaContract - initialAvans1 - initialAvans2 - initialAvans3,
                        datorieProducere: initialPretProducere - initialAvansProducere - initialTransa,
                        sinecost: mostRecentOpportunity ? mostRecentOpportunity.amount : 0,
                        partner: contact.partner ? `${contact.partner.partnerLastName} ${contact.partner.partnerFirstName}` : '',
                        sumaPartener: initialSumaContract * 0.1,
                        designer: contact.designer ? `${contact.designer.designerLastName} ${contact.designer.designerFirstName}` : '',
                        sumaDesigner: initialSumaContract * 0.05,
                        qc: contract.qc || 0,
                        cadou: 0,
                        reducere: 0,
                        profit: initialSumaContract - initialPretProducere / 19.2 - (initialSumaContract * 0.1) - (initialSumaContract * 0.05) - 0 - 0 - 0
                    };
                });
        };

        const fullData = generateDataSource(contacts);
        const filteredData = generateDataSource(applyFilter(contacts));

        if (spreadsheetData && spreadsheetData.length > 0) {
            setDataSource(spreadsheetData);
        } else {
            setFullDataSource(fullData); // Store full data
            setDataSource(filteredData); // Store filtered data
        }
    }, [contacts, opportunities, contracts, filter, spreadsheetData, currentUser]);


    const applyFilter = (contacts) => {
        let filteredContacts = contacts;

        if (filter === 'esuat') {
            filteredContacts = contacts.filter(contact =>
                contact.contactStage?.contactStageName === 'Eșuat'
            );
        } else if (filter === 'finisat') {
            filteredContacts = contacts.filter(contact =>
                contracts.some(contract =>
                    contract.contactId === contact.id && contact.contactStage?.contactStageName === 'Finisat'
                )
            );
        } else if (filter === 'noContract') {
            filteredContacts = contacts.filter(contact =>
                !contracts.some(contract => contract.contactId === contact.id)
            );
        }

        return filteredContacts;
    };

    const handleSave = (key, dataIndex, value) => {
        const newData = dataSource.map((item) => {
            if (item.key === key) {
                // Create a new object with the updated value
                const updatedItem = {
                    ...item,
                    [dataIndex]: value,
                    // Recalculate values with more precise calculations
                    datorieClient: Math.round((item.sumaContract -
                        (dataIndex === 'sumaContract' ? value : item.sumaContract) -
                        (dataIndex === 'avans1' ? value : item.avans1) -
                        (dataIndex === 'avans2' ? value : item.avans2) -
                        (dataIndex === 'avans3' ? value : item.avans3)) * 100) / 100,

                    datorieProducere: Math.round((
                        (dataIndex === 'pretProducere' ? value : item.pretProducere) -
                        (dataIndex === 'avansProducere' ? value : item.avansProducere) -
                        (dataIndex === 'transaFinalaProducere' ? value : item.transaFinalaProducere)
                    ) * 100) / 100,

                    profit: Math.round((
                        (dataIndex === 'sumaContract' ? value : item.sumaContract) -
                        ((dataIndex === 'pretProducere' ? value : item.pretProducere) / 19.2) -
                        ((dataIndex === 'sumaContract' ? value : item.sumaContract) * 0.1) -
                        ((dataIndex === 'sumaContract' ? value : item.sumaContract) * 0.05) -
                        (dataIndex === 'qc' ? value : item.qc) -
                        (dataIndex === 'cadou' ? value : item.cadou) -
                        (dataIndex === 'reducere' ? value : item.reducere)
                    ) * 100) / 100
                };

                // Update fullDataSource as well
                setFullDataSource((prevData) =>
                    prevData.map((dataItem) =>
                        dataItem.key === key ? updatedItem : dataItem
                    )
                );

                return updatedItem;
            }
            return item;
        });
        setDataSource(newData);
    };

    const saveSpreadsheet = async () => {
        try {
            const response = await axios.post('/spreadsheet/full', { data: fullDataSource });

            if (response.status === 200) {
                toast.success('Spreadsheet saved successfully!');
            }
        } catch (error) {
            toast.error('Failed to save spreadsheet.');
        }
    };


    const columns = [
        { title: 'NR. CONTRACT', dataIndex: 'nrContract', key: 'nrContract' },
        { title: 'ID CLIENT NUME PRENUME', dataIndex: 'idClient', key: 'idClient' },
        {
            title: 'SINECOST',
            dataIndex: 'sinecost',
            key: 'sinecost',
            render: (text, record) => (
                <InputNumber
                    className="w-32"
                    value={text}
                    onChange={(value) => handleSave(record.key, 'sinecost', value)}
                    parser={(value) => value.replace(' lei', '')}
                    formatter={(value) => `${value} lei`}
                    disabled={currentUser && (currentUser.role.name === 'Operator Producere' || currentUser.role.name === 'Producere')}
                />
            ),
        },
        {
            title: 'PREȚ PRODUCERE',
            dataIndex: 'pretProducere',
            key: 'pretProducere',
            render: (text, record) => (
                <InputNumber
                    className="w-32"
                    value={text}
                    onChange={(value) => handleSave(record.key, 'pretProducere', value)}
                    parser={(value) => value.replace(' lei', '')}
                    formatter={(value) => `${value} lei`}
                    disabled={currentUser && (currentUser.role.name === 'Operator Producere' || currentUser.role.name === 'Producere')}
                />
            ),
        },
        {
            title: 'SUMA CONTRACT',
            dataIndex: 'sumaContract',
            key: 'sumaContract',
            render: (text, record) => (
                <InputNumber
                    className="w-32"
                    value={text}
                    onChange={(value) => handleSave(record.key, 'sumaContract', value)}
                    parser={(value) => value.replace(/[€\s]/g, '')}
                    formatter={(value) => value ? `€${value}` : ''}
                    disabled={currentUser && (currentUser.role.name === 'Operator Producere' || currentUser.role.name === 'Producere')}
                />
            ),
        },
        {
            title: 'AVANS 1',
            dataIndex: 'avans1',
            key: 'avans1',
            render: (text, record) => (
                <InputNumber
                    className="w-32"
                    value={text}
                    onChange={(value) => handleSave(record.key, 'avans1', value)}
                    parser={(value) => value.replace(/[€\s]/g, '')}
                    formatter={(value) => value ? `€${value}` : ''}
                    disabled={currentUser && (currentUser.role.name === 'Operator Producere' || currentUser.role.name === 'Producere')}
                />
            ),
        },
        {
            title: 'AVANS 2',
            dataIndex: 'avans2',
            key: 'avans2',
            render: (text, record) => (
                <InputNumber
                    className="w-32"
                    value={text}
                    onChange={(value) => handleSave(record.key, 'avans2', value)}
                    parser={(value) => value.replace(/[€\s]/g, '')}
                    formatter={(value) => value ? `€${value}` : ''}
                    disabled={currentUser && (currentUser.role.name === 'Operator Producere' || currentUser.role.name === 'Producere')}
                />
            ),
        },
        {
            title: 'AVANS 3',
            dataIndex: 'avans3',
            key: 'avans3',
            render: (text, record) => (
                <InputNumber
                    className="w-32"
                    value={text}
                    onChange={(value) => handleSave(record.key, 'avans3', value)}
                    parser={(value) => value.replace(/[€\s]/g, '')}
                    formatter={(value) => value ? `€${value}` : ''}
                    disabled={currentUser && (currentUser.role.name === 'Operator Producere' || currentUser.role.name === 'Producere')}
                />
            ),
        },
        {
            title: 'AVANS PRODUCERE',
            dataIndex: 'avansProducere',
            key: 'avansProducere',
            render: (text, record) => (
                <InputNumber
                    className="w-32"
                    value={text}
                    onChange={(value) => handleSave(record.key, 'avansProducere', value)}
                    parser={(value) => value.replace(' lei', '')}
                    formatter={(value) => `${value} lei`}
                    disabled={currentUser && (currentUser.role.name === 'Operator Producere' || currentUser.role.name === 'Producere')}
                />
            ),
        },
        {
            title: 'TRANSA FINALĂ PRODUCERE',
            dataIndex: 'transaFinalaProducere',
            key: 'transaFinalaProducere',
            render: (text, record) => (
                <InputNumber
                    className="w-32"
                    value={text}
                    onChange={(value) => handleSave(record.key, 'transaFinalaProducere', value)}
                    parser={(value) => value.replace(' lei', '')}
                    formatter={(value) => `${value} lei`}
                    disabled={currentUser && (currentUser.role.name === 'Operator Producere' || currentUser.role.name === 'Producere')}
                />
            ),
        },
        {
            title: 'DATORIE CLIENT',
            dataIndex: 'datorieClient',
            key: 'datorieClient',
            render: (text) => `€ ${Number(text).toFixed(2)}`,
        },
        {
            title: 'DATORIE PRODUCERE',
            dataIndex: 'datorieProducere',
            key: 'datorieProducere',
            render: (text) => `${Number(text).toFixed(2)} lei`,
        },
        {
            title: 'SUMA PARTENER',
            dataIndex: 'sumaPartener',
            key: 'sumaPartener',
            render: (text) => `€ ${Number(text).toFixed(2)}`,
        },
        {
            title: 'SUMA DESIGNER',
            dataIndex: 'sumaDesigner',
            key: 'sumaDesigner',
            render: (text) => `€ ${Number(text).toFixed(2)}`,
        },
        {
            title: 'PROCENT QC',
            dataIndex: 'qc',
            key: 'qc',
            render: (text) => `€ ${Number(text).toFixed(2)}`,
        },
        {
            title: 'CADOU',
            dataIndex: 'cadou',
            key: 'cadou',
            render: (text, record) => (
                <InputNumber
                    className="w-32"
                    value={text}
                    onChange={(value) => handleSave(record.key, 'cadou', value)}
                    parser={(value) => value.replace(/[€\s]/g, '')}
                    formatter={(value) => value ? `€${value}` : ''}
                    disabled={currentUser && (currentUser.role.name === 'Operator Producere' || currentUser.role.name === 'Producere')}
                />
            ),
        },
        {
            title: 'REDUCERE',
            dataIndex: 'reducere',
            key: 'reducere',
            render: (text, record) => (
                <InputNumber
                    className="w-32"
                    value={text}
                    onChange={(value) => handleSave(record.key, 'reducere', value)}
                    parser={(value) => value.replace(/[€\s]/g, '')}
                    formatter={(value) => value ? `€${value}` : ''}
                    disabled={currentUser && (currentUser.role.name === 'Operator Producere' || currentUser.role.name === 'Producere')}
                />
            ),
        },
        {
            title: 'PROFIT',
            dataIndex: 'profit',
            key: 'profit',
            render: (text) => `€ ${Number(text).toFixed(2)}`,
        },
    ];

    const filteredColumns = currentUser && currentUser.role.name === "Operator Producere"
        ? columns.filter(column => ['nrContract', 'idClient', 'sinecost', 'pretProducere', 'avansProducere', 'transaFinalaProducere', 'datorieProducere'].includes(column.key))
        : columns;

    if (contactLoading || contractLoading || opportunityLoading)
        return <div>Loading...</div>

    return (
        <Card
            title="Finanțe"
            extra={
                <>
                    <Select value={filter} onChange={setFilter} style={{ width: "8.6em", marginRight: "1.2rem" }}>
                        <Option value="all">Toate</Option>
                        <Option value="esuat">Eșuat</Option>
                        <Option value="finisat">Finisat</Option>
                        <Option value="noContract">Fără Contract</Option>
                    </Select>
                    <Button type="primary" onClick={saveSpreadsheet}>Salvează</Button>
                </>
            }
        >
            <Table
                dataSource={dataSource}
                columns={filteredColumns}
                loading={contactLoading}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </Card>
    );
}

function generateContactID(contact) {
    const departmentId = contact.contactOwner.department.id < 10
        ? `0${contact.contactOwner.department.id}`
        : contact.contactOwner.department.id;

    const contactId = contact.id < 10
        ? `0${contact.id}`
        : contact.id;
    const createdAt = new Date(contact.createdAt);
    const month = (createdAt.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
    const year = createdAt.getFullYear().toString().slice(-2); // Get last two digits of the year

    return `${departmentId}/${contactId}/${month}/${year}`;
}
