import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Typography, Input, Space } from 'antd';
import { loadAllContract } from '../../../redux/rtk/features/crm/contract/contractSlice';
import { TEXTS } from "../../../constants";
import moment from 'moment';
import { AiOutlinePlus } from 'react-icons/ai';
import CreateDrawer from '../CommonUi/CreateDrawer';
import CreatePredatPreluatForm from './CreatePredatPreluatForn';

const { Text } = Typography;

const Contracts = () => {
    const dispatch = useDispatch();
    const { list, loading, error } = useSelector(state => state.contract);
    const [open, setOpen] = useState(false);

    // States for search inputs
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    // Load contracts initially without search parameters
    useEffect(() => {
        dispatch(loadAllContract());
    }, [dispatch]);

    const onClose = () => {
        setOpen(false);
    };

    const handleSearch = () => {
        const searchParams = {};
        // Only add firstName if it's not empty
        if (firstName) {
            searchParams.firstName = firstName;
        }
        // Only add lastName if it's not empty
        if (lastName) {
            searchParams.lastName = lastName;
        }
        // Dispatch the action with searchParams (it could be empty if no values)
        dispatch(loadAllContract(searchParams));
    };


    const handleDownload = async (contractPath, contractName) => {
        try {
            const baseUrl = process.env.REACT_APP_API;
            const url = `${baseUrl}/files/${contractPath}`;

            const response = await fetch(url);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = contractName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const columns = [
        {
            title: 'Denumire',
            dataIndex: 'contractName',
            key: 'contractName',
        },
        {
            title: 'Nume Persoana',
            dataIndex: 'personName',
            key: 'personName',
        },
        {
            title: 'Tip Contract',
            dataIndex: 'contractType',
            key: 'contractType',
        },
        {
            title: `${TEXTS.LABELS.OPPORTUNITIES}`,
            dataIndex: "opportunity",
            key: "opportunity",
            render: (opportunity) => opportunity ? (
                <span>{opportunity.opportunityName}</span>
            ) : (
                "-"
            ),
        },
        {
            title: `${TEXTS.LABELS.CREATE_DATE}`,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => moment(date).format('MMM Do YYYY, hh:mm'),
        },
        {
            title: `${TEXTS.LABELS.ACTIONS}`,
            key: 'action',
            render: (text, record) => (
                <Button type="primary" onClick={() => handleDownload(record.contractPath, record.contractName)}>
                    Download
                </Button>
            ),
        },
    ];

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const mappedContracts = list.map(contract => ({
        key: contract.id,
        contractName: contract.contractName,
        opportunity: contract.opportunity ? `${contract.opportunity.opportunityName}` : `-`,
        personName: contract.contact ? `${contract.contact.firstName} ${contract.contact.lastName}` : contract.company.companyName,
        contractType: contract.contact ? `${TEXTS.LABELS.CONTACT}` : `${TEXTS.LABELS.COMPANY}`,
        createdAt: contract.createdAt,
        contractPath: contract.contractPath,
    }));

    return (
        <div className="container">
            {/* Search Form */}
            <div className="search-container">
                <Space style={{ marginBottom: 16 }}>
                    <Input
                        placeholder={TEXTS.LABELS.FIRST_NAME}
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                    />
                    <Input
                        placeholder={TEXTS.LABELS.LAST_NAME}
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                    />
                    <Button type="primary" onClick={handleSearch}>
                        Caută
                    </Button>
                </Space>
            </div>

            {/* Create Button */}
            <div className="bg-white dark:bg-DTableBg rounded-lg flex flex-col md:flex-row justify-end items-center gap-3 p-3 px-6 border">
                <button
                    onClick={() => setOpen(true)}
                    className="py-2 px-3 w-[263px] border bg-lime-700 hover:bg-lime-500 text-white rounded cursor-pointer flex items-center gap-2"
                >
                    <AiOutlinePlus /> {TEXTS.TEXT.CREATE} Predat/Preluat Document
                </button>
            </div>

            {/* Contracts Table */}
            <Table columns={columns} dataSource={mappedContracts} />

            {/* Drawer for form */}
            <CreateDrawer
                onClose={onClose}
                open={open}
                title='Document'
                width={50}
            >
                <CreatePredatPreluatForm onClose={onClose} open={open} />
            </CreateDrawer>
        </div>
    );
};

export default Contracts;
