// slices/settingsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    settings: {},
    loading: false,
    error: null,
};

// Async thunk to fetch settings
export const fetchSettings = createAsyncThunk('settings/fetchSettings', async () => {
    const response = await axios.get('settings');
    return response.data;
});

// Async thunk to update settings
export const updateSettings = createAsyncThunk('settings/updateSettings', async (settings) => {
    const response = await axios.put(`/settings/1`, settings);
    return response.data;
});

// Create the settings slice
const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        clearSettings: (state) => {
            state.settings = null;
        },
    },
    extraReducers: (builder) => {
        // Handle fetch settings actions
        builder
            .addCase(fetchSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSettings.fulfilled, (state, action) => {
                state.loading = false;
                state.settings = action.payload;
            })
            .addCase(fetchSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Handle update settings actions
            .addCase(updateSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSettings.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export the settings slice reducer
export default settingsSlice.reducer;
export const { clearSettings } = settingsSlice.actions;


