import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    production: null,
    error: "",
    loading: false,
};

export const getAllProductions = createAsyncThunk(
    "production/getAllProductions",
    async () => {
        try {
            const { data } = await axios.get(`production`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const addSingleProduction = createAsyncThunk(
    "production/addSingleProduction",
    async (values) => {
        try {
            const { data } = await axios({
                method: "post",
                url: `production/`,
                data: {
                    ...values,
                },
            });
            toast.success("Production Selection Added");
            return {
                data,
                message: "success",
            }
        } catch (error) {
            toast.error("Error while adding production");
            console.log(error.message);
        }
    }
);

export const updateProduction = createAsyncThunk(
    "production/updateProduction",
    async ({ id, values }) => {
        try {
            const { data } = await axios({
                method: "put",
                url: `production/${id}`,
                data: {
                    ...values,
                }
            });
            toast.success("Production Succession Updated");
            return {
                data,
                message: "success",
            };
        } catch (error) {
            toast.error("Error while updating production");
            console.log(error.message);
            return {
                message: "error",
            }
        }
    }
);

export const deleteProduction = createAsyncThunk(
    "production/deleteProduction",
    async (id, { dispatch }) => {
        try {
            const resp = await axios({
                method: "delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `production/${id}`,
            });
            dispatch(getAllProductions());  // Fetch updated list
            toast.success("Production Succession Deleted");
            return id;  // Return id for direct usage in reducer
        } catch (error) {
            toast.error("Error while deleting Production");
            console.log(error.message);
            throw error; // Make sure to handle rejection
        }
    }
);

const productionSlice = createSlice({
    name: "production",
    initialState,
    reducers: {
        clearProduction: (state) => {
            state.production = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllProductions.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getAllProductions.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(getAllProductions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(addSingleProduction.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addSingleProduction.fulfilled, (state) => {
            state.loading = false;
        });

        builder.addCase(addSingleProduction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(updateProduction.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateProduction.fulfilled, (state) => {
            state.loading = false;
        });

        builder.addCase(updateProduction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(deleteProduction.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteProduction.fulfilled, (state, action) => {
            state.loading = false;
            state.list = state.list.filter(item => item.id !== action.payload);
        });

        builder.addCase(deleteProduction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    }
});


export default productionSlice.reducer;
export const { clearProduction } = productionSlice.actions;