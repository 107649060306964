import { useState } from "react"
import { AiOutlinePlus } from "react-icons/ai";
import { TEXTS } from "../../../../../constants";
import PartnerTable from "./PartnerTable";
import CreatePartner from "./CreatePartner";

const PartnerSetup = () => {
    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    }

    return (
        <div className="container">
            <div className="py-4 flex justify-center md:justify-end">
                <button
                    onClick={() => setOpen(true)}
                    className="py-2 px-3 border bg-teal-700 hover:bg-teal-500 text-white rounded cursor-pointed flex items-center gap-2"
                >
                    <AiOutlinePlus /> {TEXTS.TEXT.CREATE} {TEXTS.LABELS.PARTNER}
                </button>
            </div>
            <PartnerTable />
            <CreatePartner onClose={onClose} open={open} />
        </div>
    )
}

export default PartnerSetup