import { Button, DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllContactStage } from "../../../redux/rtk/features/crm/ContactStage/contactStageSlice";
import { loadAllCompany } from "../../../redux/rtk/features/crm/company/companySlice";
import {
  addSingleContact,
  loadAllContactPaginated,
} from "../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllContactSource } from "../../../redux/rtk/features/crm/contactSource/contactSourceSlice";
import { loadAllIndustry } from "../../../redux/rtk/features/crm/industry/industrySlice";
import { loadAllStaff } from "../../../redux/rtk/features/user/userSlice";
import UserPrivateComponent from "../../PrivateRoutes/UserPrivateComponent";
import { TEXTS } from "../../../constants";
import sendEmail from "../Email/sendEmailApi";
import { loadAllReparations } from "../../../redux/rtk/features/crm/contact/reparationSlice";
import { loadAllPartners } from "../../../redux/rtk/features/crm/partner/partnerSlice";
import { loadAllDesigners } from "../../../redux/rtk/features/crm/designer/designerSlice";

export default function CreateContactForm({ onClose, createAs }) {
  // selector
  const { list: companyList, loading: companyLoading } = useSelector(
    (state) => state.company
  );
  const { list: contactSourceList, loading: contactSourceLoading } =
    useSelector((state) => state.contactSource);
  const { list: contactStageList, loading: contactStageLoading } = useSelector(
    (state) => state.contactStage
  );
  const { list: staffList, loading: staffLoading } = useSelector(
    (state) => state.users
  );
  const { list: reparationList, loading: reparationLoading } = useSelector(
    (state) => state.reparation
  );
  const { list: partnerList, loading: partnerLoading } = useSelector(
    (state) => state.partner
  );
  const { list: designerList, loading: designerLoading } = useSelector(
    (state) => state.designer
  );
  const { loading: contactLoading } = useSelector((state) => state.contact);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [contactSourceId, setContactSourceId] = useState(null);
  const [isPartnerSelectable, setIsPartnerSelectable] = useState(false);

  useEffect(() => {
    if (contactLoading) return;

    dispatch(loadAllContactSource());
    dispatch(loadAllCompany());
    dispatch(loadAllContactStage());
    dispatch(loadAllIndustry());
    dispatch(loadAllReparations());
    dispatch(loadAllStaff({ status: true }));
    dispatch(loadAllPartners());
    dispatch(loadAllDesigners());
  }, [dispatch]);


  const onValuesChanged = (changedValues) => {
    if (changedValues.contactSourceId) {
      setContactSourceId(changedValues.contactSourceId);

      // Find the selected contact source by ID
      const selectedSource = contactSourceList.find(
        (source) => source.id === changedValues.contactSourceId
      );

      // Check if the contactSourceName is "Recomandare partener"
      if (selectedSource?.contactSourceName.toLowerCase() === "recomandare partener") {
        setIsPartnerSelectable(true);
      } else {
        setIsPartnerSelectable(false);
      }
    }
  };

  const onFinish = async (values) => {
    const formData = {
      ...values,
      dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
      contactOwnerId: parseInt(values.contactOwnerId),
      companyId: parseInt(values.companyId),
      industryId: parseInt(values.industryId),
      contactSourceId: parseInt(values.contactSourceId),
      contactStageId: parseInt(values.contactStageId),
      partnerId: parseInt(values.partnerId),
      reparationId: parseInt(values.reparationId),
      designerId: parseInt(values.designerId),
    };

    const resp = await dispatch(addSingleContact(formData));
    if (resp.payload.message === "success" && !contactLoading) {
      const selectedOwner = staffList.find(staff => staff.id === values.contactOwnerId)
      const emailBody = `A fost adăugat client Nou: ${values.firstName} ${values.lastName}`;

      const emailData = {
        receiverEmail: selectedOwner.email,
        subject: `Creare Client`,
        body: emailBody.replace(/\n/g, '<br>'),
      }
      sendEmail(emailData);
      form.resetFields();
      dispatch(loadAllContactPaginated({ status: true }));
      onClose();
    }
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex justify-center mt-5">
      <UserPrivateComponent permission="create-contact">
        <Form
          className="w-4/5"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChanged}
          colon={false}
          layout="vertical"
          form={form}
          initialValues={
            createAs
              ? {
                [createAs.name]: createAs.value,
                contactStageId: 1,
              }
              : { contactStageId: 1 }
          }
        >
          <div className="flex justify-between gap-5">
            <div className="w-1/2">
              <Form.Item
                style={{ width: "100%" }}
                label="Email"
                name="email"
              >
                <Input placeholder="example@email.com" />
              </Form.Item>

              <Form.Item label={TEXTS.LABELS.FIRST_NAME} name="firstName">
                <Input placeholder="Jhon" />
              </Form.Item>

              <Form.Item label={TEXTS.LABELS.LAST_NAME} name="lastName">
                <Input placeholder="Doe" />
              </Form.Item>

              <Form.Item label={TEXTS.LABELS.BIRTHDATE} name="dateOfBirth">
                <DatePicker format="YYYY-MM-DD HH:mm"

                  placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.BIRTHDATE}`}
                />
              </Form.Item>

              <Form.Item
                label={TEXTS.LABELS.CONTACT_OWNER}
                name={"contactOwnerId"}
              >
                <Select
                  style={{ width: "100%" }}
                  loading={staffLoading}
                  allowClear
                  showSearch
                  placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.CONTACT_OWNER}`}
                >
                  {staffList?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item?.firstName} {item?.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label={TEXTS.LABELS.COMPANY} name="companyId">
                <Select
                  style={{ width: "100%" }}
                  loading={companyLoading}
                  allowClear
                  showSearch
                  placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.COMPANY}`}
                  disabled={!!(createAs?.name === "companyId")}
                >
                  {companyList?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.companyName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={TEXTS.LABELS.PARTNER}
                name="partnerId"
              >
                <Select
                  style={{ width: "100%" }}
                  loading={partnerLoading}
                  disabled={isPartnerSelectable}

                  allowClear
                  showSearch
                  placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.PARTNER}`}
                >
                  {partnerList?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.partnerFirstName} {item.partnerLastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={TEXTS.LABELS.PHONE} name="phone">
                <Input placeholder="+8801700000000" />
              </Form.Item>
              <Form.Item
                label={`IDNP`}
                name="idnp"
              >
                <Input
                  placeholder={`Adaugă IDNP`}
                />
              </Form.Item>
            </div>
            <div className="w-1/2">
              <Form.Item
                label={`${TEXTS.LABELS.CITY} ${TEXTS.TEXT.PRESENT1}`}
                name="presentCity"
              >
                <Input placeholder="Dhaka" />
              </Form.Item>

              <Form.Item
                label={`${TEXTS.LABELS.COUNTRY}`}
                name="presentCountry"
              >
                <Input placeholder="Bangladesh" />
              </Form.Item>

              <Form.Item
                label={`Adresa de livrare`}
                name="permanentAddress"
              >
                <Input
                  placeholder={`${TEXTS.LABELS.ADDRESS} ${TEXTS.TEXT.PERMANENT}`}
                />
              </Form.Item>
              <Form.Item
                label={`Adresa de domicilui`}
                name="permanentCountry"
              >
                <Input placeholder="Bangladesh" />
              </Form.Item>
              <Form.Item
                label={TEXTS.LABELS.CONTACT_SOURCE}
                name="contactSourceId"
              >
                <Select
                  style={{ width: "100%" }}
                  loading={contactSourceLoading}
                  allowClear
                  showSearch
                  placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.CONTACT_SOURCE}`}
                >
                  {contactSourceList?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.contactSourceName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={TEXTS.LABELS.DESIGNER}
                name="designerId"
              >
                <Select
                  style={{ width: "100%" }}
                  loading={designerLoading}
                  allowClear
                  showSearch
                  placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.DESIGNER}`}
                >
                  {designerList?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.designerFirstName} {item.designerLastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={TEXTS.LABELS.CONTACT_STAGE}
                name="contactStageId"
                rules={[{ required: true, message: `${TEXTS.TEXT.REQUIRED_FIELD}` }]}
              >
                <Select
                  style={{ width: "100%" }}
                  loading={contactStageLoading}
                  allowClear
                  showSearch
                  defaultValue={1}
                  placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.CONTACT_STAGE}`}
                >
                  {contactStageList?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.contactStageName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label='Etapa de reparație'
                name="reparationId"
              >
                <Select
                  style={{ width: "100%" }}
                  loading={reparationLoading}
                  allowClear
                  showSearch
                  placeholder={`${TEXTS.TEXT.SELECT} ${TEXTS.LABELS.STAGE}`}
                >
                  {reparationList?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.reparationName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

          </div>

          <Form.Item label={TEXTS.LABELS.DESCRPTION} name="description">
            <Input.TextArea
              placeholder={TEXTS.PLACEHOLDERS.CONTACT_DESCRIPTION}
            />
          </Form.Item>

          <Form.Item label="">
            <div className="flex items-center gap-2">
              <Button
                size={"large"}
                htmlType="submit"
                type="primary"
                loading={contactLoading}
              >
                {TEXTS.TEXT.CREATE}
              </Button>
              {/* <button
              className='py-1 px-3 text-lg border border-orange-500 rounded cursor-pointer'
              type='submit'
            >
              Create and add another
            </button> */}
              <Button
                size={"large"}
                htmlType="submit"
                type="danger"
                onClick={onCancel}
              >
                {TEXTS.BUTTON_TEXT.CLOSE_BTN}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </UserPrivateComponent>
    </div >
  );
}
