import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { loadAllShift } from "../../../redux/rtk/features/shift/shiftSlice";
import {
  clearUser,
  loadSingleStaff,
  updateUser,
} from "../../../redux/rtk/features/user/userSlice";
import { getDepartments } from "../../department/departmentApis";
import { getRoles } from "../../role/roleApis";
import BtnEditSvg from "../Button/btnEditSvg";
import { TEXTS } from "../../../constants";

const ProfileEditPopup = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const shift = useSelector((state) => state.shift?.list);
  const user = useSelector((state) => state.users?.user);

  const { id } = useParams("id");

  const dispatch = useDispatch();
  const { Option } = Select;
  const [list, setList] = useState(null);

  const [department, setDepartment] = useState(null);

  const [initialValues, setInitialValues] = useState({});

  const [roleId, setRoleId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [shiftId, setShiftId] = useState("");

  useEffect(() => {
    getRoles()
      .then((d) => setList(d))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    dispatch(loadAllShift());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadSingleStaff(id));
    setInitialValues({
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      userName: user.userName ? user.userName : "",
      email: user.email ? user.email : "",
      phone: user.phone ? user.phone : "",
      street: user.street ? user.street : "",
      city: user.city ? user.city : "",
      state: user.state ? user.state : "",
      zipCode: user.zipCode ? user.zipCode : "",
      country: user.country ? user.country : "",
      joinDate: dayjs(user.joinDate),
      leaveDate: user.leaveDate ? dayjs(user.leaveDate) : null,
      employeeId: user.employeeId ? user.employeeId : "",
      bloodGroup: user.bloodGroup ? user.bloodGroup : "",
      image: user.image ? user.image : "",
      roleId: user.roleId ? user.roleId : "",
      departmentId: user.departmentId ? user.departmentId : "",
      shiftId: user.shiftId ? user.shiftId : "",
    });
  }, [id]);

  useEffect(() => {
    getDepartments()
      .then((d) => setDepartment(d))
      .catch((error) => console.log(error));
  }, []);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const resp = await dispatch(
        updateUser({
          id: id,
          values: {
            ...values,
            roleId: roleId ? roleId : data.roleId,
            departmentId: departmentId ? departmentId : data.departmentId,
            shiftId: shiftId ? shiftId : data.shiftId,
          },
        })
      );

      setLoader(true);
      if (resp.payload.message === "success") {
        setLoader(false);
        dispatch(clearUser());
        dispatch(loadSingleStaff(id));
        setIsModalOpen(false);
      } else {
        setLoader(false);
      }

      // form.resetFields();
    } catch (error) {
      console.log(error.message);
      setLoader(false);
      toast.error("Something went wrong");
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoader(false);
    console.log("Failed:", errorInfo);
  };

  const bloodGroups = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"]; // blood groups

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button onClick={showModal}>
        <BtnEditSvg size={30} />
      </button>
      <Modal
        width={"50%"}
        title={TEXTS.TITLES.EMPLOYEE_INFO_EDIT}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          size='small'
          form={form}
          name='basic'
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <h2 className='text-center text-xl mt-3 mb-3 txt-color'>
            {TEXTS.TEXT.USER_INFO_TEXT}
          </h2>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.FIRST_NAME}
            name='firstName'
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.FIRST_NAME_MESSAGE}`,
              },
            ]}
          >
            <Input placeholder='John' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.LAST_NAME}
            name='lastName'
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.LAST_NAME_MESSAGE}`,
              },
            ]}
          >
            <Input placeholder='Doe' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.USERNAME}
            name='userName'
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.USERNAME_MESSAGE}`,
              },
            ]}
          >
            <Input placeholder='john_doe' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.PASSWORD}
            name='password'
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.PASSWORD_MESSAGE}`,
              },
            ]}
          >
            <Input placeholder='Strong Password' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.EMAIL_MESSAGE}`,
              },
            ]}
          >
            <Input placeholder='johndoe2@example.com' />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.PHONE}
            name='phone'
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.PHONE_MESSAGE}`,
              },
            ]}
          >
            <Input placeholder='1234584515' />
          </Form.Item>

          <h2 className='text-center text-xl mt-3 mb-3 txt-color'>
            {TEXTS.TEXT.ADDRESS_INFO_TEXT}
          </h2>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.STREET}
            name='street'
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.STREET_MESSAGE}`,
              },
            ]}
          >
            <Input placeholder='123 Main Street' style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.CITY}
            name='city'
            rules={[{ required: true, message: `${TEXTS.MESSAGES.CITY_MESSAGE}` }]}
          >
            <Input placeholder='Los Angeles' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.STATE}
            name='state'
            rules={[{ required: true, message: `${TEXTS.MESSAGES.STATE_MESSAGE}` }]}
          >
            <Input placeholder='CA' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.ZIP_CODE}
            name='zipCode'
            rules={[{ required: true, message: `${TEXTS.MESSAGES.ZIP_CODE_MESSAGE}` }]}
          >
            <Input placeholder='90211' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.COUNTRY}
            name='country'
            rules={[{ required: true, message: `${TEXTS.MESSAGES.COUNTRY_MESSAGE}` }]}
          >
            <Input placeholder='USA' />
          </Form.Item>

          <h2 className='text-center text-xl mt-3 mb-3 txt-color'>
            {" "}
            {TEXTS.TEXT.EMPLOYEE_INFO_TEXT}{" "}
          </h2>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.JOIN_DATE}
            name='joinDate'
            valuePropName='date'
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.JOIN_DATE_MESSAGE}`,
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD HH:mm"
              className='date-picker hr-staffs-date-picker'
              defaultValue={initialValues.joinDate}

            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.LEAVE_DATE}
            name='leaveDate'
            valuePropName='leaveDate'
          >
            <DatePicker format="YYYY-MM-DD HH:mm"
              className='date-picker hr-staffs-date-picker'
              defaultValue={initialValues.leaveDate}

            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.EMPLOYEE_ID}
            name='employeeId'
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.EMPLOYEE_ID_MESSAGE}`,
              },
            ]}
          >
            <Input placeholder='OE-012' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.BLOOD_GROUP}
            name='bloodGroup'
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.BLOOD_GROUP_MESSAGE}`,
              },
            ]}
          >
            <Select
              placeholder={TEXTS.PLACEHOLDERS.BLOOD_GROUP_PLACEHOLDER}
              allowClear
              defaultValue={initialValues.bloodGroup}
              mode='single'
              size='middle'
              style={{
                width: "100%",
              }}
            >
              {bloodGroups.map((bloodGroup) => (
                <Option key={bloodGroup} value={bloodGroup}>
                  {bloodGroup}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* TODO: Add a Upload Seciton for Image */}

          <Form.Item
            name={"departmentId"}
            style={{ marginBottom: "10px" }}
            label={TEXTS.LABELS.DEPARTMENT}
            rules={[{ required: true, message: `${TEXTS.MESSAGES.DEPARTMENT_MESSAGE}` }]}
          >
            <Select
              onChange={(value) => setDepartmentId(value)}
              placeholder={TEXTS.PLACEHOLDERS.DEPARTMENT_PLACEHOLDER}
              allowClear
              size={"middle"}
              defaultValue={initialValues.departmentId}
            >
              {department &&
                department.map((department) => (
                  <Option key={department.id} value={department.id}>
                    {department.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Pleases Select Type!",
              },
            ]}
            label={TEXTS.LABELS.ROLE}
            name={"roleId"}
            style={{ marginBottom: "10px" }}
          >
            <Select
              onChange={(value) => setRoleId(value)}
              defaultValue={initialValues.roleId}
              loading={!list}
              size='middle'
              mode='single'
              allowClear
              style={{
                width: "100%",
              }}
              placeholder={TEXTS.PLACEHOLDERS.ROLE_PLACEHOLDER}
            >
              {list &&
                list.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: `${TEXTS.MESSAGES.TYPE_MESSAGE}`,
              },
            ]}
            label={TEXTS.LABELS.SHIFT}
            name={"shiftId"}
            style={{ marginBottom: "10px" }}
          >
            <Select
              onChange={(value) => setShiftId(value)}
              defaultValue={initialValues.shiftId}
              loading={!shift}
              size='middle'
              mode='single'
              allowClear
              style={{
                width: "100%",
              }}
              placeholder={TEXTS.PLACEHOLDERS.SHIFT_PLACEHOLDER}
            >
              {shift &&
                shift.map((shift) => (
                  <Option key={shift.id} value={shift.id}>
                    {shift.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px", marginTop: "10px" }}
            wrapperCol={{
              offset: 4,
              span: 16,
            }}
          >
            <Button
              className='mt-5'
              block
              onClick={() => setLoader(true)}
              type='primary'
              shape='round'
              htmlType='submit'
              loading={loader}
            >
              {TEXTS.BUTTON_TEXT.UPDATE_BTN}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ProfileEditPopup;
