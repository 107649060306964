import { Button, Form, Input, Select, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addSingleProduct,
  loadAllProduct,
  updateProduct,
} from "../../../redux/rtk/features/crm/product/productSlice";
import { TEXTS } from "../../../constants";
import { useEffect, useState } from "react";
import { loadAllUnits } from "../../../redux/rtk/features/crm/unit/unitSlice";
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons"
import { toast } from "react-toastify";

export default function CreateProductForm({ onClose, edit }) {
  const [form] = Form.useForm();
  const [uploadFilename, setUploadFilename] = useState(null);
  const dispatch = useDispatch();
  const { list: unitList, loading: unitLoading } = useSelector((state) => state.unit);

  const onFinish = async (values) => {
    if (!uploadFilename) {
      // No image uploaded
      const confirmResult = window.confirm("No image uploaded. Are you sure you want to proceed without an image?");
      if (!confirmResult) {
        return; // Do nothing if user cancels
      }
    }

    const formData = {
      ...values,
      filename: uploadFilename,
    };

    const resp = await dispatch(
      edit?.id
        ? updateProduct({ id: edit?.id, values: formData })
        : addSingleProduct(formData)
    );
    // Add the uploaded filename to the form values
    if (resp.payload.message === "success") {
      dispatch(loadAllProduct());
      if (!edit?.id) {
        onClose();
        form.resetFields();
      }
    }
  };

  const onCancel = () => {
    form.resetFields();
    handleRemove();
    onClose();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = function () {
        if (img.width > 256 || img.height > 256) {
          onError("Image dimensions should not exceed 256x256.");
          toast.error("Image dimensions should not exceed 256x256.")
          return;
        }
        const formData = new FormData();
        formData.append("productImage", file);
        axios
          .post("/upload", formData)
          .then((response) => {
            handleUploadFilename(response);
            onSuccess(response.data, file);
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            onError("Error uploading file.");
          });
      };
    } catch (error) {
      console.error("Error uploading file:", error);
      onError("Error uploading file.");
    }
  };

  const handleRemove = async (file) => {
    try {
      await axios.delete(`/upload/${uploadFilename}`);
      setUploadFilename(null);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleUploadFilename = (response) => {
    setUploadFilename(response.data.filename);
  }

  useEffect(() => {
    dispatch(loadAllUnits({ status: true }));
  }, [dispatch])

  return (
    <div className='flex justify-center mt-5'>
      <Form
        className='w-4/5'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        colon={false}
        layout='vertical'
        initialValues={edit?.id ? { ...edit?.values } : {}}
        form={form}
      >
        <Form.Item
          style={{ width: "350px" }}
          label={TEXTS.LABELS.PRODUCT_NAME}
          name='productName'
          tooltip={TEXTS.TEXT.REQUIRED_FIELD}
          rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.PRODUCT_NAME}` }]}
        >
          <Input placeholder={TEXTS.LABELS.PRODUCT_NAME} />
        </Form.Item>
        <Form.Item label={TEXTS.LABELS.UNIT} name='unitId'>
          <Select
            style={{ width: "100%" }}
            loading={unitLoading}
            allowClear
            showSearch
            placeholder={TEXTS.PLACEHOLDERS.UNIT_PLACEHOLDER}>
            {unitList?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.unitType}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={TEXTS.LABELS.PRICE_PER_UNIT} name='pricePerUnit'>
          <Input placeholder='10000' />
        </Form.Item>
        <Form.Item>
          <Upload
            name="productImage"
            customRequest={customRequest}
            onRemove={handleRemove}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item label=''>
          <div className='flex items-center gap-2'>
            <Button size={"large"} htmlType='submit' type='primary'>
              {edit?.id ? `${TEXTS.BUTTON_TEXT.UPDATE_BTN}` : `${TEXTS.TEXT.CREATE}`}
            </Button>
            {!edit?.id && (
              <Button
                size={"large"}
                htmlType='submit'
                type='danger'
                onClick={onCancel}
              >
                {TEXTS.BUTTON_TEXT.CLOSE_BTN}
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
