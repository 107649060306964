import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    list: [],
    opportunityRoom: null,
    error: "",
    loading: false,
}

export const getOpportunityRooms = createAsyncThunk(
    "opportunityRoom/getOpportunityRooms",
    async (opportunityId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/opportunity-room/${opportunityId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateOpportunityRoom = createAsyncThunk(
    "opportunityRoom/updateOpportunityRoom",
    async ({ id, data }) => {
        try {
            const response = await axios.put(`/opportunity-room/${id}`, data);
            return response.data;
        } catch (error) {
            console.error(error);
            return {
                message: "error",
            };
        }
    }
);

export const deleteOpportunityRoom = createAsyncThunk(
    "opportunityRoom/deleteOpportunityRoom",
    async (id) => {
        try {
            await axios.delete(`/opportunity-room/${id}`);
            return id;
        } catch (error) {
            console.log(error);
            return {
                message: "error"
            }
        }
    }
);

const opportunityRoomSlice = createSlice({
    name: "opportunityRoom",
    initialState,
    reducers: {
        clearOpportunityRoom: (state) => {
            state.opportunityRoom = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOpportunityRooms.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getOpportunityRooms.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
        });

        builder.addCase(getOpportunityRooms.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });


        builder.addCase(updateOpportunityRoom.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(updateOpportunityRoom.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(updateOpportunityRoom.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });


        builder.addCase(deleteOpportunityRoom.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteOpportunityRoom.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(deleteOpportunityRoom.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });
    }
});

export default opportunityRoomSlice.reducer;