import { MdDelete } from "react-icons/md";
import getRoleFromToken from "../../utils/getRoleFromToken";

const Notification = ({ notification, onApprove, onDelete }) => {
    const userRole = getRoleFromToken();

    // Construct dynamic URLs
    const contactUrl = `/admin/contact/${notification.contact.id}`;
    const opportunityUrl = `/admin/opportunity/${notification.opportunityId}`;
    const roomUrl = `/admin/room/${notification.contactRoomId}`;

    return (
        <div className="flex items-center justify-between p-2 bg-white shadow-md rounded-md mb-1">
            {/* Conditional rendering of the notification message */}
            <p className="text-xs">
                {userRole === "Tehnician Prețuri" ? (
                    <>
                        Clientul{" "}
                        <a href={contactUrl} className="text-blue-500">
                            {`${notification.contact.lastName} ${notification.contact.firstName}`}
                        </a>
                        {` cu Proiectul `}
                        <a href={opportunityUrl} className="text-blue-500">
                            {notification.opportunityName} {/* OpportunityName */}
                        </a>
                        {` și Camera `}
                        <a href={roomUrl} className="text-blue-500">
                            {notification.roomName} {/* RoomName */}
                        </a>
                        {` așteaptă aprobarea pentru a fi plasat în etapa Prezentare.`}
                    </>
                ) : (
                    notification.message // Default message for other roles
                )}
            </p>

            {/* Conditional buttons based on user role */}
            {userRole === "Tehnician Prețuri" ? (
                <button
                    className="bg-green-500 text-white px-2 py-1 text-sm rounded hover:bg-green-700"
                    onClick={() => onApprove(notification.id)}
                >
                    Aprobă
                </button>
            ) : (
                <button onClick={() => onDelete(notification.id)} className="text-red-600">
                    <MdDelete size={"24px"} />
                </button>
            )}
        </div>
    );
};

export default Notification;
