import { Button, Form, Input } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	loadAllEmailConfig,
	updateEmailConfig,
} from "../../../../redux/rtk/features/crm/email/emailConfigSlice";
import UserPrivateComponent from "../../../PrivateRoutes/UserPrivateComponent";
import { TEXTS } from "../../../../constants";

export default function UpdateEmailConfig({ onClose, edit }) {
	const { loading: emailConfigLoading } = useSelector(
		(state) => state.emailConfig
	);

	const [form] = Form.useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadAllEmailConfig());
	}, [dispatch]);

	const onFinish = async (values) => {
		const formData = {
			...values,

			emailPort: parseInt(values.emailPort),
		};


		const resp = await dispatch(
			updateEmailConfig({ id: edit?.id, values: formData })
		);
		if (resp.payload.message === "success") {
			form.resetFields();
			dispatch(loadAllEmailConfig());
			// reload the whole page
			window.location.reload();
			onClose();
		}
	};

	const onCancel = () => {
		form.resetFields();
		onClose();
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className='flex justify-center mt-5'>
			<UserPrivateComponent permission='create-contact'>
				<Form
					className='w-4/5'
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					colon={false}
					layout='vertical'
					form={form}
					initialValues={
						edit
							? {
								...edit,
							}
							: {}
					}>
					<Form.Item
						style={{ width: "100%" }}
						label={TEXTS.LABELS.CONFING_NAME}
						name='emailConfigName'
						rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.CONFING_NAME}` }]}>
						<Input placeholder={TEXTS.LABELS.CONFING_NAME} />
					</Form.Item>

					<Form.Item
						label='Host'
						name='emailHost'
						rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} Host` }]}>
						<Input placeholder='mail.google.com' />
					</Form.Item>

					<Form.Item
						label='Port'
						name='emailPort'
						rules={[{ required: true, message: "Port is Required." }]}>
						<Input placeholder='465' />
					</Form.Item>

					<Form.Item
						label={`${TEXTS.LABELS.USER} Email`}
						name='emailUser'
						rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.USER}` }]}>
						<Input placeholder='example@email.com' />
					</Form.Item>

					<Form.Item
						label={TEXTS.LABELS.PASSWORD}
						name='emailPass'
						rules={[{ required: true, message: `${TEXTS.MESSAGES.REQUIRED_MESSAGE} ${TEXTS.LABELS.PASSWORD}` }]}>
						<Input.Password placeholder={TEXTS.PLACEHOLDERS.PASSWORD_PLACEHOLDER} />
					</Form.Item>
					<Form.Item label=''>
						<div className='flex items-center gap-2'>
							<Button
								size={"large"}
								htmlType='submit'
								type='primary'
								loading={emailConfigLoading}>
								{TEXTS.TEXT.UPDATE}
							</Button>

							<Button
								size={"large"}
								htmlType='submit'
								type='danger'
								onClick={onCancel}>
								{TEXTS.BUTTON_TEXT.CLOSE_BTN}
							</Button>
						</div>
					</Form.Item>
				</Form>
			</UserPrivateComponent>
		</div>
	);
}
