import { Button, Form, Input, Select } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addSingleCompany,
	loadAllCompany,
} from "../../../redux/rtk/features/crm/company/companySlice";
import { loadAllCompanyType } from "../../../redux/rtk/features/crm/companyType/companyTypeSlice";
import { loadAllContact } from "../../../redux/rtk/features/crm/contact/contactSlice";
import { loadAllIndustry } from "../../../redux/rtk/features/crm/industry/industrySlice";
import { loadAllStaff } from "../../../redux/rtk/features/user/userSlice";
import { TEXTS } from "../../../constants";
import TextArea from "antd/lib/input/TextArea";

export default function CreateCompanyForm({ onClose }) {
	const [form] = Form.useForm();
	const { list: industryList, loading: industryLoading } = useSelector(
		(state) => state.industry
	);

	const { loading: companyLoading } = useSelector((state) => state.company);

	const { list: companyTypeList, loading: companyTypeLoading } = useSelector(
		(state) => state.companyType
	);
	const { list: ownerList, loading: ownerLoading } = useSelector(
		(state) => state.users
	);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadAllIndustry());
		dispatch(loadAllContact());
		dispatch(loadAllCompanyType());
		dispatch(loadAllStaff({ status: true }));
	}, [dispatch]);

	const onFinish = async (values) => {
		const formData = {
			...values,
			companySize: parseInt(values.companySize),
			annualRevenue: parseInt(values.annualRevenue),
		};
		const resp = await dispatch(addSingleCompany(formData));
		if (resp.payload.message === "success") {
			form.resetFields();
			dispatch(loadAllCompany());
			onClose();
		}
	};

	const onCancel = () => {
		form.resetFields();
		onClose();
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className='flex justify-center mt-5'>
			<Form
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				colon={false}
				layout='vertical'
				form={form}
				style={{
					width: "80%",
				}}>
				<Form.Item
					label={`${TEXTS.LABELS.LAST_NAME} ${TEXTS.LABELS.COMPANY}`}
					name='companyName'
					tooltip={TEXTS.TEXT.REQUIRED_FIELD}
					rules={[{ required: true, message: `${TEXTS.MESSAGES.NAME_REQUIRED_MESSAGE} ${TEXTS.LABELS.LAST_NAME} ${TEXTS.LABELS.COMPANY}` }]}>
					<Input placeholder='XYZ Limited' />
				</Form.Item>
				<Form.Item
					label={`${TEXTS.LABELS.LAST_NAME} ${TEXTS.LABELS.COMPANY_OWNER}`}
					name='companyAdmin'
					tooltip={TEXTS.TEXT.REQUIRED_FIELD}
					rules={[{ required: true, message: `${TEXTS.MESSAGES.NAME_REQUIRED_MESSAGE} ${TEXTS.LABELS.LAST_NAME} ${TEXTS.LABELS.COMPANY_OWNER}` }]}>
					<Input placeholder='Admin' />
				</Form.Item>
				<Form.Item label={TEXTS.LABELS.PHONE} name='phone'>
					<Input placeholder='+01 454884657' />
				</Form.Item>

				<Form.Item label='Email' name='email'>
					<Input placeholder='xyz@xyz.com' />
				</Form.Item>

				<Form.Item label='IDNO' name='website'>
					<Input placeholder='01234567890123' />
				</Form.Item>

				<Form.Item label='Cod TVA' name='linkedin'>
					<Input placeholder='Codul' />
				</Form.Item>
				<div className="text-xl pb-2">
					Richizite Bancare:
				</div>
				<Form.Item label='IBAN' name='twitter'>
					<Input placeholder='MD77AG00000000000002323232' />
				</Form.Item>

				<Form.Item label='BIC' name='instagram'>
					<Input placeholder='XXXXYYZZ123' />
				</Form.Item>

				<Form.Item label='Denumirea Băncii' name='facebook'>
					<Input placeholder='MICB' />
				</Form.Item>

				<Form.Item label={TEXTS.TEXT.BILLING_STREET} name='billingStreet'>
					<Input placeholder=' Xyz road ' />
				</Form.Item>

				<Form.Item label={TEXTS.TEXT.BILLING_CITY} name='billingCity'>
					<Input placeholder='LA' />
				</Form.Item>

				<Form.Item label={TEXTS.TEXT.BILLING_ZIP_CODE} name='billingZipCode'>
					<Input placeholder='45004' />
				</Form.Item>
				<Form.Item label={TEXTS.TEXT.SHIPPING_STREET} name='shippingStreet'>
					<Input placeholder=' zyx road' />
				</Form.Item>

				<Form.Item label={TEXTS.TEXT.SHIPPING_CITY} name='shippingCity'>
					<Input placeholder='Mancester City' />
				</Form.Item>

				<Form.Item label={TEXTS.TEXT.SHIPPING_ZIP_CODE} name='shippingZipCode'>
					<Input placeholder='45871' />
				</Form.Item>
				<Form.Item>
					<div className='flex items-center gap-2'>
						<Button
							size={"large"}
							htmlType='submit'
							type='primary'
							loading={companyLoading}>
							{TEXTS.TEXT.CREATE}
						</Button>
						{/* 	<button
							className='py-1 px-3 text-lg border border-orange-500 rounded cursor-pointer'
							type='submit'>
							Create and add another
						</button> */}
						<Button
							size={"large"}
							htmlType='submit'
							type='danger'
							onClick={onCancel}>
							{TEXTS.BUTTON_TEXT.CLOSE_BTN}
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
}
